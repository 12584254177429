export default {
    components: {},
    props: {},
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        successMessages: [],
        workorderNumber: '',
        isUploadingImage: false
    };
}

function getComputed(){
    return {};
}

function created(){}

function getMethods(){
    return {
        displayErrorMessage,
        uploadImage
    };

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function uploadImage(event){
        const vm = this;
        vm.isUploadingImage = true;
        const formData = new FormData();
        const files = event.target.files || event.dataTransfer.files;
        const photo = files[0];
        formData.append('photo', photo);
        formData.append('send_email', true);

        return Vue.appApi().authorized().workorders(vm.workorderNumber).storeWarrantyImage(formData).then(displaySuccess).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function displaySuccess(response){
            const successMessage = 'Successfully uploaded image for ' + vm.workorderNumber;
            vm.successMessages.push(successMessage);
            setTimeout(removeMessage, 3000);

            function removeMessage(){
                const messageIndex = vm.successMessages.indexOf(successMessage);
                if(messageIndex >= 0){
                    vm.successMessages.splice(messageIndex, 1);
                }
            }
        }

        function resetLoadingState(){
            vm.isUploadingImage = false;
        }
    }
}
