export default {
    props: {
        fleetCode: {
            type: String,
            required: true,
        }
    },
    data,
    watch: getWatches(),
    created,
    methods: getMethods(),
};

function data(){
    return {
        validationErrors: {},
        errorMessages: [],
        successMessages: [],
        newCheckinRecord: null,
        checkinRecords: [],
        isLoadingCheckins: false,
        checkinStatusOptions: [
            { value: 'N/A', text: 'N/A' },
            { value: 'OK', text: 'OK' },
            { value: 'Not Ok', text: 'Not Ok' },
        ]
    };
}

function getWatches(){
    return {
        fleetCode,
    };

    function fleetCode(){
        const vm = this;
        vm.getCheckinRecords();
    }
}

function created(){
    const vm = this;
    vm.initializeNewCheckinRecord();
    vm.getCheckinRecords();
}

function getMethods(){
    return {
        initializeNewCheckinRecord,
        displayErrorMessage,
        getCheckinRecords,
        saveCheckinRecord,
    };
    function initializeNewCheckinRecord(){
        const vm = this;
        vm.newCheckinRecord = {
            date: '',
            hours: '',
            fuel: vm.checkinStatusOptions[0].value,
            batteries: vm.checkinStatusOptions[0].value,
            hyd_oil: vm.checkinStatusOptions[0].value,
            eng_oil: vm.checkinStatusOptions[0].value,
            radiator: vm.checkinStatusOptions[0].value,
            tires: vm.checkinStatusOptions[0].value,
            glass: vm.checkinStatusOptions[0].value,
            op_manual: vm.checkinStatusOptions[0].value,
            decals: vm.checkinStatusOptions[0].value,
            guardrail: vm.checkinStatusOptions[0].value,
            sheet_metal: vm.checkinStatusOptions[0].value,
            propane: vm.checkinStatusOptions[0].value,
            overhead_guard: vm.checkinStatusOptions[0].value,
            op_controls: vm.checkinStatusOptions[0].value,
            safety_devices: vm.checkinStatusOptions[0].value,
            lug_nuts: vm.checkinStatusOptions[0].value,
            notes: '',
        };
    }
    function displayErrorMessage(error){
        const vm = this;
        if(error){
            if(error.status === 422 && error.data.errors){
                vm.validationErrors = error.data.errors;
            } else {
                const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
                vm.errorMessages.push(errorMessage);
            }
        }
    }
    function getCheckinRecords(){
        const vm = this;
        vm.isLoadingCheckins = true;
        Vue.appApi().authorized().rentalCheckins(vm.fleetCode).getCheckinRecords()
            .then(setCheckinRecords)
            .catch(vm.displayErrorMessage)
            .finally(stopSpinner);

        function setCheckinRecords(response){
            vm.checkinRecords = response.data.data;
        }

        function stopSpinner(){
            vm.isLoadingCheckins = false;
        }
    }
    function saveCheckinRecord(){
        const vm = this;
        const payload = {
            ...vm.newCheckinRecord
        };
        vm.errorMessages = [];
        vm.successMessages = [];
        vm.validationErrors = {};
        Vue.appApi().authorized().rentalCheckins(vm.fleetCode).storeCheckinRecord(payload)
            .then(clearCheckinRecord)
            .catch(alertError);

        function clearCheckinRecord(){
            vm.successMessages.push('successfully saved');
            vm.getCheckinRecords();
            vm.initializeNewCheckinRecord();
        }

        function alertError(error){
            vm.displayErrorMessage('error storing rental checkin');
            vm.displayErrorMessage(error);
        }
    }
}
