const state = {
    submittedLeads: []
};

export default {
    namespaced: true,
    state,
    mutations: getMutations(),
    actions: getActions(),
    getters: getGetters(),
    modules: {}
};

function getActions(){

    return {
        NOTIFY_LEAD_SUBMISSION: notifyLeadSubmission,
    };
    function notifyLeadSubmission({ commit }, lead){
        commit('SET_SUBMITTED_LEAD', lead);
    }
}

function getGetters(){}

function getMutations(){

    return {
        SET_SUBMITTED_LEAD: setSubmittedLead,
    };
    function setSubmittedLead(state, lead){
        state.submittedLeads = lead;
    }
}
