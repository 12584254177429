import { Pie } from 'vue-chartjs';

export default {
    extends: Pie,
    props: {
        workOrderLines: {
            type: Array,
            required: true
        },
        chartId: {
            type: String,
            default: 'job-code-pie-chart'
        }
    },
    data,
    computed: getComputed(),
    mounted,
    methods: getMethods()
};

function data(){
    const vm = this;
    return {
        chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                datalabels: {
                    formatter: (value, ctx) => {
                        const dataArr = ctx.chart.data.datasets[0].data;
                        const sum = dataArr.reduce((sum, data) => sum + data, 0);
                        return ((value * 100) / sum).toFixed(1) + '%';
                    },
                    color: '#fff',
                }
            },
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data){
                        var label = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || '0.00';
                        return ` $${label.toFixed(2)}`;
                    }
                }
            },
            onClick: vm.handlePieChartClick
        },
        jobCodeDetails: {
            10: { label: 'Engine', color: '#458EDE', relatedJobCodes: ['10'] },
            18: { label: 'Transmission', color: '#6AD5D5', relatedJobCodes: ['18'] },
            23: { label: 'Electrical Systems', color: '#FFB617', relatedJobCodes: ['23'] },
            30: { label: 'Hydraulics', color: '#DE6445', relatedJobCodes: ['30'] },
            43: { label: 'Steering System', color: '#00D19D', relatedJobCodes: ['43'] },
            47: { label: 'Drive Axle', color: '#31AE80', relatedJobCodes: ['47'] },
            49: { label: 'Tires', color: '#C0D22F', relatedJobCodes: ['49'] },
            50: { label: 'Mast/Boom', color: '#133CF2', relatedJobCodes: ['50'] },
            60: { label: 'Painting', color: '#FF346C', relatedJobCodes: ['60'] },
            80: { label: 'Electrical – Electric Truck', color: '#C42348', relatedJobCodes: ['80'] },
            92: { label: 'Planned Maintenance', color: '#09EAF2', relatedJobCodes: ['92', 'PM'] },
            94: { label: 'Maintenance – Shop/Yard Internal WO only', color: '#8A179E', relatedJobCodes: ['94'] },
            97: { label: 'Misuse and Abuse', color: '#A572D4', relatedJobCodes: ['97'] },
            99: { label: 'Pickup/Delivery Travel/Parts Run', color: '#CC95BD', relatedJobCodes: ['99'] },
            106: { label: 'Condition Inspections', color: '#A7DCE2', relatedJobCodes: ['106'] },
            107: { label: 'Fabrication', color: '#CCB7E9', relatedJobCodes: ['107'] },
            108: { label: 'New Truck Checkout', color: '#FFBBA8', relatedJobCodes: ['108'] },
            119: { label: 'Demo', color: '#A6ADDB', relatedJobCodes: ['119'] },
            122: { label: 'Training', color: '#D4AF37', relatedJobCodes: ['122'] },
            125: { label: 'Rental Check-in Only', color: '#C0C0C0', relatedJobCodes: ['125'] },
            126: { label: 'CO Testing HUL Building Only', color: '#CD7F32', relatedJobCodes: ['126'] },
        }
    };
}

function getComputed(){
    return {
        workOrderLinesByJobCode(){
            const vm = this;
            return vm.workOrderLines.reduce(groupByJobCode, {});
            function groupByJobCode(accumulator, workOrderLine){
                const jobCodeDetailsEntry = Object.entries(vm.jobCodeDetails).find(([jobCode, details]) => details.relatedJobCodes.includes(workOrderLine.job_code));
                const jobCode = jobCodeDetailsEntry ? jobCodeDetailsEntry[0] : workOrderLine.job_code;
                if(accumulator[jobCode]){
                    accumulator[jobCode].push(workOrderLine);
                } else {
                    accumulator[jobCode] = [workOrderLine];
                }
                return accumulator;
            }
        },
        sortedJobCodes(){
            const vm = this;
            return Object.keys(vm.jobCodeDetails).sort((a, b) => a - b);
        }
    };
}

function mounted(){
    const vm = this;
    vm.renderChart({
        labels: vm.getLabels(),
        datasets: [
            {
                backgroundColor: vm.getBackgroundColors(),
                data: vm.getChartData()
            }
        ]
    }, vm.chartOptions);
}

function getMethods(){
    return {
        getLabels,
        getBackgroundColors,
        getChartData,
        handlePieChartClick
    };

    function getLabels(){
        const vm = this;
        const labels = vm.sortedJobCodes.reduce((accumulator, jobCode) => {
            const isLabelNeeded = vm.workOrderLinesByJobCode[jobCode] && vm.workOrderLinesByJobCode[jobCode].length;
            if(isLabelNeeded){
                accumulator.push(vm.jobCodeDetails[jobCode].label);
            }
            return accumulator;
        }, []);
        return labels;
    }

    function getBackgroundColors(){
        const vm = this;
        const backgroundColors = vm.sortedJobCodes.reduce((accumulator, jobCode) => {
            const isColorNeeded = vm.workOrderLinesByJobCode[jobCode] && vm.workOrderLinesByJobCode[jobCode].length;
            if(isColorNeeded){
                accumulator.push(vm.jobCodeDetails[jobCode].color);
            }
            return accumulator;
        }, []);
        return backgroundColors;
    }

    function getChartData(){
        const vm = this;
        const chartData = vm.sortedJobCodes.reduce((accumulator, jobCode) => {
            const hasDataForCode = vm.workOrderLinesByJobCode[jobCode] && vm.workOrderLinesByJobCode[jobCode].length;
            if(hasDataForCode){
                const data = vm.workOrderLinesByJobCode[jobCode].reduce((totalAmount, { Amount }) => totalAmount + (Amount || 0), 0);
                accumulator.push(data);
            }
            return accumulator;
        }, []);
        return chartData;
    }

    function handlePieChartClick(event, chartElements){
        const vm = this;
        const slice = chartElements[0];
        const label = slice && slice._view && slice._view.label;
        const jobCode = label ? Object.keys(vm.jobCodeDetails).find(matchJobCodeLabel) : null;
        const relatedJobCodes = jobCode ? vm.jobCodeDetails[jobCode].relatedJobCodes : null;
        vm.$emit('slice-clicked', relatedJobCodes);

        function matchJobCodeLabel(jobCode){
            return vm.jobCodeDetails[jobCode].label === label;
        }
    }
}
