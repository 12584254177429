import fileDownload from 'js-file-download';

export default {
    components: {},
    props: {},
    data,
    computed: getComputed(),
    watch: getWatches(),
    created,
    beforeRouteUpdate,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        validationErrors: {},
        mode: 'delivery_request',
        caseSearchString: '',
        isSearchingCases: false,
        isCaseAutocompleteDisabled: false,
        caseAutocompleteCancelToken: null,
        caseAutoCompleteSource: [],
        salesProcess: null,
        locationOptions: [
            { property: 'MP', value: 'Maple Plain' },
            { property: 'SF', value: 'Sioux Falls' },
            { property: 'GR', value: 'Grand Rapids' },
            { property: 'SC', value: 'St. Cloud' },
            { property: 'IA', value: 'Iowa' },
            { property: 'Fargo', value: 'Fargo' },
            { property: 'Minot', value: 'Minot' },
            { property: 'Williston', value: 'Williston' },
            { property: 'Wisconsin', value: 'Wisconsin' },
        ],
        notesOptions: [
            { property: 'prep_sale', value: 'Prep for Sale' },
            { property: 'prep_demo', value: 'Prep for Demo' },
            { property: 'prep_rpo', value: 'Prep for RPO' },
            { property: 'as_is', value: 'Sold As Is' },
            { property: 'full_bat', value: 'Fully Charge Battery' },
            { property: 'inst_bat', value: 'Install Battery' },
            { property: 'clean_wash', value: 'Clean Wash as needed' },
            { property: 'new', value: 'Unit needs to look New – Advise' },
        ],
        departmentOptions: [
            { property: 'Sales', value: 'Sales' },
            { property: 'Used', value: 'Used' },
            { property: 'Service', value: 'Service' },
            { property: 'Trucking', value: 'Trucking' },
            { property: 'Rental', value: 'Rental' },
            { property: 'Parts', value: 'Parts' },
        ],
        reportOfSale: null,
        isSavingSalesProcess: false,
        isPreparingSalesProcess: false,
        isLoadingReportOfSale: false,
        isSavingReportOfSale: false,
        isFetchingNutshellData: false,
        isDownloadingDeliveryRequest: false,
        isUploadingDeliveryRequest: false,
        isDownloadingReportOfSale: false,
        isUploadingReportOfSale: false,
    };
}

function getComputed(){
    return {
        isLoading(){
            const vm = this;
            return vm.isSavingSalesProcess ||
                vm.isPreparingSalesProcess ||
                vm.isLoadingReportOfSale ||
                vm.isSavingReportOfSale ||
                vm.isFetchingNutshellData ||
                vm.isUploadingReportOfSale ||
                vm.isUploadingDeliveryRequest ||
                vm.isDownloadingDeliveryRequest ||
                vm.isDownloadingReportOfSale;
        },
        loadingMessage(){
            const vm = this;
            let loadingMessage = '';
            if(vm.isSavingSalesProcess || vm.isSavingReportOfSale){
                loadingMessage = 'Saving...';
            } else if(vm.isFetchingNutshellData){
                loadingMessage = 'Fetching data from Nutshell...';
            } else if(vm.isPreparingSalesProcess || vm.isLoadingReportOfSale){
                loadingMessage = 'Preparing sales data...';
            } else if(vm.isUploadingReportOfSale || vm.isUploadingDeliveryRequest){
                loadingMessage = 'Uploading document to Nutshell...';
            } else if(vm.isDownloadingDeliveryRequest || vm.isDownloadingReportOfSale){
                loadingMessage = 'Downloading document...';
            }
            return loadingMessage;
        },
        deliveryRequestTitle(){
            const vm = this;
            let title = '';
            if(vm.salesProcess && vm.salesProcess.lead_source === 'erp'){
                title = vm.salesProcess.casenumber;
            } else if(vm.salesProcess && vm.salesProcess.lead_source === 'nutshell'){
                title = 'Nutshell ' + vm.salesProcess.nutshell_name;

            }
            return title;
        },
        reportOfSaleTitle(){
            const vm = this;
            let title = '';
            if(vm.reportOfSale && vm.reportOfSale.lead_source === 'erp'){
                title = vm.reportOfSale.caseNumber;
            } else if(vm.reportOfSale && vm.reportOfSale.lead_source === 'nutshell'){
                title = 'Nutshell ' + vm.salesProcess.nutshell_name;
            }
            return title;
        }
    };
}

function getWatches(){
    return {
        caseSearchString,
    };

    function caseSearchString(searchString){
        const vm = this;
        if(vm.isCaseAutocompleteDisabled){
            vm.isCaseAutocompleteDisabled = false;
            vm.caseAutoCompleteSource = [];
            return;
        }
        if(searchString.length >= 2){
            vm.autocompleteCaseSearch(searchString);
        } else {
            vm.cancelCaseAutocomplete();
        }
    }
}

function created(){
    const vm = this;
    const nutshellId = vm.$route.query.nutshell_id || '';
    vm.resetAllData(nutshellId);
}
function beforeRouteUpdate(to, from, next){
    const vm = this;
    const nutshellId = to.query.nutshell_id || '';
    vm.resetAllData(nutshellId);
    next();
}

function getMethods(){
    return {
        displayErrorMessage,
        autocompleteCaseSearch,
        cancelCaseAutocomplete,
        searchCaseAndPrepareData,
        prepareSalesProcess,
        saveSalesProcess,
        saveAndDownloadDeliveryRequest,
        uploadDeliveryRequestToNutshell,
        prepareReportOfSale,
        saveReportOfSale,
        saveAndDownloadReportOfSale,
        uploadReportOfSaleToNutshell,
        updateLeaseValues,
        calculateInvoiceAmount,
        calculateTotals,
        resetAllData,
        initializeSalesProcessFromNutshell
    };

    function displayErrorMessage(error){
        const vm = this;
        const isValidationError = error && error.status === 422 && error.data.errors;
        if(isValidationError){
            vm.validationErrors = error.data.errors;
        } else {
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function autocompleteCaseSearch(searchString){
        const vm = this;
        vm.isSearchingCases = true;
        if(vm.caseAutocompleteCancelToken){
            vm.caseAutocompleteCancelToken.cancel('request cancelled');
        }
        const cancelToken = window.axios.CancelToken.source();
        vm.caseAutocompleteCancelToken = cancelToken;
        const query = {
            search_string: searchString,
            search_type: 'company_name'
        };
        return Vue.appApi().authorized().cases().casesNew().searchHulCasesNew(query, cancelToken.token).then(displaySearchResults).catch(handleError);

        function displaySearchResults(response){
            vm.caseAutocompleteCancelToken = null;
            vm.caseAutoCompleteSource = response.data;
            vm.isSearchingCases = false;
        }
        function handleError(error){
            if(error.message !== 'request cancelled'){
                vm.isSearchingCases = false;
                vm.displayErrorMessage(error);
            }
        }
    }

    function cancelCaseAutocomplete(){
        const vm = this;
        if(vm.caseAutocompleteCancelToken){
            vm.caseAutocompleteCancelToken.cancel('request cancelled');
        }
        vm.caseAutoCompleteSource = [];
        vm.isSearchingCases = false;
    }

    function searchCaseAndPrepareData(){
        const vm = this;
        if(!vm.caseSearchString){
            vm.errorMessages = ['Please provide a case number.'];
        } else {
            vm.prepareSalesProcess(vm.caseSearchString, 'erp');
        }
    }

    function prepareSalesProcess(leadId, leadSource = 'erp'){
        const vm = this;
        vm.errorMessages = [];
        vm.isPreparingSalesProcess = true;
        vm.mode = 'delivery_request';
        return Vue.appApi().authorized().sales().prepareSalesProcessForLead({ lead_id: leadId, lead_source: leadSource })
            .then(setSalesProcess)
            .catch(vm.displayErrorMessage)
            .finally(resetLoadingState);
        function setSalesProcess({ data }){
            data.locations = data.locations || {};
            data.departments = data.departments || {};
            data.notes_1 = data.notes_1 || {};
            vm.salesProcess = data;
        }
        function resetLoadingState(){
            vm.isPreparingSalesProcess = false;
        }
    }

    function saveSalesProcess(){
        const vm = this;
        vm.errorMessages = [];
        vm.isSavingSalesProcess = true;
        return Vue.appApi().authorized().sales().storeSalesProcess(vm.salesProcess)
            .then(setSalesProcess)
            .finally(resetLoadingState);
        function setSalesProcess({ data }){
            data.locations = data.locations || {};
            data.departments = data.departments || {};
            data.notes_1 = data.notes_1 || {};
            vm.salesProcess = data;
        }
        function resetLoadingState(){
            vm.isSavingSalesProcess = false;
        }
    }

    function saveAndDownloadDeliveryRequest(){
        const vm = this;
        vm.errorMessages = [];
        vm.isDownloadingDeliveryRequest = true;
        return vm.saveSalesProcess()
            .then(downloadDeliveryRequest)
            .catch(vm.displayErrorMessage)
            .finally(resetLoadingState);

        function downloadDeliveryRequest(){
            const query = {
                document_type: 'get_ready',
                lead_id: vm.salesProcess.casenumber,
                lead_source: vm.salesProcess.lead_source
            };
            return Vue.appApi().authorized().sales().downloadSalesDocument(query).then(downloadFile);

            function downloadFile(response){
                const contentDisposition = response.headers['content-disposition'];
                const sentFileName = contentDisposition ? contentDisposition.split('filename=').pop() : null;
                const filename = sentFileName || 'GetReady.docx';
                fileDownload(response.data, filename, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
            }
        }
        function resetLoadingState(){
            vm.isDownloadingDeliveryRequest = false;
        }
    }

    function uploadDeliveryRequestToNutshell(){
        const vm = this;
        vm.errorMessages = [];
        vm.isUploadingDeliveryRequest = true;
        return vm.saveSalesProcess()
            .then(uploadDeliveryRequest)
            .catch(vm.displayErrorMessage)
            .finally(resetLoadingState);

        function uploadDeliveryRequest(){
            const query = {
                document_type: 'get_ready',
                lead_id: vm.salesProcess.nutshell_id,
                lead_source: vm.salesProcess.lead_source
            };
            return Vue.appApi().authorized().sales().nutshell().uploadSalesDocument(query);
        }
        function resetLoadingState(){
            vm.isUploadingDeliveryRequest = false;
        }
    }

    function prepareReportOfSale(){
        const vm = this;
        vm.errorMessages = [];
        vm.isLoadingReportOfSale = true;
        const query = {
            lead_source: vm.salesProcess.lead_source,
            lead_id: vm.salesProcess.lead_source === 'nutshell' ? vm.salesProcess.nutshell_id : vm.salesProcess.casenumber
        };
        return Vue.appApi().authorized().sales().prepareReportOfSaleForLead(query)
            .then(setReportOfSale)
            .catch(vm.displayErrorMessage)
            .finally(resetLoadingState);

        function setReportOfSale({ data }){
            vm.reportOfSale = data;
            vm.mode = 'report_of_sale';
        }
        function resetLoadingState(){
            vm.isLoadingReportOfSale = false;
        }
    }

    function saveReportOfSale(){
        const vm = this;
        vm.isSavingReportOfSale = true;
        return Vue.appApi().authorized().sales().storeReportOfSale(vm.reportOfSale)
            .then(setReportOfSale)
            .finally(resetLoadingState);
        function setReportOfSale({ data }){
            vm.reportOfSale = data;
        }
        function resetLoadingState(){
            vm.isSavingReportOfSale = false;
        }
    }

    function saveAndDownloadReportOfSale(){
        const vm = this;
        vm.errorMessages = [];
        vm.isDownloadingReportOfSale = true;
        return vm.saveReportOfSale()
            .then(downloadReportOfSale)
            .catch(vm.displayErrorMessage)
            .finally(resetLoadingState);

        function downloadReportOfSale(){
            const query = {
                document_type: 'report_of_sale',
                lead_id: vm.reportOfSale.caseNumber,
                lead_source: vm.reportOfSale.lead_source
            };
            return Vue.appApi().authorized().sales().downloadSalesDocument(query).then(downloadFile);

            function downloadFile(response){
                const contentDisposition = response.headers['content-disposition'];
                const sentFileName = contentDisposition ? contentDisposition.split('filename=').pop() : null;
                const filename = sentFileName || 'ReportOfSale.docx';
                fileDownload(response.data, filename, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
            }
        }
        function resetLoadingState(){
            vm.isDownloadingReportOfSale = false;
        }
    }

    function uploadReportOfSaleToNutshell(){
        const vm = this;
        vm.errorMessages = [];
        vm.isUploadingReportOfSale = true;
        return vm.saveReportOfSale()
            .then(uploadReportOfSale)
            .catch(vm.displayErrorMessage)
            .finally(resetLoadingState);

        function uploadReportOfSale(){
            const query = {
                document_type: 'report_of_sale',
                lead_id: vm.reportOfSale.nutshell_id,
                lead_source: vm.reportOfSale.lead_source
            };
            return Vue.appApi().authorized().sales().nutshell().uploadSalesDocument(query);
        }
        function resetLoadingState(){
            vm.isUploadingReportOfSale = false;
        }
    }

    function updateLeaseValues(){
        const vm = this;
        if(vm.reportOfSale.sale_type === 'cash_sale'){
            vm.reportOfSale.lease_terms = 'Blank';
            vm.reportOfSale.lease_type = 'Blank';
            vm.reportOfSale.principal = 0;
            vm.reportOfSale.interest_rate = 0;
            vm.reportOfSale.monthly_payment = 0;
            vm.reportOfSale.terms = 0;
            vm.reportOfSale.residual_amount = 0;
        }
    }

    function calculateInvoiceAmount(){
        const vm = this;
        vm.reportOfSale.invoice_amount = Number(vm.reportOfSale.sell_price || 0) - Number(vm.reportOfSale.less_tradein || 0);
    }

    function calculateTotals(){
        const vm = this;
        const total_cost_calculated = Number(vm.reportOfSale.equip_cost || 0) +
            Number(vm.reportOfSale.freight_in || 0) +
            Number(vm.reportOfSale.prep || 0) +
            Number(vm.reportOfSale.freight_out || 0) +
            Number(vm.reportOfSale.attach_cost || 0) +
            Number(vm.reportOfSale.battery_cost || 0) +
            Number(vm.reportOfSale.charger_cost || 0) +
            Number(vm.reportOfSale.shop_wo || 0) +
            Number(vm.reportOfSale.shop_wo2 || 0) +
            Number(vm.reportOfSale.addons || 0) +
            Number(vm.reportOfSale.estimated_shop || 0) +
            Number(vm.reportOfSale.other_cost || 0);

        const margin_calculated = Number(vm.reportOfSale.sell_price || 0) - total_cost_calculated;
        const sales_comm_calculated = margin_calculated * 0.45;
        const total_comm_calculated = sales_comm_calculated + Number(vm.reportOfSale.in_house_demo || 0);

        vm.reportOfSale.total_cost = total_cost_calculated;
        vm.reportOfSale.margin = margin_calculated;
        vm.reportOfSale.sales_comm = sales_comm_calculated;
        vm.reportOfSale.total_comm = total_comm_calculated;
    }

    function resetAllData(nutshellId = ''){
        const vm = this;
        const originalData = data();
        Object.keys(originalData).forEach(resetVMData);
        if(nutshellId){
            vm.initializeSalesProcessFromNutshell(nutshellId);
        }
        function resetVMData(key){
            vm[key] = originalData[key];
        }
    }

    function initializeSalesProcessFromNutshell(nutshellId = ''){
        const vm = this;
        if(nutshellId){
            vm.isFetchingNutshellData = true;
            vm.nutshellId = parseInt(nutshellId);
            validateNutshellId().then(makeInitializationRequest).catch(vm.displayErrorMessage).finally(resetLoadingState);
        }

        function validateNutshellId(){
            const nutshellIdType = nutshellId.split('-')[1];
            const canInitializeSalesProcess = nutshellIdType === 'leads';
            if(!canInitializeSalesProcess){
                const errorMessage = `Cannot create Delivery Request for Nutshell ${nutshellIdType}, currently only leads are supported.`;
                vm.$bvModal.msgBoxConfirm(errorMessage, {
                    title: 'Unsupported Nutshell Integration',
                    footerClass: 'justify-content-between',
                    cancelTitle: 'Return to Dashboard',
                    okTitle: 'Proceed without Nutshell',
                    noCloseOnBackdrop: true,
                    centered: true
                }).then(resetData);
                return Promise.reject('Unable to initialize sales process for provided Nutshell id');
            } else {
                return Promise.resolve();
            }

            function resetData(resetForm){
                if(resetForm){
                    vm.$router.push({ name: vm.$route.name });
                } else {
                    vm.$router.push({ name: 'dashboard' });
                }
            }
        }

        function makeInitializationRequest(){
            return vm.prepareSalesProcess(vm.nutshellId, 'nutshell');
        }

        function resetLoadingState(){
            vm.isFetchingNutshellData = false;
        }
    }
}
