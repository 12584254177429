export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        workorderNumber: {
            type: String,
            required: true,
        },
        warrantiesType: {
            type: String,
            required: true,
            validator: function(value){
                return ['internal', 'external', ''].includes(value);
            }
        }
    },
    data,
    watch: getWatches(),
    created,
    methods: getMethods(),
};

function data(){
    return {
        isLoadingWarranties: false,
        localWarranties: [],
        tableColumns: [
            {
                key: 'Description',
                label: 'Lines'
            }
        ],
    };
}

function getWatches(){
    return {
        workorderNumber,
    };

    function workorderNumber(){
        const vm = this;
        vm.getWorkorderWarranties();
    }
}

function created(){
    const vm = this;
    vm.getWorkorderWarranties();
}

function getMethods(){
    return {
        getWorkorderWarranties,
        emitError
    };

    function getWorkorderWarranties(){
        const vm = this;
        vm.isLoadingWarranties = true;
        const queryObject = {
            type: vm.warrantiesType
        };
        return Vue.appApi().authorized().workorders(vm.workorderNumber).getWarranties(queryObject)
            .then(setLocalWarranties)
            .catch(vm.emitError)
            .finally(stopSpinner);

        function setLocalWarranties(data){
            vm.localWarranties = data.data;
            vm.$emit('loading-complete');
        }

        function stopSpinner(){
            vm.isLoadingWarranties = false;
        }
    }

    function emitError(error){
        const vm = this;
        vm.$emit('error', error);
    }
}
