export default {
    props: {
        workorders: {
            type: Array,
            required: true,
        },
        differenceProperty: {
            type: String,
            default: 'numberOfWorkingDays'
        },
        excludedWorkorderNumbers: {
            type: Array,
            required: true
        }
    },
    data,
    watch: getWatches(),
    methods: getMethods(),
    mounted
};

function data(){
    return {
        localWorkorders: [],
        tableColumns: [
            {
                key: 'selectChecked',
                label: 'Remove?'
            },
            {
                key: 'search',
                label: 'More Info',
            },
            {
                key: 'No_',
                label: 'Work Order',
                sortable: true
            },
            {
                key: 'numberOfWorkingDays',
                label: 'Difference',
                sortable: true,
                sortByFormatted: true,
                formatter: (value, key, workorder) => {
                    const vm = this;
                    return workorder[vm.differenceProperty];
                }
            },
        ],
    };
}

function getWatches(){
    return {
        workorders,
    };

    function workorders(){
        const vm = this;
        vm.initializeLocalWorkorders();
    }
}

function mounted(){
    const vm = this;
    vm.initializeLocalWorkorders();
}

function getMethods(){
    return {
        initializeLocalWorkorders,
        excludeWorkorder,
        isWorkorderExcluded,
    };

    function initializeLocalWorkorders(){
        const vm = this;
        vm.localWorkorders = JSON.parse(JSON.stringify(vm.workorders));
    }

    function excludeWorkorder(workorder){
        const vm = this;
        vm.$emit('workorder-excluded', workorder.No_);
    }

    function isWorkorderExcluded(workorder){
        const vm = this;
        return workorder && vm.excludedWorkorderNumbers.includes(workorder.No_) ? 'bg-danger' : '';
    }
}
