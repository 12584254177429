import CustomerDetail from 'vue_root/components/customer-detail/customer-detail.vue';
import EquipmentDetail from 'vue_root/components/equipment-detail/equipment-detail.vue';
import EquipmentSummary from 'vue_root/components/equipment-summary/equipment-summary.vue';
import RentalCheckin from 'vue_root/components/rental-checkin/rental-checkin.vue';

export default {
    components: {
        CustomerDetail,
        EquipmentDetail,
        EquipmentSummary,
        RentalCheckin,
    },
    props: {},
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        validationErrors: [],
        errorMessages: [],
        showMenu: true,
        equipmentPmsTotal: 0,
        equipmentPmsData: null,
        equipmentWorkordersTotal: 0,
        equipmentWorkordersData: null,
        workordersFleetCode: null,
        warrantyWorkorderNumber: null,
        linesWorkorderNumber: null,
        fleetCode: '',
        isLoadingEquipment: false,
        equipment: null,
        barCode: null,
        meterReadings: [],
        customer: null,
    };
}

function getComputed(){
    return {};
}

function created(){}

function getMethods(){
    return {
        displayErrorMessage,
        loadEquipmentData,
        findEquipment,
        getEquipmentWorkorders,
        getEquipmentPms,
        getEquipmentMeterReadings,
        getEquipmentBarCode,
        getWorkorderWarrantiesAndLines,
    };

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function loadEquipmentData(){
        const vm = this;
        vm.errorMessages = [];
        vm.isLoadingEquipment = true;
        vm.fleetCode = vm.fleetCode.toUpperCase();
        vm.workordersFleetCode = vm.fleetCode;
        const apiRequests = [
            vm.findEquipment(),
            vm.getEquipmentWorkorders(),
            vm.getEquipmentPms(),
            vm.getEquipmentMeterReadings(),
            vm.getEquipmentBarCode(),
        ];

        Promise.all(apiRequests).then(setEquipmentData).catch(vm.displayErrorMessage).finally(stopSpinner);

        function setEquipmentData(){
            if(vm.equipmentWorkordersData.length){
                vm.getWorkorderWarrantiesAndLines(vm.equipmentWorkordersData[0].WO_Number);
            }
            if(vm.meterReadings.length){
                return getCustomerData(vm.meterReadings[0].Source_No);
            }

            function getCustomerData(workorderNumber){
                Vue.appApi().authorized().workorders(workorderNumber).getWorkorderCustomer().then(setCustomerData);

                function setCustomerData(response){
                    vm.customer = response.data;
                }
            }
        }

        function stopSpinner(){
            vm.isLoadingEquipment = false;
        }
    }

    function findEquipment(){
        const vm = this;
        return Vue.appApi().authorized().rentalCheckins(vm.fleetCode).findByFleetCode()
            .then(setEquipment);
        function setEquipment(response){
            vm.equipment = response.data;
        }
    }

    function getEquipmentWorkorders(){
        const vm = this;
        return Vue.appApi().authorized().rentalCheckins(vm.fleetCode).getWorkorders()
            .then(setEquipmentWorkorders);
        function setEquipmentWorkorders(response){
            vm.equipmentWorkordersData = response.data.data;
            vm.equipmentWorkordersTotal = response.data.total;
        }
    }

    function getEquipmentPms(){
        const vm = this;
        return Vue.appApi().authorized().rentalCheckins(vm.fleetCode).getPms()
            .then(setEquipmentPms);
        function setEquipmentPms(response){
            vm.equipmentPmsData = response.data.data;
            vm.equipmentPmsTotal = response.data.total;
        }
    }

    function getEquipmentMeterReadings(){
        const vm = this;
        return Vue.appApi().authorized().rentalCheckins(vm.fleetCode).getMeterReadings()
            .then(setMeterReadings);
        function setMeterReadings(response){
            vm.meterReadings = response.data.data;
        }
    }

    function getEquipmentBarCode(){
        const vm = this;
        return Vue.appApi().authorized().rentalCheckins(vm.fleetCode).getBarCode()
            .then(setBarCode);
        function setBarCode(response){
            vm.barCode = response.data.barCode;
        }
    }

    function getWorkorderWarrantiesAndLines(workorderNumber){
        const vm = this;
        vm.warrantyWorkorderNumber = workorderNumber;
        vm.linesWorkorderNumber = workorderNumber;
        vm.scrollTo('warrantyLines');
    }
}
