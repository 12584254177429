import RentalPagesBaseComponent from './rental-pages-base.vue';
import RedtagComponent from './rental-redtag/redtag/redtag.vue';
import RedtagBaseComponent from './rental-redtag/rental-redtag-base.vue';
import L4Component from './l4/l4.vue';
import RentalStatusBaseComponent from './rental-status/rental-status-base.vue';
import StatusComponent from './rental-status/status/status.vue';
import RentalInvoicingComponent from './rental-invoicing/rental-invoicing.vue';

export default {
    path: '/rental',
    component: RentalPagesBaseComponent,
    meta: {},
    children: [
        {
            path: 'redtag',
            component: RedtagBaseComponent,
            name: 'rental-redtag-home',
            meta: {
                title: 'Branch Equipment / Red Tag',
                permissions: ['view rental-redtag-page']
            },
            children: [
                {
                    path: ':branch',
                    component: RedtagComponent,
                    name: 'rental-redtag',
                    meta: {
                        title: 'Branch Equipment / Red Tag'
                    }
                },
            ]
        },
        {
            path: 'l4',
            component: L4Component,
            name: 'l4-home',
            meta: {
                title: 'Rental Check-In',
                permissions: ['view rental-checkin-page']
            },
        },
        {
            path: 'rental-status',
            component: RentalStatusBaseComponent,
            name: 'rental-status-home',
            meta: {
                title: 'Rental Status',
                permissions: ['view rental-status-page']
            },
            children: [
                {
                    path: ':branch',
                    component: StatusComponent,
                    name: 'rental-status-view',
                    meta: {
                        title: 'Rental Status'
                    }
                },
            ]
        },
        {
            path: 'invoicing',
            name: 'rental-invoicing',
            component: RentalInvoicingComponent,
            meta: {
                title: 'Rental Invoicing',
                permissions: ['view rental-invoicing-page']
            }
        }
    ]
};
