import AuthorizedComponent from './authorized.vue';
import SubscriberRoutes from './subscriber/subscriber.routes';
import VerificationRequiredComponent from './verification-required/verification-required.vue';
import AcceptInviteComponent from './accept-invite/accept-invite.vue';
import NoAccountComponent from './no-account/no-account.vue';

export default {
    path: '',
    component: AuthorizedComponent,
    meta: {
        requiresAuth: true,
    },
    children: [
        SubscriberRoutes,
        {
            path: 'verification-required',
            name: 'verification-required',
            component: VerificationRequiredComponent,
            meta: {
                title: 'Herc-U-Lift - Verification Required'
            }
        },
        {
            path: 'accept-invite',
            name: 'accept-invite',
            component: AcceptInviteComponent,
            meta: {
                title: 'Herc-U-Lift - Join Account'
            }
        },
        {
            path: 'no-account',
            name: 'no-account',
            component: NoAccountComponent,
            meta: {
                title: 'Herc-U-Lift - No Account'
            }
        },
    ]
};
