import { render, staticRenderFns } from "./shop-base.vue?vue&type=template&id=89ca0f60&scoped=true&"
import script from "./shop-base.controller.js?vue&type=script&lang=js&"
export * from "./shop-base.controller.js?vue&type=script&lang=js&"
import style0 from "./_shop-base.scss?vue&type=style&index=0&id=89ca0f60&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89ca0f60",
  null
  
)

export default component.exports