export default {
    components: {},
    props: {},
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        showMenu: true,
        branch: 'branch_all',
    };
}

function getComputed(){
    return {};
}

function created(){}

function getMethods(){
    return {
        displayErrorMessage,
        loadEquipmentCheckins,
    };

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function loadEquipmentCheckins(){
        const vm = this;
        const routeObject = {
            name: 'rental-status-view',
            params: {
                branch: vm.branch,
            },
        };
        vm.$router.push(routeObject);
    }
}
