import Datepicker from 'vuejs-datepicker';
import CrmNotesTable from './components/crm-notes-table/crm-notes-table.vue';
import CrmCaseNotesTable from './components/crm-case-notes-table/crm-case-notes-table.vue';
import NoteLinesTable from './components/notes-lines-table/notes-lines-table.vue';

export default {
    components: {
        Datepicker,
        CrmNotesTable,
        CrmCaseNotesTable,
        NoteLinesTable
    },
    props: {},
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        validationErrors: {},
        isLoadingCrmNotes: false,
        isLoadingNotesLines: false,
        displayedUser: '',
        crmNotes: null,
        crmCaseNotes: null,
        notesLines: null,
        crmQuery: {
            start_date: '',
            end_date: '',
            user: ''
        },
        userOptions: [
            'ALATZIG',
            'BFLANNIGAN',
            'CARENS',
            'CKOHLER',
            'CWURZER',
            'DTROW',
            'DHELGERSON',
            'DMESSNER',
            'DOESTREICH',
            'EHAMMER',
            'JAKEJ',
            'JBARSNESS',
            'JBECKER',
            'JGUNDERSON',
            'JHOVDE',
            'JHESTER',
            'JHUISKEN',
            'JMCCLOUD',
            'JMCGRATH',
            'JOYEN',
            'JSTEVENS',
            'JUHAN',
            'LHELTEN',
            'MWEBER',
            'RLINDBERG',
            'SMADISION',
            'SVOLKOTRUB',
            'TCHERMAK',
            'TBLACKWELL',
            'TSCHMIDT',
            'TSHOWALTER',
            'TDENNESON',
            'WBOSIER',
        ]
    };
}

function getComputed(){
    return {
        isLoading(){
            const vm = this;
            return vm.isLoadingCrmNotes || vm.isLoadingNotesLines;
        }
    };
}

function created(){
    const vm = this;
    vm.crmQuery.user = vm.userOptions[0];
}

function getMethods(){
    return {
        displayErrorMessage,
        getCrmNotes,
        fetchNoteLines,
        fetchCaseNoteLines,
    };

    function displayErrorMessage(error){
        const vm = this;
        const isValidationError = error.status === 422 && error.data.errors;
        if(isValidationError){
            vm.validationErrors = error.data.errors;
            vm.errorMessages.push('Validation Error. Check inputs and try again.');
        } else {
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function getCrmNotes(){
        const vm = this;
        vm.displayedUser = vm.crmQuery.user;
        vm.isLoadingCrmNotes = true;
        vm.errorMessages = [];
        vm.validationErrors = {};
        vm.crmNotes = null;
        vm.crmCaseNotes = null;
        const apiRequests = [
            Vue.appApi().authorized().crmNotes().getCrmNotes(vm.crmQuery).then(setCrmNotes),
            Vue.appApi().authorized().crmNotes().getCrmCaseNotes(vm.crmQuery).then(setCrmCaseNotes)
        ];

        return Promise.all(apiRequests).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function setCrmNotes(response){
            vm.crmNotes = response.data;
        }
        function setCrmCaseNotes(response){
            vm.crmCaseNotes = response.data;
        }
        function resetLoadingState(){
            vm.isLoadingCrmNotes = false;
            vm.scrollTo('crmNotesTable');
        }
    }

    function fetchNoteLines(crmNote){
        const vm = this;
        vm.isLoadingNotesLines = true;
        vm.notesLines = [];
        const query = {
            user: crmNote.Createdby_User,
            date: crmNote.Date,
            entry_no: crmNote.Entry_No
        };
        return Vue.appApi().authorized().crmNotes().getCrmNoteLines(query).then(setNotesLines).catch(vm.displayErrorMessage).finally(resetLoadingState);
        function setNotesLines(response){
            vm.notesLines = response.data;
            vm.scrollTo('notesLines');
        }
        function resetLoadingState(){
            vm.isLoadingNotesLines = false;
        }
    }

    function fetchCaseNoteLines(crmCaseNote){
        const vm = this;
        vm.isLoadingNotesLines = true;
        vm.notesLines = [];
        const query = {
            user: crmCaseNote.Createdby_User,
            case_no: crmCaseNote.Case_No,
            entry_no: crmCaseNote.Entry_No
        };
        return Vue.appApi().authorized().crmNotes().getCrmCaseNoteLines(query).then(setNotesLines).catch(vm.displayErrorMessage).finally(resetLoadingState);
        function setNotesLines(response){
            vm.notesLines = response.data;
            vm.scrollTo('notesLines');
        }
        function resetLoadingState(){
            vm.isLoadingNotesLines = false;
        }
    }
}
