export default {
    components: {},
    props: {
        query: {
            type: Object,
            required: true
        },
    },
    data,
    computed: getComputed(),
    watch: getWatchers(),
    created,
    methods: getMethods()
};

function data(){
    return {
        isLoadingTerritoryReport: false,
        customers: null,
        customerTableFields: [
            { key: 'search', label: '', sortable: false },
            { key: 'No_', label: 'Customer No', sortable: true },
            { key: 'Name', label: 'Customer', sortable: true },
            { key: 'City', label: 'City', sortable: true },
            { key: 'County Code', label: 'County', sortable: true },
            { key: 'fleet_average_age', label: 'Age (months)', sortable: true },
            { key: 'fleet_total_hours', label: 'Hours', sortable: true },
            { key: 'fleet_avoidable_spend', label: 'Fleet Avoidable Spend ($)', sortable: true },
            { key: 'fleet_tire_spend', label: 'Fleet Tire Spend ($)', sortable: true },
            { key: 'fleet_total_spend', label: 'Fleet Total Spend ($)', sortable: true },
            { key: 'fleet_avoidable_cost_per_hour', label: 'Fleet Avoidable CPH', sortable: true },
            { key: 'fleet_tire_cost_per_hour', label: 'Fleet Tire CPH', sortable: true },
            { key: 'fleet_cost_per_hour', label: 'Fleet Cost per Running Hour ($/Hr)', sortable: true },
            { key: 'fleet_cph_benchmark_percentage', label: 'Status', sortable: true },
        ]
    };
}

function getComputed(){
    return {};
}

function getWatchers(){
    return {
        query: {
            handler: created,
            deep: true
        }
    };
}

function created(){
    const vm = this;
    vm.getFleetReport();
}

function getMethods(){
    return {
        getFleetReport,
    };

    function getFleetReport(){
        const vm = this;
        vm.isLoadingTerritoryReport = true;
        vm.customerEquipment = null;
        const query = {
            query_type: vm.query.query_type,
            salesperson_code: vm.query.salesperson_code,
            county: vm.query.county,
            start_date: vm.query.start_date,
            end_date: vm.query.end_date
        };
        return Vue.appApi().authorized().reports().generateCustomerFleetReport(query).then(displayReportData).catch(emitQueryError).finally(resetLoadingState);

        function displayReportData(response){
            vm.customers = response.data;
        }

        function emitQueryError(response){
            vm.$emit('query-error', response);
        }

        function resetLoadingState(){
            vm.isLoadingTerritoryReport = false;
        }
    }
}
