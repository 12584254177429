export default {
    props: {
        fleetCode: {
            type: String,
            required: true,
        },
    },
    data,
    watch: getWatches(),
    methods: getMethods(),
};

function data(){
    return {
        tableColumns: [
            {
                key: 'Action_Code',
                label: 'Action Code',
                sortable: true
            },
            {
                key: 'Description',
                label: 'Description',
                sortable: true
            },
            {
                key: 'Period_Formula',
                label: 'Period',
                sortable: true
            },
            {
                key: 'Last_Meter_Reading',
                label: 'Last Meter',
                sortable: true
            },
            {
                key: 'Exp_Meter_Reading',
                label: 'Expected Meter',
                sortable: true
            },
            {
                key: 'Next_Planned_Date',
                label: 'Next Date',
                sortable: true
            },
            {
                key: 'Last_Date',
                label: 'Last Date',
                sortable: true
            },
            {
                key: 'Open_on_WO',
                label: 'Open On WO#',
                sortable: true
            },
        ],
    };
}

function getWatches(){
    return {
        fleetCode,
    };

    function fleetCode(){
        const vm = this;
        vm.$refs.workorderPmsTable.refresh();
    }
}

function getMethods(){
    return {
        getEquipmentPms,
        emitError
    };

    function getEquipmentPms(queryObject){
        const vm = this;
        return Vue.appApi().authorized().rentalCheckins(vm.fleetCode).getPms(queryObject);
    }
    function emitError(error){
        const vm = this;
        vm.$emit('error', error);
    }
}
