import SubscriberComponent from './subscriber.vue';
import AdminRoutes from './admin/admin.routes';
import RentalRoutes from './rental-pages/rental.routes';
import ServiceRoutes from './service-pages/service.routes';
import PartsRoutes from './parts/parts.routes';
import SalesRoutes from './sales/sales.routes';
import AllRoutes from './all/all.routes';

export default {
    path: '',
    component: SubscriberComponent,
    meta: {
        requiresAccount: true,
        requiresVerifiedEmail: true,
    },
    children: [
        AdminRoutes,
        AllRoutes,
        RentalRoutes,
        ServiceRoutes,
        PartsRoutes,
        SalesRoutes,
    ]
};
