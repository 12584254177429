export default {
    props: {
        showSpinner: {
            type: Boolean,
            default: false
        },
        customClass: {
            type: String,
            default: '',
            validator: function(value){
                const availableCustomClasses = [
                    'size-auto',
                    'overlay',
                    'overlay opaque',
                    'overlay fixed',
                    ''
                ];
                return availableCustomClasses.includes(value);
            }
        }
    }
};
