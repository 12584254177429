export default {
    data,
    methods: getMethods(),
};

function data(){
    return {
        tableOptions: {
            sortIcon: {
                base: 'fa',
                up: 'fa-sort-asc',
                down: 'fa-sort-desc',
                is: 'fa-sort'
            },
            perPage: 10,
            perPageValues: [
                10,
                25,
                50,
                100,
            ],
        },
    };
}

function getMethods(){
    return {
        displayErrorMessage,
        buildApiQueryObject,
    };

    function displayErrorMessage(error){
        const vm = this;
        vm.$emit('error', error);
    }

    function buildApiQueryObject(queryObject){
        const apiQueryObject = {};
        apiQueryObject.page = queryObject.page;
        apiQueryObject.per_page = queryObject.limit;
        if(queryObject.orderBy){
            apiQueryObject.order_by = queryObject.orderBy;
            apiQueryObject.order_direction = queryObject.ascending ? 'asc' : 'desc';
        }
        if(queryObject.query !== ''){
            apiQueryObject.query_string = queryObject.query;
        }
        return apiQueryObject;
    }
}
