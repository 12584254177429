import Datepicker from 'vuejs-datepicker';

export default {
    components: {
        Datepicker
    },
    props: {
        validationErrors: {
            type: Object,
            required: true
        }
    },
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        queryTypeOptions: [
            { value: 'date_range', text: 'Date Range' },
            { value: 'workorder_number', text: 'Work Order #' },
        ],
        serviceTypeOptions: [
            { value: '-', text: '-' },
            { value: 'C', text: 'C' },
            { value: 'OTHER', text: 'OTHER' },
        ],
        workordersQuery: {
            start_date: '',
            end_date: '',
            location: '',
            type: '-',
            workorder_number: '',
            query_type: 'date_range'
        },
    };
}

function getComputed(){
    return {};
}

function created(){}

function getMethods(){
    return {
        emitQueryObject,
        setQueryDefaults
    };

    function emitQueryObject(){
        const vm = this;
        const queryPayload = {};
        Object.keys(vm.workordersQuery).forEach(key => {
            if(vm.workordersQuery[key]){
                queryPayload[key] = vm.workordersQuery[key];
            }
        });
        vm.$emit('query-updated', queryPayload);
    }

    function setQueryDefaults(){
        const vm = this;
        if(vm.workordersQuery.query_type === 'date_range'){
            vm.workordersQuery.start_date = '';
            vm.workordersQuery.end_date = '';
            vm.workordersQuery.location = '';
            vm.workordersQuery.type = '-';
            vm.workordersQuery.workorder_number = '';
        } else {
            vm.workordersQuery.start_date = '';
            vm.workordersQuery.end_date = '';
            vm.workordersQuery.location = '';
            vm.workordersQuery.type = '';
            vm.workordersQuery.workorder_number = '';
        }
        vm.$emit('query-type-changed');
    }
}
