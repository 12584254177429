import TechnicianList from 'vue_root/components/technician-list/technician-list';

export default {
    components: {
        TechnicianList
    },
    props: {},
    data,
    computed: getComputed(),
    created,
    beforeRouteEnter,
    beforeRouteUpdate,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        technicianListSections: [],
        isLoadingTechnicianList: false
    };
}

function getComputed(){
    return {};
}

function created(){
    const vm = this;
    vm.getTechnicianListSections();
}

function beforeRouteEnter(to, from, next){
    next(vm => {
        if(to.name !== 'shop-home'){
            vm.scrollTo('pageContent', { block: 'center' });
        }
    });
}

function beforeRouteUpdate(to, from, next){
    const vm = this;
    if(to.name !== 'shop-home'){
        vm.scrollTo('pageContent', { block: 'center' });
    }
    next();
}

function getMethods(){
    return {
        displayErrorMessage,
        getTechnicianListSections
    };

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function getTechnicianListSections(){
        const vm = this;
        vm.isLoadingTechnicianList = true;
        return Vue.appApi().authorized().technicianLists('shop-home').getTechnicianListSections().then(setTechnicianListSections).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function setTechnicianListSections({ data }){
            vm.technicianListSections = data;
        }
        function resetLoadingState(){
            vm.isLoadingTechnicianList = false;
        }
    }
}
