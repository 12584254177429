export default {
    data,
    methods: getMethods(),
};

function data(){
    return {
        credentials: {
            email: '',
        },
        apiErrors: [],
        validationErrors: {},
        requestingResetPasswordEmail: false,
        successMessages: [],
    };
}

function getMethods(){

    return {
        requestResetPasswordEmail,
    };

    function requestResetPasswordEmail(credentials){

        var vm = this;

        vm.requestingResetPasswordEmail = true;
        vm.apiErrors = [];
        vm.validationErrors = {};

        vm.$store.dispatch('user/forgot_password/FORGOT_PASSWORD', credentials).then(handleEmailSent).catch(handleRequestError);

        function handleEmailSent(response){
            vm.requestingResetPasswordEmail = false;
            vm.successMessages.push('Please check your inbox and follow the link provided.');
        }
        function handleRequestError(response){
            vm.requestingResetPasswordEmail = false;
            if(response.status === 422 && response.data.errors){
                vm.validationErrors = response.data.errors;
            } else {
                vm.apiErrors.push(response.appMessage || response.data.message);
            }
        }
    }
}
