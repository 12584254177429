import LeadNotesTable from './lead-notes/lead-notes-table';
export default {
    components: {
        LeadNotesTable,
    },
    props: {},
    data,
    computed: getComputed(),
    watch: getWatches(),
    created,
    methods: getMethods(),
};

function data(){
    const vm = this;
    return {
        isLoadingLeads: false,
        isLoadingCaseNotes: false,
        errorMessages: [],
        leads: [],
        selectedLead: null,
        caseNotes: [],
        tableColumns: [
            {
                key: 'details-link',
                label: '',
                tdClass: 'text-center'
            },
            {
                key: 'case_no',
                label: 'Case',
                sortable: true,
            },
            {
                key: 'status',
                label: 'Status',
                sortable: true,
                formatter: vm.getStatusText,
            },
            {
                key: 'company',
                label: 'Company',
                sortable: true,
            },
            {
                key: 'contact',
                label: 'Contact',
                sortable: true,
            },
            {
                key: 'department',
                label: 'Department',
                sortable: true,
            },
            {
                key: 'priority',
                label: 'Priority',
                sortable: true,
            },
            {
                key: 'message',
                label: 'Message',
                sortable: true,
            },
            {
                key: 'id',
                label: 'ID',
                sortable: true,
            },
            {
                key: 'resource_no',
                label: 'Assigned Person',
                sortable: true,
            },
        ],
    };
}

function getComputed(){
    return {
        submittedLeads,
    };

    function submittedLeads(){
        const vm = this;
        return vm.$store.state.pmLeads.submittedLeads;
    }
}

function getWatches(){
    return {
        submittedLeads,
    };

    function submittedLeads(){
        const vm = this;
        vm.searchLeads();
    }
}

function created(){
    const vm = this;
    vm.searchLeads();
}

function getMethods(){
    return {
        searchLeads,
        displayErrorMessage,
        getStatusText,
        selectLead,
    };

    function searchLeads(){
        const vm = this;
        vm.isLoadingLeads = true;
        vm.selectedLeadId = null;
        vm.errorMessages = [];
        vm.leads = [];
        Vue.appApi().authorized().leads().indexMyLeads()
            .then(setLeadsData)
            .catch(vm.displayErrorMessage)
            .finally(stopSpinner);

        function setLeadsData(data){
            vm.leads = data.data;
        }

        function stopSpinner(){
            vm.isLoadingLeads = false;
        }
    }

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function getStatusText(status){
        if(status === 0){
            return 'OPEN';
        }
        if(status === 1){
            return 'ON HOLD';
        }
        if(status === 2){
            return 'CLOSED';
        }
        return '';
    }

    function selectLead(lead){
        const vm = this;
        vm.selectedLead = lead;
        vm.scrollTo('leadNotes');
    }
}
