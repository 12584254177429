export default {
    components: {},
    props: {},
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        invite_code: '',
        email: '',
        isInitializingView: false
    };
}

function getComputed(){
    return {
        user(){
            const vm = this;
            return vm.$store.getters.user;
        }
    };
}

function created(){
    const vm = this;
    vm.invite_code = vm.$route.query.invite_code;
    vm.email = vm.$route.query.email;
    vm.isInitializingView = true;
    if(!vm.invite_code){
        vm.$router.replace({ name: 'page-not-found' });
    } else if(!vm.user){
        vm.redirectInvitedUser().then(resetLoadingState);
    } else {
        vm.$router.replace({ name: 'accept-invite', query: { invite_code: vm.invite_code }});
    }
    function resetLoadingState(){
        vm.isInitializingView = false;
    }
}

function getMethods(){
    return {
        displayErrorMessage,
        redirectInvitedUser,
    };

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function redirectInvitedUser(){
        const vm = this;
        return Vue.appApi().guest().user().getInvitedUserStatus({ invite_code: vm.invite_code }).then(redirectToLoginOrRegistration).catch(vm.displayErrorMessage);

        function redirectToLoginOrRegistration({ data }){
            const isCurrentUser = data.is_current_user;
            vm.$router.redirectedFrom = { name: 'accept-invite', query: { invite_code: vm.invite_code }};
            if(isCurrentUser){
                vm.$router.push({ name: 'login', query: { invite_code: vm.invite_code, email: vm.email }});
            } else {
                vm.$router.push({ name: 'register', query: { invite_code: vm.invite_code, email: vm.email }});
            }
        }
    }
}
