export default {
    data,
    computed: getComputed(),
    methods: getMethods(),
};

function data(){
    return {
        searchString: '',
        paginationOptions: {
            per_page: 50,
            current_page: '1',
            total: 0
        },
    };
}

function getComputed(){
    return {
        displayedEntryCounts
    };

    function displayedEntryCounts(){
        const vm = this;
        const lastRecord = Math.min((vm.paginationOptions.per_page * vm.paginationOptions.current_page), vm.paginationOptions.total);
        const firstRecord = lastRecord > 0 ? Math.max((vm.paginationOptions.per_page * (vm.paginationOptions.current_page - 1) + 1), 0) : 0;
        return {
            lastRecord,
            firstRecord
        };
    }
}

function getMethods(){
    return {
        updatePaginationTotal,
        resetPagination,
    };

    function updatePaginationTotal(resultsArray, count){
        const vm = this;
        vm.paginationOptions.total = count;
    }

    function resetPagination(){
        const vm = this;
        vm.paginationOptions.current_page = '1';
    }
}
