import Datepicker from 'vuejs-datepicker';
import KpiBillingChart from 'vue_root/authorized/subscriber/service-pages/kpi-billing/kpi-billing-chart';
import MechanicMetricsTable from './components/mechanic-metrics-table/mechanic-metrics-table';

export default {
    components: {
        Datepicker,
        KpiBillingChart,
        MechanicMetricsTable
    },
    props: {},
    data,
    computed: getComputed(),
    watch: getWatchers(),
    methods: getMethods()
};

function data(){
    return {
        validationErrors: {},
        errorMessages: [],
        serviceTypeOptions: [
            { value: '-', text: '-' },
            { value: 'C', text: 'C' },
            { value: 'WARRANTY', text: 'WARRANTY' },
            { value: 'OTHER', text: 'OTHER' },
        ],
        workordersQuery: {
            start_date: '',
            end_date: '',
            location: '',
            type: '-',
            modified_user: '',
        },
        isQueryDirty: true,
        calculationType: 'all',
        calculationTypeOptions: [
            { value: 'all', text: 'All' },
            { value: 'tech', text: 'Tech' },
            { value: 'office', text: 'Office' },
        ],
        workingDaysProperty: 'numberOfWorkingDays',
        workorders: null,
        workorderNumbersToExclude: [],
        excludedWorkorderNumbers: [],
        isLoadingWorkorders: false,
        chartOptions: {
            scales: {
                xAxes: [{
                    type: 'linear',
                    position: 'bottom'
                }]
            }
        }
    };
}

function getComputed(){
    return {
        allWorkingDayDatapoints,
        filteredWorkingDayDatapoints,
        allowedDatapoints,
        groupingOfDayCounts,
        groupingOfFilteredDayCounts,
        graphCoordinates,
        mean,
        median,
        mode,
        range,
        chartData,
    };

    function allWorkingDayDatapoints(){
        const vm = this;
        return vm.workorders.slice(0).map(vm.extractWorkingDaysFromDatapoint);
    }

    function filteredWorkingDayDatapoints(){
        const vm = this;
        return vm.allowedDatapoints.map(vm.extractWorkingDaysFromDatapoint).filter(value => value !== null);
    }

    function allowedDatapoints(){
        const vm = this;
        return vm.workorders.slice(0).filter(removeExcludedWorkorderNumbers);

        function removeExcludedWorkorderNumbers(eachWorkorder){
            return vm.excludedWorkorderNumbers.indexOf(eachWorkorder.No_) === -1;
        }
    }

    function groupingOfDayCounts(){
        const vm = this;
        return vm.getGroupingOfDayCounts(vm.allWorkingDayDatapoints);
    }

    function groupingOfFilteredDayCounts(){
        const vm = this;
        return vm.getGroupingOfDayCounts(vm.filteredWorkingDayDatapoints);
    }

    function graphCoordinates(){
        const vm = this;
        const coordinates = [{ 'x': 0, 'y': 0 }];
        Object.keys(vm.groupingOfDayCounts).forEach((dayCount) => {
            coordinates.push({ 'x': dayCount, 'y': vm.groupingOfDayCounts[dayCount] });
        });
        return coordinates;
    }

    function mean(){
        const vm = this;
        const countOfDatapoints = vm.filteredWorkingDayDatapoints.length;
        if(countOfDatapoints){
            const sumOfWorkingDays = vm.filteredWorkingDayDatapoints.reduce(sumWorkingDays, 0);
            return Number((sumOfWorkingDays / countOfDatapoints).toFixed(2)).toString();
        }
        return 0;

        function sumWorkingDays(total, numberOfWorkingDays){
            return total + numberOfWorkingDays;
        }
    }

    function median(){
        const vm = this;
        const orderedDatapoints = vm.filteredWorkingDayDatapoints.slice(0).sort();
        const middle = Math.round(orderedDatapoints.length / 2);
        return orderedDatapoints.length ? orderedDatapoints[middle - 1] : 0;
    }

    function mode(){
        const vm = this;
        let mode = 0;
        let maxDayCount = 0;
        Object.keys(vm.groupingOfFilteredDayCounts).forEach(key => {
            if(vm.groupingOfFilteredDayCounts[key] >= maxDayCount){
                maxDayCount = vm.groupingOfFilteredDayCounts[key];
                mode = key;
            }
        });
        return mode;
    }

    function range(){
        const vm = this;
        let range = 0;
        if(vm.filteredWorkingDayDatapoints.length){
            let smallest = vm.filteredWorkingDayDatapoints[0];
            let largest = 0;
            vm.filteredWorkingDayDatapoints.forEach((dayCount) => {
                if(dayCount < smallest){
                    smallest = dayCount;
                }
                if(dayCount > largest){
                    largest = dayCount;
                }
            });
            range = largest - smallest;
        }
        return range;
    }

    function chartData(){
        const vm = this;
        return {
            datasets: [{
                label: 'Scatter Dataset',
                lineTension: 0,
                borderColor: 'rgba(75,192,192,1)',
                fill: false,
                pointBorderWidth: 3,
                data: vm.graphCoordinates
            }]
        };
    }
}

function getWatchers(){
    return {
        'workordersQuery.start_date': setQueryDirty,
        'workordersQuery.end_date': setQueryDirty,
        'workordersQuery.location': setQueryDirty,
        'workordersQuery.type': setQueryDirty,
        'workordersQuery.modified_user': setQueryDirty,
    };

    function setQueryDirty(){
        const vm = this;
        vm.isQueryDirty = true;
    }
}

function getMethods(){
    return {
        displayErrorMessage,
        reEvaluateReportData,
        getWorkorders,
        workorderExcluded,
        extractWorkingDaysFromDatapoint,
        getGroupingOfDayCounts,
        scrollTo,
        getWorkingDaysPropertyForCalculationType
    };

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            if(error.status === 422 && error.data.errors){
                vm.validationErrors = error.data.errors;
            } else {
                const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
                vm.errorMessages.push(errorMessage);
            }
        }
    }

    function reEvaluateReportData(){
        const vm = this;
        vm.excludedWorkorderNumbers = vm.workorderNumbersToExclude.slice(0);
    }

    function getWorkorders(){
        const vm = this;
        const areWorkordersStale = vm.isQueryDirty;

        vm.workorderNumbersToExclude = [];
        vm.excludedWorkorderNumbers = [];
        vm.workingDaysProperty = vm.getWorkingDaysPropertyForCalculationType(vm.calculationType);

        if(areWorkordersStale){
            vm.isQueryDirty = false;
            vm.validationErrors = {};
            vm.isLoadingWorkorders = true;
            vm.workorders = null;
            return Vue.appApi().authorized().kpiBillingWorkorders().getBillingOpenAndCloseDates(vm.workordersQuery)
                .then(setWorkorders)
                .catch(vm.displayErrorMessage)
                .finally(stopSpinner);
        }

        function setWorkorders(response){
            vm.workorders = response.data.map(setNumberOfWorkingDays);

            function setNumberOfWorkingDays(workorder){
                workorder.numberOfWorkingDays = calculateNumberOfWorkingDays('End_Date_Clean', 'posting_date');
                workorder.numberOfTechWorkingDays = workorder.trindocs_upload_date ? calculateNumberOfWorkingDays('End_Date_Clean', 'trindocs_upload_date') : null;
                workorder.numberOfOfficeWorkingDays = workorder.trindocs_upload_date ? calculateNumberOfWorkingDays('trindocs_upload_date', 'posting_date') : null;

                return workorder;
                function calculateNumberOfWorkingDays(startDateProperty, endDateProperty){
                    const startDate = Vue.moment(workorder[startDateProperty]);
                    const endDate = Vue.moment(workorder[endDateProperty]);
                    const workingDays = [1, 2, 3, 4, 5];

                    let countOfWorkingDays = 0;
                    while(startDate.isBefore(endDate)){
                        const notAWorkDay = !workingDays.includes(startDate.day());
                        const isAFixedHoliday = startDate.isSame('2016-11-24', 'day') && startDate.isSame('2016-11-25', 'day');
                        const isNewYearsDay = startDate.month() === 0 && startDate.date() === 1;
                        const isChristmas = startDate.month() === 11 && startDate.date() === 25;
                        const isAHoliday = isNewYearsDay || isChristmas;
                        startDate.add(1, 'days');
                        if(notAWorkDay || isAFixedHoliday || isAHoliday){
                            continue;
                        }
                        countOfWorkingDays++;
                    }
                    return countOfWorkingDays;
                }
            }
        }

        function stopSpinner(){
            vm.isLoadingWorkorders = false;
        }
    }

    function workorderExcluded(workorderNumber){
        const vm = this;
        vm.workorderNumbersToExclude.push(workorderNumber);
    }

    function extractWorkingDaysFromDatapoint(datapoint){
        const vm = this;
        return datapoint[vm.workingDaysProperty];
    }

    function getGroupingOfDayCounts(workingDayDatapoints){
        return workingDayDatapoints.reduce(reduceWorkingDayDatapointsToCounts, {});

        function reduceWorkingDayDatapointsToCounts(accumulator, currentDayCount){
            accumulator[currentDayCount] = (accumulator[currentDayCount] || 0) + 1;
            return accumulator;
        }
    }

    function scrollTo(ref){
        const vm = this;
        if(vm.$refs[ref]){
            vm.$refs[ref].scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    }

    function getWorkingDaysPropertyForCalculationType(calculationType){
        let property = 'numberOfWorkingDays';
        switch (calculationType){
            case 'all':
                property = 'numberOfWorkingDays';
                break;
            case 'tech':
                property = 'numberOfTechWorkingDays';
                break;
            case 'office':
                property = 'numberOfOfficeWorkingDays';
                break;
        }
        return property;
    }
}
