import ClientPaginationMixin from 'vue_root/mixins/client-pagination-mixin';

export default {
    components: {},
    mixins: [
        ClientPaginationMixin,
    ],
    inheritAttrs: false,
    props: {
        apiMethod: {
            type: Function,
            required: true
        },
        baseQuery: {
            type: Object,
            default: function(){
                return {};
            }
        },
        defaultPerPage: {
            type: Number,
            default: 10
        },
        title: {
            type: String,
            default: ''
        },
        titleClass: {
            type: String,
            default: ''
        },
        headerClass: {
            type: String,
            default: ''
        },
        searchable: {
            type: Boolean,
            default: true
        }
    },
    data,
    computed: getComputed(),
    watch: getWatches(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        validationErrors: {},
        isLoading: false,
        slotsWithDefaultContent: ['emptyfiltered', 'empty', 'table-busy']
    };
}

function getComputed(){
    return {
        dynamicSlots
    };
    function dynamicSlots(){
        const vm = this;
        const slotNames = Object.keys(vm.$slots);
        return slotNames.filter(slotName => !vm.slotsWithDefaultContent.includes(slotName));
    }
}

function getWatches(){
    return {};
}

function created(){
    const vm = this;
    vm.paginationOptions.per_page = vm.defaultPerPage;
}

function getMethods(){
    return {
        displayErrorMessage,
        makeApiRequest,
        buildApiQueryObject,
        refresh
    };

    function displayErrorMessage(error){
        const vm = this;
        const isValidationError = error && error.status === 422 && error.data.errors;
        if(isValidationError){
            vm.validationErrors = error.data.errors;
        } else {
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function makeApiRequest(context){
        const vm = this;
        const query = Object.assign(JSON.parse(JSON.stringify(vm.baseQuery)), vm.buildApiQueryObject(context));
        return vm.apiMethod(query).then(updateTable).catch(emitError);

        function updateTable({ data }){
            vm.paginationOptions.total = data.total;
            return data.data;
        }

        function emitError(response){
            vm.$emit('error', response);
            return [];
        }
    }

    function buildApiQueryObject(queryObject){
        const apiQueryObject = {};
        apiQueryObject.page = queryObject.currentPage;
        apiQueryObject.per_page = queryObject.perPage;
        if(queryObject.sortBy){
            apiQueryObject.order_by = queryObject.sortBy;
            apiQueryObject.order_direction = queryObject.sortDesc ? 'desc' : 'asc';
        }
        if(queryObject.filter !== ''){
            apiQueryObject.query_string = queryObject.filter;
        }
        return apiQueryObject;
    }

    function refresh(){
        const vm = this;
        vm.$refs.serverTable.refresh();
    }
}
