export default {
    data,
    computed: getComputed(),
    created,
    methods: getMethods(),
};

function data(){
    const vm = this;
    return {
        credentials: {
            name: '',
            email: vm.$route.query.email || '',
            password: '',
            password_confirmation: '',
            invite_code: vm.$route.query.invite_code || '',
        },
        apiErrors: [],
        validationErrors: {},
        registeringUser: false,
        isPasswordDisplayed: false,
        isPasswordConfirmationDisplayed: false,
        isEmailInputDisabled: !!vm.$route.query.email
    };
}

function getComputed(){
    return {
        passwordInputType(){
            const vm = this;
            return vm.isPasswordDisplayed ? 'text' : 'password';
        },
        passwordConfirmationInputType(){
            const vm = this;
            return vm.isPasswordConfirmationDisplayed ? 'text' : 'password';
        }
    };
}

function created(){
    const vm = this;
    const isInviteCodeRequired = vm.$store.state.appEnv.registrationType === 'invite-only';
    const hasInviteCode = vm.credentials.invite_code;
    if(isInviteCodeRequired && !hasInviteCode){
        vm.$router.replace({ name: 'login' });
    }
}

function getMethods(){

    return {
        attemptRegisterUser,
    };

    function attemptRegisterUser(credentials){

        var vm = this;

        vm.registeringUser = true;
        vm.apiErrors = [];
        vm.validationErrors = {};

        if(credentials.password !== vm.credentials.password_confirmation){
            vm.apiErrors.push('Your passwords do not match.');
            vm.registeringUser = false;
            return false;
        }

        vm.$store.dispatch('user/register/REGISTER_USER', credentials).then(registerSuccess).catch(registerError);

        function registerSuccess(response){
            vm.$router.redirectAfterLogin();
            vm.registeringUser = false;
        }
        function registerError(response){
            vm.apiErrors.push(response.appMessage || response.data.message);
            vm.registeringUser = false;
            if(response.status === 422 && response.data.errors){
                vm.validationErrors = response.data.errors;
            }
        }
    }
}
