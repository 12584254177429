import WorkorderAndCheckinDetails from 'vue_root/components/workorder-and-checkin-details/workorder-and-checkin-details.vue';

export default {
    components: {
        WorkorderAndCheckinDetails
    },
    props: {},
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        isLoading: false,
        hulWorkOrders: null,
        displayedServiceType: '',
        displayedWorkOrderNumber: null,
        tableColumns: [
            {
                key: 'details-link',
                label: '',
                tdClass: 'text-center'
            },
            {
                key: 'No_',
                label: 'WO#',
                sortable: true,
            },
            {
                key: 'posting_status',
                label: 'Posting',
                sortable: true,
                formatter: (value) => {
                    let formattedValue = '';
                    if(value === 0){
                        formattedValue = 'OPEN';
                    } else if(value === 2){
                        formattedValue = 'CLOSED';
                    }
                    return formattedValue;
                }
            },
            {
                key: 'billto_name',
                label: 'Customer',
                sortable: true,
            },
            {
                key: 'service_type',
                label: 'Type',
                sortable: true,
            },
            {
                key: 'service_description',
                label: 'Description1',
                sortable: true,
            },
            {
                key: 'model',
                sortable: true,
            },
            {
                key: 'location_code',
                label: 'Branch',
                sortable: true,
            },
            {
                key: 'starting_date',
                label: 'Doc. Date',
                sortable: true,
                sortByFormatted: (value) => new Date(value),
            },
            {
                key: 'equipment_check_in.redtag',
                label: 'Red Tag?',
                sortable: true,
                formatter: (value) => value ? 'X' : ''
            },
            {
                key: 'equipment_check_in.workorder',
                label: 'Red WO#',
                sortable: true,
            },
        ],
    };
}

function getComputed(){
    return {};
}

function created(){}

function getMethods(){
    return {
        displayErrorMessage,
        fetchWorkOrdersForServiceType,
        fetchWorkOrderDetails
    };

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const isValidationError = error.status === 422 && error.data.errors;
            if(isValidationError){
                vm.errorMessages = [...Object.values(error.data.errors)].flat();
            } else {
                const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
                vm.errorMessages.push(errorMessage);
            }
        }
    }

    function fetchWorkOrdersForServiceType(serviceType){
        const vm = this;
        vm.isLoading = true;
        vm.displayedServiceType = serviceType;
        vm.errorMessages = [];
        vm.displayedWorkOrderNumber = null;
        vm.hulWorkOrders = null;
        Vue.appApi().authorized().workorders().indexHulWorkOrders({ service_type: serviceType }).then(setHulWorkOrders).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function setHulWorkOrders(response){
            vm.hulWorkOrders = response.data;
            vm.scrollTo('ordersTable', { block: 'center' });
        }
        function resetLoadingState(){
            vm.isLoading = false;
        }
    }

    function fetchWorkOrderDetails(workorderNumber){
        const vm = this;
        vm.displayedWorkOrderNumber = workorderNumber.toUpperCase();
    }
}
