export default {
    namespaced: true,
    actions: getActions(),
    mutations: getMutations(),
};

function getActions(){

    return {
        REGISTER_USER: registerUser,
    };
    function registerUser({ dispatch }, credentials){

        return Vue.appApi().guest().user().register(credentials).then(handleSuccessfulRegistraction);

        function handleSuccessfulRegistraction(response){
            dispatch('user/REGISTER_USER_SUCCESS', response.data, { root: true }).then(returnResponse);
            function returnResponse(){
                return response;
            }
        }
    }
}

function getMutations(){

    return {};
}
