import fileDownload from 'js-file-download';

export default {
    components: {},
    data,
    computed: getComputed(),
    created,
    mounted,
    methods: getMethods(),
};

function data(){
    return {
        errorMessages: [],
        isLoading: false,
        isDownloading: false,
        folderFiles: [],
        expandedFolders: [],
    };
}

function getComputed(){
    return {
        openDirectory,
        folderFilesToShow,
        isActiveFolder,
    };

    function openDirectory(){
        const vm = this;
        return vm.$store.state.fileManager.openDirectory;
    }

    function folderFilesToShow(){
        const vm = this;
        if(!vm.openDirectory){
            return [];
        }
        return [...vm.openDirectory.directories, ...vm.openDirectory.files];
    }
    function isActiveFolder(){
        const vm = this;
        const openDirectoryName = vm.openDirectory ? vm.openDirectory.path + '/' + vm.openDirectory.name : null;
        return openDirectoryName === vm.folderFiles.path + '/' + vm.folderFiles.name;
    }
}

function created(){}

function mounted(){
    const vm = this;
    vm.getFolderFiles();
}

function getMethods(){
    return {
        displayApiError,
        getFolderFiles,
        setOpenDirectory,
        isADirectory,
        getModifiedDate,
        downloadFile,
    };
    function displayApiError(error){
        const vm = this;
        if(error.appMessage){
            vm.errorMessages = [error.appMessage];
        }
    }
    function getFolderFiles(){
        const vm = this;
        vm.isLoading = true;
        Vue.appApi().authorized().fileManagement().getFiles()
            .then(setFolderFilesData)
            .catch(vm.displayApiError)
            .finally(stopSpinner);
        function setFolderFilesData(response){
            vm.folderFiles = response.data;
            vm.setOpenDirectory();
        }
        function stopSpinner(){
            vm.isLoading = false;
        }
    }
    function setOpenDirectory(directory){
        const vm = this;
        if(directory){
            vm.$store.dispatch('fileManager/SET_OPEN_DIRECTORY', directory);
        } else {
            vm.$store.dispatch('fileManager/SET_OPEN_DIRECTORY', vm.folderFiles);
        }
    }
    function isADirectory(folderFile){
        return typeof folderFile.directories !== 'undefined';
    }
    function getModifiedDate(folderFile){
        const modifiedDate = Vue.moment(folderFile.lastModified);
        return modifiedDate.format('MMM DD, YYYY hh:mm A');
    }
    function downloadFile(folderFile){
        const vm = this;
        vm.isDownloading = true;
        const queryObject = {
            path: folderFile.path + '/' + folderFile.name
        };
        Vue.appApi().authorized().fileManagement().downloadFile(queryObject)
            .then(triggerClientDownload)
            .catch(vm.displayApiError)
            .finally(stopSpinner);

        function stopSpinner(){
            vm.isDownloading = false;
        }

        function triggerClientDownload(response){
            vm.isDownloading = false;
            fileDownload(response.data, folderFile.name);
        }
    }
}
