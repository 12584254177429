export default {
    props: {
        name: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: 'text'
        },
        placeholder: {
            type: String,
            default: ''
        },
        validationErrors: {
            type: Object,
            default: function(){
                return {};
            }
        },
        value: {
            type: [String, Number, Boolean, Date],
        },
        customClass: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data,
    watch: {
        'inputValue': updateParent,
        'value': syncValue
    },
    created: syncValue
};

function data(){
    return {
        inputValue: ''
    };
}

function updateParent(){
    this.$emit('input', this.inputValue);
}

function syncValue(){
    this.inputValue = this.value;
}
