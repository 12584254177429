import draggable from 'vuedraggable';
import TechnicianList from 'vue_root/components/technician-list/technician-list';

export default {
    components: {
        draggable,
        TechnicianList
    },
    props: {},
    data,
    computed: getComputed(),
    created,
    beforeRouteLeave,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        selectedPage: null,
        isLoadingTechnicianList: false,
        isSavingChanges: false,
        mode: 'edit',
        technicianListSections: [],
        uneditedTechnicanList: [],
        childItemTypes: ['divider', 'link'],
        availablePages: [
            {
                text: 'service-home',
                value: {
                    owner_page_name: 'service-home',
                    linkLocations: [
                        {
                            value: 'all-mechanics-workorders',
                            text: 'all-mechanics-workorders',
                            linkParams: ['mechanicId']
                        },
                        {
                            value: 'all-pms',
                            text: 'all-pms',
                            linkParams: ['mechanicId']
                        },
                        {
                            value: 'mechanic-workorders',
                            text: 'mechanic-workorders',
                            linkParams: ['mechanicId']
                        },
                    ]
                }
            },
            {
                text: 'shop-home',
                value: {
                    owner_page_name: 'shop-home',
                    linkLocations: [
                        {
                            value: 'shop-workorders',
                            text: 'shop-workorders',
                            linkParams: ['mechanicId']
                        },
                    ]
                }
            }
        ]
    };
}

function getComputed(){
    return {
        previewSections,
        isTechnicianListDirty,
    };

    function previewSections(){
        const vm = this;
        return JSON.parse(JSON.stringify(vm.technicianListSections)).filter(removeDeletedItems);

        function removeDeletedItems(listItem){
            if(!listItem.isDeleted && listItem.type === 'section'){
                listItem.child_items = listItem.child_items.filter(removeDeletedItems);
            }
            return !listItem.isDeleted;
        }
    }

    function isTechnicianListDirty(){
        const vm = this;
        return JSON.stringify(vm.technicianListSections) !== JSON.stringify(vm.uneditedTechnicanList);
    }
}

function created(){}

function beforeRouteLeave(toRoute, fromRoute, next){
    const vm = this;
    vm.confirmNavigation().then(isConfirmed => {
        if(isConfirmed){
            next();
        }
    });
}

function getMethods(){
    return {
        displayErrorMessage,
        toggleMode,
        confirmNavigation,
        selectPageToManage,
        addChildItem,
        addSection,
        initializeLinkParams,
        deleteChildItem,
        deleteSection,
        saveTechnicianList,
        getTechnicianListSections,
    };

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function toggleMode(){
        const vm = this;
        vm.mode = vm.mode === 'edit' ? 'preview' : 'edit';
    }

    function confirmNavigation(){
        const vm = this;
        let confirmationPromise = Promise.resolve(true);
        if(vm.isTechnicianListDirty){
            confirmationPromise = vm.$bvModal.msgBoxConfirm('Unsaved changes will be lost, continue?');
        }
        return confirmationPromise;
    }

    function selectPageToManage(){
        const vm = this;
        vm.confirmNavigation().then(isConfirmed => {
            if(isConfirmed){
                vm.getTechnicianListSections();
            }
        });
    }

    function addChildItem(section){
        const vm = this;
        const childItem = {
            draggableId: Date.now(),
            isDeleted: false,
            owner_page_name: vm.selectedPage.owner_page_name,
            type: vm.childItemTypes[1],
            text: '',
            display_order: 0,
            link_params: {},
            linked_page_name: '',
            parent_item_id: null,
        };
        section.child_items.unshift(childItem);
    }

    function addSection(){
        const vm = this;
        const section = {
            draggableId: Date.now(),
            isDeleted: false,
            owner_page_name: vm.selectedPage.owner_page_name,
            type: 'section',
            text: 'section-title',
            display_order: 0,
            link_params: {},
            linked_page_name: '',
            parent_item_id: null,
            child_items: []
        };
        vm.technicianListSections.push(section);
    }

    function initializeLinkParams(technicianListItem){
        const vm = this;
        const linkedPage = vm.selectedPage.linkLocations.find(({ value }) => value === technicianListItem.linked_page_name);
        const linkParams = {};
        if(linkedPage && linkedPage.linkParams){
            linkedPage.linkParams.forEach(param => {
                linkParams[param] = '';
            });
        }
        technicianListItem.link_params = linkParams;
    }

    function deleteChildItem(section, childItem){
        if(childItem.id){
            childItem.isDeleted = true;
        } else {
            const index = section.child_items.indexOf(childItem);
            section.child_items.splice(index, 1);
        }
    }

    function deleteSection(section){
        const vm = this;
        if(section.id){
            section.isDeleted = true;
        } else {
            const index = vm.technicianListSections.indexOf(section);
            vm.technicianListSections.splice(index, 1);
        }
    }

    function saveTechnicianList(){
        const vm = this;
        vm.isSavingChanges = true;
        const payload = {
            sections: JSON.parse(JSON.stringify(vm.technicianListSections))
        };
        setDisplayOrders(payload.sections);
        return Vue.appApi().authorized().technicianLists(vm.selectedPage.owner_page_name).updateTechnicianListSections(payload).then(vm.getTechnicianListSections).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function resetLoadingState(){
            vm.isSavingChanges = false;
        }
        function setDisplayOrders(technicianListItems){
            technicianListItems.sort(byDeleteStatus);
            technicianListItems.forEach(updateDisplayOrder);
            function byDeleteStatus(a, b){
                if(a.isDeleted === b.isDeleted){
                    return 0;
                } else {
                    return a.isDeleted ? 1 : -1;
                }
            }
            function updateDisplayOrder(technicianListItem, index){
                technicianListItem.display_order = index;
                if(technicianListItem.child_items){
                    setDisplayOrders(technicianListItem.child_items);
                }
            }
        }
    }

    function getTechnicianListSections(){
        const vm = this;
        vm.isLoadingTechnicianList = true;
        return Vue.appApi().authorized().technicianLists(vm.selectedPage.owner_page_name).getTechnicianListSections().then(setTechnicianListSections).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function setTechnicianListSections({ data }){
            vm.technicianListSections = data.map(setDisplayProperties);
            vm.uneditedTechnicanList = JSON.parse(JSON.stringify(vm.technicianListSections));

            function setDisplayProperties(technicianListItem){
                technicianListItem.draggableId = technicianListItem.id + Date.now();
                technicianListItem.isDeleted = false;
                if(technicianListItem.child_items){
                    technicianListItem.child_items = technicianListItem.child_items.map(setDisplayProperties);
                }
                return technicianListItem;
            }
        }
        function resetLoadingState(){
            vm.isLoadingTechnicianList = false;
        }
    }
}
