const state = {
    workorders: [],
    pms: [],
};

export default {
    namespaced: true,
    state,
    mutations: getMutations(),
    actions: getActions(),
    getters: getGetters(),
    modules: {}
};

function getActions(){

    return {
        GET_WORKORDERS: getWorkorders,
        GET_ALL_WORKORDERS: getAllWorkorders,
        GET_PMS: getPMs,
        GET_ALL_PMS: getAllPms,
        UPDATE_WORKORDER: updateWorkorder,
        UPDATE_PM: updatePM,
        DOWNLOAD_PMS: downloadPms,
    };

    function getWorkorders({ commit }, payload){
        return Vue.appApi().authorized().mechanics(payload.mechanic_id).getWorkorders(payload).then(setWorkorders);

        function setWorkorders(response){
            commit('SET_WORKORDERS', response.data);
            return Promise.resolve(JSON.parse(JSON.stringify(response.data)));
        }
    }

    function getAllWorkorders({ commit }, payload){
        return Vue.appApi().authorized().mechanics(payload.mechanic_id).getAllWorkorders(payload).then(setWorkorders);

        function setWorkorders(response){
            commit('SET_WORKORDERS', response.data);
            return Promise.resolve(JSON.parse(JSON.stringify(response)));
        }
    }

    function getPMs({ commit }, payload){
        return Vue.appApi().authorized().mechanics(payload.mechanic_id).getPMs(payload).then(setPms);

        function setPms(response){
            commit('SET_PMS', response.data);
            return Promise.resolve(JSON.parse(JSON.stringify(response)));
        }
    }

    function getAllPms({ commit }, payload){
        return Vue.appApi().authorized().mechanics(payload.mechanic_id).getAllPms(payload).then(setPms);

        function setPms(response){
            commit('SET_PMS', response.data);
            return Promise.resolve(JSON.parse(JSON.stringify(response)));
        }
    }

    function updateWorkorder({ commit }, payload){
        return Vue.appApi().authorized().workorders(payload.workorder_number).update(payload).then(updateWorkorder);

        function updateWorkorder(response){
            commit('SET_WORKORDER_NOTE', response.data);
            commit('SET_WORKORDER_STATUS', response.data);
            return Promise.resolve(JSON.parse(JSON.stringify(response.data)));
        }
    }

    function updatePM({ commit }, payload){
        return Vue.appApi().authorized().pms().update(payload).then(updatePm);

        function updatePm(response){
            commit('SET_PM_INITIALS', response.data);
            commit('SET_PM_NOTE', response.data);
            return Promise.resolve(JSON.parse(JSON.stringify(response.data)));
        }
    }

    function downloadPms({ commit }, payload){
        return Vue.appApi().authorized().pms().download(payload);
    }
}

function getGetters(){
    return {
        workorders,
        pms,
    };
    function workorders(state){
        return state.workorders;
    }
    function pms(state){
        return state.pms;
    }
}

function getMutations(){

    return {
        SET_WORKORDERS: setWorkordersState,
        SET_PMS: setPMsState,
        SET_WORKORDER_NOTE: setWorkorderNote,
        SET_WORKORDER_STATUS: setWorkorderStatus,
        SET_PM_INITIALS: setPMInitials,
        SET_PM_NOTE: setPMNote,
    };
    function setWorkordersState(state, payload){
        state.workorders = payload;
    }
    function setPMsState(state, payload){
        state.pms = payload;
    }
    function setWorkorderNote(state, payload){
        state.workorders = state.workorders.map((eachWorkorder) => {
            if(eachWorkorder.WO_Number === payload.workorder){
                eachWorkorder.text = payload.note;
                if(payload.note !== ''){
                    eachWorkorder.datetimenote = payload.datetime;
                }
            }
            return eachWorkorder;
        });
    }
    function setWorkorderStatus(state, payload){
        state.workorders = state.workorders.map((eachWorkorder) => {
            if(eachWorkorder.WO_Number === payload.workorder){
                eachWorkorder.WOstatus = payload.status;
                if(payload.status !== ''){
                    eachWorkorder.datetime = payload.datetime;
                }
            }
            return eachWorkorder;
        });
    }
    function setPMInitials(state, payload){
        state.pms = state.pms.map((eachPm) => {
            if(eachPm.WO_Number === payload.workorder){
                eachPm.initials = payload.initials;
                if(payload.initials !== ''){
                    eachPm.datetime = payload.datetime;
                }
            }
            return eachPm;
        });
    }
    function setPMNote(state, payload){
        state.pms = state.pms.map((eachPm) => {
            if(eachPm.WO_Number === payload.workorder){
                eachPm.text = payload.note;
            }
            return eachPm;
        });
    }
}
