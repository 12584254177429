import { render, staticRenderFns } from "./kpi-billing.vue?vue&type=template&id=1c90da28&scoped=true&"
import script from "./kpi-billing.controller.js?vue&type=script&lang=js&"
export * from "./kpi-billing.controller.js?vue&type=script&lang=js&"
import style0 from "./_kpi-billing.scss?vue&type=style&index=0&id=1c90da28&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c90da28",
  null
  
)

export default component.exports