import axios from 'axios';
import router from 'vue_root/router';
import store from '../app.store';

export default appApi();

function appApi(){

    return {
        install: install,
    };

    function install(Vue, options){

        const appHttp = axios.create({
            baseURL: window.appEnv.baseURL
        });

        let refreshAuthenticationPromise = null;

        appHttp.interceptors.response.use(response => response, catchAllResponseFailures);
        appHttp.interceptors.request.use(modifyAllRequestConfigs, error => error);

        Vue.appApi = _appApi;

        function _appApi(){

            return {
                guest,
                authorized,
            };
            // Vue.appApi().guest()
            function guest(){

                return {
                    user,
                    getClientEnv
                };

                // Vue.appApi().guest().user()
                function user(){

                    return {
                        register,
                        logout,
                        login,
                        forgotPassword,
                        resetPassword,
                        verifyEmailAddress,
                        getInvitedUserStatus
                    };

                    // Vue.appApi().guest().user().register()
                    function register(payload){
                        return appHttp.put(`/v1/user/register`, payload);
                    }
                    // Vue.appApi().guest().user().login()
                    function login(payload){
                        return appHttp.post(`/v1/user/login`, payload);
                    }
                    // Vue.appApi().guest().user().logout()
                    function logout(){
                        return appHttp.post(`/api/v1/logout`);
                    }
                    // Vue.appApi().guest().user().forgotPassword()
                    function forgotPassword(payload){
                        return appHttp.post(`/v1/user/forgot-password`, payload);
                    }
                    // Vue.appApi().guest().user().resetPassword()
                    function resetPassword(payload){
                        return appHttp.post(`/v1/user/reset`, payload);
                    }

                    // Vue.appApi().guest().user().verifyEmailAddress(payload)
                    function verifyEmailAddress(payload){
                        return appHttp.post(`/api/v1/user/verify-email`, payload);
                    }

                    // Vue.appApi().guest().user().getInvitedUserStatus(query)
                    function getInvitedUserStatus(query){
                        return appHttp.get(`/api/v1/user/account-invite`, { params: query });
                    }
                }
                // Vue.appApi().guest().getClientEnv()
                function getClientEnv(){
                    return appHttp.get(`/api/v1/credentials`);
                }
            }
            // Vue.appApi().authorized()
            function authorized(){
                return {
                    user,
                    technicianLists,
                    lookups,
                    account,
                    reports,
                    claims,
                    equipment,
                    fileManagement,
                    invoicedWorkorders,
                    kpiBillingWorkorders,
                    leads,
                    mechanics,
                    pms,
                    rentalCheckins,
                    shopWorkorders,
                    workorders,
                    changeLog,
                    salesOrderLines,
                    partsVan,
                    repair,
                    cases,
                    crmNotes,
                    salesmen,
                    sales,
                    flyers,
                };
                // Vue.appApi().authorized().user()
                function user(){
                    return {
                        store,
                        changePassword,
                        changeEmail,
                        getUser,
                        resendVerificationEmail,
                        acceptAccountInvite,
                    };

                    // Vue.appApi().authorized().user().store(payload)
                    function store(payload){
                        return appHttp.put('/api/v1/user/', payload);
                    }

                    // Vue.appApi().authorized().user().changePassword(payload)
                    function changePassword(payload){
                        return appHttp.put(`/api/v1/user/change-password`, payload);
                    }

                    // Vue.appApi().authorized().user().changeEmail(payload)
                    function changeEmail(payload){
                        return appHttp.put(`/api/v1/user/change-email`, payload);
                    }

                    // Vue.appApi().authorized().user().getUser()
                    function getUser(){
                        return appHttp.get(`/api/v1/user`);
                    }

                    // Vue.appApi().authorized().user().resendVerificationEmail()
                    function resendVerificationEmail(){
                        return appHttp.get(`/api/v1/user/verify-email`);
                    }

                    // Vue.appApi().authorized().user().acceptAccountInvite(payload)
                    function acceptAccountInvite(payload){
                        return appHttp.post(`/api/v1/user/accept-invite`, payload);
                    }
                }
                // Vue.appApi().authorized().technicianLists(pageName)
                function technicianLists(pageName){
                    return {
                        getTechnicianListSections,
                        updateTechnicianListSections
                    };
                    // Vue.appApi().authorized().technicianLists(pageName).getTechnicianListSections()
                    function getTechnicianListSections(){
                        return appHttp.get(`/api/v1/technician-list/${pageName}`);
                    }
                    // Vue.appApi().authorized().technicianLists(pageName).updateTechnicianListSections(payload)
                    function updateTechnicianListSections(payload){
                        return appHttp.post(`/api/v1/technician-list/${pageName}`, payload);
                    }
                }
                // Vue.appApi().authorized().lookups(lookupId)
                function lookups(lookupId){
                    return {
                        getLookupsByType,
                        createLookup,
                        updateLookup,
                        deleteLookup
                    };
                    // Vue.appApi().authorized().lookups().getLookupsByType(type_slug)
                    function getLookupsByType(type_slug){
                        return appHttp.get(`/api/v1/lookups`, { params: { type_slug }});
                    }
                    // Vue.appApi().authorized().lookups().createLookup(payload)
                    function createLookup(payload){
                        return appHttp.put(`/api/v1/lookups`, payload);
                    }
                    // Vue.appApi().authorized().lookups(lookupId).updateLookup(payload)
                    function updateLookup(payload){
                        return appHttp.put(`/api/v1/lookups/${lookupId}`, payload);
                    }
                    // Vue.appApi().authorized().lookups(lookupId).deleteLookup()
                    function deleteLookup(){
                        return appHttp.delete(`/api/v1/lookups/${lookupId}`);
                    }
                }
                // Vue.appApi().authorized().account()
                function account(){
                    return {
                        getUser,
                        getDirectoryResources,
                        indexPermissions,
                        roles,
                        accountUsers,
                    };
                    // Vue.appApi().authorized().account().getUser()
                    function getUser(){
                        return appHttp.get(`/api/v1/account/user`);
                    }
                    // Vue.appApi().authorized().account().getDirectoryResources()
                    function getDirectoryResources(){
                        return appHttp.get(`/api/v1/account/directory-resources`);
                    }
                    // Vue.appApi().authorized().account().indexPermissions()
                    function indexPermissions(){
                        return appHttp.get(`/api/v1/account/permissions`);
                    }
                    // Vue.appApi().authorized().account().roles(roleId)
                    function roles(roleId){
                        return {
                            indexRoles,
                            createRole,
                            updateRole,
                            deleteRole,
                        };
                        // Vue.appApi().authorized().account().roles().indexRoles()
                        function indexRoles(){
                            return appHttp.get(`/api/v1/account/roles`);
                        }
                        // Vue.appApi().authorized().account().roles().createRole(payload)
                        function createRole(payload){
                            return appHttp.put(`/api/v1/account/roles`, payload);
                        }
                        // Vue.appApi().authorized().account().roles(roleId).updateRole(payload)
                        function updateRole(payload){
                            return appHttp.post(`/api/v1/account/roles/${roleId}`, payload);
                        }
                        // Vue.appApi().authorized().account().roles(roleId).deleteRole()
                        function deleteRole(){
                            return appHttp.delete(`/api/v1/account/roles/${roleId}`);
                        }
                    }
                    // Vue.appApi().authorized().account().accountUsers(accountUserId)
                    function accountUsers(accountUserId){
                        return {
                            getAccountUsers,
                            updateAccountUser,
                            deleteAccountUser,
                            createInvite,
                            listInvites,
                            deleteAccountInvite,
                            resendAccountInvite,
                        };
                        // Vue.appApi().authorized().account().accountUsers().getAccountUsers()
                        function getAccountUsers(){
                            return appHttp.get(`/api/v1/account/account-users`);
                        }
                        // Vue.appApi().authorized().account().accountUsers(accountUserId).updateAccountUser(payload)
                        function updateAccountUser(payload){
                            return appHttp.post(`/api/v1/account/account-users/${accountUserId}`, payload);
                        }
                        // Vue.appApi().authorized().account().accountUsers(accountUserId).deleteAccountUser()
                        function deleteAccountUser(){
                            return appHttp.delete(`/api/v1/account/account-users/${accountUserId}`);
                        }
                        // Vue.appApi().authorized().account().accountUsers().createInvite(payload)
                        function createInvite(payload){
                            return appHttp.post(`/api/v1/account/account-users/invite`, payload);
                        }
                        // Vue.appApi().authorized().account().accountUsers().listInvites()
                        function listInvites(){
                            return appHttp.get(`/api/v1/account/account-users/invite`);
                        }
                        // Vue.appApi().authorized().account().accountUsers().deleteAccountInvite(accountInviteId)
                        function deleteAccountInvite(accountInviteId){
                            return appHttp.delete(`/api/v1/account/account-users/invite/${accountInviteId}`);
                        }
                        // Vue.appApi().authorized().account().accountUsers().resendAccountInvite(accountInviteId)
                        function resendAccountInvite(accountInviteId){
                            return appHttp.post(`/api/v1/account/account-users/invite/${accountInviteId}/resend`);
                        }
                    }
                }
                function reports(){
                    return {
                        getEquipmentDataList,
                        getEquipmentServiceActionSpreadsheet,
                        getCustomerDataList,
                        getZipcodeSpreadsheet,
                        getTerritoryListSpreadsheet,
                        generateGenericSpreadsheet,
                        generateCustomerFleetReport,
                    };
                    // Vue.appApi().authorized().reports().getEquipmentDataList()
                    function getEquipmentDataList(){
                        return appHttp.get(`/api/v1/reports/equipment-data-list`, { responseType: 'blob' });
                    }
                    // Vue.appApi().authorized().reports().getEquipmentServiceActionSpreadsheet()
                    function getEquipmentServiceActionSpreadsheet(){
                        return appHttp.get(`/api/v1/reports/service-action-spreadsheet`, { responseType: 'blob' });
                    }
                    // Vue.appApi().authorized().reports().getCustomerDataList(query)
                    function getCustomerDataList(query){
                        return appHttp.get(`/api/v1/reports/customer-data-list`, { params: query, responseType: 'blob' });
                    }
                    // Vue.appApi().authorized().reports().getZipcodeSpreadsheet(query)
                    function getZipcodeSpreadsheet(query){
                        return appHttp.get(`/api/v1/reports/zipcodes`, { params: query, responseType: 'blob' });
                    }
                    // Vue.appApi().authorized().reports().getTerritoryListSpreadsheet(query)
                    function getTerritoryListSpreadsheet(query){
                        return appHttp.get(`/api/v1/reports/territory-list`, { params: query, responseType: 'blob' });
                    }
                    // Vue.appApi().authorized().reports().generateGenericSpreadsheet(payload)
                    function generateGenericSpreadsheet(payload){
                        return appHttp.post(`/api/v1/reports/generate-spreadsheet`, payload, { responseType: 'blob' });
                    }
                    // Vue.appApi().authorized().reports().generateCustomerFleetReport(query)
                    function generateCustomerFleetReport(query){
                        return appHttp.get(`/api/v1/reports/fleet`, { params: query });
                    }
                }
                function claims(){
                    return {
                        indexClosedClaims,
                    };
                    // Vue.appApi().authorized().claims().indexClosedClaims(queryObject)
                    function indexClosedClaims(queryObject){
                        return appHttp.get('/api/v1/claims/closed', { params: queryObject });
                    }
                }
                function equipment(fleetCode){
                    return {
                        getEquipment,
                        autocompleteEquipmentSearch,
                        autocompleteCustomerSearch,
                        getEquipmentCategories,
                        getEquipmentGroups,
                        getEquipmentCheckins,
                        updateEquipmentCheckin,
                        getByCustomerNumber,
                        getByCustomerName,
                        images,
                        notes,
                        fleetReport,
                        equipmentGroups
                    };
                    // Vue.appApi().authorized().equipment().getEquipment(query, cancelToken)
                    function getEquipment(query, cancelToken = null){
                        const options = { params: query };
                        if(cancelToken){
                            options.cancelToken = cancelToken;
                        }
                        return appHttp.get('/api/v1/equipment', options);
                    }
                    // Vue.appApi().authorized().equipment().autocompleteEquipmentSearch(query, cancelToken)
                    function autocompleteEquipmentSearch(query, cancelToken = null){
                        const options = { params: query };
                        if(cancelToken){
                            options.cancelToken = cancelToken;
                        }
                        return appHttp.get('/api/v1/equipment/auto-complete', options);
                    }
                    // Vue.appApi().authorized().equipment().autocompleteCustomerSearch(query, cancelToken)
                    function autocompleteCustomerSearch(query, cancelToken = null){
                        const options = { params: query };
                        if(cancelToken){
                            options.cancelToken = cancelToken;
                        }
                        return appHttp.get('/api/v1/equipment/customer/auto-complete', options);
                    }
                    // Vue.appApi().authorized().equipment().getEquipmentCategories()
                    function getEquipmentCategories(){
                        return appHttp.get('/api/v1/equipment/equipment-categories', {});
                    }
                    // Vue.appApi().authorized().equipment().getEquipmentGroups()
                    function getEquipmentGroups(){
                        return appHttp.get('/api/v1/equipment/equipment-groups', {});
                    }
                    // Vue.appApi().authorized().equipment().getEquipmentCheckins(payload)
                    function getEquipmentCheckins(payload){
                        return appHttp.get('/api/v1/equipment/checkins', { params: payload });
                    }
                    // Vue.appApi().authorized().equipment().updateEquipmentCheckin(payload)
                    function updateEquipmentCheckin(payload){
                        return appHttp.post('/api/v1/equipment/checkins/' + fleetCode + '/' + payload.contract, payload);
                    }
                    // Vue.appApi().authorized().equipment().getByCustomerNumber(customerNumber)
                    function getByCustomerNumber(customerNumber){
                        return appHttp.get(`/api/v1/equipment/customer/${customerNumber}`);
                    }
                    // Vue.appApi().authorized().equipment().getByCustomerName(customerNumber)
                    function getByCustomerName(customerName){
                        return appHttp.get(`/api/v1/equipment/customer/by-customer-name`, { params: { customer_name: customerName }});
                    }
                    // Vue.appApi().authorized().equipment().fleetReport(query)
                    function fleetReport(query){
                        return appHttp.get(`/api/v1/equipment/fleet-report`, { params: query });
                    }
                    // Vue.appApi().authorized().equipment().equipmentGroups()
                    function equipmentGroups(){
                        return {
                            indexGroups
                        };
                        // Vue.appApi().authorized().equipment().equipmentGroups().indexGroups()
                        function indexGroups(){
                            return appHttp.get(`/api/v1/equipment/equipment-groups`);
                        }
                    }

                    // Vue.appApi().authorized().equipment().images()
                    function images(){
                        return {
                            deleteEquipmentImage,
                            acceptEquipmentImage,
                            indexPendingEquipmentImages,
                            getEncodedImage,
                            storeEquipmentImage
                        };
                        // Vue.appApi().authorized().equipment().images().deleteEquipmentImage(query)
                        function deleteEquipmentImage(query){
                            return appHttp.delete(`/api/v1/equipment/images`, { params: query });
                        }
                        // Vue.appApi().authorized().equipment().images().acceptEquipmentImage(payload)
                        function acceptEquipmentImage(payload){
                            return appHttp.post(`/api/v1/equipment/images/accept`, payload);
                        }
                        // Vue.appApi().authorized().equipment().images().indexPendingEquipmentImages(query)
                        function indexPendingEquipmentImages(query){
                            return appHttp.get(`/api/v1/equipment/images/uploads`, { params: query });
                        }
                        // Vue.appApi().authorized().equipment().images().getEncodedImage(query)
                        function getEncodedImage(query){
                            return appHttp.get(`/api/v1/equipment/images`, { params: query });
                        }
                        // Vue.appApi().authorized().equipment().images().storeEquipmentImage(payload)
                        function storeEquipmentImage(formData, progressCallback){
                            const options = {
                                headers: { 'Content-Type': 'multipart/form-data' }
                            };
                            if(typeof progressCallback === 'function'){
                                options.onUploadProgress = progressCallback;
                            }
                            return appHttp.post(`/api/v1/equipment/images`, formData, options);
                        }
                    }
                    // Vue.appApi().authorized().equipment().notes(noteId)
                    function notes(noteId = 0){
                        return {
                            createEquipmentNote,
                            updateEquipmentNote,
                        };
                        // Vue.appApi().authorized().equipment().notes().createEquipmentNote(payload)
                        function createEquipmentNote(payload){
                            return appHttp.post(`/api/v1/equipment/notes`, payload);
                        }
                        // Vue.appApi().authorized().equipment().notes(noteId).updateEquipmentNote(payload)
                        function updateEquipmentNote(payload){
                            return appHttp.post(`/api/v1/equipment/notes/${noteId}`, payload);
                        }
                    }
                }
                function fileManagement(){
                    return {
                        getFiles,
                        downloadFile,
                    };
                    // Vue.appApi().authorized().fileManagement().getFiles()
                    function getFiles(){
                        return appHttp.get('/api/v1/file-management');
                    }
                    function downloadFile(payload){
                        return appHttp.get('/api/v1/file-management/download', {
                            params: payload,
                            responseType: 'arraybuffer',
                        });
                    }
                }
                function invoicedWorkorders(){
                    return {
                        getWorkorders,
                        exportServiceInvoicedWorkorders,
                        createUpdateServiceAdjustNote,
                        createUpdateServiceInvoiceNote,
                    };
                    // Vue.appApi().authorized().invoicedWorkorders().getWorkorders(payload)
                    function getWorkorders(payload){
                        return appHttp.get('/api/v1/invoiced-workorders', { params: payload });
                    }
                    // Vue.appApi().authorized().invoicedWorkorders().exportServiceInvoicedWorkorders(query)
                    function exportServiceInvoicedWorkorders(query){
                        return appHttp.get('/api/v1/invoiced-workorders/download', { params: query, responseType: 'blob' });
                    }
                    // Vue.appApi().authorized().invoicedWorkorders().createUpdateServiceAdjustNote(payload)
                    function createUpdateServiceAdjustNote(payload){
                        return appHttp.post('/api/v1/invoiced-workorders/service-adjust-notes', payload);
                    }
                    // Vue.appApi().authorized().invoicedWorkorders().createUpdateServiceInvoiceNote(payload)
                    function createUpdateServiceInvoiceNote(payload){
                        return appHttp.post('/api/v1/invoiced-workorders/service-invoice-notes', payload);
                    }
                }
                function kpiBillingWorkorders(){
                    return {
                        getBillingOpenAndCloseDates,
                    };
                    // Vue.appApi().authorized().kpiBillingWorkorders().getBillingOpenAndCloseDates(queryObject)
                    function getBillingOpenAndCloseDates(queryObject){
                        return appHttp.get('/api/v1/kpi-billing-workorders', { params: queryObject });
                    }
                }
                function leads(leadId){
                    return {
                        indexLeads,
                        indexMyLeads,
                        createLead,
                        updateLead,
                    };
                    // Vue.appApi().authorized().leads().indexLeads(query)
                    function indexLeads(query){
                        return appHttp.get('/api/v1/leads', { params: query });
                    }
                    // Vue.appApi().authorized().leads().indexMyLeads(query)
                    function indexMyLeads(query){
                        return appHttp.get('/api/v1/leads/mine', { params: query });
                    }
                    // Vue.appApi().authorized().leads().createLead()
                    function createLead(payload){
                        return appHttp.post('/api/v1/leads', payload);
                    }
                    // Vue.appApi().authorized().leads(leadId).updateLead(payload)
                    function updateLead(payload){
                        return appHttp.post('/api/v1/leads/' + leadId, payload);
                    }
                }
                function mechanics(mechanicId){
                    return {
                        getAllWorkorders,
                        getWorkorders,
                        getAllPms,
                        getPMs,
                        getShopWorkorders,
                        exportServiceWork,
                        exportAllServiceWork,
                        exportServicePms,
                    };
                    // Vue.appApi().authorized().mechanics(mechanicId).getAllWorkorders(payload)
                    function getAllWorkorders(payload){
                        return appHttp.get('/api/v1/mechanics/' + mechanicId + '/all-workorders', { params: payload });
                    }
                    // Vue.appApi().authorized().mechanics(mechanicId).getWorkorders(payload)
                    function getWorkorders(payload){
                        return appHttp.get('/api/v1/mechanics/' + mechanicId + '/workorders', { params: payload });
                    }
                    // Vue.appApi().authorized().mechanics(mechanicId).getAllPms(payload)
                    function getAllPms(payload){
                        return appHttp.get('/api/v1/mechanics/' + mechanicId + '/all-pms', { params: payload });
                    }
                    // Vue.appApi().authorized().mechanics(mechanicId).getPMs(payload)
                    function getPMs(payload){
                        return appHttp.get('/api/v1/mechanics/' + mechanicId + '/pms', { params: payload });
                    }
                    // Vue.appApi().authorized().mechanics(mechanicId).getShopWorkorders(payload)
                    function getShopWorkorders(payload){
                        return appHttp.get('/api/v1/mechanics/' + mechanicId + '/shop-workorders', { params: payload });
                    }
                    // Vue.appApi().authorized().mechanics(mechanicId).exportServiceWork(payload)
                    function exportServiceWork(payload){
                        return appHttp.get('/api/v1/mechanics/' + mechanicId + '/export-service-work', { params: payload, responseType: 'blob' });
                    }
                    // Vue.appApi().authorized().mechanics(mechanicId).exportAllServiceWork()
                    function exportAllServiceWork(){
                        return appHttp.get('/api/v1/mechanics/' + mechanicId + '/export-all-service-work', { responseType: 'blob' });
                    }
                    // Vue.appApi().authorized().mechanics(mechanicId).exportServicePms()
                    function exportServicePms(){
                        return appHttp.get('/api/v1/mechanics/' + mechanicId + '/export-service-pms', { responseType: 'blob' });
                    }
                }
                function pms(){
                    return {
                        getComments,
                        update,
                        download,
                    };
                    // Vue.appApi().authorized().mechanics().getComments(payload)
                    function getComments(payload){
                        return appHttp.get('/api/v1/pms/' + payload.workorder_number + '/comments', { params: payload });
                    }
                    // Vue.appApi().authorized().pms().update(payload)
                    function update(payload){
                        return appHttp.post('/api/v1/pms/' + payload.workorder_number, payload);
                    }
                    // Vue.appApi().authorized().pms().download(payload)
                    function download(payload){
                        return appHttp.get('/api/v1/pms/download', { params: payload });
                    }
                }
                function rentalCheckins(fleetCode){
                    return {
                        findByFleetCode,
                        getBarCode,
                        getCheckinRecords,
                        getMeterReadings,
                        getPms,
                        getWorkorders,
                        indexHulRentalCheckins,
                        storeCheckinRecord,
                        createUpdateWorkOrderInvoiceNote,
                        exportHulRentalCheckins
                    };

                    // Vue.appApi().authorized().rentalCheckins(fleetCode).findByFleetCode()
                    function findByFleetCode(){
                        return appHttp.get('/api/v1/rental-checkins/' + fleetCode);
                    }

                    // Vue.appApi().authorized().rentalCheckins(fleetCode).getBarCode(fleetCode)
                    function getBarCode(){
                        return appHttp.get('/api/v1/rental-checkins/' + fleetCode + '/bar-code');
                    }

                    // Vue.appApi().authorized().rentalCheckins(fleetCode).getCheckinRecords(payload)
                    function getCheckinRecords(payload){
                        return appHttp.get('/api/v1/rental-checkins/' + fleetCode + '/checkin-records', { params: payload });
                    }

                    // Vue.appApi().authorized().rentalCheckins(fleetCode).getMeterReadings(payload)
                    function getMeterReadings(payload){
                        return appHttp.get('/api/v1/rental-checkins/' + fleetCode + '/meter-readings', { params: payload });
                    }

                    // Vue.appApi().authorized().rentalCheckins(fleetCode).getPms(payload)
                    function getPms(payload){
                        return appHttp.get('/api/v1/rental-checkins/' + fleetCode + '/pms', { params: payload });
                    }

                    // Vue.appApi().authorized().rentalCheckins(fleetCode).getWorkorders(payload)
                    function getWorkorders(payload){
                        return appHttp.get('/api/v1/rental-checkins/' + fleetCode + '/workorders', { params: payload });
                    }

                    // Vue.appApi().authorized().rentalCheckins(fleetCode).storeCheckinRecord(payload)
                    function storeCheckinRecord(payload){
                        return appHttp.post('/api/v1/rental-checkins/' + fleetCode + '/checkin-records', payload);
                    }
                    // Vue.appApi().authorized().rentalCheckins().indexHulRentalCheckins(query)
                    function indexHulRentalCheckins(query){
                        return appHttp.get(`/api/v1/rental-checkins`, { params: query });
                    }
                    // Vue.appApi().authorized().rentalCheckins().createUpdateWorkOrderInvoiceNote(payload)
                    function createUpdateWorkOrderInvoiceNote(payload){
                        return appHttp.post('/api/v1/rental-checkins/workorder-invoice-notes', payload);
                    }
                    // Vue.appApi().authorized().rentalCheckins().exportHulRentalCheckins(query)
                    function exportHulRentalCheckins(query){
                        return appHttp.get('/api/v1/rental-checkins/download/xlsx', { params: query, responseType: 'blob' });
                    }
                }
                function shopWorkorders(workorderNumber){
                    return {
                        update,
                    };
                    // Vue.appApi().authorized().shopWorkorders(workorderNumber).update(payload)
                    function update(payload){
                        return appHttp.post('/api/v1/shop-workorders/' + workorderNumber, payload);
                    }
                }
                function workorders(workorderNumber){
                    return {
                        findWorkorder,
                        update,
                        calculateHours,
                        getWarranties,
                        storeWarrantyImage,
                        getWorkorderCustomer,
                        getWorkorderLines,
                        indexHulWorkOrders,
                        orderLines,
                    };
                    // Vue.appApi().authorized().workorders(workorderNumber).findWorkorder()
                    function findWorkorder(){
                        return appHttp.get('/api/v1/workorders/' + workorderNumber);
                    }
                    // Vue.appApi().authorized().workorders(workorderNumber).update(payload)
                    function update(payload){
                        return appHttp.post('/api/v1/workorders/' + workorderNumber, payload);
                    }
                    // Vue.appApi().authorized().workorders(workorderNumber).calculateHours(payload)
                    function calculateHours(payload){
                        return appHttp.get('/api/v1/workorders/' + workorderNumber + '/calculate', { params: payload });
                    }
                    // Vue.appApi().authorized().workorders(workorderNumber).getWarranties(payload)
                    function getWarranties(payload){
                        return appHttp.get('/api/v1/workorders/' + workorderNumber + '/warranties', { params: payload });
                    }
                    // Vue.appApi().authorized().workorders(workorderNumber).storeWarrantyImage(payload)
                    function storeWarrantyImage(formData, progressCallback){
                        const options = {
                            headers: { 'Content-Type': 'multipart/form-data' }
                        };
                        if(typeof progressCallback === 'function'){
                            options.onUploadProgress = progressCallback;
                        }
                        return appHttp.post(`/api/v1/workorders/${workorderNumber}/warranty-upload`, formData, options);
                    }
                    // Vue.appApi().authorized().workorders(workorderNumber).getWorkorderCustomer()
                    function getWorkorderCustomer(){
                        return appHttp.get('/api/v1/workorders/' + workorderNumber + '/customer');
                    }
                    // Vue.appApi().authorized().workorders(workorderNumber).getWorkorderLines(payload)
                    function getWorkorderLines(payload){
                        return appHttp.get('/api/v1/workorders/' + workorderNumber + '/lines', { params: payload });
                    }
                    // Vue.appApi().authorized().workorders().indexHulWorkOrders(query)
                    function indexHulWorkOrders(query){
                        return appHttp.get(`/api/v1/workorders`, { params: query });
                    }
                    // Vue.appApi().authorized().workorders().orderLines()
                    function orderLines(){
                        return {
                            searchPartsWorkOrderLines,
                            getUniqueReqCodes,
                            queryPartsOrderLines,
                        };
                        // Vue.appApi().authorized().workorders().orderLines().searchPartsWorkOrderLines(payload)
                        function searchPartsWorkOrderLines(payload){
                            return appHttp.post('/api/v1/workorders/lines/parts-margin', payload);
                        }
                        // Vue.appApi().authorized().workorders().orderLines().getUniqueReqCodes()
                        function getUniqueReqCodes(){
                            return appHttp.get('/api/v1/workorders/lines/parts-requisition/req-codes');
                        }
                        // Vue.appApi().authorized().workorders().orderLines().queryPartsOrderLines(payload)
                        function queryPartsOrderLines(payload){
                            return appHttp.post('/api/v1/workorders/lines/parts-requisition', payload);
                        }
                    }
                }
                // Vue.appApi().authorized().changeLog()
                function changeLog(){
                    return {
                        indexChangeLogs
                    };
                    // Vue.appApi().authorized().changeLog().indexChangeLogs(query)
                    function indexChangeLogs(query){
                        return appHttp.get(`/api/v1/change-logs`, { params: query });
                    }
                }
                // Vue.appApi().authorized().salesOrderLines()
                function salesOrderLines(){
                    return {
                        querySalesOrderLines
                    };
                    // Vue.appApi().authorized().salesOrderLines().querySalesOrderLines(payload)
                    function querySalesOrderLines(payload){
                        return appHttp.post(`/api/v1/sales-order-lines/search`, payload);
                    }
                }
                // Vue.appApi().authorized().partsVan()
                function partsVan(){
                    return {
                        createPartsVanSpreadsheet
                    };
                    // Vue.appApi().authorized().partsVan().createPartsVanSpreadsheet(query)
                    function createPartsVanSpreadsheet(query){
                        return appHttp.get(`/api/v1/parts-van-spreadsheet`, { params: query, responseType: 'blob' });
                    }
                }
                // Vue.appApi().authorized().repair()
                function repair(){
                    return {
                        indexRepairEstimates,
                        indexManufacturers,
                        indexPreferredResources,
                    };
                    // Vue.appApi().authorized().repair().indexRepairEstimates(query)
                    function indexRepairEstimates(query){
                        return appHttp.get(`/api/v1/repair`, { params: query });
                    }
                    // Vue.appApi().authorized().repair().indexManufacturers()
                    function indexManufacturers(){
                        return appHttp.get(`/api/v1/repair/manufacturers`);
                    }
                    // Vue.appApi().authorized().repair().indexPreferredResources()
                    function indexPreferredResources(){
                        return appHttp.get(`/api/v1/repair/preferred-resources`);
                    }
                }
                // Vue.appApi().authorized().cases(caseNumber)
                function cases(caseNumber){
                    return {
                        indexHulCases,
                        getCaseTypes,
                        getCaseClasses,
                        getCaseManufacturers,
                        getCaseResources,
                        getEmailReasonCodes,
                        updateCaseNote,
                        emailCaseResource,
                        casesNew
                    };
                    // Vue.appApi().authorized().cases().indexHulCases(query)
                    function indexHulCases(query){
                        return appHttp.get(`/api/v1/crm/cases`, { params: query });
                    }
                    // Vue.appApi().authorized().cases().getCaseTypes()
                    function getCaseTypes(){
                        return appHttp.get(`/api/v1/crm/cases/types`);
                    }
                    // Vue.appApi().authorized().cases().getCaseClasses()
                    function getCaseClasses(){
                        return appHttp.get(`/api/v1/crm/cases/classes`);
                    }
                    // Vue.appApi().authorized().cases().getCaseManufacturers()
                    function getCaseManufacturers(){
                        return appHttp.get(`/api/v1/crm/cases/manufacturers`);
                    }
                    // Vue.appApi().authorized().cases().getCaseResources()
                    function getCaseResources(){
                        return appHttp.get(`/api/v1/crm/cases/resources`);
                    }
                    // Vue.appApi().authorized().cases().getEmailReasonCodes()
                    function getEmailReasonCodes(){
                        return appHttp.get(`/api/v1/crm/cases/reason-codes`);
                    }
                    // Vue.appApi().authorized().cases(caseNumber).updateCaseNote(payload)
                    function updateCaseNote(payload){
                        return appHttp.post(`/api/v1/crm/cases/${caseNumber}/note`, payload);
                    }
                    // Vue.appApi().authorized().cases(caseNumber).emailCaseResource(payload)
                    function emailCaseResource(payload){
                        return appHttp.post(`/api/v1/crm/cases/${caseNumber}/email`, payload);
                    }

                    // Vue.appApi().authorized().cases().casesNew()
                    function casesNew(){
                        return {
                            searchHulCasesNew
                        };
                        // Vue.appApi().authorized().cases().casesNew().searchHulCasesNew(query, cancelToken)
                        function searchHulCasesNew(query, cancelToken = null){
                            const options = {
                                params: query
                            };
                            if(cancelToken){
                                options.cancelToken = cancelToken;
                            }
                            return appHttp.get(`/api/v1/crm/cases/new/search`, options);
                        }
                    }
                }
                // Vue.appApi().authorized().crmNotes()
                function crmNotes(){
                    return {
                        getCrmNotes,
                        getCrmCaseNotes,
                        getCrmNoteLines,
                        getCrmCaseNoteLines,
                    };
                    // Vue.appApi().authorized().crmNotes().getCrmNotes(query)
                    function getCrmNotes(query){
                        return appHttp.get(`/api/v1/crm/notes`, { params: query });
                    }
                    // Vue.appApi().authorized().crmNotes().getCrmCaseNotes(query)
                    function getCrmCaseNotes(query){
                        return appHttp.get(`/api/v1/crm/case-notes`, { params: query });
                    }
                    // Vue.appApi().authorized().crmNotes().getCrmNoteLines(query)
                    function getCrmNoteLines(query){
                        return appHttp.get(`/api/v1/crm/notes-lines`, { params: query });
                    }
                    // Vue.appApi().authorized().crmNotes().getCrmCaseNoteLines(query)
                    function getCrmCaseNoteLines(query){
                        return appHttp.get(`/api/v1/crm/case-note-lines`, { params: query });
                    }
                }
                // Vue.appApi().authorized().salesmen()
                function salesmen(){
                    return {
                        search,
                    };
                    // Vue.appApi().authorized().salesmen().search(query, cancelToken)
                    function search(query, cancelToken = null){
                        const options = { params: query };
                        if(cancelToken){
                            options.cancelToken = cancelToken;
                        }
                        return appHttp.get(`/api/v1/salesmen/search`, options);
                    }
                }
                // Vue.appApi().authorized().sales()
                function sales(){
                    return {
                        prepareSalesProcessForLead,
                        storeSalesProcess,
                        storeReportOfSale,
                        downloadSalesDocument,
                        prepareReportOfSaleForLead,
                        nutshell
                    };
                    // Vue.appApi().authorized().sales().prepareSalesProcessForLead(query)
                    function prepareSalesProcessForLead(query){
                        const options = { params: query };
                        return appHttp.get(`/api/v1/sales/process/prepare`, options);
                    }
                    // Vue.appApi().authorized().sales().storeSalesProcess(payload)
                    function storeSalesProcess(payload){
                        return appHttp.post(`/api/v1/sales/process/store`, payload);
                    }
                    // Vue.appApi().authorized().sales().storeReportOfSale(payload)
                    function storeReportOfSale(payload){
                        return appHttp.post(`/api/v1/sales/report-of-sale/store`, payload);
                    }
                    // Vue.appApi().authorized().sales().downloadSalesDocument(query)
                    function downloadSalesDocument(query){
                        return appHttp.get(`/api/v1/sales/document`, { params: query, responseType: 'blob' });
                    }
                    // Vue.appApi().authorized().sales().prepareReportOfSaleForLead(query)
                    function prepareReportOfSaleForLead(query){
                        return appHttp.get(`/api/v1/sales/report-of-sale/prepare`, { params: query });
                    }
                    // Vue.appApi().authorized().sales().nutshell()
                    function nutshell(){
                        return {
                            prepareLiftnetData,
                            uploadFileFromUrl,
                            buildFlyerForNutshell,
                            getCustomerNumberForAccount,
                            uploadSalesDocument,
                        };
                        // Vue.appApi().authorized().sales().nutshell().prepareLiftnetData(query)
                        function prepareLiftnetData(query){
                            return appHttp.get(`/api/v1/nutshell/liftnet-data`, { params: query });
                        }
                        // Vue.appApi().authorized().sales().nutshell().uploadFileFromUrl(payload)
                        function uploadFileFromUrl(payload){
                            return appHttp.post(`/api/v1/nutshell/upload-from-url`, payload);
                        }
                        // Vue.appApi().authorized().sales().nutshell().buildFlyerForNutshell(payload)
                        function buildFlyerForNutshell(payload){
                            return appHttp.post(`/api/v1/nutshell/build-flyer`, payload);
                        }
                        // Vue.appApi().authorized().sales().nutshell().getCustomerNumberForAccount(query)
                        function getCustomerNumberForAccount(query){
                            return appHttp.get(`/api/v1/nutshell/customer-number`, { params: query });
                        }
                        // Vue.appApi().authorized().sales().nutshell().uploadSalesDocument(query)
                        function uploadSalesDocument(query){
                            return appHttp.get(`/api/v1/nutshell/upload-sales-doc`, { params: query });
                        }
                    }
                }
                // Vue.appApi().authorized().flyers()
                function flyers(){
                    return {
                        buildFlyer,
                    };
                    // Vue.appApi().authorized().flyers().buildFlyer(payload)
                    function buildFlyer(payload){
                        return appHttp.get('/api/v1/flyers/build-flyer', { params: payload, responseType: 'blob' });
                    }
                }
            }
        }
        function modifyAllRequestConfigs(config){

            if(store.state.guest.user.user && store.state.guest.user.user.current_account && store.state.guest.user.user.current_account.id){
                config.headers['current-account-id'] = store.state.guest.user.user.current_account.id;
            }
            config.headers['Authorization'] = window.axios.defaults.headers.common['Authorization'];

            return config;
        }
        function catchAllResponseFailures(error){
            if(!error.response){
                return Promise.reject(error);
            }
            var originalRequest = error.config;
            if(error.response.data instanceof Blob){
                return parseErrorBlob().then(recatchResponseFailure);
            }
            var endpointNotSupported = error.response && error.response.status === 410 && error.response.data && error.response.data.slug === 'endpoint_obsolete';
            if(endpointNotSupported){
                return router.push({ name: 'upgrade-required' });
            }
            var errorStatusIsUnauthorized = error.response && error.response.status === 401;
            var requestHasNotBeenTriedAgain = !originalRequest._triedAgain;

            if(errorStatusIsUnauthorized && requestHasNotBeenTriedAgain){
                originalRequest._triedAgain = true;

                if(!refreshAuthenticationPromise){
                    refreshAuthenticationPromise = Vue.clientStorage.getItem('refresh_token').then(refreshAuthToken);
                }
                return refreshAuthenticationPromise.then(getTokenSuccess).catch(getTokenError);
            }

            if(error.response && error.response.statusText){
                error.response.appMessage = error.response.statusText;
            }

            var errorHasMessageProperty = error.response && error.response.data && error.response.data.message;
            if(errorHasMessageProperty && error.response.data.message !== ''){
                error.response.appMessage += ': ' + error.response.data.message;
            }

            var errorIsValidationError = error.response.status === 422 && error.response.data.errors;
            if(errorIsValidationError){
                error.response.appMessage = 'Validation Error: Check inputs and try again.';
            }

            return Promise.reject(error.response);

            function parseErrorBlob(){
                return new Promise(function(resolve, reject){
                    const reader = new FileReader();
                    reader.addEventListener('loadend', (event) => {
                        error.response.data = JSON.parse(event.srcElement.result);
                        resolve();
                    });
                    reader.readAsText(error.response.data);
                });
            }

            function recatchResponseFailure(){
                return catchAllResponseFailures(error);
            }

            function refreshAuthToken(refreshToken){
                return appHttp.post('/v1/user/login/refresh', { refreshToken }).then(storeTokens);

                function storeTokens(response){
                    refreshAuthenticationPromise = null;
                    return Promise.all([
                        Vue.clientStorage.setItem('access_token', response.data.access_token),
                        Vue.clientStorage.setItem('refresh_token', response.data.refresh_token)
                    ]).then(returnResponse);
                    function returnResponse(){
                        return response;
                    }
                }
            }

            function getTokenSuccess(response){
                window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;
                originalRequest.headers['Authorization'] = 'Bearer ' + response.data.access_token;
                return window.axios(originalRequest);
            }
            function getTokenError(error){
                if(refreshAuthenticationPromise){
                    return store.dispatch('user/LOGOUT_FRONTEND').finally(logoutSuccess);
                } else {
                    return catchAllResponseFailures(error);
                }

                function logoutSuccess(){
                    router.go();
                }
            }
        }
    }
}
