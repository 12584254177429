import fileDownload from 'js-file-download';

export default {
    components: {},
    props: {
        workorders: {
            type: Array,
            required: true
        },
        excludedWorkorderNumbers: {
            type: Array,
            required: true
        },
        workingDaysProperty: {
            type: String,
            default: 'numberOfWorkingDays'
        },
    },
    data,
    computed: getComputed(),
    methods: getMethods()
};

function data(){
    return {
        tableColumns: [
            {
                key: 'mechanic_initials',
                label: 'Tech'
            },
            {
                key: 'mean',
                label: 'Mean',
                sortable: true,
            },
            {
                key: 'median',
                label: 'Median',
                sortable: true,
            },
            {
                key: 'mode',
                label: 'Mode',
                sortable: true,
            },
            {
                key: 'range',
                label: 'Range',
                sortable: true,
            },
            {
                key: 'count',
                label: 'Count',
                sortable: true,
            },
        ],
        errorMessages: [],
        isExportingTable: false
    };
}

function getComputed(){
    return {
        mechanicMetrics
    };

    function mechanicMetrics(){
        const vm = this;
        const workordersByMechanic = vm.workorders.filter(removeNullValues).reduce(groupByMechanic, {});
        return Object.values(workordersByMechanic).map(calculateMetrics);

        function removeNullValues(workorder){
            return workorder[vm.workingDaysProperty] !== null;
        }

        function groupByMechanic(accumulator, workorder){
            const isWorkorderExcluded = vm.excludedWorkorderNumbers.includes(workorder.No_);
            if(!isWorkorderExcluded && accumulator[workorder.mechanic_initials]){
                accumulator[workorder.mechanic_initials].push(workorder);
            } else if(!isWorkorderExcluded){
                accumulator[workorder.mechanic_initials] = [workorder];
            }
            return accumulator;
        }

        function calculateMetrics(mechanicWorkorders){
            const mechanicDataPoints = mechanicWorkorders.map((workorder) => workorder[vm.workingDaysProperty]);

            return {
                mechanic_initials: mechanicWorkorders[0].mechanic_initials,
                mean: getMean(),
                median: getMedian(),
                mode: getMode(),
                range: getRange(),
                count: mechanicDataPoints.length
            };

            function getMean(){
                const countOfDatapoints = mechanicDataPoints.length;
                let mean = 0;
                if(countOfDatapoints){
                    const sumOfWorkingDays = mechanicDataPoints.reduce((total, numWorkingDays) => (total + numWorkingDays), 0);
                    mean = Number((sumOfWorkingDays / countOfDatapoints).toFixed(2)).toString();
                }
                return mean;
            }

            function getMedian(){
                const orderedDatapoints = mechanicDataPoints.slice(0).sort();
                const middle = Math.round(orderedDatapoints.length / 2);
                return orderedDatapoints.length ? orderedDatapoints[middle - 1] : 0;
            }

            function getMode(){
                const datapointRepetitions = mechanicDataPoints.reduce((accumulator, numWorkingDays) => {
                    accumulator[numWorkingDays] = accumulator[numWorkingDays] ? accumulator[numWorkingDays] + 1 : 1;
                    return accumulator;
                }, {});
                let mode = 0;
                let maxDayCount = 0;
                Object.keys(datapointRepetitions).forEach(key => {
                    if(datapointRepetitions[key] >= maxDayCount){
                        maxDayCount = datapointRepetitions[key];
                        mode = key;
                    }
                });
                return mode;
            }

            function getRange(){
                const smallest = Math.min(...mechanicDataPoints) || 0;
                const largest = Math.max(...mechanicDataPoints) || 0;
                return largest - smallest;
            }
        }
    }
}

function getMethods(){
    return {
        exportTable(){
            const vm = this;
            vm.isExportingTable = true;
            vm.errorMessages = [];
            const payload = {
                columnDefinitions: vm.tableColumns,
                dataRows: vm.mechanicMetrics,
                fileName: 'MechanicMetrics.xlsx'
            };
            Vue.appApi().authorized().reports().generateGenericSpreadsheet(payload).then(downloadFile).catch(displayError).finally(resetLoadingState);

            function downloadFile(response){
                fileDownload(response.data, payload.fileName, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
            }
            function displayError(error){
                vm.errorMessages = [error.appMessage || error.message];
            }
            function resetLoadingState(){
                vm.isExportingTable = false;
            }
        }
    };
}
