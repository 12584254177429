export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        workorderNumber: {
            type: String,
            required: true,
        },
        shouldConvertTypeToText: {
            type: Boolean,
            required: false,
            default: false,
        },
        hiddenColumns: {
            type: Array,
            required: false,
            default: function(){
                return [];
            }
        },
    },
    data,
    computed: getComputed(),
    watch: getWatches(),
    methods: getMethods(),
};

function data(){
    return {
        errorMessages: [],
    };
}

function getComputed(){
    return {
        visibleTableColumns,
    };

    function visibleTableColumns(){
        const vm = this;
        const tableColumns = [
            { key: 'No_', sortable: true },
            { key: 'Type', sortable: true, formatter: vm.shouldConvertTypeToText ? vm.getTypeText : (value) => value },
            { key: 'Service_Type', sortable: true },
            { key: 'Line_No', sortable: true },
            { key: 'Description', sortable: true },
            { key: 'Description2', sortable: true },
            { key: 'Quantity', sortable: true },
            { key: 'Task', sortable: true },
            { key: 'Amount', sortable: true },
        ];
        return tableColumns.filter(filterColumns);

        function filterColumns({ key }){
            return !vm.hiddenColumns.includes(key);
        }
    }
}

function getWatches(){
    return {
        workorderNumber,
    };

    function workorderNumber(){
        const vm = this;
        vm.$refs.workorderLinesTable.refresh();
    }
}

function getMethods(){
    return {
        displayErrorMessage,
        getWorkorderLines,
        getTypeText,
    };

    function displayErrorMessage(error){
        const vm = this;
        vm.$emit('error', error);
    }

    function getWorkorderLines(queryObject){
        const vm = this;
        return Vue.appApi().authorized().workorders(vm.workorderNumber).getWorkorderLines(queryObject);
    }

    function getTypeText(type){
        let text = type;
        if(type === 1){
            text = 'Item';
        } else if(type === 2){
            text = 'Resource';
        } else if(type === 3){
            text = 'Charge';
        } else if(type === 5){
            text = 'Job Code';
        } else if(type === 10){
            text = 'Rental';
        }
        return text;
    }
}
