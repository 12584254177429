import PmCommentsModal from '../pm-comments/pm-comments-modal';

export default {
    components: {
        PmCommentsModal
    },
    props: {
        pms: {
            type: Array,
            required: true
        },
        mechanicId: {
            type: String,
            default: ''
        },
        isLoadingPms: {
            type: Boolean,
            required: true
        },
        mutableDispatchNotes: {
            type: Boolean,
            default: false
        },
        mutableTechNotes: {
            type: Boolean,
            default: false
        },
        assignmentType: {
            type: String,
            default: 'self',
            validator: function(assignmentType){
                const allowedTypes = ['', 'self', 'any'];
                return allowedTypes.includes(assignmentType);
            }
        },
        showInitialsColumn: {
            type: Boolean,
            default: false
        }
    },
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        pmTableColumns: [
            {
                key: 'WO_Number',
                label: 'WO#',
                sortable: true
            },
            {
                key: 'Name',
                label: 'Ship-to Name',
                sortable: true
            },
            {
                key: 'Address',
                label: 'Address',
                sortable: true
            },
            {
                key: 'City',
                label: 'Ship-to City',
                sortable: true
            },
            {
                key: 'Zip_Code',
                label: 'Zip',
                sortable: true
            },
            {
                key: 'due',
                label: 'Due',
                sortable: true,
                sortByFormatted: true,
                filterByFormatted: true,
                formatter: function(value, key, pm){
                    let daysOverdue = '';
                    if(pm && pm.Next_Planned_Date){
                        const today = Vue.moment.utc();
                        const nextPlannedDate = Vue.moment.utc(pm.Next_Planned_Date);
                        daysOverdue = nextPlannedDate.diff(today, 'days');
                    }
                    return daysOverdue;
                }
            },
            {
                key: 'Next_Planned_Date',
                label: 'Next PM',
                tdClass: 'text-nowrap',
                sortable: true
            },
            {
                key: 'Make',
                label: 'Make',
                sortable: true
            },
            {
                key: 'Model',
                label: 'Model',
                sortable: true
            },
            {
                key: 'Serial',
                label: 'Equipment',
                sortable: true
            },
            {
                key: 'Fleet_Code',
                label: 'Fleet',
                sortable: true
            },
            {
                key: 'Period',
                label: 'Period',
                sortable: true
            },
            {
                key: 'Action_Code',
                label: 'Action',
                sortable: true
            },
            {
                key: 'assigment',
                label: 'Sent',
                sortable: true
            },
            {
                key: 'initials',
                label: 'Sent To',
                sortable: true
            },
            {
                key: 'datetime',
                label: 'Sent Time',
                sortable: true
            },
            {
                key: 'text',
                label: 'Notes',
                sortable: true
            },
            {
                key: 'technician_notes',
                label: 'Tech Notes',
                sortable: true
            },
            {
                key: 'tech_note_updated_at',
                label: 'Tech Note Updated',
                sortable: true
            },
            {
                key: 'PM_Type',
                label: 'Type',
                sortable: true
            },
        ]
    };
}

function getComputed(){
    return {
        localPms(){
            const vm = this;
            return JSON.parse(JSON.stringify(vm.pms));
        },
        displayedTableColumns(){
            const vm = this;
            const excludedColumns = [];
            if(!vm.showInitialsColumn){
                excludedColumns.push('initials');
            }
            if(!vm.assignmentType){
                excludedColumns.push('assignment');
            }
            return vm.pmTableColumns.filter(({ key }) => !excludedColumns.includes(key));
        }
    };
}

function created(){}

function getMethods(){
    return {
        emitError,
        updatePmInitials,
        pmNotesChanged,
        updatePmTechNotes,
        getPmRowClass,
    };

    function emitError(error){
        const vm = this;
        vm.$emit('error', error);
    }

    function updatePmInitials(pm, initials){
        const vm = this;
        vm.errorMessages = [];
        const payload = {
            'workorder_number': pm.WO_Number,
            'initials': initials
        };

        vm.$store.dispatch('mechanics/UPDATE_PM', payload)
            .then(updateLocalPms)
            .catch(alertClearInitialsError);

        function updateLocalPms(data){
            const localPm = vm.localPms.find(({ WO_Number }) => WO_Number === data.workorder);
            if(localPm){
                vm.$set(localPm, 'initials', data.initials);
                vm.$set(localPm, 'datetime', data.initials ? data.datetime : '');
            }
        }

        function alertClearInitialsError(error){
            vm.emitError('Error Finding Equipment');
            vm.emitError(error);
        }
    }

    function pmNotesChanged(pm){
        const vm = this;
        vm.errorMessages = [];
        const payload = {
            'workorder_number': pm.WO_Number,
            'note': pm.text
        };

        vm.$store.dispatch('mechanics/UPDATE_PM', payload)
            .catch(alertNotesError);

        function alertNotesError(error){
            vm.displayErrorMessage('Error Finding Equipment');
            vm.displayErrorMessage(error);
        }
    }

    function updatePmTechNotes(pm){
        const vm = this;
        vm.errorMessages = [];
        const payload = {
            'workorder_number': pm.WO_Number,
            'technician_notes': pm.technician_notes
        };

        vm.$store.dispatch('mechanics/UPDATE_PM', payload)
            .then(updateLocalPm)
            .catch(alertNotesError);

        function updateLocalPm(data){
            pm.technician_notes = data.technician_notes;
            pm.tech_note_updated_at = data.tech_note_updated_at;
        }

        function alertNotesError(error){
            vm.displayErrorMessage('Error Finding Equipment');
            vm.displayErrorMessage(error);
        }
    }

    function getPmRowClass(pm){
        let pmRowClass = '';
        if(pm && pm.Next_Planned_Date){
            const nextPlannedDate = new Date(pm.Next_Planned_Date.slice(0, -9));
            const twoWeeksAgo = new Date();
            twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
            const isNextPlannedDateOlderThanTwoWeeks = nextPlannedDate < twoWeeksAgo;
            pmRowClass += isNextPlannedDateOlderThanTwoWeeks ? 'time-warning' : '';
        }

        return pmRowClass;
    }
}
