/*
Version 1.1.3
-- 2016/09/09 - added autorevise parameter to the CreateUpdatePortalQuote function.
-- 2015/03/03 - added ability for get entity JSON to read the quote's data.
-- 2015/03/01 - removed unused variables from the ConfigureQuote and SubmitQuoteRequests functions.
*/
/*
    Adapted from source code found at https://liftnet2.mcfadealers.com/Scripts/MCFA.Configurator.Integration.js by Buink Web Development
*/

/* eslint-disable */
const $ = require('jquery');
const MCFAConfigurator = {
    CreateMCFAObject
};
export default MCFAConfigurator;

function CreateMCFAObject(hosturl) {
    this.Authentication = new CreateAuthenticationObject(hosturl);
    this.CreateUpdatePortalQuote = function (username, password, exid, exxml, exusername, config, exsys, id, autorevise, callback, dealer) {
        if (typeof autorevise === 'function') {
            callback = autorevise;
            autorevise = false;
        }
        var adddealer = "";
        if (typeof dealer != "undefined" && dealer != null && dealer.length > 0) {
            adddealer = "&dealer=" + encodeURIComponent(dealer);
        }

        var url = hosturl + "/PDC/CreateUpdatePortalQuote?username=" + encodeURIComponent(username) + "&password=" + encodeURIComponent(password) + adddealer + "&exid=" + encodeURIComponent(exid) + "&exxml=" + encodeURIComponent(exxml) + "&exusername=" + encodeURIComponent(exusername) + "&config=" + encodeURIComponent(config) + "&exsys=" + encodeURIComponent(exsys) + "&id=" + encodeURIComponent(id) + "&autorevise=" + autorevise + "&epoc=" + new Date().getTime();
        url += "&callback=?";
        $.getJSON(url, function (data) {
            callback(data);
        });
    };

    this.GetSerialNumbers = function (username, password, dealer, callback) {

        var adddealer = "";
        if (typeof dealer != "undefined" && dealer != null && dealer.length > 0) {
            adddealer = "&dealer=" + encodeURIComponent(dealer);
        }

        var url = hosturl + "/PDC/GetSerial?username=" + encodeURIComponent(username) + "&password=" + encodeURIComponent(password) + adddealer + "&epoc=" + new Date().getTime();
        url += "&callback=?";
        $.getJSON(url, function (data) {
            callback(data);
        });
    };

    this.LaunchConfig = function (username, password, id, type, callback) {
        var wino = window.open(hosturl + "/PDC/LaunchConfig?username=" + encodeURIComponent(username) + "&password=" + encodeURIComponent(password) + "&id=" + encodeURIComponent(id) + "&type=" + encodeURIComponent(type) + "&epoc=" + new Date().getTime(), "", "location=0,resizable=1,toolbar=0,menubar=0");
        if (callback != undefined) {
            var myint = setInterval(function () {
                if (wino.closed) {
                    clearInterval(myint);
                    return callback(id);
                }
            }, 100);
        }
    };
    this.CloseQuote = function (username, password, id, callback) {
        var wino = window.open(hosturl + "/PortalCommands/QuoteCloseAPI?pid=f66768e5-6626-e311-8559-005056870039&username=" + encodeURIComponent(username) + "&password=" + encodeURIComponent(password) + "&id=" + encodeURIComponent(id) + "&epoc=" + new Date().getTime(), "", "location=0,resizable=1,toolbar=0,menubar=0");
        if (callback != undefined) {
            var myint = setInterval(function () {
                if (wino.closed) {
                    clearInterval(myint);
                    return callback(id);
                }
            }, 100);
        }
    };
    this.SubmitQuoteRequests = function (username, password, id, callback) {
        var url = hosturl + "/PDC/SubmitQuoteRequests?username=" + encodeURIComponent(username) + "&password=" + encodeURIComponent(password) + "&id=" + encodeURIComponent(id) + "&epoc=" + new Date().getTime();
        url += "&callback=?";
        $.getJSON(url, function (data) {
            callback(data);
        });
    };
    this.ConfigureQuote = function (username, password, id, callback) {
        var url = hosturl + "/PDC/ConfigureQuote?username=" + encodeURIComponent(username) + "&password=" + encodeURIComponent(password) + "&id=" + encodeURIComponent(id) + "&epoc=" + new Date().getTime();
        url += "&callback=?";
        $.getJSON(url, function (data) {
            var ret = new ConfigureQuoteResponse(true, data);
            callback(ret);
        });
    }
    function ConfigureQuoteResponse(suc, result) {
        var self = this;
        self.Raw = result;
        self.GetValue = function (key) {
            return $("<div>" + self.Raw + "</div>").find("CrmField[name='" + key + "']").attr("value");
        };
        self.GetFields = function (key) {
            return $("<div>" + self.Raw + "</div>").find("CrmField[name='" + key + "']");
        };
        self.GetEntityJSON = function (entity) {
            var ret = new Array();
            var mys = $("<div></div>").html(self.Raw).find("CrmEntity, Parent");
            mys.each(function (i, el) {
                el = $(el);
                if (el.find("LogicalName").text() == entity) {
                    var myobj = {};
                    myobj["LogicalName"] = entity;
                    myobj["Id"] = el.find("Id").text();
                    el.find("CrmField").each(function (ii, ell) {
                        ell = $(ell);
                        myobj[ell.attr("name")] = {value: ell.attr("value"), entityid: ell.attr("entityid"), entityname: ell.attr("entityname")};
                    });
                    myobj["HasError"] = el.find("HasError").text();
                    ret[i] = myobj;
                }
                i++;
            });
            return ret;
        };
    }
    function CreateAuthenticationObject(hosturl) {
        this.IsAuthenticated = function () {
            var retval = false;
            $.ajax({
                url: hosturl + "/PDC/TryLogin",
                success: function (a) {
                    retval = (a == "True");
                },
                async: false
            });
            return retval;
        };
        this.ModalLogin = function () {
            var loggedin = window.showModalDialog(hosturl + "/AutoLogin/ModalLogin", window, "dialogWidth:700px;dialogHeight:750px");
            return loggedin == true;
        };
    }
}
