import { render, staticRenderFns } from "./login.vue?vue&type=template&id=65166c89&scoped=true&"
import script from "./login.controller.js?vue&type=script&lang=js&"
export * from "./login.controller.js?vue&type=script&lang=js&"
import style0 from "./_login.scss?vue&type=style&index=0&id=65166c89&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65166c89",
  null
  
)

export default component.exports