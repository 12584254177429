export default {
    components: {},
    props: {},
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        isLoadingReqCodes: false,
        isLoadingPartsOrderLines: false,
        availableReqCodes: [],
        queries: {
            req_code: '',
            resp_center: '',
            buy_vendor: ''
        },
        partsOrderLines: null,
        tableColumns: [
            {
                key: 'document_no',
                sortable: true,
                label: 'Doc_No',
            },
            {
                key: 'no',
                sortable: true,
                label: 'No.',
                tdClass: 'text-nowrap'
            },
            {
                key: 'description',
                sortable: true,
            },
            {
                key: 'location_code',
                sortable: true,
            },
            {
                key: 'resp_center',
                sortable: true,
                label: 'Responsibility Center'
            },
            {
                key: 'purchase_document_no',
                sortable: true,
                label: 'Purchase Doc.No.'
            },
            {
                key: 'req_code',
                sortable: true,
                label: 'Requisition Code'
            },
            {
                key: 'bin_code',
                sortable: true,
            },
            {
                key: 'qty',
                sortable: true,
                label: 'Quantity'
            },
            {
                key: 'buy_vendor',
                sortable: true,
                label: 'Buy From Vendor'
            },
            {
                key: 'vendor_item_no',
                sortable: true,
            },
        ]
    };
}

function getComputed(){
    return {
        partsOrderLinesQuery,
    };

    function partsOrderLinesQuery(){
        const vm = this;
        const queryParams = {};
        for(const [key, value] of Object.entries(vm.queries)){
            if(value !== '' && value !== '*'){
                queryParams[key] = value.toUpperCase();
            }
        }
        return queryParams;
    }
}

function created(){
    const vm = this;
    vm.getReqCodesOptions();
}

function getMethods(){
    return {
        displayErrorMessage,
        getReqCodesOptions,
        queryPartsOrderLines,
    };

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const isValidationError = error.status === 422 && error.data.errors;
            if(isValidationError){
                vm.errorMessages = [...Object.values(error.data.errors)].flat();
            } else {
                const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
                vm.errorMessages.push(errorMessage);
            }
        }
    }

    function getReqCodesOptions(){
        const vm = this;
        vm.isLoadingReqCodes = true;
        return Vue.appApi().authorized().workorders().orderLines().getUniqueReqCodes().then(setReqCodesOptions).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function setReqCodesOptions(response){
            const defaultOption = { value: '', text: '*' };
            const reqCodeOptions = response.data.map(formatForSelectOptions);
            vm.availableReqCodes = [defaultOption].concat(reqCodeOptions);
            function formatForSelectOptions(codeObject){
                return { value: codeObject.req_code, text: codeObject.req_code };
            }
        }

        function resetLoadingState(){
            vm.isLoadingReqCodes = false;
        }
    }

    function queryPartsOrderLines(){
        const vm = this;
        vm.isLoadingPartsOrderLines = true;
        return Vue.appApi().authorized().workorders().orderLines().queryPartsOrderLines(vm.partsOrderLinesQuery).then(setPartsOrderLines).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function setPartsOrderLines(response){
            vm.partsOrderLines = response.data;
        }
        function resetLoadingState(){
            vm.isLoadingPartsOrderLines = false;
        }
    }
}
