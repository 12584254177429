export default {
    components: {},
    props: {
        equipmentCode: {
            type: String,
            required: true
        }
    },
    data,
    computed: getComputed(),
    watch: getWatchers(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        imageSource: '',
        isLoadingImage: false
    };
}

function getComputed(){
    return {};
}

function getWatchers(){
    return {
        equipmentCode
    };

    function equipmentCode(newLocation, oldLocation){
        const vm = this;
        vm.updateImageDetails();
    }
}

function created(){
    const vm = this;
    vm.updateImageDetails();
}

function getMethods(){
    return {
        displayErrorMessage,
        updateImageDetails,
        getEncodedImage
    };

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function updateImageDetails(){
        const vm = this;
        if(vm.equipmentCode){
            vm.getEncodedImage();
        }
    }

    function getEncodedImage(){
        const vm = this;
        vm.isLoadingImage = true;
        const query = {
            equipment_code: vm.equipmentCode
        };
        return Vue.appApi().authorized().equipment().images().getEncodedImage(query).then(setImageSource).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function setImageSource({ data }){
            vm.imageSource = data;
            if(!data){
                vm.$emit('image-broken');
            }
        }
        function resetLoadingState(){
            vm.isLoadingImage = false;
        }
    }
}
