import ClientPaginationMixin from 'vue_root/mixins/client-pagination-mixin';
export default {
    components: {},
    mixins: [
        ClientPaginationMixin
    ],
    props: getProps(),
    data,
    computed: getComputed(),
    watch: getWatches(),
    created,
    methods: getMethods(),
};

function getProps(){
    return {
        lead: {
            type: Object,
            required: true,
        }
    };
}

function data(){
    return {
        isLoadingCaseNotes: false,
        errorMessages: [],
        caseNotes: [],
        tableColumns: [
            {
                key: 'Case_No',
                label: 'Case No.',
                sortable: true,
            },
            {
                key: 'Company_No',
                label: 'CM#',
                sortable: true,
            },
            {
                key: 'Createdby_User',
                label: 'User',
                sortable: true,
            },
            {
                key: 'Text',
                label: 'Text',
                sortable: true,
            },
            {
                key: 'Line',
                label: 'Line',
                sortable: true,
            },
        ],
    };
}

function getComputed(){}

function getWatches(){
    return {
        lead,
    };

    function lead(){
        const vm = this;
        vm.searchNotes();
    }
}

function created(){
    const vm = this;
    if(vm.lead){
        vm.searchNotes();
    }
}

function getMethods(){
    return {
        searchNotes,
        displayErrorMessage,
    };

    function searchNotes(){
        const vm = this;
        vm.isLoadingCaseNotes = true;
        vm.errorMessages = [];
        vm.caseNotes = [];
        const query = {
            case_no: vm.lead.case_no,
        };
        Vue.appApi().authorized().crmNotes().getCrmCaseNoteLines(query)
            .then(setCaseNotesData)
            .catch(vm.displayErrorMessage)
            .finally(stopSpinner);

        function setCaseNotesData(data){
            vm.caseNotes = data.data;
        }

        function stopSpinner(){
            vm.isLoadingCaseNotes = false;
        }
    }

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }
}
