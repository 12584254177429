import fileDownload from 'js-file-download';
import Datepicker from 'vuejs-datepicker';

export default {
    components: {
        Datepicker
    },
    data,
    computed: getComputed(),
    watch: getWatchers(),
    created,
    beforeRouteUpdate,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        validationErrors: {},
        isDownloading: false,
        canSubmitForm: true,
        flyerType: null,
        dateFormat: 'MMMM d, yyyy',
        nutshellId: null,
        isUploadingToNutshell: false,
        form: {
            fleet_code: '',
            fleet_code_2: '',
            sale_price: '',
            sale_price_2: '',
            start_date: '',
            end_date: '',
            sales_person_code: '',
            years_xp: '',
            case_number: '',
            branch_code: '',
            warranty_choice: '',
            racking_image: '',
        },
        fieldSettings: {
            'flyer': [
                'fleet_code',
                'sale_price',
                'sales_person_code',
            ],
            'quote': [
                'fleet_code',
                'sale_price',
                'sales_person_code',
                'case_number',
                'warranty_choice',
            ],
            'person_flyer': [
                'sales_person_code',
                'years_xp',
            ],
            'multi_flyer': [
                'fleet_code',
                'fleet_code_2',
                'sale_price',
                'sale_price_2',
                'start_date',
                'end_date',
                'sales_person_code',
            ],
            'pallet': [
                'sales_person_code',
            ],
            'service': [
                'sales_person_code',
                'branch_code',
            ],
            'training': [
                'sales_person_code',
                'branch_code',
            ],
            'training-list': [
                'sales_person_code',
                'branch_code',
            ],
            'palletv2': [
                'sales_person_code',
            ],
            'blank': [
                'sales_person_code',
                'case_number'
            ],
            'fargo_person_flyer': [
                'sales_person_code',
                'years_xp',
            ],
            'minot_person_flyer': [
                'sales_person_code',
                'years_xp',
            ],
            'williston_person_flyer': [
                'sales_person_code',
                'years_xp',
            ],
            'forklift-training-list': [
                'sales_person_code',
                'branch_code'
            ],
            'storage-concepts-flyer': [
                'sales_person_code',
            ],
            'storage-concepts-quote': [
                'sale_price',
                'sales_person_code',
                'case_number',
                'racking_image',
            ],
        }
    };
}

function getComputed(){
    return {};
}

function getWatchers(){
    return {
        'form.fleet_code': transformFormValue('fleet_code'),
        'form.fleet_code_2': transformFormValue('fleet_code_2'),
        'form.case_number': transformFormValue('case_number'),
        'form.sales_person_code': transformFormValue('sales_person_code'),
    };
    function transformFormValue(formField){
        return function transformValue(newValue){
            const vm = this;
            const shouldTransform = newValue.toUpperCase() !== vm.form[formField];
            if(shouldTransform){
                vm.form[formField] = newValue.toUpperCase();
            }
        };
    }
}

function created(){
    const vm = this;
    vm.resetForm(vm.$route.query.nutshell_id || '');
}
function beforeRouteUpdate(to, from, next){
    const vm = this;
    vm.resetForm(to.query.nutshell_id || '');
    next();
}

function getMethods(){
    return {
        displayErrorMessage,
        fieldIsVisible,
        buildFlyer,
        resetForm,
        buildFlyerForNutshell,
        validateNutshellId
    };

    function displayErrorMessage(error){
        const vm = this;
        const isValidationError = error && error.status === 422 && error.data.errors;
        if(isValidationError){
            vm.validationErrors = error.data.errors;
        } else {
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function fieldIsVisible(field){
        const vm = this;
        return vm.flyerType && vm.fieldSettings[vm.flyerType].includes(field);
    }

    function buildFlyer(){
        const vm = this;
        vm.isDownloading = true;
        vm.validationErrors = {};
        vm.errorMessages = [];
        const queryObject = {
            flyerType: vm.flyerType,
            ...vm.form
        };
        queryObject.start_date = Vue.moment(queryObject.start_date).format('MMMM D, YYYY');
        queryObject.end_date = Vue.moment(queryObject.end_date).format('MMMM D, YYYY');
        Vue.appApi().authorized().flyers().buildFlyer(queryObject)
            .then(downloadFlyer)
            .catch(vm.displayErrorMessage)
            .finally(stopSpinner);
        function downloadFlyer(response){
            const contentDisposition = response.headers['content-disposition'];
            const sentFileName = contentDisposition ? contentDisposition.split('filename=').pop() : null;
            const filename = sentFileName || 'Flyer.docx';
            fileDownload(response.data, filename, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
        }
        function stopSpinner(){
            vm.isDownloading = false;
        }
    }

    function resetForm(nutshellId = ''){
        const vm = this;
        vm.validationErrors = {};
        Object.keys(vm.form).map(resetValue);
        vm.flyerType = null;
        if(nutshellId){
            vm.nutshellId = parseInt(vm.$route.query.nutshell_id);
            vm.form.fleet_code = 'Prepared By Nutshell';
            vm.form.sales_person_code = 'Prepared By Nutshell';
            vm.form.case_number = 'Prepared By Nutshell';
            vm.validateNutshellId(nutshellId);
        } else {
            vm.nutshellId = null;
            vm.canSubmitForm = true;
        }
        function resetValue(formKey){
            vm.form[formKey] = '';
        }
    }

    function buildFlyerForNutshell(){
        const vm = this;
        vm.isUploadingToNutshell = true;
        vm.validationErrors = {};
        vm.errorMessages = [];
        const queryObject = {
            lead_id: vm.nutshellId,
            flyerType: vm.flyerType,
            ...vm.form
        };
        queryObject.start_date = Vue.moment(queryObject.start_date).format('MMMM D, YYYY');
        queryObject.end_date = Vue.moment(queryObject.end_date).format('MMMM D, YYYY');
        return Vue.appApi().authorized().sales().nutshell().buildFlyerForNutshell(queryObject)
            .then(displaySuccess)
            .catch(vm.displayErrorMessage)
            .finally(resetLoadingState);

        function displaySuccess(){
            vm.$bvToast.toast(`Your flyer has been successfully uploaded to Nutshell`, {
                title: 'Flyer Uploaded',
                autoHideDelay: 5000,
                variant: 'success'
            });
        }

        function resetLoadingState(){
            vm.isUploadingToNutshell = false;
        }
    }

    function validateNutshellId(nutshellId){
        const vm = this;
        const nutshellIdType = nutshellId.split('-')[1];
        vm.canSubmitForm = nutshellIdType === 'leads';
        if(!vm.canSubmitForm){
            const errorMessage = `Cannot create used flyers for Nutshell ${nutshellIdType}, currently only leads are supported.`;
            vm.$bvModal.msgBoxConfirm(errorMessage, {
                title: 'Unsupported Nutshell Integration',
                footerClass: 'justify-content-between',
                cancelTitle: 'Return to Dashboard',
                okTitle: 'Proceed without Nutshell',
                noCloseOnBackdrop: true,
                centered: true
            }).then(resetData);
        }

        function resetData(resetForm){
            if(resetForm){
                vm.$router.push({ name: vm.$route.name });
            } else {
                vm.$router.push({ name: 'dashboard' });
            }
        }
    }
}
