import KpiBillingComponent from './kpi-billing/kpi-billing';
import ServiceComponent from './service/home/home';
import AllMechanicOrdersComponent from './service/mechanic-orders/all-mechanics-orders/all-mechanics-orders';
import MechanicOrdersComponent from './service/mechanic-orders/mechanic-orders';
import AllPmsComponent from './service/mechanic-orders/all-pms/all-pms';
import ShopBaseComponent from './shop/base/shop-base';
import WorkordersComponent from './shop/workorders/workorders';
import ServiceAdjustComponent from './service-adjust/service-adjust';
import ServiceInvoicingComponent from './service-invoicing/service-invoicing';
import ServiceInvoicedComponent from './service-invoiced/service-invoiced';
import ServiceRepairComponent from './repair/repair';
import TechnicianRoadComponent from './technician-road/road';

export default {
    path: '/service',
    component: { template: `<router-view></router-view>` },
    meta: {},
    children: [
        {
            path: 'road',
            component: ServiceComponent,
            name: 'service-home',
            meta: {
                title: 'Service Dispatching',
                permissions: ['view service-road-page']
            },
            children: [
                {
                    path: 'all-mechanics-workorders/:mechanicId',
                    component: AllMechanicOrdersComponent,
                    name: 'all-mechanics-workorders',
                    meta: {
                        title: 'Service Dispatching'
                    }
                },
                {
                    path: 'mechanic-workorders/:mechanicId',
                    component: MechanicOrdersComponent,
                    name: 'mechanic-workorders',
                    meta: {
                        title: 'Service Dispatching'
                    }
                },
                {
                    path: 'all-pms/:mechanicId',
                    component: AllPmsComponent,
                    name: 'all-pms',
                    meta: {
                        title: 'Service Dispatching'
                    }
                },
            ]
        },
        {
            path: 'kpi-billing',
            component: KpiBillingComponent,
            name: 'kpi-billing-home',
            meta: {
                title: 'KPI Billing',
                permissions: ['view kpi-billing-page']
            },
        },
        {
            path: 'service-adjust',
            component: ServiceAdjustComponent,
            name: 'service-adjust-home',
            meta: {
                title: 'Service Adjust Report',
                permissions: ['view service-adjust-page']
            },
        },
        {
            path: 'service-invoiced',
            component: ServiceInvoicedComponent,
            name: 'service-invoiced-home',
            meta: {
                title: 'Service Invoiced Labor Report',
                permissions: ['view service-invoiced-page']
            },
        },
        {
            path: 'repair',
            component: ServiceRepairComponent,
            name: 'service-repair',
            meta: {
                title: 'Service Repair',
                permissions: ['view service-repair-page']
            }
        },
        {
            path: 'shop',
            component: ShopBaseComponent,
            name: 'shop-home',
            meta: {
                title: 'Service Dispatching',
                permissions: ['view service-shop-page']
            },
            children: [
                {
                    path: 'workorders/:mechanicId',
                    component: WorkordersComponent,
                    name: 'shop-workorders',
                    meta: {
                        title: 'Shop'
                    }
                },
            ]
        },
        {
            path: 'technician-road',
            component: TechnicianRoadComponent,
            name: 'technician-road',
            meta: {
                title: 'Service Technician',
                permissions: ['view technician-road-page']
            },
        },
        {
            path: 'invoicing',
            component: ServiceInvoicingComponent,
            name: 'service-invoicing-home',
            meta: {
                title: 'Service Invoicing',
                permissions: ['view service-invoicing-page']
            },
        },
    ]
};
