export default {
    components: {},
    data,
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        validationErrors: {},
        orderNumber: '',
        isSearchingChangeLog: false,
        changeLogLines: null,
        tableColumns: [
            {
                key: 'datetime',
                label: 'Date & Time',
                sortable: true,
            },
            {
                key: 'time',
                sortable: true,
            },
            {
                key: 'user_id',
                sortable: true,
            },
            {
                key: 'order_no',
                label: 'Order #',
                sortable: true,
            },
            {
                key: 'line_no',
                label: 'Line',
                sortable: true,
            },
            {
                key: 'field_name',
                sortable: true,
            },
            {
                key: 'old_val',
                label: 'Old Value',
                sortable: true,
            },
            {
                key: 'new_val',
                label: 'New Value',
                sortable: true,
            },
            {
                key: 'type_of_change',
                sortable: true,
                filterByFormatted: true,
                formatter: (value) => {
                    let formattedValue = '';
                    if(value === 0){
                        formattedValue = 'Modification';
                    } else if(value === 1){
                        formattedValue = 'Insertion';
                    } else if(value === 2){
                        formattedValue = 'Deletion';
                    }
                    return formattedValue;
                }
            },
        ],
    };
}

function created(){}

function getMethods(){
    return {
        displayErrorMessage,
        searchChangeLog,
    };

    function displayErrorMessage(error){
        const vm = this;
        if(error.status === 422 && error.data.errors){
            vm.validationErrors = error.data.errors;
        } else {
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function searchChangeLog(){
        const vm = this;
        vm.isSearchingChangeLog = true;
        vm.validationErrors = {};
        vm.errorMessages = [];
        vm.searchString = '';
        if(vm.$refs.changeLogTable){
            vm.$refs.changeLogTable.paginationOptions.current_page = 1;
        }

        const query = {
            order_number: vm.orderNumber
        };

        Vue.appApi().authorized().changeLog().indexChangeLogs(query).then(setChangeLogLines).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function setChangeLogLines(response){
            vm.changeLogLines = response.data;
            Vue.nextTick(() => {
                vm.$refs.changeLogTable.paginationOptions.total = vm.changeLogLines.length;
            });
        }

        function resetLoadingState(){
            vm.isSearchingChangeLog = false;
        }
    }
}
