export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        tableType: {
            type: String,
            required: true,
        },
        queryObject: {
            type: Object,
            required: true,
        },
        hiddenColumns: {
            type: Array,
            required: false,
            default: function(){
                return [];
            },
        },
    },
    data,
    created,
    computed: getComputed(),
    methods: getMethods(),
    watch: getWatches(),
};

function data(){
    return {
        isLoadingWorkorders: false,
        isLoadingServiceAdjustReasons: false,
        localWorkorders: [],
        defaultTableColumns: [
            {
                key: 'search',
                label: '',
            },
            {
                key: 'No_',
                label: 'WO #',
                sortable: true,
            },
            {
                key: 'Type',
                sortable: true,
            },
            {
                key: 'Service_Type',
                sortable: true,
            },
            {
                key: 'Line_No',
                label: 'Line_No',
                sortable: true,
            },
            {
                key: 'Description',
                sortable: true,
            },
            {
                key: 'Description2',
                sortable: true,
            },
            {
                key: 'Quantity',
                sortable: true,
            },
            {
                key: 'Task',
                sortable: true,
            },
            {
                key: 'Amount',
                sortable: true,
            },
            {
                key: 'billto_name',
                label: 'BillTo Name',
                sortable: true,
            },
            {
                key: 'posting_date',
                label: 'Posting Date',
                sortable: true,
            },
            {
                key: 'resp_center',
                label: 'Location',
                sortable: true,
            },
            {
                key: 'Qty_invoiced',
                sortable: true,
            },
            {
                key: 'Diff',
                sortable: true,
            },
            {
                key: 'service_adjust_note.mechanic_initials',
                label: 'Tech',
                tdClass: 'input-cell-sm'
            },
            {
                key: 'service_adjust_note.hours',
                label: 'Hours',
                tdClass: 'input-cell-sm'
            },
            {
                key: 'service_adjust_note.reason',
                label: 'Reason'
            },
            {
                key: 'service_adjust_note.notes',
                label: 'Notes',
                tdClass: 'input-cell'
            },
            {
                key: 'service_adjust_note.corrective_action',
                label: 'Corrective Action',
                tdClass: 'input-cell'
            },
            {
                key: 'service_adjust_note.authorized_by',
                label: 'Authorized By',
                tdClass: 'input-cell'
            },
            {
                key: 'service_invoice_note.reason',
                label: 'Reason'
            },
            {
                key: 'service_invoice_note.notes',
                label: 'Notes',
                tdClass: 'input-cell'
            },
            {
                key: 'service_invoice_note.corrective_action',
                label: 'Corrective Action',
                tdClass: 'input-cell'
            },
            {
                key: 'service_invoice_note.authorized_by',
                label: 'Authorized By',
                tdClass: 'input-cell'
            },
            {
                key: 'save_notes',
                label: ''
            },
            {
                key: 'service_adjust_note.user.name',
                label: 'User',
            },
            {
                key: 'service_invoice_note.user.name',
                label: 'User',
            },
        ],
        serviceAdjustReasons: []
    };
}

function getComputed(){
    return {
        visibleTableColumns,
        tableTypeColumns,
        noteReasonOptions
    };

    function visibleTableColumns(){
        const vm = this;
        return vm.tableTypeColumns.filter(filterColumns);

        function filterColumns(column){
            return !vm.hiddenColumns.includes(column.key);
        }
    }

    function tableTypeColumns(){
        const vm = this;
        const tableColumns = JSON.parse(JSON.stringify(vm.defaultTableColumns));
        let tableTypeDefaults = [];
        if(vm.tableType === 'service-adjust'){
            tableTypeDefaults = [
                'search',
                'No_',
                'billto_name',
                'posting_date',
                'resp_center',
                'Amount',
                'service_adjust_note.mechanic_initials',
                'service_adjust_note.hours',
                'service_adjust_note.reason',
                'service_adjust_note.notes',
                'service_adjust_note.corrective_action',
                'service_adjust_note.authorized_by',
                'save_notes',
                'service_adjust_note.user.name',
            ];
        } else if(vm.tableType === 'service-invoiced'){
            tableTypeDefaults = [
                'search',
                'No_',
                'billto_name',
                'posting_date',
                'resp_center',
                'Line_No',
                'Quantity',
                'Qty_invoiced',
                'Diff',
                'service_invoice_note.reason',
                'service_invoice_note.notes',
                'service_invoice_note.corrective_action',
                'service_invoice_note.authorized_by',
                'save_notes',
                'service_invoice_note.user.name',
            ];
        }
        return tableColumns.filter(({ key }) => tableTypeDefaults.includes(key));
    }

    function noteReasonOptions(){
        const vm = this;
        const currentReasons = vm.serviceAdjustReasons.map(lookup => lookup.meta_data.value);
        const previouslySelectedReasons = vm.localWorkorders.map(({ service_adjust_note, service_invoice_note }) => {
            return vm.tableType === 'servce-adjust' ? service_adjust_note : service_invoice_note;
        }).filter(note => note)
            .map(({ reason }) => reason);
        const oldReasons = previouslySelectedReasons.filter((reason, index, array) => {
            return reason && !currentReasons.includes(reason) && array.indexOf(reason) === index;
        });
        const validOptions = currentReasons.map(reason => { return { value: reason, text: reason }; });
        const disabledOptions = oldReasons.map(reason => { return { value: reason, text: reason, disabled: true }; });
        return validOptions.concat(disabledOptions);
    }
}

function created(){
    const vm = this;
    vm.searchWorkorders();
    vm.fetchServiceAdjustReasons();
}

function getMethods(){
    return {
        displayErrorMessage,
        searchWorkorders,
        searchServiceAdjustWorkorders,
        searchServiceInvoicedWorkorders,
        getWorkorderWarranties,
        saveNote,
        saveServiceAdjustNote,
        saveServiceInvoiceNote,
        fetchServiceAdjustReasons
    };

    function displayErrorMessage(error){
        const vm = this;
        vm.$emit('error', error);
    }

    function searchWorkorders(){
        const vm = this;
        vm.isLoadingWorkorders = true;
        if(vm.tableType === 'service-adjust'){
            return vm.searchServiceAdjustWorkorders().then(scrollIntoView);
        }
        if(vm.tableType === 'service-invoiced'){
            return vm.searchServiceInvoicedWorkorders().then(scrollIntoView);
        }
        function scrollIntoView(){
            vm.scrollTo('serviceWorkordersTable');
        }
    }

    function searchServiceAdjustWorkorders(){
        const vm = this;
        const queryObject = {
            ...vm.queryObject,
            filter: ['has_service_adjust'],
            appends: ['service_adjust_notes']
        };
        queryObject.with_service_adjust = true;
        return Vue.appApi()
            .authorized()
            .invoicedWorkorders()
            .getWorkorders(queryObject)
            .then(setLocalWorkorders)
            .catch(vm.displayErrorMessage)
            .finally(stopSpinner);

        function setLocalWorkorders(data){
            vm.localWorkorders = data.data.map(addDisplayProperties);
            function addDisplayProperties(workorder){
                const noteDefault = {
                    workorder_doc_no: workorder.Doc_No,
                    workorder_doc_type: workorder.Doc_Type,
                    workorder_line: workorder.Line,
                };
                workorder.isSavingNote = false;
                workorder.service_adjust_note = workorder.service_adjust_note || noteDefault;
                return workorder;
            }
        }

        function stopSpinner(){
            vm.$emit('loading-complete');
            vm.isLoadingWorkorders = false;
        }
    }

    function searchServiceInvoicedWorkorders(){
        const vm = this;
        const queryObject = {
            ...vm.queryObject,
            filter: ['has_diff'],
            appends: ['service_invoice_notes']
        };
        queryObject.with_diff = true;
        return Vue.appApi()
            .authorized()
            .invoicedWorkorders()
            .getWorkorders(queryObject)
            .then(setLocalWorkorders)
            .catch(vm.displayErrorMessage)
            .finally(stopSpinner);

        function setLocalWorkorders(data){
            vm.localWorkorders = data.data.map(addDisplayProperties);
            function addDisplayProperties(workorder){
                const noteDefault = {
                    workorder_doc_no: workorder.Doc_No,
                    workorder_doc_type: workorder.Doc_Type,
                    workorder_line: workorder.Line,
                    mechanic_initials: workorder.Line_No
                };
                workorder.isSavingNote = false;
                workorder.service_invoice_note = workorder.service_invoice_note || noteDefault;
                return workorder;
            }
        }

        function stopSpinner(){
            vm.$emit('loading-complete');
            vm.isLoadingWorkorders = false;
        }
    }

    function getWorkorderWarranties(workorder){
        const vm = this;
        vm.$emit('search-warranties', workorder.No_);
    }

    function saveNote(workorder){
        const vm = this;
        if(vm.tableType === 'service-adjust'){
            vm.saveServiceAdjustNote(workorder);
        } else if(vm.tableType === 'service-invoiced'){
            vm.saveServiceInvoiceNote(workorder);
        }
    }

    function saveServiceAdjustNote(workorder){
        const vm = this;
        workorder.isSavingNote = true;
        return Vue.appApi().authorized().invoicedWorkorders().createUpdateServiceAdjustNote(workorder.service_adjust_note)
            .then(updateLocalNote)
            .catch(emitError)
            .finally(resetLoadingState);

        function updateLocalNote(response){
            workorder.service_adjust_note = response.data;
        }
        function emitError(){
            vm.displayErrorMessage(new Error('Failed to save service adjust note for workorder: ' + workorder.No_));
        }
        function resetLoadingState(){
            workorder.isSavingNote = false;
        }
    }
    function saveServiceInvoiceNote(workorder){
        const vm = this;
        workorder.isSavingNote = true;
        return Vue.appApi().authorized().invoicedWorkorders().createUpdateServiceInvoiceNote(workorder.service_invoice_note)
            .then(updateLocalNote)
            .catch(emitError)
            .finally(resetLoadingState);

        function updateLocalNote(response){
            workorder.service_invoice_note = response.data;
        }
        function emitError(){
            vm.displayErrorMessage(new Error('Failed to save service invoice note for workorder: ' + workorder.No_));
        }
        function resetLoadingState(){
            workorder.isSavingNote = false;
        }
    }

    function fetchServiceAdjustReasons(){
        const vm = this;
        vm.isLoadingServiceAdjustReasons = true;
        return Vue.appApi().authorized().lookups().getLookupsByType('service_note_reason')
            .then(setReasons)
            .catch(vm.displayErrorMessage)
            .finally(resetLoadingState);
        function setReasons(response){
            vm.serviceAdjustReasons = response.data;
        }
        function resetLoadingState(){
            vm.isLoadingServiceAdjustReasons = false;
        }
    }
}

function getWatches(){
    return {
        queryObject,
    };

    function queryObject(){
        const vm = this;
        vm.searchWorkorders();
    }
}
