import ServiceNoteReasons from './service-note-reasons/service-note-reasons';
import EquipmentGroupBenchmarks from './equipment-group-benchmarks/equipment-group-benchmarks';

export default {
    components: {
        ServiceNoteReasons,
        EquipmentGroupBenchmarks
    },
    props: {},
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        selectedLookupTable: null,
        lookupTableOptions: [
            { value: null, text: 'Select a lookup to manage' },
            { value: 'service_note_reason', text: 'Service Adj/Inv Note Reasons' },
            { value: 'equipment_group_benchmark', text: 'Equipment Group Benchmarks' },
        ]
    };
}

function getComputed(){
    return {};
}

function created(){
    const vm = this;
    if(vm.$route.query.type_slug){
        vm.selectedLookupTable = vm.$route.query.type_slug;
    }
}

function getMethods(){
    return {
        updateQueryString
    };

    function updateQueryString(){
        const vm = this;
        const { name, params, query = {}} = vm.$route;
        const updatedQuery = JSON.parse(JSON.stringify(query));
        updatedQuery.type_slug = vm.selectedLookupTable;
        vm.$router.replace({ name, params, query: updatedQuery });
    }
}
