import Datepicker from 'vuejs-datepicker';
import WorkorderAndCheckinDetails from 'vue_root/components/workorder-and-checkin-details/workorder-and-checkin-details.vue';

export default {
    components: {
        Datepicker,
        WorkorderAndCheckinDetails
    },
    props: {},
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        validationErrors: {},
        workOrderNumbersQuery: {
            startDate: '',
            endDate: '',
            serviceType: '',
            responseCenter: '',
        },
        availableRentalCheckins: [],
        displayedWorkOrderNumber: null,
        isLoadingAvailableRentalCheckins: false,
        rentalCheckinColumns: [
            {
                key: 'WO_Number',
                sortable: true
            },
            {
                key: 'Amount',
                sortable: true,
                sortByFormatted: true,
                formatter: (value, key, checkin) => {
                    return (checkin.work_order_lines || []).reduce((acc, { Amount }) => {
                        return acc + (+Amount || 0);
                    }, 0);
                }
            },
        ],
        isSearchInitiated: false
    };
}

function getComputed(){
    return {};
}

function created(){}

function getMethods(){
    return {
        displayErrorMessage,
        searchRentalCheckins,
        displayRentalCheckinInvoice,
        getWorkorderHistoryLines,
    };

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function searchRentalCheckins(page = 1){
        const vm = this;
        const isQueryValid = validateQuery();
        if(isQueryValid){
            vm.isSearchInitiated = true;
            const query = {
                start_date: vm.workOrderNumbersQuery.startDate,
                end_date: vm.workOrderNumbersQuery.endDate,
                service_type: vm.workOrderNumbersQuery.serviceType.toUpperCase(),
                response_center: vm.workOrderNumbersQuery.responseCenter,
            };
            vm.isLoadingAvailableRentalCheckins = true;
            return Vue.appApi().authorized().rentalCheckins().indexHulRentalCheckins(query).then(setAvailableRentalCheckins).catch(vm.displayErrorMessage).finally(resetLoadingState);
        }

        function validateQuery(){
            vm.validationErrors = {};
            if(!vm.workOrderNumbersQuery.startDate){
                vm.validationErrors.start_date = ['Please select a start date.'];
            }
            if(!vm.workOrderNumbersQuery.endDate){
                vm.validationErrors.end_date = ['Please select an end date.'];
            }
            if(!vm.workOrderNumbersQuery.serviceType){
                vm.validationErrors.service_type = ['Please enter a service type.'];
            }
            if(!vm.workOrderNumbersQuery.responseCenter){
                vm.validationErrors.response_center = ['Please enter a branch.'];
            }
            return Object.keys(vm.validationErrors).length === 0;
        }

        function setAvailableRentalCheckins(response){
            vm.availableRentalCheckins = response.data;
        }

        function resetLoadingState(){
            vm.isLoadingAvailableRentalCheckins = false;
        }
    }

    function displayRentalCheckinInvoice(rentalCheckin){
        const vm = this;
        vm.displayedWorkOrderNumber = rentalCheckin.WO_Number;
        vm.scrollTo('workorderDetails');
    }

    function getWorkorderHistoryLines(workOrderNumber){
        const vm = this;
        vm.workOrderHistoryNumber = workOrderNumber;
    }
}
