export default {
    components: {},
    props: {},
    data,
    computed: getComputed(),
    watch: getWatchers(),
    created,
    methods: getMethods()
};

function data(){
    return {
        showCompletedUploadAlert: false,
        isDragging: false,
        fileUploads: []
    };
}

function getComputed(){
    return {
        failedUploads,
        successfulUploads,
        pendingUploads,
        uploadProgress,
        uploadState,
    };
    function failedUploads(){
        const vm = this;
        return vm.fileUploads.filter(({ success, errors, isLoading }) => !success && !isLoading && errors.length);
    }
    function successfulUploads(){
        const vm = this;
        return vm.fileUploads.filter(({ success, isLoading }) => success && !isLoading);
    }
    function pendingUploads(){
        const vm = this;
        return vm.fileUploads.filter(({ isLoading }) => isLoading);
    }
    function uploadProgress(){
        const vm = this;
        let totalProgress = 0;
        if(vm.fileUploads.length){
            totalProgress = vm.fileUploads.reduce((sum, { uploadProgress }) => sum + uploadProgress, 0) / vm.fileUploads.length;
            if(pendingUploads.length){
                totalProgress -= 1;
            }
        }
        return totalProgress;
    }
    function uploadState(){
        const vm = this;
        let uploadState = 'incomplete';
        const areAllUploadsComplete = vm.fileUploads.length && !vm.pendingUploads.length;
        if(areAllUploadsComplete){
            const allUploadsSuccessful = !vm.failedUploads.length;
            const someUploadsSuccessful = vm.successfulUploads.length && vm.failedUploads.length;
            if(allUploadsSuccessful){
                uploadState = 'success';
            } else if(someUploadsSuccessful){
                uploadState = 'partial-success';
            } else {
                uploadState = 'error';
            }
        }

        return uploadState;
    }
}

function getWatchers(){
    return {
        uploadState(state){
            const vm = this;
            if(state === 'success'){
                vm.$refs.successToast.show();
            } else if(state === 'partial-success'){
                vm.$refs.warningToast.show();
            } else if(state === 'error'){
                vm.$refs.errorToast.show();
            }
        }
    };
}

function created(){}

function getMethods(){
    return {
        triggerFileInput,
        handleFileDrop,
        uploadImage,
        handleDragEvent,
        handleDragLeaveEvent,
        resetFileInput
    };

    function triggerFileInput(){
        const vm = this;
        vm.$refs.fileInput.click();
    }

    function handleFileDrop(event){
        const vm = this;
        vm.isDragging = false;
        const files = event.target.files || event.dataTransfer.files;
        const fileUploads = Object.values(files).map(createFileUploads);
        vm.fileUploads.push(...fileUploads);
        batchUploadFiles(fileUploads);

        function createFileUploads(file){
            const fileUploadTracker = {
                file,
                uploadProgress: 0,
                errors: [],
                success: false,
                isLoading: true,
                successHandler,
                errorHandler,
                progressCallback
            };
            return fileUploadTracker;
            function successHandler(response){
                fileUploadTracker.success = true;
                fileUploadTracker.isLoading = false;
            }
            function errorHandler(error){
                let errorMessages = ['Something went wrong'];
                const isValidationError = error && error.status === 422 && error.data.errors;
                if(isValidationError){
                    errorMessages = Object.values(error.data.errors).reduce((acc, arr) => acc.concat(arr), []);
                } else {
                    errorMessages = typeof error === 'string' ? [error] : [(error.appMessage || error.message)];
                }
                fileUploadTracker.errors.push(...errorMessages);
                fileUploadTracker.isLoading = false;
            }
            function progressCallback(progressEvent){
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                fileUploadTracker.uploadProgress = percentCompleted;
            }
        }

        function batchUploadFiles(fileUploads){
            const batchSize = 30;
            const batchInterval = 60000;
            const uploadBatch = fileUploads.splice(0, batchSize);
            uploadBatch.forEach(vm.uploadImage);
            if(fileUploads.length){
                setTimeout(() => {
                    batchUploadFiles(fileUploads);
                }, batchInterval);
            }
        }
    }

    function handleDragEvent(event){
        const vm = this;
        vm.isDragging = true;
    }

    function handleDragLeaveEvent(event){
        const vm = this;
        vm.isDragging = false;
    }

    function uploadImage(fileUploadTracker){
        const formData = new FormData();
        formData.append('photo', fileUploadTracker.file);
        formData.append('file_name', fileUploadTracker.file.name);

        return Vue.appApi().authorized().equipment().images()
            .storeEquipmentImage(formData, fileUploadTracker.progressCallback)
            .then(fileUploadTracker.successHandler)
            .catch(fileUploadTracker.errorHandler);
    }

    function resetFileInput(){
        const vm = this;
        vm.fileUploads = [];
    }
}
