export default {
    components: {},
    props: {
        role: {
            type: Object,
            required: true
        },
        startOpen: {
            type: Boolean,
            default: false
        },
        permissions: {
            type: Array,
            required: true
        }
    },
    data,
    computed: getComputed(),
    mounted,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        validationErrors: {},
        isEditingRole: false,
        isSavingRole: false,
        isCreatingRole: false,
        isDeletingRole: false,
        isConfirmingDelete: false,
        localRole: null
    };
}

function getComputed(){
    return {
        canEditRole(){
            const vm = this;
            return !vm.role.is_default;
        },
        canDeleteRole(){
            const vm = this;
            return vm.role.id && !vm.role.is_default;
        },
        canSaveRole(){
            const vm = this;
            return vm.role.id && !vm.role.is_default;
        },
        canCreateRole(){
            const vm = this;
            return !vm.role.id;
        },
        isLoading(){
            const vm = this;
            return vm.isSavingRole || vm.isCreatingRole || vm.isDeletingRole;
        }
    };
}

function mounted(){
    const vm = this;
    if(vm.startOpen){
        vm.beginEditingRole();
    }
}

function getMethods(){
    return {
        displayErrorMessage,
        beginEditingRole,
        resetErrors,
        createRole,
        saveRole,
        deleteRole,
        cancelEdit
    };

    function displayErrorMessage(error){
        const vm = this;
        const isValidationError = error && error.status === 422 && error.data.errors;
        if(isValidationError){
            vm.validationErrors = error.data.errors;
        }
        const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
        vm.errorMessages.push(errorMessage);
    }

    function beginEditingRole(){
        const vm = this;
        vm.localRole = JSON.parse(JSON.stringify(vm.role));
        vm.localRole.permissions = vm.localRole.permissions.map(({ name }) => name);
        vm.isEditingRole = true;
    }

    function resetErrors(){
        const vm = this;
        vm.validationErrors = {};
        vm.errorMessages = [];
    }

    function createRole(){
        const vm = this;
        vm.isCreatingRole = true;
        vm.resetErrors();
        return Vue.appApi().authorized().account().roles().createRole(vm.localRole).then(emitSuccess).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function emitSuccess({ data }){
            vm.$emit('role-created', data);
            vm.isEditingRole = false;
        }
        function resetLoadingState(){
            vm.isCreatingRole = false;
        }
    }

    function saveRole(){
        const vm = this;
        vm.isSavingRole = true;
        vm.resetErrors();
        return Vue.appApi().authorized().account().roles(vm.localRole.id).updateRole(vm.localRole).then(emitSuccess).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function emitSuccess({ data }){
            vm.$emit('role-saved', data);
            vm.isEditingRole = false;
        }
        function resetLoadingState(){
            vm.isSavingRole = false;
        }
    }

    function deleteRole(){
        const vm = this;
        vm.isDeletingRole = true;
        vm.resetErrors();
        return Vue.appApi().authorized().account().roles(vm.localRole.id).deleteRole().then(emitSuccess).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function emitSuccess(){
            vm.isEditingRole = false;
            vm.$emit('role-deleted', vm.role);
        }
        function resetLoadingState(){
            vm.isDeletingRole = false;
        }
    }

    function cancelEdit(){
        const vm = this;
        vm.isEditingRole = false;
        vm.localRole = null;
        vm.resetErrors();
        vm.$emit('edit-cancelled', vm.role);
    }
}
