import { render, staticRenderFns } from "./document-lines-table.vue?vue&type=template&id=53d004d0&"
import script from "./document-lines-table.controller.js?vue&type=script&lang=js&"
export * from "./document-lines-table.controller.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports