import MyLeads from './my-leads/my-leads';
import NewLead from './new-lead/new-lead';
export default {
    components: {
        MyLeads,
        NewLead,
    },
    props: {},
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        showNewLeadForm: false,
        errorMessages: [],
        showMenu: true
    };
}

function getComputed(){
    return {};
}

function created(){}

function getMethods(){
    return {
        displayErrorMessage
    };

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }
}
