import Vue from 'vue';
import VueRouter from 'vue-router';
import appRoutes from './app.routes';
import store from './app.store';
import PageNotFound from './page-not-found.vue';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        appRoutes,
        { name: 'page-not-found', path: '/page-not-found', component: PageNotFound },
        { path: '*', component: PageNotFound },
    ],
});

router.beforeEach(redirectDisabledRoutes);
router.beforeEach(checkIfAuthorized);
router.beforeEach(checkForPermission);
router.beforeEach(checkForEmailVerification);
router.beforeEach(checkCurrentAccount);
router.beforeEach(updatePageTitle);
router.redirectAfterLogin = redirectAfterLogin;

export default router;

function redirectDisabledRoutes(toRoute, fromRoute, next){
    const isRouteDisabled = toRoute.matched.some(route => route.meta.disabled);
    if(isRouteDisabled){
        next({ name: 'page-not-found' });
    } else {
        next();
    }
}

function checkIfAuthorized(toRoute, fromRoute, next){

    const authenticationIsRequired = toRoute.matched.some((route) => route.meta.requiresAuth);
    const userIsAuthorized = store.state.guest.user.hasAccessToken;
    const userWantsAuthRouteButNotLoggedIn = authenticationIsRequired && !userIsAuthorized;

    if(userIsAuthorized === 'pending'){
        return store.dispatch('user/GET_STORED_ACCESS_TOKEN').then(() => checkIfAuthorized(toRoute, fromRoute, next));
    } else if(userWantsAuthRouteButNotLoggedIn){
        router.redirectedFrom = toRoute;
        next({ name: 'login' });
    } else {
        next();
    }
}

function checkForPermission(toRoute, fromRoute, next){
    const requiredPermissions = toRoute.matched.reduce(consolidatePermissions, []);
    const user = store.state.guest.user.user;

    if(requiredPermissions.length && !user){
        store.dispatch('user/GET_USER').then(() => {
            checkForPermission(toRoute, fromRoute, next);
        });
    } else {
        const userHasPermission = requiredPermissions.every(checkUserPermission);
        if(userHasPermission){
            next();
        } else {
            next({ name: 'dashboard' });
        }
    }
    function consolidatePermissions(accumulator, route){
        return accumulator.concat(route.meta.permissions || []);
    }
    function checkUserPermission(permission){
        const hasPermission = store.getters.userHasPermissionTo(permission);
        return hasPermission;
    }
}

function checkForEmailVerification(to, from, next){
    const verifiedEmailRequired = to.matched.some(({ meta }) => meta.requiresVerifiedEmail);
    const user = store.getters.user;
    if(!verifiedEmailRequired){
        next();
    } else if(!user){
        store.dispatch('user/GET_USER').then(() => {
            checkForEmailVerification(to, from, next);
        });
    } else if(user.email_verified){
        next();
    } else {
        next({ name: 'verification-required' });
    }
}

function checkCurrentAccount(to, from, next){
    const currentAccountRequired = to.matched.some(({ meta }) => meta.requiresAccount);
    const user = store.getters.user;
    if(!currentAccountRequired){
        next();
    } else if(!user){
        store.dispatch('user/GET_USER').then(() => {
            checkCurrentAccount(to, from, next);
        });
    } else if(user.current_account){
        next();
    } else {
        next({ name: 'no-account' });
    }
}

function updatePageTitle(toRoute, fromRoute, next){
    Vue.nextTick(setPageTitle); // use nextTick to ensure proper page in history gets the title
    next();
    function setPageTitle(){
        const inheritedRoutes = toRoute.matched.slice().reverse();
        const closestRouteWithTitle = inheritedRoutes.find(route => route.meta && route.meta.title);
        if(closestRouteWithTitle){
            document.title = closestRouteWithTitle.meta.title;
        } else {
            document.title = 'Herc-U-Lift';
        }
    }
}
function redirectAfterLogin(toRoute){
    toRoute = toRoute || { name: 'dashboard' };
    if(router.redirectedFrom){
        toRoute.name = router.redirectedFrom.name;
        toRoute.params = router.redirectedFrom.params;
        toRoute.query = router.redirectedFrom.query;
        delete router.redirectedFrom;
    }
    router.push(toRoute);
}
