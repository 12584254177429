import MechanicPmsTable from '../components/mechanic-pms-table/mechanic-pms-table';
import MechanicWorkordersTable from '../components/mechanic-workorders-table/mechanic-workorders-table';

export default {
    components: {
        MechanicPmsTable,
        MechanicWorkordersTable
    },
    data,
    computed: getComputed(),
    created,
    methods: getMethods(),
};

function data(){
    const vm = this;
    return {
        localWorkorders: [],
        localPms: [],
        isLoadingWorkorders: false,
        isLoadingPMs: false,
        errorMessages: [],
        mechanicId: vm.$store.getters.user.current_account_user.mechanic_initials,
        selectedWorkorderNumber: null
    };
}

function getComputed(){
    return {};
}

function created(){
    const vm = this;
    if(vm.mechanicId){
        vm.searchWorkorders();
        vm.searchPMs();
    } else {
        vm.errorMessages = ['Initials not found, please contact your administrator to configure your Technician Road page.'];
    }
}

function getMethods(){
    return {
        searchWorkorders,
        searchPMs,
        displayErrorMessage,
        selectWorkorder
    };

    function searchWorkorders(){
        const vm = this;
        const payload = {
            mechanic_id: vm.mechanicId,
        };
        vm.isLoadingWorkorders = true;
        vm.$store.dispatch('mechanics/GET_WORKORDERS', payload)
            .then(setLocalWorkorders)
            .finally(stopSpinner);

        function setLocalWorkorders(workorders){
            vm.localWorkorders = workorders;
            vm.isLoadingWorkorders = false;
        }

        function stopSpinner(){
            vm.isLoadingWorkorders = false;
        }
    }

    function searchPMs(){
        const vm = this;
        const payload = {
            mechanic_id: vm.mechanicId,
        };
        vm.isLoadingPMs = true;
        vm.$store.dispatch('mechanics/GET_PMS', payload)
            .then(setLocalPms)
            .finally(stopSpinner);

        function setLocalPms(data){
            vm.localPms = data.data;
            vm.isLoadingPMs = false;
        }

        function stopSpinner(){
            vm.isLoadingPMs = false;
        }
    }

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }
    function selectWorkorder(workorderNumber){
        const vm = this;
        vm.selectedWorkorderNumber = workorderNumber;
    }
}
