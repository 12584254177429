import { render, staticRenderFns } from "./invite-redirect.vue?vue&type=template&id=32b8d478&scoped=true&"
import script from "./invite-redirect.controller.js?vue&type=script&lang=js&"
export * from "./invite-redirect.controller.js?vue&type=script&lang=js&"
import style0 from "./_invite-redirect.scss?vue&type=style&index=0&id=32b8d478&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32b8d478",
  null
  
)

export default component.exports