import UserComponent from './user.vue';
import LoginComponent from './login/login.vue';
import RegisterComponent from './register/register.vue';
import ForgotPasswordComponent from './forgot-password/forgot-password.vue';
import ResetPasswordComponent from './reset-password/reset-password.vue';
import store from 'vue_root/app.store';

export default {
    path: '/user',
    name: 'user',
    redirect: { name: 'home' },
    component: UserComponent,
    children: [
        {
            path: 'login',
            name: 'login',
            component: LoginComponent,
            meta: {
                title: 'Herc-U-Lift - Login'
            }
        },
        {
            path: 'register',
            name: 'register',
            component: RegisterComponent,
            meta: {
                title: 'Herc-U-Lift - Register',
                disabled: store.state.appEnv.registrationType === 'admin-only'
            }
        },
        {
            path: 'forgot',
            name: 'forgot-password',
            component: ForgotPasswordComponent,
            meta: {
                title: 'Herc-U-Lift - Forgot Password'
            }
        },
        {
            path: 'reset-password',
            name: 'reset-password',
            component: ResetPasswordComponent,
            meta: {
                title: 'Herc-U-Lift - Login'
            }
        }
    ],
};
