export default {
    components: {},
    props: {
        workOrderNumber: {
            type: String,
            default: ''
        },
        scrollOnLoad: {
            type: Boolean,
            default: true
        }
    },
    data,
    computed: getComputed(),
    watch: getWatches(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        isLoadingRentalInvoice: false,
        workOrder: null,
        rentalCheckin: null,
        workOrderHistoryNumber: null,
    };
}

function getComputed(){
    return {};
}

function getWatches(){
    return {
        workOrderNumber
    };

    function workOrderNumber(value){
        const vm = this;
        vm.displayRentalCheckinInvoice(value);
        if(!value){
            vm.workOrder = null;
            vm.rentalCheckin = null;
            vm.workOrderHistoryNumber = null;
        }
    }
}

function created(){
    const vm = this;
    vm.displayRentalCheckinInvoice(vm.workOrderNumber);
}

function getMethods(){
    return {
        emitError,
        displayRentalCheckinInvoice,
        getWorkorderHistoryLines
    };

    function emitError(error){
        const vm = this;
        vm.$emit('error', error);
    }

    function displayRentalCheckinInvoice(workOrderNumber){
        const vm = this;
        if(!workOrderNumber){
            return false;
        }
        const query = {
            distinct: true,
            select: ['equipment_object']
        };
        vm.isLoadingRentalInvoice = true;
        Vue.appApi().authorized().workorders(workOrderNumber).findWorkorder(query)
            .then(setDisplayedWorkOrder)
            .then(getRentalCheckinDetailsByFleetCode)
            .catch(vm.emitError)
            .finally(resetLoadingState);

        function setDisplayedWorkOrder(response){
            vm.workOrder = response.data;
        }
        function getRentalCheckinDetailsByFleetCode(){
            const fleetCode = vm.workOrder.equipment_object;
            return Vue.appApi().authorized().rentalCheckins(fleetCode).findByFleetCode().then(setRentalCheckin).catch(handle404Error);

            function setRentalCheckin(response){
                vm.rentalCheckin = response.data;
            }

            function handle404Error(error){
                if(error.status === 404){
                    vm.rentalCheckin = {};
                } else {
                    return Promise.reject(error);
                }
            }
        }
        function resetLoadingState(){
            vm.isLoadingRentalInvoice = false;
            vm.scrollTo('workorderInfo');
        }
    }

    function getWorkorderHistoryLines(workOrderNumber, isUserAction = false){
        const vm = this;
        vm.workOrderHistoryNumber = workOrderNumber;
        if(isUserAction){
            vm.scrollTo('historyLines');
        }
    }
}
