import fileDownload from 'js-file-download';

export default {
    components: {},
    props: {},
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        tabIndex: 0,
        queryFields: {},
        isDownloadingReport: false,
        reportTypes: [
            {
                displayName: 'Equipment List',
                value: 'equipment-data-list',
                makeApiRequest: Vue.appApi().authorized().reports().getEquipmentDataList,
                filename: 'EquipmentData_List.xlsx',
                queries: []
            },
            {
                displayName: 'PM List',
                value: 'service-action-spreadsheet',
                makeApiRequest: Vue.appApi().authorized().reports().getEquipmentServiceActionSpreadsheet,
                filename: 'EquipmentData_List.xlsx',
                queries: []
            },
            {
                displayName: 'Customer Cleanup List',
                value: 'customer-data-list',
                makeApiRequest: Vue.appApi().authorized().reports().getCustomerDataList,
                filename: 'CustomerData_List.xlsx',
                queries: [
                    {
                        label: 'State',
                        queryParam: 'state',
                        inputType: 'text'
                    }
                ]
            },
            {
                displayName: 'Contacts List',
                value: 'territory-list',
                makeApiRequest: Vue.appApi().authorized().reports().getTerritoryListSpreadsheet,
                filename: 'Territory_List.xlsx',
                queries: [
                    {
                        label: 'Search Type',
                        queryParam: 'search_type',
                        inputType: 'select',
                        options: [
                            { text: 'Salesperson Code/Rental', value: 'Salesperson' },
                            { text: 'Salesperson Service/Parts', value: 'Salesperson_Parts' },
                            { text: 'Salesperson Aerial', value: 'Salespeople' },
                            { text: 'Salesperson RT', value: 'Salespeople' },
                        ]
                    },
                    {
                        label: 'Search Term',
                        queryParam: 'search_term',
                        inputType: 'text'
                    }
                ]
            },
            {
                displayName: 'Zip List',
                value: 'zipcodes-list',
                makeApiRequest: Vue.appApi().authorized().reports().getZipcodeSpreadsheet,
                filename: 'Zip_List.xlsx',
                queries: [
                    {
                        label: 'Search Type',
                        queryParam: 'search_type',
                        inputType: 'select',
                        options: [
                            { text: 'Salesperson Code/Rental', value: 'Salesperson' },
                            { text: 'Salesperson Service/Parts', value: 'CSS' },
                            { text: 'Salesperson Aerial', value: 'AERIAL' },
                            { text: 'Salesperson RT', value: 'RT' },
                        ]
                    },
                    {
                        label: 'Search Term',
                        queryParam: 'search_term',
                        inputType: 'text'
                    }
                ]
            },
        ]
    };
}

function getComputed(){
    return {
        selectedReportType(){
            const vm = this;
            return vm.reportTypes[vm.tabIndex];
        }
    };
}

function created(){}

function getMethods(){
    return {
        displayErrorMessage,
        resetQueries,
        generateReport
    };

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const isValidationError = error.status === 422 && error.data.errors;
            if(isValidationError){
                vm.errorMessages = [...Object.values(error.data.errors)].flat();
            } else {
                const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
                vm.errorMessages.push(errorMessage);
            }
        }
    }

    function resetQueries(){
        const vm = this;
        Object.keys(vm.queryFields).forEach(key => {
            delete vm.queryFields[key];
        });
    }

    function generateReport(){
        const vm = this;
        vm.isDownloadingReport = true;
        return vm.selectedReportType.makeApiRequest(vm.queryFields).then(handleResult).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function handleResult(response){
            fileDownload(response.data, vm.selectedReportType.filename, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        }

        function resetLoadingState(){
            vm.isDownloadingReport = false;
        }
    }
}
