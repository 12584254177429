export default {
    components: {},
    props: {},
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        isLoadingImageList: false,
        pendingImageList: [],
    };
}

function getComputed(){
    return {};
}

function created(){
    const vm = this;
    vm.indexPendingEquipmentImages();
}

function getMethods(){
    return {
        displayErrorMessage,
        indexPendingEquipmentImages,
        displayImagePreview,
        deleteEquipmentImage,
        acceptEquipmentImage
    };

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function indexPendingEquipmentImages(){
        const vm = this;
        vm.isLoadingImageList = true;
        return Vue.appApi().authorized().equipment().images().indexPendingEquipmentImages().then(setPendingImageList).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function setPendingImageList({ data }){
            vm.pendingImageList = data.map(setDisplayProperties);
            function setDisplayProperties(imageLocation){
                return {
                    fileName: imageLocation.split('/').pop(),
                    fileLocation: imageLocation,
                    isConfirmingDelete: false,
                    isDeleting: false,
                    isPreviewActive: false,
                    isLoadingPreview: false,
                    isAccepting: false,
                    isAccepted: false,
                    source: '',
                    validationErrors: {},
                    errorMessages: []
                };
            }
        }
        function resetLoadingState(){
            vm.isLoadingImageList = false;
        }
    }

    function displayImagePreview(image){
        const vm = this;
        let getSourcePromise = Promise.resolve();
        if(!image.source){
            image.isLoadingPreview = true;
            const query = {
                file_location: image.fileLocation
            };
            getSourcePromise = Vue.appApi().authorized().equipment().images().getEncodedImage(query).then(setImageSource).catch(vm.displayErrorMessage).finally(resetLoadingState);
        }
        image.isPreviewActive = true;
        return getSourcePromise;

        function setImageSource({ data }){
            image.source = data;
        }
        function resetLoadingState(){
            image.isLoadingPreview = false;
        }
    }

    function deleteEquipmentImage(image){
        const vm = this;
        image.isDeleting = true;
        const query = {
            file_location: image.fileLocation
        };
        return Vue.appApi().authorized().equipment().images().deleteEquipmentImage(query).then(removeFromList).catch(vm.displayErrorMessage).finally(resetLoadingState);
        function removeFromList(){
            const imageIndex = vm.pendingImageList.indexOf(image);
            if(imageIndex >= 0){
                vm.pendingImageList.splice(imageIndex, 1);
            }
        }
        function resetLoadingState(){
            image.isDeleting = false;
        }
    }

    function acceptEquipmentImage(image){
        const vm = this;
        image.isAccepting = true;
        const payload = {
            file_location: image.fileLocation,
            file_name: image.fileName
        };
        return Vue.appApi().authorized().equipment().images().acceptEquipmentImage(payload).then(displaySuccessMessage).catch(handleError).finally(resetLoadingState);

        function displaySuccessMessage(){
            image.isAccepted = true;
            setTimeout(removeImageFromList, 3000);
            function removeImageFromList(){
                const imageIndex = vm.pendingImageList.indexOf(image);
                if(imageIndex >= 0){
                    vm.pendingImageList.splice(imageIndex, 1);
                }
            }
        }
        function handleError(error){
            if(error){
                const isValidationError = error.status === 422 && error.data.errors;
                if(isValidationError){
                    image.validationErrors = error.data.errors;
                } else {
                    const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
                    image.errorMessages = [errorMessage];
                }
            }
        }
        function resetLoadingState(){
            image.isAccepting = false;
        }
    }
}
