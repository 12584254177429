import fileDownload from 'js-file-download';
import MechanicPmsTable from '../../components/mechanic-pms-table/mechanic-pms-table';
import MechanicWorkordersTable from '../../components/mechanic-workorders-table/mechanic-workorders-table';
import PmMetrics from './pm-metrics/pm-metrics';

export default {
    components: {
        MechanicPmsTable,
        MechanicWorkordersTable,
        PmMetrics
    },
    props: {},
    data,
    computed: getComputed(),
    watch: getWatches(),
    created,
    beforeRouteUpdate,
    methods: getMethods(),
};

function data(){
    const vm = this;
    return {
        localWorkorders: [],
        localPms: [],
        isLoadingWorkorders: false,
        isLoadingPMs: false,
        isDownloadingSpreadsheet: false,
        errorMessages: [],
        mechanicId: vm.$route.params.mechanicId,
        selectedWorkorderNumber: null
    };
}

function getComputed(){
    return {};
}

function getWatches(){}

function created(){
    const vm = this;
    vm.searchWorkorders();
    vm.searchPMs();
}

function beforeRouteUpdate(to, from, next){
    const vm = this;
    vm.mechanicId = to.params.mechanicId;
    vm.searchWorkorders();
    vm.searchPMs();
    next();
}

function getMethods(){
    return {
        searchWorkorders,
        searchPMs,
        displayErrorMessage,
        exportResultsToExcel,
        selectWorkorder
    };

    function searchWorkorders(){
        const vm = this;
        const payload = {
            mechanic_id: vm.mechanicId,
        };
        vm.isLoadingWorkorders = true;
        vm.selectedWorkorderNumber = null;
        vm.$store.dispatch('mechanics/GET_WORKORDERS', payload)
            .then(setLocalWorkorders)
            .finally(stopSpinner);

        function setLocalWorkorders(workorders){
            vm.localWorkorders = workorders;
            vm.isLoadingWorkorders = false;
        }

        function stopSpinner(){
            vm.isLoadingWorkorders = false;
        }
    }

    function searchPMs(){
        const vm = this;
        const payload = {
            mechanic_id: vm.mechanicId,
        };
        vm.isLoadingPMs = true;
        vm.$store.dispatch('mechanics/GET_PMS', payload)
            .then(setLocalPms)
            .finally(stopSpinner);

        function setLocalPms(data){
            vm.localPms = data.data;
            vm.isLoadingPMs = false;
        }

        function stopSpinner(){
            vm.isLoadingPMs = false;
        }
    }

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function exportResultsToExcel(){
        const vm = this;
        vm.isDownloadingSpreadsheet = true;
        const payload = {
            mechanicId: vm.mechanicId
        };

        return Vue.appApi().authorized().mechanics(vm.mechanicId).exportServiceWork(payload).then(handleResult).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function handleResult(response){
            fileDownload(response.data, `Mechanic_Work_${vm.mechanicId}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        }

        function resetLoadingState(){
            vm.isDownloadingSpreadsheet = false;
        }
    }

    function selectWorkorder(workorderNumber){
        const vm = this;
        vm.selectedWorkorderNumber = workorderNumber;
    }
}
