export default {
    components: {},
    props: {},
    data,
    computed: getComputed(),
    methods: getMethods(),
};

function data(){
    return {
        successMessages: [],
        errorMessages: [],
        company: '',
        contact: '',
        department: '',
        priority: '',
        message: '',
        isSubmittingLead: false,
    };
}

function getComputed(){
    return {
        canSubmitLead,
    };

    function canSubmitLead(){
        const vm = this;
        return vm.company !== '' ||
            vm.contact !== '' ||
            vm.department !== '' ||
            vm.priority !== '' ||
            vm.message !== '';
    }
}

function getMethods(){
    return {
        submitLead,
        displayErrorMessage
    };

    function submitLead(){
        const vm = this;
        vm.errorMessages = [];
        vm.successMessages = [];
        vm.isSubmittingLead = true;
        const payload = {
            company: vm.company,
            contact: vm.contact,
            department: vm.department,
            priority: vm.priority,
            message: vm.message,
        };
        return Vue.appApi().authorized().leads().createLead(payload)
            .then(notifyLeadSubmission)
            .catch(vm.displayErrorMessage)
            .finally(stopSpinner);

        function notifyLeadSubmission(){
            vm.$store.dispatch('pmLeads/NOTIFY_LEAD_SUBMISSION', payload);
            clearForm();
        }

        function clearForm(){
            vm.successMessages.push('Successfully saved.');
            vm.company = '';
            vm.contact = '';
            vm.department = '';
            vm.priority = '';
            vm.message = '';
        }

        function stopSpinner(){
            vm.isSubmittingLead = false;
        }
    }

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

}

