const state = {
    pmComments: []
};

export default {
    namespaced: true,
    state,
    mutations: getMutations(),
    actions: getActions(),
    getters: getGetters(),
    modules: {}
};

function getActions(){

    return {
        GET_PM_COMMENTS: getComments,
    };

    function getComments({ commit }, payload){
        return Vue.appApi().authorized().pms().getComments(payload).then(setPmComments);

        function setPmComments(response){
            commit('SET_PM_COMMENTS', response.data);
            return Promise.resolve(JSON.parse(JSON.stringify(response)));
        }
    }
}

function getGetters(){

    return {
        pmComments,
    };

    function pmComments(state){
        return state.pmComments;
    }
}

function getMutations(){

    return {
        SET_PM_COMMENTS: setPmCommentsState,
    };

    function setPmCommentsState(state, payload){
        state.pmComments = payload;
    }
}
