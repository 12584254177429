export default {
    components: {},
    props: {},
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        showMenu: true,
        rentalQuery: {
            branch: 'branch_all',
            posting_status: '*',
            owner_status: '*',
            rental_status: '*',
            equip_category: '*',
            equip_group: '*',
        },
        equipmentCategories: [],
        equipmentGroups: [],
    };
}

function getComputed(){
    return {};
}

function created(){
    const vm = this;
    vm.$store.dispatch('rentalRedtag/GET_EQUIPMENT_CATEGORIES')
        .then(setEquipmentCategories);
    vm.$store.dispatch('rentalRedtag/GET_EQUIPMENT_GROUPS')
        .then(setEquipmentGroups);
    function setEquipmentCategories(response){
        vm.equipmentCategories = response.data;
    }
    function setEquipmentGroups(response){
        vm.equipmentGroups = response.data;
    }
}

function getMethods(){
    return {
        displayErrorMessage,
        loadRedtagEquipment,
    };

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function loadRedtagEquipment(){
        const vm = this;
        const routeObject = {
            name: 'rental-redtag',
            params: {
                branch: vm.rentalQuery.branch,
            },
            query: {
                posting_status: vm.rentalQuery.posting_status,
                owner_status: vm.rentalQuery.owner_status,
                rental_status: vm.rentalQuery.rental_status,
                equip_category: vm.rentalQuery.equip_category,
                equip_group: vm.rentalQuery.equip_group,
            }
        };
        vm.$router.push(routeObject);
    }
}
