import CustomerDetail from 'vue_root/components/customer-detail/customer-detail.vue';
import EquipmentDetail from 'vue_root/components/equipment-detail/equipment-detail.vue';
import EquipmentSummary from 'vue_root/components/equipment-summary/equipment-summary.vue';
import RentalCheckin from 'vue_root/components/rental-checkin/rental-checkin.vue';

export default {
    components: {
        CustomerDetail,
        EquipmentDetail,
        EquipmentSummary,
        RentalCheckin,
    },
    props: {},
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        equipmentNumber: '',
        equipment: null,
        customer: null,
        meterReadings: [],
        barCode: null,
        equipmentPms: null,
        equipmentPmsTotal: 0,
        equipmentWorkorders: null,
        equipmentWorkordersTotal: 0,
        workordersFleetCode: null,
        warrantyWorkorderNumber: null,
        linesWorkorderNumber: null,
        isLoadingEquipment: false,
        isUploadingImage: false
    };
}

function getComputed(){
    return {
        hiddenWoLineColumns(){
            const vm = this;
            return vm.$store.getters.userHasPermissionTo('view pm-info-wo-line-amounts') ? [] : ['Amount'];
        }
    };
}

function created(){}

function getMethods(){
    return {
        displayErrorMessage,
        displayInfoForEquipmentNumber,
        findEquipment,
        getEquipmentWorkorders,
        getEquipmentPms,
        getEquipmentMeterReadings,
        getEquipmentBarCode,
        getWorkorderWarrantiesAndLines,
        uploadImage
    };

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function displayInfoForEquipmentNumber(equipmentNumber){
        const vm = this;
        vm.equipmentNumber = equipmentNumber.toUpperCase();
        vm.isLoadingEquipment = true;
        vm.workordersFleetCode = vm.equipmentNumber;
        vm.errorMessages = [];
        const apiRequests = [
            vm.findEquipment(),
            vm.getEquipmentWorkorders(),
            vm.getEquipmentPms(),
            vm.getEquipmentMeterReadings(),
            vm.getEquipmentBarCode(),
        ];

        Promise.all(apiRequests).then(setEquipmentData).then(getCustomerData).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function setEquipmentData(){
            if(vm.equipmentWorkorders.length){
                vm.getWorkorderWarrantiesAndLines(vm.equipmentWorkorders[0].WO_Number);
            }
        }

        function getCustomerData(){
            if(vm.meterReadings.length){
                const workorderNumber = vm.meterReadings[0].Source_No;
                return Vue.appApi().authorized().workorders(workorderNumber).getWorkorderCustomer().then(setCustomerData);
            }

            function setCustomerData(response){
                vm.customer = response.data;
            }
        }

        function resetLoadingState(){
            vm.isLoadingEquipment = false;
        }
    }

    function findEquipment(){
        const vm = this;
        vm.equipment = null;
        return Vue.appApi().authorized().rentalCheckins(vm.equipmentNumber).findByFleetCode().then(setEquipment).catch(throwError);
        function setEquipment(response){
            vm.equipment = response.data;
        }
        function throwError(err){
            if(err.status && err.status === 404){
                throw new Error('No results found for: ' + vm.equipmentNumber);
            } else {
                throw err;
            }
        }
    }

    function getEquipmentWorkorders(){
        const vm = this;
        return Vue.appApi().authorized().rentalCheckins(vm.equipmentNumber).getWorkorders()
            .then(setEquipmentWorkorders);
        function setEquipmentWorkorders(response){
            vm.equipmentWorkorders = response.data.data;
            vm.equipmentWorkordersTotal = response.data.total;
        }
    }

    function getEquipmentPms(){
        const vm = this;
        return Vue.appApi().authorized().rentalCheckins(vm.equipmentNumber).getPms()
            .then(setEquipmentPms);
        function setEquipmentPms(response){
            vm.equipmentPms = response.data.data;
            vm.equipmentPmsTotal = response.data.total;
        }
    }

    function getEquipmentMeterReadings(){
        const vm = this;
        return Vue.appApi().authorized().rentalCheckins(vm.equipmentNumber).getMeterReadings()
            .then(setMeterReadings);
        function setMeterReadings(response){
            vm.meterReadings = response.data.data;
        }
    }

    function getEquipmentBarCode(){
        const vm = this;
        return Vue.appApi().authorized().rentalCheckins(vm.equipmentNumber).getBarCode()
            .then(setBarCode);
        function setBarCode(response){
            vm.barCode = response.data.barCode;
        }
    }

    function getWorkorderWarrantiesAndLines(workorderNumber){
        const vm = this;
        vm.warrantyWorkorderNumber = workorderNumber;
        vm.linesWorkorderNumber = workorderNumber;
        vm.scrollTo('warrantyLines');
    }

    function uploadImage(event){
        const vm = this;
        vm.isUploadingImage = true;
        const formData = new FormData();
        const files = event.target.files || event.dataTransfer.files;
        const photo = files[0];
        formData.append('photo', photo);

        return Vue.appApi().authorized().workorders(vm.equipmentNumber).storeWarrantyImage(formData).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function resetLoadingState(){
            vm.isUploadingImage = false;
        }
    }
}
