export default {
    components: {},
    props: {},
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        validationErrors: {},
        isLoadingGroups: false,
        isLoadingBenchmarks: false,
        equipmentGroups: [],
        benchmarks: [],
        defaultBenchmark: null,
        newBenchmark: {},
        tableColumns: [
            {
                key: 'meta_data.equipment_group_code',
                label: 'Equipment Group',
                sortable: true
            },
            {
                key: 'meta_data.benchmark_cph',
                label: 'Benchmark CPH',
                sortable: true,
            },
            {
                key: 'new_benchmark',
                label: 'New value',
            },
            {
                key: 'actions',
                label: '',
            },
        ]
    };
}

function getComputed(){
    return {
        isLoading(){
            const vm = this;
            return vm.isLoadingGroups || vm.isLoadingBenchmarks;
        },
        equipmentGroupOptions(){
            const vm = this;
            return vm.equipmentGroups.map(formatAsOption).filter(removeSelectedOptions);

            function formatAsOption(group){
                return group.Code;
            }
            function removeSelectedOptions(option){
                const isOptionSelected = vm.benchmarks.find(({ meta_data }) => meta_data.equipment_group_code === option);
                return !isOptionSelected;
            }
        }
    };
}

function created(){
    const vm = this;
    vm.initializeNewBenchmark();
    vm.fetchEquipmentGroups();
    vm.fetchBenchmarks();
}

function getMethods(){
    return {
        displayErrorMessage,
        fetchEquipmentGroups,
        fetchBenchmarks,
        updateBenchmark,
        initializeNewBenchmark,
        saveBenchmark,
        deleteBenchmark
    };

    function displayErrorMessage(error){
        const vm = this;
        const isValidationError = error && error.status === 422 && error.data.errors;
        if(isValidationError){
            vm.validationErrors = error.data.errors;
        } else {
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function fetchEquipmentGroups(){
        const vm = this;
        vm.isLoadingGroups = true;
        return Vue.appApi().authorized().equipment().equipmentGroups().indexGroups()
            .then(setGroups)
            .catch(vm.displayErrorMessage)
            .finally(resetLoadingState);
        function setGroups(response){
            vm.equipmentGroups = response.data.map(setDisplayProperties);
            function setDisplayProperties(group){
                group.isSavingBenchmark = false;
                return group;
            }
        }
        function resetLoadingState(){
            vm.isLoadingGroups = false;
        }
    }

    function fetchBenchmarks(){
        const vm = this;
        vm.isLoadingBenchmarks = true;
        return Vue.appApi().authorized().lookups().getLookupsByType('equipment_group_benchmark')
            .then(setReasons)
            .catch(vm.displayErrorMessage)
            .finally(resetLoadingState);
        function setReasons(response){
            vm.benchmarks = response.data.map(addDisplayProperties);
            vm.defaultBenchmark = vm.benchmarks.find(({ meta_data }) => meta_data.equipment_group_code === 'DEFAULT_BENCHMARK_VALUE');

            function addDisplayProperties(benchmark){
                benchmark.new_benchmark = benchmark.meta_data.benchmark_cph;
                benchmark.isSaving = false;
                benchmark.isDeleting = false;
                benchmark.validationErrors = {};
                return benchmark;
            }
        }
        function resetLoadingState(){
            vm.isLoadingBenchmarks = false;
        }
    }

    function updateBenchmark(benchmark){
        const vm = this;
        const isValid = validateBenchmark(benchmark);
        if(!isValid){
            return false;
        }
        benchmark.isSaving = true;
        const payload = JSON.parse(JSON.stringify(benchmark));
        if(benchmark.new_benchmark !== ''){
            payload.meta_data.benchmark_cph = parseFloat(benchmark.new_benchmark);
        }
        payload.meta_data = JSON.stringify(payload.meta_data);

        return Vue.appApi().authorized().lookups(benchmark.id).updateLookup(payload)
            .then(updateLocalCopy)
            .catch(vm.displayErrorMessage)
            .finally(resetLoadingState);
        function updateLocalCopy(response){
            benchmark.isDirty = false;
            Object.assign(benchmark, response.data);
        }
        function resetLoadingState(){
            benchmark.isSaving = false;
        }
    }

    function initializeNewBenchmark(){
        const vm = this;
        vm.newBenchmark = {
            meta_data: {
                equipment_group_code: '',
                benchmark_cph: 1
            },
            type_slug: 'equipment_group_benchmark',
            new_benchmark: 1,
            display_order: 1,
            isSaving: false,
            isDeleting: false,
            validationErrors: {}
        };
    }

    function saveBenchmark(){
        const vm = this;
        const isValid = validateBenchmark(vm.newBenchmark);
        if(!isValid){
            return false;
        }
        vm.newBenchmark.isSaving = true;
        vm.newBenchmark.meta_data.benchmark_cph = vm.newBenchmark.new_benchmark;
        const payload = JSON.parse(JSON.stringify(vm.newBenchmark));
        payload.meta_data = JSON.stringify(payload.meta_data);
        return Vue.appApi().authorized().lookups().createLookup(payload).then(updateLocalCopy).catch(vm.displayErrorMessage).finally(resetLoadingState);
        function updateLocalCopy(response){
            Object.assign(vm.newBenchmark, response.data);
            vm.newBenchmark.new_benchmark = vm.newBenchmark.meta_data.benchmark_cph;
            vm.benchmarks.push(vm.newBenchmark);
            vm.newBenchmark.isSaving = false;
            vm.initializeNewBenchmark();
        }
        function resetLoadingState(){
            vm.newBenchmark.isSaving = false;
        }
    }

    function deleteBenchmark(benchmark){
        const vm = this;
        benchmark.isDeleting = true;
        return Vue.appApi().authorized().lookups(benchmark.id).deleteLookup()
            .then(removeLocalCopy)
            .catch(vm.displayErrorMessage)
            .finally(resetLoadingState);

        function removeLocalCopy(response){
            const benchmarkIndex = vm.benchmarks.findIndex(({ id }) => id === benchmark.id);
            if(benchmarkIndex >= 0){
                vm.benchmarks.splice(benchmarkIndex, 1);
            }
        }
        function resetLoadingState(){
            benchmark.isDeleting = false;
        }
    }

    function validateBenchmark(benchmark){
        benchmark.validationErrors = {};
        const newBenchmarkValue = parseFloat(benchmark.new_benchmark);
        if(newBenchmarkValue < 0 || isNaN(newBenchmarkValue)){
            benchmark.validationErrors.new_benchmark = ['Benchmark value must be a number greater than 0'];
        }
        if(!benchmark.meta_data.equipment_group_code){
            benchmark.validationErrors.equipment_group_code = ['Please select an equipment group'];
        }
        return !Object.keys(benchmark.validationErrors).length;
    }
}
