export default {
    data,
    computed: getComputed(),
    methods: getMethods()
};

function data(){
    return {
        isLoadingPmComments: false,
    };
}

function getComputed(){
    return {
        pmComments: getPmComments,
    };

    function getPmComments(){
        const vm = this;
        return vm.$store.getters['pmComments/pmComments'];
    }
}

function getMethods(){
    return {
        launchModal
    };

    function launchModal(pm){
        const vm = this;
        vm.isLoadingPmComments = true;
        vm.$refs['pm-notes-modal'].show();

        const payload = { 'workorder_number': pm.WO_Number };
        vm.$store.dispatch('pmComments/GET_PM_COMMENTS', payload).catch(closeModalAndDisplayError).finally(resetLoadingState);

        function closeModalAndDisplayError(error){
            vm.$refs['pm-notes-modal'].hide();
            vm.$emit('error', error);
        }
        function resetLoadingState(){
            vm.isLoadingPmComments = false;
        }
    }
}
