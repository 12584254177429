export default {
    components: {},
    props: {
        notesLines: {
            type: Array,
            required: true
        },
        title: {
            type: String,
            default: ''
        }
    },
    data,
    computed: getComputed(),
    watch: getWatchers(),
    created,
    methods: getMethods()
};

function data(){
    return {
        tableColumns: [
            {
                key: 'Company_No',
                label: 'CM#',
                sortable: true
            },
            {
                key: 'Case_No',
                label: 'Case No.',
                sortable: true
            },
            {
                key: 'Createdby_User',
                label: 'User',
                sortable: true
            },
            {
                key: 'Text',
                sortable: true
            },
            {
                key: 'Line',
                sortable: true
            },
        ]
    };
}

function getComputed(){
    return {};
}

function getWatchers(){
    return {};
}

function created(){}

function getMethods(){
    return {};
}
