import CustomerDetail from './customer-detail/customer-detail.vue';
import WarrantyDetail from './warranty-detail/warranty-detail.vue';
import WorkorderDetail from './workorder-detail/workorder-detail.vue';
import apiPaginationMixin from 'vue_root/mixins/api-pagination-mixin';
import WorkorderAndCheckinDetails from 'vue_root/components/workorder-and-checkin-details/workorder-and-checkin-details.vue';

export default {
    mixins: [apiPaginationMixin],
    components: {
        CustomerDetail,
        WarrantyDetail,
        WorkorderDetail,
        WorkorderAndCheckinDetails,
    },
    props: {},
    data,
    beforeRouteUpdate,
    created,
    methods: getMethods(),
};

function data(){
    const vm = this;
    return {
        errorMessages: [],
        workorders: [],
        displayedWorkOrderNumber: null,
        warrantyWorkorderNumber: null,
        linesWorkorderNumber: null,
        historyLinesWorkorderNumber: null,
        mechanicId: vm.$route.params.mechanicId,
        isLoadingWorkorders: false,
        isLoadingDetails: false,
        statusOptions: [
            { value: '', text: '-' },
            { value: 'C', text: 'C' },
            { value: 'W', text: 'W' },
            { value: 'S', text: 'S' },
        ],
        tableColumns: [
            {
                key: 'search',
                label: '',
                sortable: false
            },
            {
                key: 'WO_Number',
                label: 'WO#',
                sortable: true
            },
            {
                key: 'WO_Hardcopy_Ref',
                label: 'WO Ref.',
                sortable: true
            },
            {
                key: 'Resource_No',
                label: 'Tech',
                sortable: true
            },
            {
                key: 'Posting_Status',
                label: 'Posting',
                sortable: true,
                formatter: vm.getPostingStatusText,
                sortByFormatted: true
            },
            {
                key: 'Customer',
                label: 'Customer',
                sortable: true
            },
            {
                key: 'Service_Type',
                label: 'Type',
                sortable: true
            },
            {
                key: 'Description1',
                label: 'Description1',
                sortable: true
            },
            {
                key: 'Starting_Date',
                label: 'Start Date',
                sortable: true
            },
            {
                key: 'totalHours',
                label: 'Total Hours',
                sortable: false,
                tdClass: vm.getTotalHoursClasses
            },
            {
                key: 'totalQuotedHours',
                label: 'Total QHours',
                sortable: false
            },
            {
                key: 'totalAmount',
                label: 'Total Amount',
                sortable: false
            },
            {
                key: 'totalQuotedAmount',
                label: 'Total QAmount',
                sortable: false
            },
            {
                key: 'mech',
                label: 'Mech',
                sortable: false
            },
            {
                key: 'comment',
                label: 'Comment',
                sortable: false
            },
            {
                key: 'datetime',
                label: 'Datetime',
                sortable: false
            },
        ],
        workorder: null,
        equipment: null,
        workordersFleetCode: null,
    };
}

function beforeRouteUpdate(to, from, next){
    const vm = this;
    vm.mechanicId = to.params.mechanicId;
    vm.searchWorkorders();
    next();
}

function created(){
    const vm = this;
    vm.mechanicId = vm.$route.params.mechanicId;
    vm.searchWorkorders();
}

function getMethods(){
    return {
        searchWorkorders,
        displayErrorMessage,
        calculateHours,
        mechChanged,
        commentChanged,
        searchWorkorder,
        getWorkorderHistoryLines,
        getPostingStatusText,
        getTotalHoursClasses
    };

    function searchWorkorders(){
        const vm = this;
        vm.isLoadingWorkorders = true;
        vm.displayedWorkOrderNumber = null;
        return Vue.appApi().authorized().mechanics(vm.mechanicId).getShopWorkorders()
            .then(setLocalWorkorders)
            .catch(vm.displayErrorMessage)
            .finally(stopSpinner);

        function setLocalWorkorders(response){
            vm.workorders = response.data.map(mapWorkorders);
        }

        function mapWorkorders(eachWorkorder){
            eachWorkorder.From_Quote = eachWorkorder.From_Quote.trim();
            eachWorkorder.totalHours = null;
            eachWorkorder.totalQuotedHours = null;
            eachWorkorder.totalAmount = null;
            eachWorkorder.totalQuotedAmount = null;
            eachWorkorder.isCalculatingHours = null;
            return eachWorkorder;
        }

        function stopSpinner(){
            vm.isLoadingWorkorders = false;
        }
    }

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function calculateHours(workorder){
        workorder.isCalculatingHours = true;
        Vue.appApi().authorized().workorders(workorder.WO_Number).calculateHours()
            .then(setCalculatedData)
            .finally(stopSpinner);

        function setCalculatedData(response){
            workorder.totalHours = response.data.totalHours;
            workorder.totalQuotedHours = response.data.totalQuotedHours;
            workorder.totalAmount = response.data.totalAmount;
            workorder.totalQuotedAmount = response.data.totalQuotedAmount;
        }

        function stopSpinner(){
            workorder.isCalculatingHours = false;
        }
    }

    function mechChanged(workorder){
        const vm = this;
        vm.errorMessages = [];
        const payload = {
            'workorder_number': workorder.WO_Number,
            'mech': workorder.mech
        };

        vm.$store.dispatch('shop/UPDATE_WORKORDER', payload)
            .then(updateLocalWorkorders)
            .catch(alertMechError);

        function updateLocalWorkorders(data){
            const localWorkorder = vm.workorders.find(eachWorkorder => eachWorkorder.WO_Number === data.workorder);
            if(localWorkorder){
                localWorkorder.datetime = data.datetime;
            }
        }

        function alertMechError(error){
            if(workorder.mech === ''){
                vm.displayErrorMessage('Error Clearing Mech');
            } else {
                vm.displayErrorMessage('Error Adding Mech Input');
            }
            vm.displayErrorMessage(error);
        }
    }

    function commentChanged(workorder){
        const vm = this;
        vm.errorMessages = [];
        const payload = {
            'workorder_number': workorder.WO_Number,
            'comment': workorder.comment
        };

        vm.$store.dispatch('shop/UPDATE_WORKORDER', payload)
            .then(updateLocalWorkorders)
            .catch(alertCommentError);

        function updateLocalWorkorders(data){
            const localWorkorder = vm.workorders.find(eachWorkorder => eachWorkorder.WO_Number === data.workorder);
            if(localWorkorder){
                localWorkorder.datetime = data.datetime;
            }
        }

        function alertCommentError(error){
            vm.displayErrorMessage('Error Finding Equipment');
            vm.displayErrorMessage(error);
        }
    }

    function searchWorkorder(workorder){
        const vm = this;
        vm.displayedWorkOrderNumber = workorder.WO_Number;
        vm.scrollTo('workorderDetails');
    }

    function getWorkorderHistoryLines(workorderNumber){
        const vm = this;
        vm.historyLinesWorkorderNumber = workorderNumber;
    }

    function getPostingStatusText(workorder){
        let formattedText = '';
        if(workorder){
            if(workorder.Posting_Status === 0){
                formattedText = 'OPEN';
            } else if(workorder.Posting_Status === 2){
                formattedText = 'CLOSED';
            }
        }
        return formattedText;
    }

    function getTotalHoursClasses(workorder){
        const classes = [];
        const isOverHours = checkOverHours();
        const hasHoursWarning = checkWarningHours();
        if(isOverHours){
            classes.push('over-hours');
        }
        if(hasHoursWarning){
            classes.push('warning-hours');
        }
        return classes.join(' ');

        function checkOverHours(){
            return workorder &&
                workorder.From_Quote !== '' &&
                workorder.totalHours &&
                workorder.totalQuotedHours &&
                parseFloat(workorder.totalHours) > (parseFloat(workorder.totalQuotedHours) + 8);
        }

        function checkWarningHours(){
            return workorder &&
                workorder.From_Quote === '' &&
                workorder.totalHours &&
                parseFloat(workorder.totalHours) >= 5;
        }
    }
}
