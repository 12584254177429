import Datepicker from 'vuejs-datepicker';

export default {
    components: {
        Datepicker
    },
    props: {},
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        validationErrors: {},
        availableCaseTypes: [],
        availableCaseStatuses: [],
        availableCaseClasses: [],
        availableCaseManufacturers: [],
        availableCaseResources: [],
        emailReasonCodes: [],
        isInitializingView: false,
        crmQuery: {
            resource_no: '*',
            status: '*',
            case_type: '*',
            manufacturer: '*',
            class: '*',
            group: '*',
            start_date: '',
            end_date: ''
        },
        isLoadingCrmCases: false,
        resourceName: '',
        crmCases: null,
        tableColumns: [
            {
                key: 'case_no',
                label: 'Case No.',
                sortable: true,
            },
            {
                key: 'Status',
                sortable: true,
                sortByFormatted: true,
                formatter: (value) => {
                    let formattedValue = '';
                    if(value === 0){
                        formattedValue = 'OPEN';
                    } else if(value === 1){
                        formattedValue = 'ON HOLD';
                    } else if(value === 2){
                        formattedValue = 'CLOSED';
                    }
                    return formattedValue;
                }
            },
            {
                key: 'case_type',
                sortable: true,
            },
            {
                key: 'sellto_contact_company_name',
                label: 'Co. Name',
                sortable: true,
            },
            {
                key: 'case_subject',
                sortable: true,
                tdClass: (value) => !value ? 'bg-danger text-white' : ''
            },
            {
                key: 'created_on',
                sortable: true
            },
            {
                key: 'next_meeting_date',
                label: 'Next Mtg Date',
                sortable: true,
                tdClass: (meetingDate, key, item) => {
                    const now = Vue.moment();
                    const meetingDateMoment = Vue.moment(meetingDate);
                    const isMeetingDatePassed = meetingDateMoment.isBefore(now);
                    const isPercentOver10WithoutMeetingDate = item.percent_complete > 10 && !meetingDate;
                    const isMeetingAfterSale = meetingDateMoment.isAfter(Vue.moment(item.expected_sale_date));
                    return (isMeetingDatePassed || isPercentOver10WithoutMeetingDate || isMeetingAfterSale) ? 'bg-danger text-white' : '';
                }
            },
            {
                key: 'expected_sale_date',
                label: 'Exp. Sale Date',
                sortable: true,
                tdClass: (expectedSaleDate, key, item) => {
                    const isPercentOver10WithoutSaleDate = item.percent_complete > 10 && !expectedSaleDate;
                    return isPercentOver10WithoutSaleDate ? 'bg-danger text-white' : '';
                }
            },
            {
                key: 'eq_class',
                label: 'Class',
                sortable: true,
                tdClass: (value) => !value ? 'bg-danger text-white' : ''
            },
            {
                key: 'eq_group',
                label: 'Group',
                sortable: true,
                tdClass: (value) => !value ? 'bg-danger text-white' : ''
            },
            {
                key: 'eq_man',
                label: 'Man',
                sortable: true,
                tdClass: (value) => !value ? 'bg-danger text-white' : ''
            },
            {
                key: 'sales_stage',
                label: 'Stage',
                sortable: true
            },
            {
                key: 'percent_complete',
                label: '%',
                sortable: true
            },
            {
                key: 'stage_date',
                label: 'Quote Date',
                sortable: true,
                formatter: (value, key, hulCase) => {
                    let formattedValue = 'NA';
                    if(hulCase.percent_complete > 49){
                        if(hulCase.crm_stage_history){
                            formattedValue = Vue.moment(hulCase.crm_stage_history.modified).format('YYYY-MM-DD H:m:s');
                        } else {
                            formattedValue = 'Skipped 50';
                        }
                    }
                    return formattedValue;
                }
            },
            {
                key: 'price',
                sortable: true,
                tdClass: (price, key, item) => {
                    return (item.percent_complete > 10 && price <= 1) ? 'bg-danger text-white' : '';
                }
            },
            {
                key: 'resource_name',
                label: 'Resource',
                sortable: true
            },
            {
                key: 'note_text',
                label: 'Notes',
                sortable: true,
                tdClass: 'input-cell'
            },
        ]
    };
}

function getComputed(){
    return {};
}

function created(){
    const vm = this;
    vm.isInitializingView = true;
    vm.initializeSelectOptions().finally(resetLoadingState);

    function resetLoadingState(){
        vm.isInitializingView = false;
    }
}

function getMethods(){
    return {
        displayErrorMessage,
        initializeSelectOptions,
        getCaseTypes,
        getCaseClasses,
        getCaseManufacturers,
        getCaseResources,
        getEmailReasonCodes,
        getCrmCases,
        updateCaseNote,
        emailCaseResource,
    };

    function displayErrorMessage(error){
        const vm = this;
        const isValidationError = error.status === 422 && error.data.errors;
        if(isValidationError){
            vm.validationErrors = error.data.errors;
            vm.errorMessages.push('Validation Error. Check inputs and try again.');
        } else {
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function initializeSelectOptions(){
        const vm = this;
        return Promise.all([
            vm.getCaseTypes(),
            vm.getCaseClasses(),
            vm.getCaseManufacturers(),
            vm.getCaseResources(),
            vm.getEmailReasonCodes(),
        ]);
    }

    function getCaseTypes(){
        const vm = this;
        return Vue.appApi().authorized().cases().getCaseTypes().then(setCaseTypes).catch(vm.displayErrorMessage);
        function setCaseTypes(response){
            vm.availableCaseTypes = response.data;
        }
    }

    function getCaseClasses(){
        const vm = this;
        return Vue.appApi().authorized().cases().getCaseClasses().then(setCaseClasses).catch(vm.displayErrorMessage);
        function setCaseClasses(response){
            vm.availableCaseClasses = response.data;
        }
    }

    function getCaseManufacturers(){
        const vm = this;
        return Vue.appApi().authorized().cases().getCaseManufacturers().then(setCaseManufacturers).catch(vm.displayErrorMessage);
        function setCaseManufacturers(response){
            vm.availableCaseManufacturers = response.data;
        }
    }

    function getCaseResources(){
        const vm = this;
        return Vue.appApi().authorized().cases().getCaseResources().then(setCaseResources).catch(vm.displayErrorMessage);
        function setCaseResources(response){
            vm.availableCaseResources = response.data;
        }
    }

    function getEmailReasonCodes(){
        const vm = this;
        return Vue.appApi().authorized().cases().getEmailReasonCodes().then(setEmailReasonCodes).catch(vm.displayErrorMessage);
        function setEmailReasonCodes(response){
            vm.emailReasonCodes = response.data;
        }
    }

    function getCrmCases(){
        const vm = this;
        const isQueryValid = validateQuery();
        if(isQueryValid){
            vm.errorMessages = [];
            vm.validationErrors = {};
            vm.isLoadingCrmCases = true;
            return Vue.appApi().authorized().cases().indexHulCases(vm.crmQuery).then(setCrmCases).catch(vm.displayErrorMessage).finally(resetLoadingState);
        }

        function validateQuery(){
            let isQueryValid = true;
            if(vm.crmQuery.resource_no === '*' && vm.crmQuery.status === '*'){
                isQueryValid = false;
                vm.displayErrorMessage('To Select All Resources, Please Select a Status');
            }
            return isQueryValid;
        }

        function setCrmCases(response){
            const selectedResource = vm.availableCaseResources.find(({ z_number }) => z_number === vm.crmQuery.resource_no);
            vm.resourceName = selectedResource ? selectedResource.name : '*';
            vm.crmCases = response.data.map(setDisplayProperties);
            vm.scrollTo('crmCases');

            function setDisplayProperties(crmCase){
                crmCase.isSendingEmail = false;
                crmCase.isUpdatingNote = false;
                return crmCase;
            }
        }
        function resetLoadingState(){
            vm.isLoadingCrmCases = false;
        }
    }

    function updateCaseNote(crmCase){
        const vm = this;
        crmCase.isUpdatingNote = true;
        return Vue.appApi().authorized().cases(crmCase.case_no).updateCaseNote({ note_text: crmCase.note_text }).catch(handleUpdateError).finally(resetLoadingState);

        function handleUpdateError(error){
            vm.displayErrorMessage(`Failed to update note for case: ${crmCase.case_no}`);
            vm.displayErrorMessage(error);
        }
        function resetLoadingState(){
            crmCase.isUpdatingNote = false;
        }
    }

    function emailCaseResource(crmCase){
        const vm = this;
        const payload = {
            reason_code: crmCase.last_email_reason || ' ', // need to manually set to space since vue is trimming it from the input
            resource_no: crmCase.resource_no
        };
        crmCase.isSendingEmail = true;
        return Vue.appApi().authorized().cases(crmCase.case_no).emailCaseResource(payload).then(setEmailDate).catch(handleEmailError).finally(resetLoadingState);

        function setEmailDate(response){
            crmCase.last_email_date = response.data.datetime;
        }
        function handleEmailError(error){
            vm.displayErrorMessage(`Failed to send email for case: ${crmCase.case_no}`);
            vm.displayErrorMessage(error);
        }
        function resetLoadingState(){
            crmCase.isSendingEmail = false;
        }
    }
}
