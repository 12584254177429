export default {
    namespaced: true,
    actions: getActions(),
    mutations: getMutations(),
};

function getActions(){

    return {
        LOGIN: postLogin,
    };
    function postLogin({ commit, dispatch }, credentials){

        return Vue.appApi().guest().user().login(credentials).then(loginSuccess);

        function loginSuccess(response){
            return Promise.all([
                dispatch('user/REMEMBER_ME', credentials, { root: true }),
                dispatch('user/REGISTER_USER_SUCCESS', response.data, { root: true }),
                dispatch('user/GET_USER', null, { root: true })
            ]).then(returnResponse);
            function returnResponse(){
                return response;
            }
        }
    }
}

function getMutations(){

    return {};
}
