export default {
    props: {
        'type': {
            type: String,
            default: 'error',
            validator: function(value){
                const validMessageTypes = [
                    'error',
                    'success'
                ];
                return validMessageTypes.includes(value);
            }
        },
        'messages': {
            type: Array
        },
    },
    computed: {
        alertClass(){
            const vm = this;
            let alertClass = '';
            switch (vm.type){
                case 'error':
                    alertClass = 'alert-danger';
                    break;
                case 'success':
                    alertClass = 'alert-success';
                    break;
            }
            return alertClass;
        }
    }
};
