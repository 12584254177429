export default {
    components: {},
    props: {
        repairEstimate: {
            type: Object,
            required: true
        }
    },
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        tableColumns: [
            {
                key: 'Document_no',
                label: 'Document_No',
            },
            {
                key: 'Serial',
            },
            {
                key: 'Man',
                label: 'Make'
            },
            {
                key: 'Model',
                tdClass: 'font-weight-bold'
            },
            {
                key: 'Description',
            },
            {
                key: 'Service_type',
                label: 'Service Type'
            },
            {
                key: 'Type',
                label: 'Line Type',
                formatter: (value) => {
                    let formattedValue = value;
                    switch (value){
                        case 1:
                            formattedValue = 'Item';
                            break;
                        case 2:
                            formattedValue = 'Resource';
                            break;
                        case 3:
                            formattedValue = 'Charge';
                            break;
                        case 5:
                            formattedValue = 'Job Code';
                            break;
                        case 6:
                            formattedValue = 'Purchase';
                            break;
                        case 9:
                            formattedValue = 'Q Resource';
                            break;
                    }
                    return formattedValue;
                }
            },
            {
                key: 'Line_no',
            },
            {
                key: 'Description_line',
                label: 'Line Description'
            },
            {
                key: 'Quantity',
                label: 'Qty'
            },
            {
                key: 'Amount',
            },
        ]
    };
}

function getComputed(){
    return {};
}

function created(){}

function getMethods(){
    return {
        displayErrorMessage
    };

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }
}
