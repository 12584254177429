export default {
    components: {},
    props: {},
    data,
    computed: getComputed(),
    watch: getWatches(),
    created,
    beforeRouteUpdate,
    methods: getMethods(),
};

function data(){
    return {
        errorMessages: [],
        branch: 'branch_all',
        localCheckins: [],
        isLoadingCheckins: false,
        tableColumns: [
            {
                key: 'Object',
                label: 'Object',
                sortable: true,
            },
            {
                key: 'Model',
                label: 'Model',
                sortable: true,
            },
            {
                key: 'Fleet_Code',
                label: 'Fleet Code',
                sortable: true,
            },
            {
                key: 'DateTime',
                label: 'DateTime',
                sortable: true,
            },
            {
                key: 'Source',
                label: 'Source',
                sortable: true,
            },
            {
                key: 'Source_No',
                label: 'Source_No',
                sortable: true,
            },
            {
                key: 'Default_Rental',
                label: 'Default Rental',
                sortable: true,
            },
            {
                key: 'checked',
                label: 'Green',
                sortable: true,
            },
            {
                key: 'redtag',
                label: 'Red',
                sortable: true,
            },
            {
                key: 'datetime',
                label: 'Done Time',
                sortable: true,
            },
            {
                key: 'workorder',
                label: 'Red WO',
                sortable: true,
            },
            {
                key: 'tech',
                label: 'Tech',
                sortable: true,
            },
        ],
    };
}

function getComputed(){}

function getWatches(){}

function created(){
    const vm = this;
    vm.initializeCheckins(vm.$route);
}

function beforeRouteUpdate(to, from, next){
    const vm = this;
    vm.initializeCheckins(to);
    next();
}

function getMethods(){
    return {
        initializeCheckins,
        searchCheckins,
        displayErrorMessage,
        getSourceText,
        updateCheckinCheckedProperty,
        updateCheckinRedtagProperty,
        updateCheckinNotesProperty,
        updateCheckinTechProperty,
    };

    function initializeCheckins($route){
        const vm = this;
        vm.branch = $route.params.branch;
        vm.searchCheckins();
    }

    function searchCheckins(){
        const vm = this;
        const payload = {
            branch: vm.branch === 'branch_all' ? '*' : vm.branch,
        };
        vm.isLoadingCheckins = true;
        Vue.appApi().authorized().equipment().getEquipmentCheckins(payload)
            .then(setRentalCheckins)
            .catch(vm.displayErrorMessage)
            .finally(stopSpinner);

        function setRentalCheckins(response){
            vm.localCheckins = response.data;
        }

        function stopSpinner(){
            vm.isLoadingCheckins = false;
        }
    }

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function getSourceText(checkin){
        return checkin.Source === 2 ? 'Return' : '';
    }

    function updateCheckinCheckedProperty(checkin){
        const vm = this;
        const localCheckin = vm.localCheckins.find(({ Fleet_Code, Source_No }) => Fleet_Code === checkin.Fleet_Code && Source_No === checkin.Source_No);

        const payload = {
            'fleet': checkin.Fleet_Code,
            'checked': checkin.checked,
            'contract': checkin.Source_No
        };

        Vue.appApi().authorized().equipment(checkin.Fleet_Code).updateEquipmentCheckin(payload)
            .then(updateTimestamp)
            .catch(alertError);

        function updateTimestamp(response){
            localCheckin.checked = +response.data.checked;
            localCheckin.redtag = +response.data.redtag;
            localCheckin.datetime = response.data.datetime;
        }

        function alertError(error){
            vm.displayErrorMessage('Error Finding Equipment');
            vm.displayErrorMessage(error);
        }
    }

    function updateCheckinRedtagProperty(checkin){
        const vm = this;
        const localCheckin = vm.localCheckins.find(({ Fleet_Code, Source_No }) => Fleet_Code === checkin.Fleet_Code && Source_No === checkin.Source_No);

        const payload = {
            'fleet': checkin.Fleet_Code,
            'redtag': checkin.redtag,
            'contract': checkin.Source_No
        };

        Vue.appApi().authorized().equipment(checkin.Fleet_Code).updateEquipmentCheckin(payload)
            .then(updateTimestamp)
            .catch(alertError);

        function updateTimestamp(response){
            localCheckin.checked = +response.data.checked;
            localCheckin.redtag = +response.data.redtag;
            localCheckin.datetime = response.data.datetime;
        }

        function alertError(error){
            vm.displayErrorMessage('Error Finding Equipment');
            vm.displayErrorMessage(error);
        }
    }

    function updateCheckinNotesProperty(checkin){
        const vm = this;
        vm.errorMessages = [];
        const payload = {
            'fleet': checkin.Fleet_Code,
            'contract': checkin.Source_No,
            'note': checkin.workorder
        };

        Vue.appApi().authorized().equipment(checkin.Fleet_Code).updateEquipmentCheckin(payload)
            .catch(alertNoteError);

        function alertNoteError(error){
            vm.displayErrorMessage('Error Finding Equipment');
            vm.displayErrorMessage(error);
        }
    }

    function updateCheckinTechProperty(checkin){
        const vm = this;
        vm.errorMessages = [];
        const payload = {
            'contract': checkin.Source_No,
            'tech': checkin.tech
        };

        Vue.appApi().authorized().equipment(checkin.Fleet_Code).updateEquipmentCheckin(payload)
            .catch(alertTechError);

        function alertTechError(error){
            if(checkin.tech === ''){
                vm.displayErrorMessage('Error Clearing Tech');
            } else {
                vm.displayErrorMessage('Error Inputting Tech');
            }
            vm.displayErrorMessage(error);
        }
    }
}
