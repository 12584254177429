import CrudRole from './components/crud-role/crud-role';

export default {
    components: {
        CrudRole
    },
    props: {},
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        validationErrors: {},
        roles: [],
        permissions: [],
        isInitializingView: false,
        isLoadingRoles: false,
        isLoadingPermissions: false,
        newRole: null
    };
}

function getComputed(){
    return {};
}

function created(){
    const vm = this;
    vm.isInitializingView = true;
    return Promise.all([
        vm.getRoles(),
        vm.getPermissions()
    ]).finally(resetLoadingState);

    function resetLoadingState(){
        vm.isInitializingView = false;
    }
}

function getMethods(){
    return {
        displayErrorMessage,
        getRoles,
        getPermissions,
        addRole,
        addNewRoleToList,
        updateRoleInList,
        removeRoleFromList,
    };

    function displayErrorMessage(error){
        const vm = this;
        const isValidationError = error && error.status === 422 && error.data.errors;
        if(isValidationError){
            vm.validationErrors = error.data.errors;
        } else {
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function getRoles(){
        const vm = this;
        vm.isLoadingRoles = true;
        return Vue.appApi().authorized().account().roles().indexRoles().then(setRoles).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function setRoles({ data }){
            vm.roles = data;
        }

        function resetLoadingState(){
            vm.isLoadingRoles = false;
        }
    }

    function getPermissions(){
        const vm = this;
        vm.isLoadingPermissions = true;
        return Vue.appApi().authorized().account().indexPermissions().then(setPermissions).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function setPermissions({ data }){
            vm.permissions = data;
        }

        function resetLoadingState(){
            vm.isLoadingPermissions = false;
        }
    }

    function addRole(){
        const vm = this;
        vm.newRole = {
            name: 'New Role',
            permissions: []
        };
    }

    function addNewRoleToList(role){
        const vm = this;
        vm.roles.push(role);
        vm.newRole = null;
        vm.$bvToast.toast(`${role.name} has been created.`, {
            variant: 'success',
            isStatus: true,
            solid: true,
            title: 'Success!',
            autoHideDelay: 3000,
        });
    }

    function updateRoleInList(role){
        const vm = this;
        const roleIndex = vm.roles.findIndex(({ id }) => id === role.id);
        vm.roles.splice(roleIndex, 1, role);
        vm.$bvToast.toast(`${role.name} has been updated.`, {
            variant: 'success',
            isStatus: true,
            solid: true,
            title: 'Success!',
            autoHideDelay: 3000,
        });
    }

    function removeRoleFromList(role){
        const vm = this;
        const roleIndex = vm.roles.findIndex(({ id }) => id === role.id);
        vm.roles.splice(roleIndex, 1);
        vm.$bvToast.toast(`${role.name} has been deleted.`, {
            variant: 'success',
            isStatus: true,
            solid: true,
            title: 'Success!',
            autoHideDelay: 3000,
        });
    }
}
