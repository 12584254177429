const state = {
    equipment: null,
    workorder: null,
    workorders: {
        data: [],
        currentPage: 1,
        lastPage: 1,
        from: 0,
        to: 0,
        total: 0,
    },
};

export default {
    namespaced: true,
    state,
    mutations: getMutations(),
    actions: getActions(),
    getters: getGetters(),
    modules: {}
};

function getActions(){

    return {
        FIND_WORKORDER: findWorkorder,
        GET_WORKORDERS: getWorkorders,
        FIND_EQUIPMENT: findEquipment,
        UPDATE_WORKORDER: updateWorkorder,
    };
    function findWorkorder({ commit }, workorderNumber){
        return Vue.appApi().authorized().workorders(workorderNumber).findWorkorder().then(setWorkorder);

        function setWorkorder(response){
            commit('SET_WORKORDER', response.data);
            return JSON.parse(JSON.stringify(response.data));
        }
    }
    function getWorkorders({ commit }, payload){
        return Vue.appApi().authorized().mechanics(payload.mechanic_id).getShopWorkorders(payload).then(setWorkorders);

        function setWorkorders(response){
            commit('SET_WORKORDERS', response.data);
            return JSON.parse(JSON.stringify(response.data));
        }
    }
    function findEquipment({ commit }, fleetCode){
        return Vue.appApi().authorized().rentalCheckins(fleetCode).findByFleetCode().then(setEquipment);

        function setEquipment(response){
            commit('SET_EQUIPMENT', response.data);
            return JSON.parse(JSON.stringify(response.data));
        }
    }
    function updateWorkorder({ commit }, payload){
        return Vue.appApi().authorized().shopWorkorders(payload.workorder_number).update(payload).then(updateWorkorder);

        function updateWorkorder(response){
            commit('SET_WORKORDER_NOTE', response.data);
            commit('SET_WORKORDER_MECH', response.data);
            return JSON.parse(JSON.stringify(response.data));
        }
    }
}

function getGetters(){
    return {
        workorders,
    };
    function workorders(state){
        return state.workorders;
    }
}

function getMutations(){

    return {
        SET_EQUIPMENT: setEquipmentState,
        SET_WORKORDER: setWorkorderState,
        SET_WORKORDERS: setWorkordersState,
        SET_WORKORDER_NOTE: setWorkorderNote,
        SET_WORKORDER_MECH: setWorkorderMech,
    };
    function setEquipmentState(state, equipment){
        state.equipment = equipment;
    }
    function setWorkorderState(state, workorder){
        state.workorder = workorder;
    }
    function setWorkordersState(state, payload){
        state.workorders.data = payload.data;
        state.workorders.currentPage = payload.current_page;
        state.workorders.lastPage = payload.last_page;
        state.workorders.total = payload.total;
        state.workorders.from = payload.from ? payload.from : 0;
        state.workorders.to = payload.to ? payload.to : 0;
    }
    function setWorkorderNote(state, payload){
        state.workorders.data = state.workorders.data.map((eachWorkorder) => {
            if(eachWorkorder.WO_Number === payload.workorder){
                eachWorkorder.comment = payload.note;
                if(payload.note !== ''){
                    eachWorkorder.datetimenote = payload.datetime;
                }
            }
            return eachWorkorder;
        });
    }
    function setWorkorderMech(state, payload){
        state.workorders.data = state.workorders.data.map((eachWorkorder) => {
            if(eachWorkorder.WO_Number === payload.workorder){
                eachWorkorder.mech = payload.mech;
                if(payload.status !== ''){
                    eachWorkorder.datetime = payload.datetime;
                }
            }
            return eachWorkorder;
        });
    }
}
