import Datepicker from 'vuejs-datepicker';
import fileDownload from 'js-file-download';

export default {
    components: {
        Datepicker
    },
    props: {},
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        validationErrors: {},
        isFetchingSpreadsheet: false,
        query: {
            start_date: '',
            end_date: '',
            location_code: '',
            bin: ''
        }
    };
}

function getComputed(){
    return {};
}

function created(){}

function getMethods(){
    return {
        displayErrorMessage,
        fetchVanDataSpreadsheet,
    };

    function displayErrorMessage(error){
        const vm = this;
        if(error.status === 422 && error.data.errors){
            vm.validationErrors = error.data.errors;
        } else {
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function fetchVanDataSpreadsheet(){
        const vm = this;
        vm.isFetchingSpreadsheet = true;
        vm.errorMessages = [];
        vm.validationErrors = {};
        Vue.appApi().authorized().partsVan().createPartsVanSpreadsheet(vm.query).then(handleResult).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function handleResult(response){
            fileDownload(response.data, 'VanData_List.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        }
        function resetLoadingState(){
            vm.isFetchingSpreadsheet = false;
        }
    }
}
