import LeadsTable from './leads-table/leads-table';

export default {
    components: {
        LeadsTable
    },
    props: {},
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        isLoadingLeads: false,
        errorMessages: [],
        department: '*',
        selectedDepartment: '*',
        showMenu: true,
    };
}

function getComputed(){
    return {};
}

function created(){}

function getMethods(){
    return {
        displayErrorMessage,
        setIsLoadingLeads,
    };

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function setIsLoadingLeads(isLoadingLeads){
        const vm = this;
        vm.isLoadingLeads = isLoadingLeads;
    }
}
