export default {
    components: {},
    props: {
        pms: {
            type: Array,
            required: true
        }
    },
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        validationErrors: {},
    };
}

function getComputed(){
    return {
        overduePmsCount(){
            const vm = this;
            return vm.pms.filter(olderThanTwoWeeks).length;
            function olderThanTwoWeeks(pm){
                const twoWeeksAgo = Vue.moment().startOf('day').subtract(2, 'weeks');
                return pm && pm.Next_Planned_Date && twoWeeksAgo.isAfter(pm.Next_Planned_Date);
            }
        },
        noFeedbackPmsCount(){
            const vm = this;
            return vm.pms.filter(byOldSentDate).length;
            function byOldSentDate(pm){
                const oneWeekAgo = Vue.moment().startOf('day').subtract(1, 'weeks');
                return pm && pm.datetime && oneWeekAgo.isAfter(pm.datetime);
            }
        },
        automaticPmsCount(){
            const vm = this;
            return vm.pms.filter(byOldPmaType).length;
            function byOldPmaType(pm){
                const pmExists = Boolean(pm);
                let includePM = !pmExists;
                if(pmExists){
                    const isPmaType = pm.PM_Type === 'PMA';
                    const isOverdue = pm.Next_Planned_Date && Vue.moment().startOf('day').isAfter(pm.Next_Planned_Date);
                    const isOverdueFromPreviousWeek = isOverdue && !Vue.moment().startOf('day').isSame(pm.Next_Planned_Date, 'week');
                    includePM = isPmaType && isOverdueFromPreviousWeek;
                }
                return includePM;
            }
        },
    };
}

function created(){}

function getMethods(){
    return {};
}
