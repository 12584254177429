import AdminComponent from './admin.vue';
import ManageUsersView from './manage-users/manage-users';
import ManageEquipmentImagesView from './manage-equipment-images/manage-equipment-images';
import TechnicianListsView from './manage-technician-lists/manage-technician-lists';
import ManageRolesView from './manage-roles/manage-roles';
import ManageLookupsView from './manage-lookups/manage-lookups';
import ExcelReportsView from './excel-reports/excel-reports';

export default {
    path: '/admin',
    name: 'admin',
    component: AdminComponent,
    children: [
        {
            path: 'manage-users',
            name: 'manage-users',
            component: ManageUsersView,
            meta: {
                title: 'Manage Users',
                permissions: ['view manage-users-page']
            }
        },
        {
            path: 'manage-equipment-images',
            name: 'manage-equipment-images',
            component: ManageEquipmentImagesView,
            meta: {
                title: 'Manage Equipment Images',
                permissions: ['view manage-equipment-images-page']
            }
        },
        {
            path: 'excel-reports',
            name: 'excel-reports',
            component: ExcelReportsView,
            meta: {
                title: 'Excel Reports',
                permissions: ['view excel-reports-page']
            }
        },
        {
            path: 'manage-technician-lists',
            name: 'manage-technician-lists',
            component: TechnicianListsView,
            meta: {
                title: 'Technician Lists',
                permissions: ['view technician-lists-page']
            }
        },
        {
            path: 'manage-roles',
            name: 'manage-roles',
            component: ManageRolesView,
            meta: {
                title: 'Manage Roles',
                permissions: ['view manage-roles-page']
            }
        },
        {
            path: 'manage-lookups',
            name: 'manage-lookups',
            component: ManageLookupsView,
            meta: {
                title: 'Manage Lookups',
                permissions: ['view manage-lookups-page']
            }
        },
    ]
};
