export default {
    data,
    computed: getComputed(),
    created,
    destroyed,
    methods: getMethods()
};

function data(){
    return {
        loadingUser: false,
        showScrollTop: false
    };
}

function getComputed(){
    return {
        user(){
            const vm = this;
            return vm.$store.getters.user;
        },
    };
}

function created(){
    const vm = this;
    vm.getUser();
    window.addEventListener('scroll', vm.handleScroll);
}

function destroyed(){
    const vm = this;
    window.removeEventListener('scroll', vm.handleScroll);
}

function getMethods(){
    return {
        getUser,
        handleScroll,
        scrollToTopOfPage
    };

    function getUser(){
        const vm = this;
        vm.loadingUser = true;
        loadUserData();

        function loadUserData(){
            vm.$store.dispatch('user/GET_USER').then(displayView);
            function displayView(){
                vm.loadingUser = false;
            }
        }
    }
    function handleScroll(){
        const vm = this;
        if(!vm.showScrollTop && window.scrollY > 100){
            vm.showScrollTop = true;
        } else if(vm.showScrollTop && window.scrollY < 100){
            vm.showScrollTop = false;
        }
    }

    function scrollToTopOfPage(){
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
}
