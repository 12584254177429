export default {
    components: {},
    props: {
        folder: {
            type: Object,
            required: true
        }
    },
    data,
    computed: getComputed(),
    created,
    mounted,
    methods: getMethods(),
    watch: getWatches(),
};

function data(){
    return {
        isVisible: false,
    };
}

function getComputed(){
    return {
        openDirectory,
        isActiveFolder,
    };
    function openDirectory(){
        const vm = this;
        return vm.$store.state.fileManager.openDirectory;
    }
    function isActiveFolder(){
        const vm = this;
        const openDirectoryName = vm.openDirectory ? vm.openDirectory.path + '/' + vm.openDirectory.name : null;
        return openDirectoryName === vm.folder.path + '/' + vm.folder.name;
    }
}

function created(){}

function mounted(){}

function getMethods(){
    return {
        setOpenDirectory,
    };
    function setOpenDirectory(){
        const vm = this;
        vm.$store.dispatch('fileManager/SET_OPEN_DIRECTORY', vm.folder);
    }
}

function getWatches(){
    return {
        openDirectory,
    };
    function openDirectory(newValue){
        const vm = this;
        if(newValue.path !== vm.folder.path && newValue.path.includes(vm.folder.path)){
            vm.isVisible = true;
        }
    }
}
