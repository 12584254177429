import FooterComponent from './footer/footer';
import HeaderComponent from './header/header';

export default {
    components: {
        FooterComponent,
        HeaderComponent
    },
    props: {
        title: {
            type: String,
            default: ''
        },
    },
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        isLoggingOut: false
    };
}

function getComputed(){
    return {};
}

function created(){}

function getMethods(){
    return {
        displayErrorMessage,
        logout,
    };

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function logout(){
        const vm = this;
        vm.isLoggingOut = true;
        vm.$store.dispatch('user/LOGOUT').then(refreshPage).catch(displayError);
        function refreshPage(){
            vm.$router.go();
        }
        function displayError(error){
            vm.displayErrorMessage(error);
            vm.isLoggingOut = false;
        }
    }
}
