export default {
    components: {},
    props: {},
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        equipment: [],
        isLoadingEquipment: false,
        customerNumber: '',
        tableColumns: [
            {
                key: 'search-icon',
                label: 'Search',
            },
            {
                key: 'No_',
                label: 'Object No',
                sortable: true
            },
            {
                key: 'fleet_code',
                label: 'Fleet Code',
                sortable: true
            },
            {
                key: 'Year',
                label: 'Year',
                sortable: true
            },
            {
                key: 'Manufacturer',
                label: 'Man',
                sortable: true
            },
            {
                key: 'Equip_Model',
                label: 'Model',
                sortable: true
            },
            {
                key: 'Equip_Serial',
                label: 'Serial',
                sortable: true
            },
            {
                key: 'next_pm.Next_Planned_Date',
                label: 'Next PM Date',
                sortable: true,
                formatter: setEmptyWithoutActionCode
            },
            {
                key: 'next_pm.Exp_Meter_Reading',
                label: 'Next Hour Meter',
                sortable: true,
                formatter: setEmptyWithoutActionCode
            },
            {
                key: 'next_pm.Open_on_WO',
                label: 'Open On WO#',
                sortable: true,
                formatter: setEmptyWithoutActionCode
            },
            {
                key: 'equipment_note.requires_attention',
                label: 'Attn',
            },
            {
                key: 'equipment_note.note',
                label: 'Note',
                tdClass: 'input-cell'
            },
            {
                key: 'equipment_note.actions',
                label: '',
            },
            {
                key: 'equipment_note.updated_at',
                label: 'Submitted At',
                formatter: function(value){
                    return Vue.moment(value).format('MMM DD, YYYY h:ma');
                }
            },
            {
                key: 'equipment_note.user.name',
                label: 'User',
            },
        ]
    };
    function setEmptyWithoutActionCode(value, key, equipment){
        const hasActionCode = equipment.next_pm && equipment.next_pm.Action_code !== null;
        let formattedValue = '';
        if(hasActionCode){
            formattedValue = value;
        }
        return formattedValue;
    }
}

function getComputed(){
    return {};
}

function created(){}

function getMethods(){
    return {
        emitError,
        getCustomerEquipment,
        emitEquipmentNumber,
        saveNote
    };

    function emitError(error){
        const vm = this;
        vm.$emit('error', error);
    }

    function getCustomerEquipment(customerNumber, customerSearchName = ''){
        const vm = this;
        vm.isLoadingEquipment = true;
        vm.customerNumber = customerNumber;
        if(customerSearchName === ''){
            return Vue.appApi().authorized().equipment().getByCustomerNumber(customerNumber).then(setEquipment).catch(vm.emitError).finally(resetLoadingState);
        } else {
            return Vue.appApi().authorized().equipment().getByCustomerName(customerSearchName).then(setEquipment).catch(vm.emitError).finally(resetLoadingState);
        }

        function setEquipment(response){
            vm.equipment = response.data.map(addDisplayProperties);

            function addDisplayProperties(equipment){
                const defaultNote = {
                    equipment_no: equipment.No_,
                    customer_no: equipment.customer_no
                };
                equipment.isSavingNote = false;
                equipment.equipment_note = equipment.equipment_note || defaultNote;
                return equipment;
            }
        }
        function resetLoadingState(){
            vm.isLoadingEquipment = false;
        }
    }

    function emitEquipmentNumber(equipment){
        const vm = this;
        vm.$emit('search', equipment.No_);
    }

    function saveNote(equipment){
        const vm = this;
        const equipmentNote = equipment.equipment_note;
        let savePromise;
        if(equipmentNote.id){
            savePromise = Vue.appApi().authorized().equipment().notes(equipmentNote.id).updateEquipmentNote(equipmentNote);
        } else {
            savePromise = Vue.appApi().authorized().equipment().notes().createEquipmentNote(equipmentNote);
        }
        equipment.isSavingNote = true;
        return savePromise.then(updateLocalNote).catch(vm.emitError).finally(resetLoadingState);

        function updateLocalNote({ data }){
            equipment.equipment_note = data;
        }

        function resetLoadingState(){
            equipment.isSavingNote = false;
        }
    }
}
