import { render, staticRenderFns } from "./my-leads.vue?vue&type=template&id=60353de9&scoped=true&"
import script from "./my-leads.controller.js?vue&type=script&lang=js&"
export * from "./my-leads.controller.js?vue&type=script&lang=js&"
import style0 from "./_my-leads.scss?vue&type=style&index=0&id=60353de9&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60353de9",
  null
  
)

export default component.exports