import Datepicker from 'vuejs-datepicker';
import fileDownload from 'js-file-download';
import TechnicianList from 'vue_root/components/technician-list/technician-list';

export default {
    components: {
        Datepicker,
        TechnicianList
    },
    props: {},
    data,
    computed: getComputed(),
    created,
    beforeRouteEnter,
    beforeRouteUpdate,
    methods: getMethods()
};

function data(){
    return {
        loadingCsv: false,
        validationErrors: {},
        errorMessages: [],
        technicianListSections: [],
        isLoadingTechnicianList: false,
        spreadsheetQuery: {
            date: '',
            initials: ''
        }
    };
}

function getComputed(){
    return {};
}

function created(){
    const vm = this;
    vm.getTechnicianListSections();
}

function beforeRouteEnter(to, from, next){
    next(vm => {
        if(to.name !== 'service-home'){
            vm.scrollTo('pageContent', { block: 'center' });
        }
    });
}

function beforeRouteUpdate(to, from, next){
    const vm = this;
    if(to.name !== 'service-home'){
        vm.scrollTo('pageContent', { block: 'center' });
    }
    next();
}

function getMethods(){
    return {
        displayErrorMessage,
        downloadCsv,
        getTechnicianListSections
    };

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            if(error.status === 422 && error.data.errors){
                vm.validationErrors = error.data.errors;
            } else {
                const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
                vm.errorMessages.push(errorMessage);
            }
        }
    }

    function downloadCsv(){
        const vm = this;
        if(vm.loadingCsv){
            return;
        }
        vm.loadingCsv = true;
        vm.$store.dispatch('mechanics/DOWNLOAD_PMS', vm.spreadsheetQuery)
            .then(initiateDownload)
            .catch(alertError)
            .finally(stopSpinner);

        function initiateDownload(response){
            vm.loadingCsv = false;
            fileDownload(response.data, 'pm-list.csv', response.headers['content-type']);
        }

        function alertError(error){
            vm.displayErrorMessage(error);
        }

        function stopSpinner(){
            vm.loadingCsv = false;
        }
    }

    function getTechnicianListSections(){
        const vm = this;
        vm.isLoadingTechnicianList = true;
        return Vue.appApi().authorized().technicianLists('service-home').getTechnicianListSections().then(setTechnicianListSections).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function setTechnicianListSections({ data }){
            vm.technicianListSections = data;
        }
        function resetLoadingState(){
            vm.isLoadingTechnicianList = false;
        }
    }
}
