export default {
    data,
    computed: getComputed(),
    methods: getMethods(),
};

function data(){
    return {
        credentials: {
            email: '',
            password: '',
            password_confirmation: ''
        },
        apiErrors: [],
        successMessages: [],
        validationErrors: {},
        isPasswordDisplayed: false,
        isPasswordConfirmationDisplayed: false,
        requestingPasswordReset: false,
    };
}

function getComputed(){
    return {
        passwordInputType(){
            const vm = this;
            return vm.isPasswordDisplayed ? 'text' : 'password';
        },
        passwordConfirmationInputType(){
            const vm = this;
            return vm.isPasswordConfirmationDisplayed ? 'text' : 'password';
        }
    };
}

function getMethods(){

    return {
        requestPasswordReset,
    };

    function requestPasswordReset(credentials){
        const vm = this;

        vm.requestingPasswordReset = true;
        vm.apiErrors = [];
        vm.validationErrors = {};

        if(credentials.password !== credentials.password_confirmation){
            vm.apiErrors = ['Passwords must match'];
            vm.requestingPasswordReset = false;
            return;
        }

        vm.credentials.token = vm.$route.query.token;

        vm.$store.dispatch('user/reset_password/RESET_PASSWORD', credentials).then(handleReset).catch(handleRequestError);

        function handleReset(response){
            vm.requestingPasswordReset = false;
            vm.successMessages.push('Your password has been reset.');
        }
        function handleRequestError(response){
            vm.apiErrors.push(response.appMessage);
            vm.requestingPasswordReset = false;
            if(response.status === 422 && response.data.errors){
                vm.validationErrors = response.data.errors;
            }
        }
    }
}
