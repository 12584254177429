window.Vue = require('vue');
window.axios = require('axios');
window.Decimal = require('decimal.js');

import 'font-awesome/css/font-awesome.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import './app.scss';

import AppApi from './plugins/app-api.plugin.js';
//import IosKeychainPlugin from './plugins/ios-keychain.plugin.js';
import ClientStorage from './plugins/client-storage/client-storage.plugin.js';
import ScrollToPlugin from './plugins/scroll-to.plugin.js';
import router from './router';
import store from './app.store';
import appMessage from './components/app-message/app-message.vue';
import validatedInput from './components/validated-input/validated-input.vue';
import LoadingSpinner from './components/loading-spinner/loading-spinner.vue';
import PaginatedClientTable from 'vue_root/components/paginated-tables/paginated-client-table/paginated-client-table';
import PaginatedServerTable from 'vue_root/components/paginated-tables/paginated-server-table/paginated-server-table';
import EquipmentImageViewer from './components/equipment-image-viewer/equipment-image-viewer.vue';
import EquipmentStatusTable from './components/equipment-status-table/equipment-status-table.vue';
import KpiWorkordersTable from './components/kpi-workorders-table/kpi-workorders-table.vue';
import ServiceWorkordersTable from './components/service-workorders-table/service-workorders-table.vue';
import WorkorderLinesTable from './components/workorder-lines-table/workorder-lines-table.vue';
import WorkorderPmsTable from './components/workorder-pms-table/workorder-pms-table.vue';
import WorkordersTable from './components/workorders-table/workorders-table.vue';
import WorkorderWarrantyLinesTable from './components/workorder-warranty-lines-table/workorder-warranty-lines-table.vue';
import MenuLayout from './components/menu-layout/menu-layout.vue';
import Folder from './components/folder/folder.vue';
import BootstrapVue from 'bootstrap-vue';
import Multiselect from 'vue-multiselect';

Vue.use(AppApi);
//Vue.use(IosKeychainPlugin);
Vue.use(ScrollToPlugin);
Vue.use(ClientStorage);
Vue.use(BootstrapVue);
Vue.use(require('vue-moment'));

Vue.component('multiselect', Multiselect);
Vue.component('app-message', appMessage);
Vue.component('validated-input', validatedInput);
Vue.component('loading-spinner', LoadingSpinner);
Vue.component('paginated-client-table', PaginatedClientTable);
Vue.component('paginated-server-table', PaginatedServerTable);
Vue.component('equipment-image', EquipmentImageViewer);
Vue.component('equipment-status-table', EquipmentStatusTable);
Vue.component('kpi-workorders-table', KpiWorkordersTable);
Vue.component('service-workorders-table', ServiceWorkordersTable);
Vue.component('workorder-pms-table', WorkorderPmsTable);
Vue.component('workorder-lines-table', WorkorderLinesTable);
Vue.component('workorders-table', WorkordersTable);
Vue.component('workorder-warranty-lines-table', WorkorderWarrantyLinesTable);
Vue.component('menu-layout', MenuLayout);
Vue.component('folder', Folder);

const app = new Vue({
    el: '#vueApp',
    router,
    store,
    components: {},
});
