import EquipmentInfo from './components/equipment-info/equipment-info';
import CustomerEquipment from './components/customer-equipment/customer-equipment';

export default {
    components: {
        EquipmentInfo,
        CustomerEquipment
    },
    props: {},
    data,
    computed: getComputed(),
    beforeRouteUpdate,
    mounted,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        equipmentSearchString: '',
        customerSearchString: '',
        customerSearchName: '',
        resultTypeDisplayed: 'none',
        equipmentAutocompleteCancelToken: null,
        customerAutocompleteCancelToken: null,
        isEquipmentAutocompleteDisabled: false,
        isCustomerAutocompleteDisabled: false,
        equipmentAutocompleteSource: [],
        customerAutocompleteSource: [],
        isSearchingEquipmentOptions: false,
        isSearchingCustomerOptions: false,
        isFetchingNutshellData: false,
        nutshellId: ''
    };
}

function getComputed(){
    return {
        equipmentAutocompleteOptions(){
            const vm = this;
            return vm.equipmentAutocompleteSource.map(createDisplayedOptions);
            function createDisplayedOptions(equipment){
                return {
                    text: `${equipment.No_}, ${equipment.fleet_code}, ${equipment.Equip_Serial}, ${equipment.Manufacturer}, ${equipment.Equip_Model}`,
                    value: equipment.No_
                };
            }
        },
        customerAutocompleteOptions(){
            const vm = this;
            return vm.customerAutocompleteSource.map(createDisplayedOptions);
            function createDisplayedOptions(customer){
                return {
                    text: `${customer.No}, ${customer.Name}, ${customer.City}`,
                    value: customer.No
                };
            }
        }
    };
}

function beforeRouteUpdate(toRoute, fromRoute, next){
    const vm = this;
    vm.updateDisplayFromQuery(toRoute.query);
    next();
}

function mounted(){
    const vm = this;
    vm.updateDisplayFromQuery(vm.$route.query);
}

function getMethods(){
    return {
        displayErrorMessage,
        updateDisplayFromQuery,
        searchByCustomer,
        searchByEquipmentNumber,
        searchByNutshellAccount,
        updateRouteQuery,
        autocompleteEquipmentSearch,
        autocompleteCustomerSearch,
        cancelCustomerAutocomplete,
        cancelEquipmentAutocomplete,
        equipmentViewScroll
    };

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function updateDisplayFromQuery(query){
        const vm = this;
        vm.resultTypeDisplayed = query.display_type || 'none';
        vm.equipmentSearchString = query.equipment_search || '';
        vm.customerSearchString = query.customer_search || '';
        vm.customerSearchName = query.customer_search_name || '';
        vm.nutshellId = query.nutshell_id || '';

        if(vm.resultTypeDisplayed === 'equipment'){
            vm.searchByEquipmentNumber(vm.equipmentSearchString, false);
        } else if(vm.resultTypeDisplayed === 'customer'){
            vm.searchByCustomer(vm.customerSearchString, false, vm.customerSearchName);
        } else if(vm.nutshellId){
            vm.searchByNutshellAccount();
        }
    }

    function searchByCustomer(customerSearchString, isQueryStale = true, customerSearchName = ''){
        const vm = this;
        if(!customerSearchString && customerSearchName === ''){
            return vm.displayErrorMessage('Please provide a term to search by');
        } else {
            vm.errorMessages = [];
        }
        if(isQueryStale){
            vm.updateRouteQuery({ customer_search: customerSearchString, display_type: 'customer', customer_search_name: '' });
        } else {
            vm.resultTypeDisplayed = 'customer';
            vm.customerSearchString = customerSearchName === '' ? customerSearchString : customerSearchName;
            Vue.nextTick(vm.cancelCustomerAutocomplete);
            vm.$refs.customerEquipmentComponent.getCustomerEquipment(customerSearchString, customerSearchName);
        }
    }

    function searchByEquipmentNumber(equipmentNumber, isQueryStale = true){
        const vm = this;
        if(!equipmentNumber){
            return vm.displayErrorMessage('Please provide a term to search by');
        } else {
            vm.errorMessages = [];
        }
        if(isQueryStale){
            vm.updateRouteQuery({ equipment_search: equipmentNumber, display_type: 'equipment' });
        } else {
            vm.resultTypeDisplayed = 'equipment';
            vm.equipmentSearchString = equipmentNumber;
            Vue.nextTick(vm.cancelEquipmentAutocomplete);
            vm.$refs.equipmentInfoComponent.displayInfoForEquipmentNumber(equipmentNumber);
        }
    }

    function searchByNutshellAccount(){
        const vm = this;
        const supportedIdTypes = ['accounts'];
        const query = {
            nutshell_id: parseInt(vm.nutshellId),
            nutshell_id_type: vm.nutshellId.split('-')[1]
        };
        if(supportedIdTypes.includes(query.nutshell_id_type)){
            vm.isFetchingNutshellData = true;
            return Vue.appApi().authorized().sales().nutshell().getCustomerNumberForAccount(query).then(searchByCustomer).catch(vm.displayErrorMessage).finally(resetLoadingState);
        } else {
            vm.displayErrorMessage(`Cannot perform search for Nutshell ${query.nutshell_id_type}, currently only accounts are supported.`);
        }

        function searchByCustomer({ data }){
            vm.searchByCustomer(data, true);
        }

        function resetLoadingState(){
            vm.isFetchingNutshellData = false;
        }
    }

    function updateRouteQuery(query){
        const vm = this;
        const currentRoute = vm.$route;
        const currentQuery = JSON.parse(JSON.stringify(currentRoute.query));
        const newQuery = Object.assign(currentQuery, query);
        const isRouteUpdated = JSON.stringify(newQuery) !== JSON.stringify(currentRoute.query);
        if(isRouteUpdated){
            vm.$router.push({
                name: currentRoute.name,
                params: currentRoute.params,
                query: newQuery
            });
        }
    }

    function autocompleteEquipmentSearch(value){
        const vm = this;
        vm.equipmentSearchString = value;
        if(vm.equipmentSearchString.length < 4){
            return vm.cancelEquipmentAutocomplete();
        }
        vm.isSearchingEquipmentOptions = true;
        if(vm.equipmentAutocompleteCancelToken){
            vm.equipmentAutocompleteCancelToken.cancel('request cancelled');
        }
        const cancelToken = window.axios.CancelToken.source();
        vm.equipmentAutocompleteCancelToken = cancelToken;
        return Vue.appApi().authorized().equipment().autocompleteEquipmentSearch({ search_string: vm.equipmentSearchString }, cancelToken.token).then(displaySearchResults).catch(handleError);

        function displaySearchResults(response){
            vm.equipmentAutocompleteCancelToken = null;
            vm.equipmentAutocompleteSource = response.data;
            vm.isSearchingEquipmentOptions = false;
        }
        function handleError(error){
            if(error.message !== 'request cancelled'){
                vm.isSearchingEquipmentOptions = false;
                vm.displayErrorMessage(error);
            }
        }
    }

    function autocompleteCustomerSearch(value){
        const vm = this;
        vm.customerSearchString = value;
        if(vm.customerSearchString.length < 3){
            return vm.cancelCustomerAutocomplete();
        }
        vm.isSearchingCustomerOptions = true;
        if(vm.customerAutocompleteCancelToken){
            vm.customerAutocompleteCancelToken.cancel('request cancelled');
        }
        const cancelToken = window.axios.CancelToken.source();
        vm.customerAutocompleteCancelToken = cancelToken;
        return Vue.appApi().authorized().equipment().autocompleteCustomerSearch({ search_string: vm.customerSearchString }, cancelToken.token).then(displaySearchResults).catch(handleError);

        function displaySearchResults(response){
            vm.customerAutocompleteCancelToken = null;
            vm.customerAutocompleteSource = response.data;
            vm.isSearchingCustomerOptions = false;
        }
        function handleError(error){
            if(error.message !== 'request cancelled'){
                vm.isSearchingCustomerOptions = false;
                vm.displayErrorMessage(error);
            }
        }
    }

    function cancelCustomerAutocomplete(){
        const vm = this;
        if(vm.customerAutocompleteCancelToken){
            vm.customerAutocompleteCancelToken.cancel('request cancelled');
        }
        vm.customerAutocompleteSource = [];
        vm.isSearchingCustomerOptions = false;
    }

    function cancelEquipmentAutocomplete(){
        const vm = this;
        if(vm.equipmentAutocompleteCancelToken){
            vm.equipmentAutocompleteCancelToken.cancel('request cancelled');
        }
        vm.equipmentAutocompleteSource = [];
        vm.isSearchingEquipmentOptions = false;
    }

    function equipmentViewScroll(ref){
        const vm = this;
        vm.$refs.equipmentInfoComponent.scrollTo(ref);
    }
}
