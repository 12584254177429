import MarginView from './margin/margin.vue';
import CheckView from './check/check.vue';
import ClaimView from './claim/claim.vue';
import LinesView from './lines/lines.vue';
import RequisitionView from './requisition/requisition.vue';
import VanView from './van/van.vue';

export default {
    path: '/parts',
    component: { template: `<router-view></router-view>` },
    meta: {},
    children: [
        {
            path: 'margin',
            name: 'parts-margin',
            component: MarginView,
            meta: {
                title: 'Parts Margin',
                permissions: ['view parts-margin-page']
            }
        },
        {
            path: 'check',
            name: 'parts-check',
            component: CheckView,
            meta: {
                title: 'Parts Check',
                permissions: ['view parts-check-page']
            }
        },
        {
            path: 'claim',
            name: 'parts-claim',
            component: ClaimView,
            meta: {
                title: 'Parts Claim',
                permissions: ['view parts-claim-page']
            }
        },
        {
            path: 'lines',
            name: 'parts-lines',
            component: LinesView,
            meta: {
                title: 'Parts Lines',
                permissions: ['view parts-lines-page']
            }
        },
        {
            path: 'requisition',
            name: 'parts-requisition',
            component: RequisitionView,
            meta: {
                title: 'Parts Requisition',
                permissions: ['view parts-req-page']
            }
        },
        {
            path: 'van',
            name: 'parts-van',
            component: VanView,
            meta: {
                title: 'Parts Van',
                permissions: ['view parts-van-page']
            }
        }
    ]
};
