export default {
    data,
    computed: getComputed(),
    created,
    methods: getMethods(),
};

function data(){
    return {
        apiErrors: [],
        validationErrors: {},
        credentials: {
            email: '',
            password: '',
            remember_me: false
        },
        loggingIn: false,
        isPasswordDisplayed: false,
        isEmailInputDisabled: false
    };
}

function getComputed(){
    return {
        passwordInputType(){
            const vm = this;
            return vm.isPasswordDisplayed ? 'text' : 'password';
        }
    };
}

function created(){
    const vm = this;
    const inviteEmail = vm.$route.query.email;
    if(inviteEmail){
        vm.credentials.email = inviteEmail;
        vm.isEmailInputDisabled = true;
    } else {
        vm.loggingIn = true;
        Vue.clientStorage.getItem('email').then(setEmailFromLocalStorage).finally(resetLoadingState);
    }
    function setEmailFromLocalStorage(email){
        vm.credentials.email = email || '';
        vm.credentials.remember_me = !!email;
    }
    function resetLoadingState(){
        vm.loggingIn = false;
    }
}

function getMethods(){

    return {
        login,
    };

    function login(){

        var vm = this;
        vm.apiErrors = [];
        vm.validationErrors = {};
        vm.loggingIn = true;

        vm.$store.dispatch('user/login/LOGIN', vm.credentials).then(getUserSuccess).catch(getUserError);

        function getUserSuccess(response){
            const redirectLocation = vm.$route.invite_code ? { name: 'accept-invite', query: { invite_code: vm.invite_code }} : undefined;
            vm.$router.redirectAfterLogin(redirectLocation);
        }
        function getUserError(response){
            vm.loggingIn = false;
            if(response.status === 422 && response.data.errors){
                vm.validationErrors = response.data.errors;
            }
            vm.apiErrors.push('Username or password is incorrect.');
        }
    }
}
