import fileDownload from 'js-file-download';
import Datepicker from 'vuejs-datepicker';
import WorkorderAndCheckinDetails from 'vue_root/components/workorder-and-checkin-details/workorder-and-checkin-details.vue';

export default {
    components: {
        Datepicker,
        WorkorderAndCheckinDetails
    },
    props: {},
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        validationErrors: {},
        workOrderNumbersQuery: {
            startDate: '',
            endDate: '',
            serviceType: '',
            responseCenter: '',
        },
        availableRentalCheckins: [],
        displayedWorkOrderNumber: null,
        isLoadingAvailableRentalCheckins: false,
        rentalCheckinColumns: [
            {
                key: 'WO_Number',
                sortable: true
            },
            {
                key: 'Amount',
                sortable: true,
                sortByFormatted: true,
                formatter: (value, key, checkin) => {
                    return (checkin.work_order_lines || []).reduce((acc, { Amount }) => {
                        return acc + (+Amount || 0);
                    }, 0);
                }
            },
            {
                key: 'Service_Type',
                label: 'Service Type',
                sortable: true,
            },
            {
                key: 'Sell_Name',
                label: 'BillTo Name',
                sortable: true,
            },
            {
                key: 'posting_date',
                label: 'Posting Date',
                sortable: true,
            },
            {
                key: 'workorder_invoice_note.reason',
                label: 'Reason'
            },
            {
                key: 'workorder_invoice_note.notes',
                label: 'Notes',
                tdClass: 'input-cell'
            },
            {
                key: 'workorder_invoice_note.corrective_action',
                label: 'Corrective Action',
                tdClass: 'input-cell'
            },
            {
                key: 'workorder_invoice_note.authorized_by',
                label: 'Authorized By',
                tdClass: 'input-cell'
            },
            {
                key: 'save_notes',
                label: ''
            },
            {
                key: 'workorder_invoice_note.user.name',
                label: 'User',
            },
        ],
        isSearchInitiated: false,
        isDownloadingSpreadsheet: false,

        isLoadingInvoiceNoteReasons: false,
        invoiceNoteReasons: []
    };
}

function getComputed(){
    return {
        noteReasonOptions
    };

    function noteReasonOptions(){
        const vm = this;
        const currentReasons = vm.invoiceNoteReasons.map(lookup => lookup.meta_data.value);
        const previouslySelectedReasons = vm.availableRentalCheckins.filter(checkIn => checkIn.workorder_invoice_note && checkIn.workorder_invoice_note.reason).map(checkIn => checkIn.workorder_invoice_note.reason);
        const oldReasons = previouslySelectedReasons.filter((reason, index, array) => {
            return reason && !currentReasons.includes(reason) && array.indexOf(reason) === index;
        });
        const validOptions = currentReasons.map(reason => { return { value: reason, text: reason }; });
        const disabledOptions = oldReasons.map(reason => { return { value: reason, text: reason, disabled: true }; });
        return validOptions.concat(disabledOptions);
    }
}

function created(){
    const vm = this;
    vm.fetchServiceAdjustReasons();
}

function getMethods(){
    return {
        displayErrorMessage,
        searchRentalCheckins,
        displayRentalCheckinInvoice,
        getWorkorderHistoryLines,
        saveNote,
        exportResultsToExcel,
        fetchServiceAdjustReasons,
        validateQuery
    };

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function searchRentalCheckins(page = 1){
        const vm = this;
        const isQueryValid = vm.validateQuery();
        if(isQueryValid){
            vm.displayedWorkOrderNumber = null;
            vm.isSearchInitiated = true;
            const query = {
                start_date: vm.workOrderNumbersQuery.startDate,
                end_date: vm.workOrderNumbersQuery.endDate,
                service_type: vm.workOrderNumbersQuery.serviceType.toUpperCase(),
                response_center: vm.workOrderNumbersQuery.responseCenter,
            };
            vm.isLoadingAvailableRentalCheckins = true;
            return Vue.appApi().authorized().rentalCheckins().indexHulRentalCheckins(query).then(setAvailableRentalCheckins).catch(vm.displayErrorMessage).finally(resetLoadingState);
        }

        function setAvailableRentalCheckins(response){
            vm.availableRentalCheckins = response.data.map(addDisplayProperties);

            function addDisplayProperties(workorder){
                const noteDefault = {
                    workorder_no: workorder.WO_Number,
                };
                workorder.isSavingNote = false;
                workorder.workorder_invoice_note = workorder.workorder_invoice_note || noteDefault;
                return workorder;
            }
        }

        function resetLoadingState(){
            vm.isLoadingAvailableRentalCheckins = false;
        }
    }

    function displayRentalCheckinInvoice(rentalCheckin){
        const vm = this;
        vm.displayedWorkOrderNumber = rentalCheckin.WO_Number;
        vm.scrollTo('workorderDetails');
    }

    function getWorkorderHistoryLines(workOrderNumber){
        const vm = this;
        vm.workOrderHistoryNumber = workOrderNumber;
    }

    function fetchServiceAdjustReasons(){
        const vm = this;
        vm.isLoadingInvoiceNoteReasons = true;
        return Vue.appApi().authorized().lookups().getLookupsByType('service_note_reason')
            .then(setReasons)
            .catch(vm.displayErrorMessage)
            .finally(resetLoadingState);
        function setReasons(response){
            vm.invoiceNoteReasons = response.data;
        }
        function resetLoadingState(){
            vm.isLoadingInvoiceNoteReasons = false;
        }
    }

    function saveNote(workorder){
        const vm = this;
        workorder.isSavingNote = true;
        return Vue.appApi().authorized().rentalCheckins().createUpdateWorkOrderInvoiceNote(workorder.workorder_invoice_note)
            .then(updateLocalNote)
            .catch(emitError)
            .finally(resetLoadingState);

        function updateLocalNote(response){
            workorder.workorder_invoice_note = response.data;
        }
        function emitError(){
            vm.displayErrorMessage(new Error('Failed to save invoice note for workorder: ' + workorder.WO_Number));
        }
        function resetLoadingState(){
            workorder.isSavingNote = false;
        }
    }

    function exportResultsToExcel(){
        const vm = this;

        if(!vm.validateQuery()){
            return;
        }

        const query = {
            start_date: vm.workOrderNumbersQuery.startDate,
            end_date: vm.workOrderNumbersQuery.endDate,
            service_type: vm.workOrderNumbersQuery.serviceType.toUpperCase(),
            response_center: vm.workOrderNumbersQuery.responseCenter
        };
        vm.isDownloadingSpreadsheet = true;

        return Vue.appApi().authorized().rentalCheckins().exportHulRentalCheckins(query).then(downloadFile).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function downloadFile(response){
            fileDownload(response.data, `ServiceInvoicing.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        }

        function resetLoadingState(){
            vm.isDownloadingSpreadsheet = false;
        }
    }

    function validateQuery(){
        const vm = this;
        vm.validationErrors = {};
        if(!vm.workOrderNumbersQuery.startDate){
            vm.validationErrors.start_date = ['Please select a start date.'];
        }
        if(!vm.workOrderNumbersQuery.endDate){
            vm.validationErrors.end_date = ['Please select an end date.'];
        }
        return Object.keys(vm.validationErrors).length === 0;
    }
}
