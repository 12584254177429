export default {
    components: {},
    props: {},
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        invite_code: '',
        isAcceptingInvite: false
    };
}

function getComputed(){
    return {};
}

function created(){
    const vm = this;
    vm.invite_code = vm.$route.query.invite_code;
    if(!vm.invite_code){
        vm.$router.push({ name: 'page-not-found' });
    } else {
        vm.acceptInvite();
    }
}

function getMethods(){
    return {
        displayErrorMessage,
        acceptInvite
    };

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function acceptInvite(){
        const vm = this;
        vm.isAcceptingInvite = true;
        return Vue.appApi().authorized().user().acceptAccountInvite({ invite_code: vm.invite_code })
            .then(refreshUser)
            .then(redirectToDashboard)
            .catch(handleInviteError)
            .finally(resetLoadingState);

        function refreshUser({ data }){
            vm.$store.commit('user/SET_USER', data);
            return vm.$store.dispatch('user/GET_USER');
        }
        function redirectToDashboard(){
            vm.$router.replace({ name: 'dashboard' });
        }
        function handleInviteError(response){
            const isWrongUser = response.status && response.status === 403;
            if(isWrongUser){
                vm.$store.dispatch('user/LOGOUT').then(redirectToInviteRedirectPage);
            } else {
                vm.displayErrorMessage(response);
            }

            function redirectToInviteRedirectPage(){
                vm.$router.push({ name: 'invite-redirect', query: { invite_code: vm.invite_code }});
            }
        }
        function resetLoadingState(){
            vm.isAcceptingInvite = false;
        }
    }
}
