const state = {
    equipment: [],
    getEquipmentCancelToken: null,
    equipmentCategories: {
        data: [],
        currentPage: 1,
        lastPage: 1,
        from: 0,
        to: 0,
        total: 0,
    },
    equipmentGroups: {
        data: [],
        currentPage: 1,
        lastPage: 1,
        from: 0,
        to: 0,
        total: 0,
    },
};

export default {
    namespaced: true,
    state,
    mutations: getMutations(),
    actions: getActions(),
    getters: getGetters(),
    modules: {}
};

function getActions(){

    return {
        GET_EQUIPMENT_CATEGORIES: getEquipmentCategories,
        GET_EQUIPMENT_GROUPS: getEquipmentGroups,
        GET_REDTAG_EQUIPMENT: getRedtagEquipment,
    };
    function getEquipmentCategories({ commit }){
        return Vue.appApi().authorized().equipment().getEquipmentCategories().then(setEquipmentCategories);

        function setEquipmentCategories(response){
            commit('SET_EQUIPMENT_CATEGORIES', response.data);
            return JSON.parse(JSON.stringify(response.data));
        }
    }
    function getEquipmentGroups({ commit }){
        return Vue.appApi().authorized().equipment().getEquipmentGroups().then(setEquipmentGroups);

        function setEquipmentGroups(response){
            commit('SET_EQUIPMENT_GROUPS', response.data);
            return JSON.parse(JSON.stringify(response.data));
        }
    }
    function getRedtagEquipment({ commit, state }, payload){
        if(state.getEquipmentCancelToken){
            state.getEquipmentCancelToken.cancel('request cancelled');
        }
        const cancelToken = window.axios.CancelToken.source();
        commit('SET_EQUIPMENT_CANCEL_TOKEN', cancelToken);
        return Vue.appApi().authorized().equipment().getEquipment(payload, cancelToken.token).then(setEquipment);

        function setEquipment(response){
            commit('SET_EQUIPMENT', response.data);
            return JSON.parse(JSON.stringify(response.data));
        }
    }
}

function getGetters(){
    return {
        equipmentCategories,
        equipmentGroups,
    };
    function equipmentCategories(state){
        return state.equipmentCategories;
    }
    function equipmentGroups(state){
        return state.equipmentGroups;
    }
}

function getMutations(){

    return {
        SET_EQUIPMENT: setEquipmentState,
        SET_EQUIPMENT_CANCEL_TOKEN: setEquipmentCancelToken,
        SET_EQUIPMENT_CATEGORIES: setEquipmentCategoriesState,
        SET_EQUIPMENT_GROUPS: setEquipmentGroupsState,
    };
    function setEquipmentState(state, payload){
        state.equipment = payload;
    }
    function setEquipmentCancelToken(state, payload){
        state.getEquipmentCancelToken = payload;
    }
    function setEquipmentCategoriesState(state, equipmentCategories){
        state.equipmentCategories = equipmentCategories;
    }
    function setEquipmentGroupsState(state, equipmentGroups){
        state.equipmentGroups = equipmentGroups;
    }
}
