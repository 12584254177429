import { render, staticRenderFns } from "./account-invites-table.vue?vue&type=template&id=5d42b980&scoped=true&"
import script from "./account-invites-table.controller.js?vue&type=script&lang=js&"
export * from "./account-invites-table.controller.js?vue&type=script&lang=js&"
import style0 from "./_account-invites-table.scss?vue&type=style&index=0&id=5d42b980&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d42b980",
  null
  
)

export default component.exports