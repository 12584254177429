import Vue from 'vue';
import Vuex from 'vuex';
import fileManager from './authorized/subscriber/all/file-manager/file-manager.store';
import guest from './guest/guest.store';
import mechanics from './authorized/subscriber/service-pages/service/mechanic-orders/mechanic-orders.store';
import pmComments from './authorized/subscriber/service-pages/components/pm-comments/pm-comments.store';
import pmLeads from './authorized/subscriber/all/pm-leads/pm-leads.store';
import rentalRedtag from './authorized/subscriber/rental-pages/rental-redtag/rental-redtag.store';
import shop from './authorized/subscriber/service-pages/shop/shop.store';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';
const state = {
    appEnv: window.appEnv || {}
};

export default new Vuex.Store({
    modules: {
        fileManager,
        guest,
        mechanics,
        pmComments,
        pmLeads,
        rentalRedtag,
        shop,
    },
    state,
    getters: getGetters(),
    strict: debug,
});

function getGetters(){
    return {
        user,
        userHasPermissionTo,
    };
    function user(state){
        return state.guest.user.user;
    }
    function userHasPermissionTo(state, getters){
        return function hasPermissionTo(permissionToCheck){
            const userPermissions = (getters.user && getters.user.current_account_user) ? getters.user.current_account_user.all_permission_names : [];
            return userPermissions.includes(permissionToCheck);
        };
    }
}
