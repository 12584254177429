export default {
    components: {},
    props: {
        query: {
            type: Object,
            required: true
        },
    },
    data,
    computed: getComputed(),
    watch: getWatchers(),
    created,
    methods: getMethods()
};

function data(){
    return {
        isLoadingCustomerReport: false,
        customer: null,
        equipmentTableFields: [
            { key: 'search', label: '', sortable: false },
            { key: 'No_', label: 'Object No', sortable: true },
            { key: 'Fleet Code', label: 'Fleet Code', sortable: true },
            { key: 'Year', label: 'Year', sortable: true },
            { key: 'Manufacturer Code', label: 'Man', sortable: true },
            { key: 'Equipment Model', label: 'Model', sortable: true },
            { key: 'Serial No_', label: 'Serial', sortable: true },
            { key: 'customer.Name', label: 'Customer', sortable: true },
            { key: 'age', label: 'Age (months)', sortable: true },
            { key: 'hours_diff', label: 'Hours', sortable: true },
            { key: 'avoidable_damage_cost', label: 'Avoidable Damage ($)', sortable: true },
            { key: 'tire_spend', label: 'Tire Spend ($)', sortable: true },
            { key: 'total_spend', label: 'Total Spend ($)', sortable: true },
            { key: 'avoidable_cph', label: 'Avoidable CPH', sortable: true },
            { key: 'tire_cph', label: 'Tire CPH', sortable: true },
            { key: 'total_cph', label: 'Cost per Running Hour ($/Hr)', sortable: true },
            { key: 'cph_benchmark_percentage', label: 'Status', sortable: true },
        ]
    };
}

function getComputed(){
    return {
        overallMetrcis(){
            const vm = this;
            return [
                { label: 'Fleet Cost per Hour', value: `${vm.fleetCph}`, backgroundColor: 'primary' },
                { label: 'Fleet Tires Cost per Hour', value: `${vm.fleetTireCph}`, backgroundColor: 'info' },
                { label: 'Fleet Avoidable CPH', value: `${vm.fleetAvoidableCph}`, backgroundColor: 'warning' },
                { label: 'Fleet Total Hours', value: `${vm.fleetTotalHours}`, backgroundColor: 'primary' },
                { label: 'Fleet Total Spend', value: `${vm.fleetTotalSpend}`, backgroundColor: 'primary' },
                { label: 'Fleet Tire Spend', value: `${vm.fleetTireSpend}`, backgroundColor: 'info' },
                { label: 'Fleet Avoidable Spend', value: `${vm.fleetAvoidableSpend}`, backgroundColor: 'warning' },
                { label: 'Average Age (months)', value: `${vm.fleetAverageAge}`, backgroundColor: 'primary' },
            ];
        },
        fleetCph(){
            const vm = this;
            return `$${vm.customer.fleet_cost_per_hour.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`;
        },
        fleetTireCph(){
            const vm = this;
            return `$${vm.customer.fleet_tire_cost_per_hour.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`;
        },
        fleetAvoidableCph(){
            const vm = this;
            return `$${vm.customer.fleet_avoidable_cost_per_hour.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`;
        },
        fleetTotalHours(){
            const vm = this;
            return vm.customer.fleet_total_hours.toLocaleString(undefined, { maximumFractionDigits: 0 });
        },
        fleetTotalSpend(){
            const vm = this;
            return `$${vm.customer.fleet_total_spend.toLocaleString(undefined, { maximumFractionDigits: 0 })}`;
        },
        fleetTireSpend(){
            const vm = this;
            return `$${vm.customer.fleet_tire_spend.toLocaleString(undefined, { maximumFractionDigits: 0 })}`;
        },
        fleetAvoidableSpend(){
            const vm = this;
            return `$${vm.customer.fleet_avoidable_spend.toLocaleString(undefined, { maximumFractionDigits: 0 })}`;
        },
        customerEquipment(){
            const vm = this;
            return vm.customer ? vm.customer.equipment_objects : null;
        },
        fleetAverageAge(){
            const vm = this;
            return vm.customer.fleet_average_age.toFixed(0);
        },
    };
}

function getWatchers(){
    return {
        query: {
            handler: created,
            deep: true
        }
    };
}

function created(){
    const vm = this;
    vm.getFleetReport();
}

function getMethods(){
    return {
        getFleetReport,
    };

    function getFleetReport(){
        const vm = this;
        vm.isLoadingCustomerReport = true;
        vm.customer = null;
        const query = {
            query_type: vm.query.query_type,
            customer: vm.query.customer,
            start_date: vm.query.start_date,
            end_date: vm.query.end_date
        };
        return Vue.appApi().authorized().reports().generateCustomerFleetReport(query).then(displayReportData).catch(emitQueryError).finally(resetLoadingState);

        function displayReportData(response){
            vm.customer = response.data[0];
            if(!vm.customer){
                throw new Error(`Customer not found matching ${vm.query.customer}`);
            }
        }

        function emitQueryError(response){
            vm.$emit('query-error', response);
        }

        function resetLoadingState(){
            vm.isLoadingCustomerReport = false;
        }
    }
}
