export default {
    components: {},
    props: {},
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        isLoadingAccountUsers: false,
        accountUsers: [],
        tableColumns: [
            {
                key: 'user.name',
                label: 'Name',
                sortable: true,
            },
            {
                key: 'user.email',
                label: 'Email',
                sortable: true,
            },
            {
                key: 'mechanic_initials',
                label: 'Initials',
                sortable: true,
            },
            {
                key: 'user.last_login',
                label: 'Last Login',
                sortable: true,
                formatter: (loginDate) => {
                    return loginDate ? Vue.moment(loginDate).format('MMM D, YYYY h:mma') : '';
                }
            },
            {
                key: 'all_role_names',
                label: 'Roles',
                formatter: (roleNamesArray) => {
                    return roleNamesArray.join(', ');
                }
            },
            {
                label: 'Actions',
                key: 'actions',
            }
        ],
    };
}

function getComputed(){
    return {};
}

function created(){
    const vm = this;
    vm.getAccountUsers();
}

function getMethods(){
    return {
        displayErrorMessage,
        getAccountUsers,
        deleteAccountUser,
        editAccountUser,
        updateAccountUser,
        resetAccountUserDisplayProperties
    };

    function displayErrorMessage(error){
        const vm = this;
        vm.$emit('error', error);
    }

    function getAccountUsers(){
        const vm = this;
        vm.isLoadingAccountUsers = true;
        return Vue.appApi().authorized().account().accountUsers().getAccountUsers().then(setAccountUsers).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function setAccountUsers({ data }){
            vm.accountUsers = data.map(vm.resetAccountUserDisplayProperties);
        }
        function resetLoadingState(){
            vm.isLoadingAccountUsers = false;
        }
    }

    function deleteAccountUser(accountUser){
        const vm = this;
        accountUser.isDeleting = true;
        return Vue.appApi().authorized().account().accountUsers(accountUser.id).deleteAccountUser().then(updateAccountUserList).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function updateAccountUserList(){
            const accountUserIndex = vm.accountUsers.findIndex(({ id }) => id === accountUser.id);
            vm.accountUsers.splice(accountUserIndex, 1);
        }
        function resetLoadingState(){
            accountUser.isDeleting = false;
        }
    }

    function editAccountUser(accountUser){
        const vm = this;
        vm.$emit('edit-account-user', JSON.parse(JSON.stringify(accountUser)));
    }

    function updateAccountUser(accountUser){
        const vm = this;
        const accountUserIndex = vm.accountUsers.findIndex(({ id }) => id === accountUser.id);
        vm.resetAccountUserDisplayProperties(accountUser);
        vm.accountUsers.splice(accountUserIndex, 1, accountUser);
    }

    function resetAccountUserDisplayProperties(accountUser){
        accountUser.isDeleting = false;
        accountUser.isConfirmingDelete = false;
        return accountUser;
    }
}
