import DashboardComponent from './dashboard/dashboard.vue';
import ChangeLogComponent from './change-log/change-log.vue';
import AllOrdersComponent from './all-orders/all-orders.vue';
import WarrantyUploadComponent from './warranty-upload/warranty-upload.vue';
import EquipmentUploadComponent from './equipment-upload/equipment-upload.vue';
import PMInfoComponent from './pm-info/pm-info.vue';
import ManagePMLeadsComponent from './manage-pm-leads/manage-pm-leads';
import PMLeadsComponent from './pm-leads/pm-leads';
import FileManagerComponent from './file-manager/file-manager.vue';

export default {
    path: '/all',
    component: { template: `<router-view></router-view>` },
    children: [
        {
            path: '',
            name: 'dashboard',
            component: DashboardComponent,
        },
        {
            path: 'change-log',
            name: 'change-log',
            component: ChangeLogComponent,
            meta: {
                title: 'Change Log',
                permissions: ['view change-log-page']
            }
        },
        {
            path: 'all-orders',
            name: 'all-orders',
            component: AllOrdersComponent,
            meta: {
                title: 'All Orders',
                permissions: ['view all-orders-page']
            }
        },
        {
            path: 'pm-leads',
            component: PMLeadsComponent,
            name: 'pm-leads-home',
            meta: {
                title: 'PM Leads',
                permissions: ['view pm-leads-page']
            },
        },
        {
            path: 'manage-pm-leads',
            component: ManagePMLeadsComponent,
            name: 'manage-pm-leads',
            meta: {
                title: 'Company Leads',
                permissions: ['view manage-leads-page']
            },
        },
        {
            path: 'warranty-upload',
            name: 'warranty-upload',
            component: WarrantyUploadComponent,
            meta: {
                title: 'Warranty Upload',
                permissions: ['view warranty-upload-page']
            }
        },
        {
            path: 'equipment-upload',
            name: 'equipment-upload',
            component: EquipmentUploadComponent,
            meta: {
                title: 'Warranty Upload',
                permissions: ['view equipment-upload-page']
            }
        },
        {
            path: 'file-manager',
            name: 'file-manager',
            component: FileManagerComponent,
            meta: {
                title: 'File Manager',
                permissions: ['view file-manager-page']
            }
        },
        {
            path: 'pm-info',
            name: 'pm-info',
            component: PMInfoComponent,
            meta: {
                title: 'PM Info',
                permissions: ['view pm-info-page']
            }
        },
    ]
};
