import SalesOrderLinesTable from '../components/sales-order-lines-table/sales-order-lines-table.vue';

export default {
    components: {
        SalesOrderLinesTable
    },
    props: {},
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        isLoadingSalesOrderLines: false,
    };
}

function getComputed(){
    return {};
}

function created(){}

function getMethods(){
    return {
        getSalesOrderLines,
        setIsLoadingSalesOrderLines
    };

    function getSalesOrderLines(){
        const vm = this;
        const query = {
            'Doc_Type': '1',
            'Type': '2',
            'Quantity': '0.00'
        };
        vm.$refs.salesOrderLinesTable.getSalesOrderLines(query);
    }

    function setIsLoadingSalesOrderLines(loadingState){
        const vm = this;
        vm.isLoadingSalesOrderLines = loadingState;
    }
}
