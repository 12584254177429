export default {
    components: {},
    props: {},
    data,
    computed: getComputed(),
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        validationErrors: {},
        activeMenu: '',
        menus: [
            {
                name: 'All',
                appSection: 'all',
                links: [
                    {
                        routeName: 'dashboard',
                        exactRouteMatch: true,
                        linkText: 'Dashboard'
                    },
                    {
                        routeName: 'all-orders',
                        requiredPermission: 'view all-orders-page',
                        linkText: 'Orders'
                    },
                    {
                        routeName: 'warranty-upload',
                        requiredPermission: 'view warranty-upload-page',
                        linkText: 'Warranty Upload'
                    },
                    {
                        routeName: 'equipment-upload',
                        requiredPermission: 'view equipment-upload-page',
                        linkText: 'Equipment Upload'
                    },
                    {
                        routeName: 'change-log',
                        requiredPermission: 'view change-log-page',
                        linkText: 'Change Log'
                    },
                    {
                        routeName: 'file-manager',
                        requiredPermission: 'view file-manager-page',
                        linkText: 'File Manager'
                    },
                    {
                        routeName: 'pm-info',
                        requiredPermission: 'view pm-info-page',
                        linkText: 'PM Info'
                    },
                    {
                        routeName: 'pm-leads-home',
                        requiredPermission: 'view pm-leads-page',
                        linkText: 'PM Leads'
                    },
                    {
                        routeName: 'manage-pm-leads',
                        requiredPermission: 'view manage-leads-page',
                        linkText: 'Manage PM Leads'
                    },
                ]
            },
            {
                name: 'Service',
                appSection: 'service',
                links: [
                    {
                        routeName: 'service-home',
                        requiredPermission: 'view service-road-page',
                        linkText: 'Road'
                    },
                    {
                        routeName: 'technician-road',
                        requiredPermission: 'view technician-road-page',
                        linkText: 'Technician Road'
                    },
                    {
                        routeName: 'kpi-billing-home',
                        requiredPermission: 'view kpi-billing-page',
                        linkText: 'KPI'
                    },
                    {
                        routeName: 'service-adjust-home',
                        requiredPermission: 'view service-adjust-page',
                        linkText: 'Serv. Adjust'
                    },
                    {
                        routeName: 'service-repair',
                        requiredPermission: 'view service-repair-page',
                        linkText: 'Repair'
                    },
                    {
                        routeName: 'shop-home',
                        requiredPermission: 'view service-shop-page',
                        linkText: 'Shop'
                    },
                    {
                        routeName: 'service-invoiced-home',
                        requiredPermission: 'view service-invoiced-page',
                        linkText: 'Serv. Invoiced'
                    },
                    {
                        routeName: 'service-invoicing-home',
                        requiredPermission: 'view service-invoicing-page',
                        linkText: 'Invoicing'
                    },
                ]
            },
            {
                name: 'Rental',
                appSection: 'rental',
                links: [
                    {
                        routeName: 'l4-home',
                        requiredPermission: 'view rental-checkin-page',
                        linkText: 'Checkin'
                    },
                    {
                        routeName: 'rental-status-home',
                        requiredPermission: 'view rental-status-page',
                        linkText: 'Status'
                    },
                    {
                        routeName: 'rental-redtag-home',
                        requiredPermission: 'view rental-redtag-page',
                        linkText: 'Redtag'
                    },
                    {
                        routeName: 'rental-invoicing',
                        requiredPermission: 'view rental-invoicing-page',
                        linkText: 'Invoicing'
                    },
                ]
            },
            {
                name: 'Sales',
                appSection: 'sales',
                links: [
                    {
                        routeName: 'crm-notes',
                        requiredPermission: 'view crm-notes-page',
                        linkText: 'CRMNotes'
                    },
                    {
                        routeName: 'crm-manager',
                        requiredPermission: 'view crm-manager-page',
                        linkText: 'CRMManager'
                    },
                    {
                        routeName: 'used-flyers',
                        requiredPermission: 'view used-flyers-page',
                        linkText: 'Used Quotes/Flyers'
                    },
                    {
                        routeName: 'liftnet-quote',
                        requiredPermission: 'view liftnet-quote-page',
                        linkText: 'LiftNet 2.0 Quote'
                    },
                    {
                        routeName: 'delivery-request',
                        requiredPermission: 'view delivery-request-page',
                        linkText: 'Delivery Request/Report of Sale'
                    },
                    {
                        routeName: 'fleet-report',
                        requiredPermission: 'view fleet-report-page',
                        linkText: 'Fleet Report'
                    },
                ]
            },
            {
                name: 'Parts',
                appSection: 'parts',
                links: [
                    {
                        routeName: 'parts-check',
                        requiredPermission: 'view parts-check-page',
                        linkText: 'Check'
                    },
                    {
                        routeName: 'parts-lines',
                        requiredPermission: 'view parts-lines-page',
                        linkText: 'Lines'
                    },
                    {
                        routeName: 'parts-claim',
                        requiredPermission: 'view parts-claim-page',
                        linkText: 'Claim'
                    },
                    {
                        routeName: 'parts-van',
                        requiredPermission: 'view parts-van-page',
                        linkText: 'Van'
                    },
                    {
                        routeName: 'parts-margin',
                        requiredPermission: 'view parts-margin-page',
                        linkText: 'Margin'
                    },
                    {
                        routeName: 'parts-requisition',
                        requiredPermission: 'view parts-req-page',
                        linkText: 'Req'
                    },
                ]
            },
            {
                name: 'Admin',
                appSection: 'admin',
                links: [
                    {
                        routeName: 'manage-users',
                        requiredPermission: 'view manage-users-page',
                        linkText: 'Manage Users'
                    },
                    {
                        routeName: 'manage-roles',
                        requiredPermission: 'view manage-roles-page',
                        linkText: 'Manage Roles'
                    },
                    {
                        routeName: 'manage-equipment-images',
                        requiredPermission: 'view manage-equipment-images-page',
                        linkText: 'Manage Equipment Images'
                    },
                    {
                        routeName: 'excel-reports',
                        requiredPermission: 'view excel-reports-page',
                        linkText: 'Excel Reports'
                    },
                    {
                        routeName: 'manage-technician-lists',
                        requiredPermission: 'view technician-lists-page',
                        linkText: 'Technician Lists'
                    },
                    {
                        routeName: 'manage-lookups',
                        requiredPermission: 'view manage-lookups-page',
                        linkText: 'Manage Lookups'
                    },
                ]
            },
        ],
    };
}

function getComputed(){
    return {
        visibleMenus(){
            const vm = this;
            return JSON.parse(JSON.stringify(vm.menus)).map(removeInaccessibleLinks).filter(matchMenusWithLinks);

            function removeInaccessibleLinks(menu){
                menu.links = menu.links.filter(userHasAccess);
                return menu;
                function userHasAccess(link){
                    return !link.requiredPermission || vm.$store.getters.userHasPermissionTo(link.requiredPermission);
                }
            }

            function matchMenusWithLinks(menu){
                return menu.links.length;
            }
        },
        activeAppSection(){
            const vm = this;
            return vm.$route.path.split('/')[1];
        }
    };
}

function getMethods(){
    return {
        displayErrorMessage,
        toggleMenu,
        closeAllMenus,
        openHoverMenu,
        closeHoverMenu,
        stopPropagation,
    };

    function displayErrorMessage(error){
        const vm = this;
        const isValidationError = error && error.status === 422 && error.data.errors;
        if(isValidationError){
            vm.validationErrors = error.data.errors;
        } else {
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function toggleMenu(menuName){
        const vm = this;
        if(vm.activeMenu === menuName){
            vm.activeMenu = '';
        } else {
            vm.activeMenu = menuName;
        }
    }

    function closeAllMenus(){
        const vm = this;
        vm.activeMenu = '';
    }

    function openHoverMenu(menuName){
        const vm = this;
        const isNavbarCollapsed = window.innerWidth < 992;
        if(!isNavbarCollapsed){
            vm.activeMenu = menuName;
        }
    }

    function closeHoverMenu(menuName){
        const vm = this;
        const isNavbarCollapsed = window.innerWidth < 992;
        const shouldCloseMenus = !isNavbarCollapsed && vm.activeMenu === menuName;
        if(shouldCloseMenus){
            vm.closeAllMenus();
        }
    }

    function stopPropagation(event){
        if(event){
            event.stopPropagation();
        }
    }
}
