import fileDownload from 'js-file-download';
import ServiceWorkordersQueryBuilder from 'vue_root/components/service-workorders-query-builder/service-workorders-query-builder';

export default {
    components: {
        ServiceWorkordersQueryBuilder
    },
    props: {},
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        workordersQueryObject: null,
        warrantyWorkorderNumber: null,
        validationErrors: {},
        errorMessages: [],
        isDownloadingSpreadsheet: false,
        isLoading: false,
    };
}

function getComputed(){
    return {};
}

function created(){}

function getMethods(){
    return {
        displayErrorMessage,
        setWorkordersQueryObject,
        getWorkorderWarranties,
        showWorkorders,
        exportResultsToExcel
    };

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            if(error.status === 422 && error.data.errors){
                vm.validationErrors = error.data.errors;
            } else {
                const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
                vm.errorMessages.push(errorMessage);
            }
        }
    }

    function setWorkordersQueryObject(payload){
        const vm = this;
        vm.validationErrors = {};
        vm.workordersQueryObject = payload;
    }

    function getWorkorderWarranties(workorderNumber){
        const vm = this;
        vm.warrantyWorkorderNumber = workorderNumber;
        vm.scrollTo('warrantyLines');
    }

    function showWorkorders(){
        const vm = this;
        vm.isLoading = false;
        vm.scrollTo('serviceWorkorders');
    }

    function exportResultsToExcel(){
        const vm = this;
        const query = {
            ...vm.workordersQueryObject,
            export_type: 'service_invoice'
        };
        vm.isDownloadingSpreadsheet = true;

        return Vue.appApi().authorized().invoicedWorkorders().exportServiceInvoicedWorkorders(query).then(downloadFile).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function downloadFile(response){
            fileDownload(response.data, `ServiceInvoice.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        }

        function resetLoadingState(){
            vm.isDownloadingSpreadsheet = false;
        }
    }
}
