export default {
    components: {},
    props: {},
    data,
    computed: getComputed(),
    watch: getWatchers(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        countySearchString: '',
        zipCodeSearchString: '',
        salesmen: [],
        isCountyAutocompleteDisabled: false,
        isZipCodeAutocompleteDisabled: false,
        isSearchingSalesmen: false,
        salesmenAutocompleteCancelToken: null,
        isVisible: false
    };
}

function getComputed(){
    return {};
}

function getWatchers(){
    return {
        countySearchString,
        zipCodeSearchString,
    };

    function countySearchString(value){
        const vm = this;
        if(vm.isCountyAutocompleteDisabled){
            vm.isCountyAutocompleteDisabled = false;
            return;
        }
        if(value){
            vm.zipCodeSearchString = '';
        }
        if(value.length >= 2){
            vm.searchSalesmen('county', value);
        } else {
            vm.cancelSalesmanAutocomplete();
        }
    }
    function zipCodeSearchString(value){
        const vm = this;
        if(vm.isZipCodeAutocompleteDisabled){
            vm.isZipCodeAutocompleteDisabled = false;
            return;
        }
        if(value){
            vm.countySearchString = '';
        }
        if(value.length >= 2){
            vm.searchSalesmen('zip_code', value);
        } else {
            vm.cancelSalesmanAutocomplete();
        }
    }
}
function created(){}

function getMethods(){
    return {
        displayErrorMessage,
        searchSalesmen,
        cancelSalesmanAutocomplete
    };

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function searchSalesmen(type, searchString){
        const vm = this;
        vm.isSearchingSalesmen = true;
        if(vm.salesmenAutocompleteCancelToken){
            vm.salesmenAutocompleteCancelToken.cancel('request cancelled');
        }
        const query = {
            search_type: type,
            search_string: searchString
        };
        const cancelToken = window.axios.CancelToken.source();
        vm.salesmenAutocompleteCancelToken = cancelToken;
        return Vue.appApi().authorized().salesmen().search(query, cancelToken.token).then(setSalesmen).catch(handleError);
        function setSalesmen({ data }){
            vm.isSearchingSalesmen = false;
            vm.salesmen = data;
        }
        function handleError(error){
            if(error.message !== 'request cancelled'){
                vm.isSearchingSalesmen = false;
                vm.displayErrorMessage(error);
            }
        }
    }

    function cancelSalesmanAutocomplete(){
        const vm = this;
        if(vm.salesmenAutocompleteCancelToken){
            vm.salesmenAutocompleteCancelToken.cancel('request cancelled');
        }
        vm.salesmen = [];
        vm.isSearchingSalesmen = false;
    }
}
