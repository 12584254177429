import JobCodePieChart from './job-code-pie-chart.controller.js';
import 'chartjs-plugin-datalabels';

export default {
    components: {
        JobCodePieChart
    },
    props: {
        query: {
            type: Object,
            required: true
        },
    },
    data,
    computed: getComputed(),
    watch: getWatchers(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        validationErrors: {},
        isLoadingEquipmentReport: false,
        equipment: null,
        selectedWorkorderNumber: null,
        jobCodeFilter: null,
        jobCodeTableColumns: [
            { key: 'search', label: '', sortable: false },
            { key: 'Doc_No', label: 'Doc No', sortable: true },
            { key: 'job_code', label: 'Job Code', sortable: true },
            { key: 'Line_No', label: 'Line No', sortable: true },
            { key: 'Description', label: 'Description', sortable: true },
            { key: 'Amount', label: 'Amount', sortable: true },
        ],
        equipmentTableFields: [
            { key: 'search', label: '', sortable: false },
            { key: 'No_', label: 'Object No', sortable: true },
            { key: 'Fleet Code', label: 'Fleet Code', sortable: true },
            { key: 'Year', label: 'Year', sortable: true },
            { key: 'Manufacturer Code', label: 'Man', sortable: true },
            { key: 'Equipment Model', label: 'Model', sortable: true },
            { key: 'Serial No_', label: 'Serial', sortable: true },
            { key: 'customer.Name', label: 'Customer', sortable: true },
            { key: 'age', label: 'Age (months)', sortable: true },
            { key: 'hours_diff', label: 'Hours', sortable: true },
            { key: 'avoidable_damage_cost', label: 'Avoidable Damage ($)', sortable: true },
            { key: 'tire_spend', label: 'Tire Spend ($)', sortable: true },
            { key: 'total_spend', label: 'Total Spend ($)', sortable: true },
            { key: 'avoidable_cph', label: 'Avoidable CPH', sortable: true },
            { key: 'tire_cph', label: 'Tire CPH', sortable: true },
            { key: 'total_cph', label: 'Cost per Running Hour ($/Hr)', sortable: true },
            { key: 'cph_benchmark_percentage', label: 'Status', sortable: true },
        ]
    };
}

function getComputed(){
    return {
        jobCodeTableItems(){
            const vm = this;
            return vm.workOrderLines.filter(({ job_code }) => !vm.jobCodeFilter || vm.jobCodeFilter.includes(job_code));
        },
        workOrderLines(){
            const vm = this;
            return (vm.equipment && vm.equipment.hul_work_order_lines) || [];
        }
    };
}

function getWatchers(){
    return {
        query: {
            handler: created,
            deep: true
        }
    };
}

function created(){
    const vm = this;
    vm.getFleetReport();
}

function getMethods(){
    return {
        displayErrorMessage,
        getFleetReport,
        displayWorkOrderDetails,
        handlePieChartClick
    };

    function displayErrorMessage(error){
        const vm = this;
        const isValidationError = error && error.status === 422 && error.data.errors;
        if(isValidationError){
            vm.validationErrors = error.data.errors;
        } else {
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function getFleetReport(){
        const vm = this;
        vm.isLoadingEquipmentReport = true;
        vm.equipment = null;
        const query = {
            query_type: vm.query.query_type,
            equipment: vm.query.equipment,
            start_date: vm.query.start_date,
            end_date: vm.query.end_date
        };
        return Vue.appApi().authorized().equipment().fleetReport(query).then(displayReportData).catch(emitQueryError).finally(resetLoadingState);

        function displayReportData(response){
            vm.equipment = response.data;
        }

        function emitQueryError(response){
            vm.$emit('query-error', response);
        }

        function resetLoadingState(){
            vm.isLoadingEquipmentReport = false;
        }
    }

    function displayWorkOrderDetails(workorderNumber){
        const vm = this;
        vm.selectedWorkorderNumber = workorderNumber;
    }

    function handlePieChartClick(relatedJobCodes){
        const vm = this;
        vm.jobCodeFilter = JSON.stringify(vm.jobCodeFilter) === JSON.stringify(relatedJobCodes) ? null : relatedJobCodes;
    }
}
