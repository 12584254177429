import Datepicker from 'vuejs-datepicker';
import RepairEstimatesTable from './estimates-table/estimates-table.vue';
import DocumentLinesTable from './document-lines-table/document-lines-table.vue';

export default {
    components: {
        Datepicker,
        RepairEstimatesTable,
        DocumentLinesTable
    },
    props: {},
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        validationErrors: {},
        isInitializingView: false,
        repairQuery: {
            beginning_date: null,
            ending_date: null,
            user: '*',
            manufacturer: '',
            model: '',
            repair: ''
        },
        userOptions: [],
        manufacturerOptions: [],
        isLoadingRepairEstimates: false,
        repairEstimates: null,
        selectedEstimate: null
    };
}

function getComputed(){
    return {};
}

function created(){
    const vm = this;
    vm.isInitializingView = true;
    vm.getSelectOptions().catch(vm.displayErrorMessage).finally(resetLoadingState);
    function resetLoadingState(){
        vm.isInitializingView = false;
    }
}

function getMethods(){
    return {
        displayErrorMessage,
        fetchRepairEstimates,
        getSelectOptions,
        displayDocumentRepairLines
    };

    function displayErrorMessage(error){
        const vm = this;
        const isValidationError = error.status === 422 && error.data.errors;
        if(isValidationError){
            vm.validationErrors = error.data.errors;
            vm.errorMessages = ['Validation Error. Check inputs and try again.'];
        } else {
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function fetchRepairEstimates(){
        const vm = this;
        vm.isLoadingRepairEstimates = true;
        vm.repairEstimates = null;
        vm.selectedEstimate = null;
        vm.errorMessages = [];
        vm.validationErrors = {};
        Vue.appApi().authorized().repair().indexRepairEstimates(vm.repairQuery).then(handleResponse).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function handleResponse(response){
            vm.repairEstimates = response.data;
            vm.scrollTo('repairEstimates');
        }
        function resetLoadingState(){
            vm.isLoadingRepairEstimates = false;
        }
    }

    function getSelectOptions(){
        const vm = this;
        return Promise.all([
            fetchUserOptions(),
            fetchManufacturerOptions()
        ]);

        function fetchUserOptions(){
            return Vue.appApi().authorized().repair().indexPreferredResources().then(setUserOptions);
            function setUserOptions(response){
                vm.userOptions = response.data;
            }
        }

        function fetchManufacturerOptions(){
            return Vue.appApi().authorized().repair().indexManufacturers().then(setManufacturerOptions);
            function setManufacturerOptions(response){
                vm.manufacturerOptions = response.data;
                vm.repairQuery.manufacturer = vm.manufacturerOptions[0];
            }
        }
    }

    function displayDocumentRepairLines(repairEstimate){
        const vm = this;
        vm.selectedEstimate = repairEstimate;
        vm.scrollTo('documentLinesTable');
    }
}
