import AccountUsersTable from './components/account-users-table/account-users-table.vue';
import AccountInvitesTable from './components/account-invites-table/account-invites-table.vue';

export default {
    components: {
        AccountUsersTable,
        AccountInvitesTable
    },
    props: {},
    data,
    computed: getComputed(),
    watch: getWatches(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        validationErrors: {},
        roleOptions: [],
        accountUserToEdit: null,
        accountUserToInvite: null,
        isInitializingView: false,
        isLoadingRoles: false,
        isUpdatingAccountUser: false,
        isInvitingUser: false
    };
}

function getComputed(){
    return {};
}

function getWatches(){
    return {
        'accountUserToEdit.mechanic_initials': transformToUpperCase
    };

    function transformToUpperCase(newValue){
        const vm = this;
        const shouldTransform = newValue && vm.accountUserToEdit && newValue.toUpperCase() !== vm.accountUserToEdit.mechanic_initials;
        if(shouldTransform){
            vm.accountUserToEdit.mechanic_initials = newValue.toUpperCase();
        }
    }
}

function created(){
    const vm = this;
    vm.isInitializingView = true;
    return vm.getRoles().then(resetLoadingState);

    function resetLoadingState(){
        vm.isInitializingView = false;
    }
}

function getMethods(){
    return {
        displayErrorMessage,
        getRoles,
        editAccountUser,
        cancelInviteUser,
        createNewInvite,
        cancelEditAccountUser,
        updateAccountUser,
        inviteUser,
    };

    function displayErrorMessage(error){
        const vm = this;
        const isValidationError = error && error.status === 422 && error.data.errors;
        if(isValidationError){
            vm.validationErrors = error.data.errors;
        } else {
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function getRoles(){
        const vm = this;
        vm.isLoadingRoles = true;
        return Vue.appApi().authorized().account().roles().indexRoles().then(setRoleOptions).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function setRoleOptions({ data }){
            vm.roleOptions = data.map(formatForSelectOptions);

            function formatForSelectOptions({ name }){
                return { value: name, text: name };
            }
        }

        function resetLoadingState(){
            vm.isLoadingRoles = false;
        }
    }

    function editAccountUser(accountUser){
        const vm = this;
        vm.accountUserToEdit = JSON.parse(JSON.stringify(accountUser));
    }

    function createNewInvite(){
        const vm = this;
        vm.accountUserToInvite = { all_role_names: [], email: '' };
    }

    function cancelEditAccountUser(){
        const vm = this;
        vm.accountUserToEdit = null;
        vm.validationErrors = {};
    }

    function cancelInviteUser(){
        const vm = this;
        vm.accountUserToInvite = null;
        vm.validationErrors = {};
    }

    function updateAccountUser(accountUser){
        const vm = this;
        vm.isUpdatingAccountUser = true;
        vm.validationErrors = {};
        return Vue.appApi().authorized().account().accountUsers(accountUser.id).updateAccountUser(accountUser)
            .then(updateAccountUserList)
            .then(vm.cancelEditAccountUser)
            .catch(vm.displayErrorMessage)
            .finally(resetLoadingState);

        function updateAccountUserList({ data: accountUser }){
            vm.$refs.accountUsersTable.updateAccountUser(accountUser);
        }
        function resetLoadingState(){
            vm.isUpdatingAccountUser = false;
        }
    }

    function inviteUser(accountUser){
        const vm = this;
        vm.isInvitingUser = true;
        vm.validationErrors = {};
        return Vue.appApi().authorized().account().accountUsers().createInvite(accountUser)
            .then(updateAccountInviteList)
            .then(vm.cancelInviteUser)
            .catch(vm.displayErrorMessage)
            .finally(resetLoadingState);
        function updateAccountInviteList({ data: accountInvite }){
            vm.$refs.accountInvitesTable.addInviteToList(accountInvite);
        }
        function resetLoadingState(){
            vm.isInvitingUser = false;
        }
    }
}
