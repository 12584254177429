export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        fleetCode: {
            type: String,
            required: true,
        },
        hiddenColumns: {
            type: Array,
            default: function(){
                return ['WO_Hour_Meter'];
            }
        }
    },
    data,
    computed: getComputed(),
    watch: getWatches(),
    created,
    methods: getMethods(),
};

function data(){
    return {
        isLoadingWorkorders: false,
        workOrders: [],
        columnDefinitions: [
            {
                key: 'search-icon',
                label: ''
            },
            {
                key: 'WO_Number',
                label: 'WO#',
                sortable: true
            },
            {
                key: 'Posting_Status',
                label: 'Status',
                sortable: true,
                formatter: (value) => {
                    let formattedValue = '';
                    if(value === 0){
                        formattedValue = 'OPEN';
                    } else if(value === 2){
                        formattedValue = 'CLOSED';
                    }
                    return formattedValue;
                }
            },
            {
                key: 'Service_Type',
                label: 'Type',
                sortable: true
            },
            {
                key: 'Description1',
                sortable: true
            },
            {
                key: 'Description2',
                sortable: true
            },
            {
                key: 'WO_Hour_Meter',
                sortable: true
            },
            {
                key: 'Starting_Date',
                label: 'Start Date',
                sortable: true
            },
            {
                key: 'Finishing_Date',
                label: 'Finishing Date',
                sortable: true
            },
        ]
    };
}

function getComputed(){
    return {
        tableColumns(){
            const vm = this;
            return vm.columnDefinitions.filter(({ key }) => !vm.hiddenColumns.includes(key));
        }
    };
}

function getWatches(){
    return {
        fleetCode,
    };

    function fleetCode(){
        const vm = this;
        vm.getEquipmentWorkorders();
    }
}

function created(){
    const vm = this;
    vm.getEquipmentWorkorders();
}

function getMethods(){
    return {
        getEquipmentWorkorders,
        getWorkorderLines,
    };

    function getEquipmentWorkorders(queryObject){
        const vm = this;
        vm.isLoadingWorkorders = true;
        return Vue.appApi().authorized().rentalCheckins(vm.fleetCode).getWorkorders({ per_page: 'all' }).then(setWorkorders).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function setWorkorders(response){
            vm.workOrders = response.data;
            const workOrderNumber = response.data[0] ? response.data[0].WO_Number : null;
            vm.$emit('loading-complete', workOrderNumber);
        }
        function resetLoadingState(){
            vm.isLoadingWorkorders = false;
        }
    }

    function getWorkorderLines(workorder){
        const vm = this;
        vm.$emit('search-lines', workorder.WO_Number);
    }
}
