import CrmManagerComponent from './crm-manager/crm-manager.vue';
import CrmNotesComponent from './crm-notes/crm-notes.vue';
import DeliveryRequestPage from './delivery_request/delivery_request';
import UsedFlyersComponent from './used-flyers/used-flyers.vue';
import LiftNetQuoteView from './liftnet-quote/liftnet-quote';
import FleetReportPage from './fleet-report/fleet-report';

export default {
    path: '/sales',
    component: { template: `<router-view></router-view>` },
    meta: {},
    children: [
        {
            path: 'crm-manager',
            name: 'crm-manager',
            component: CrmManagerComponent,
            meta: {
                title: 'CRM KPI',
                permissions: ['view crm-manager-page']
            }
        },
        {
            path: 'crm-notes',
            name: 'crm-notes',
            component: CrmNotesComponent,
            meta: {
                title: 'CRM Sales Dashboard',
                permissions: ['view crm-notes-page']
            }
        },
        {
            path: 'delivery-request',
            name: 'delivery-request',
            component: DeliveryRequestPage,
            meta: {
                title: 'Delivery Request',
                permissions: ['view delivery-request-page']
            }
        },
        {
            path: 'used-flyers',
            name: 'used-flyers',
            component: UsedFlyersComponent,
            meta: {
                title: 'Used Equipment Deals',
                permissions: ['view used-flyers-page']
            }
        },
        {
            path: 'liftnet-quote',
            name: 'liftnet-quote',
            component: LiftNetQuoteView,
            meta: {
                title: 'LiftNet 2.0 Quotes',
                permissions: ['view liftnet-quote-page']
            }
        },
        {
            path: 'fleet-report',
            name: 'fleet-report',
            component: FleetReportPage,
            meta: {
                title: 'Fleet Report',
                permissions: ['view fleet-report-page']
            }
        },
    ]
};
