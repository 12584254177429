import MCFAConfigurator from './MCFA.Configurator.Integration.js';

export default {
    components: {},
    props: {},
    data,
    computed: getComputed(),
    watch: getWatches(),
    created,
    beforeRouteUpdate,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        caseSearchString: '',
        isSearchingCases: false,
        isManuallySearchingCases: false,
        isCaseAutocompleteDisabled: false,
        isMCFAConfigRunning: false,
        caseAutocompleteCancelToken: null,
        caseAutoCompleteSource: [],
        liftnetXmlData: '',
        mcfaCredentials: {
            salespersonCode: '',
            username: '',
            password: ''
        },
        isCaseNumberFound: false,
        validationErrors: {},
        mcfaQuoteId: null,
        isPrintModalDisplayed: false,
        printModalSrc: '',
        isQuoteActive: false,
        mcfaService: null,
        isActivatingQuote: false,
        isPreparingNutshellData: false,
        isUploadingFile: false,
        nutshellLeadId: null,
        source: 'erp'
    };
}

function getComputed(){
    return {
        loadingOverlayText(){
            const vm = this;
            let loadingText = '';
            if(vm.isManuallySearchingCases){
                loadingText = 'Searching cases...';
            } else if(vm.isMCFAConfigRunning){
                loadingText = 'MCFA Configurator is running...';
            } else if(vm.isActivatingQuote){
                loadingText = 'Activating quote...';
            } else if(vm.isPreparingNutshellData){
                loadingText = 'Pulling data from Nutshell...';
            } else if(vm.isUploadingFile){
                loadingText = 'Uploading file...';
            }
            return loadingText;
        },
        isLoadingOverlayDisplayed(){
            const vm = this;
            return vm.isManuallySearchingCases ||
                vm.isMCFAConfigRunning ||
                vm.isActivatingQuote ||
                vm.isUploadingFile ||
                vm.isPreparingNutshellData;
        }
    };
}

function getWatches(){
    return {
        caseSearchString,
        'mcfaCredentials.salespersonCode': watchSalespersonCode
    };

    function caseSearchString(searchString){
        const vm = this;
        if(vm.isCaseAutocompleteDisabled){
            vm.isCaseAutocompleteDisabled = false;
            vm.caseAutoCompleteSource = [];
            return;
        }
        const shouldTransform = searchString.toUpperCase() !== vm.caseSearchString;
        if(shouldTransform){
            vm.caseSearchString = searchString.toUpperCase();
        }
        if(searchString.length >= 2){
            vm.autocompleteCaseSearch(searchString);
        } else {
            vm.cancelCaseAutocomplete();
        }
    }

    function watchSalespersonCode(newValue){
        const vm = this;
        const shouldTransform = newValue.toUpperCase() !== vm.mcfaCredentials.salespersonCode;
        if(shouldTransform){
            vm.mcfaCredentials.salespersonCode = newValue.toUpperCase();
        }
    }
}

function created(){
    const vm = this;
    vm.initializeData(vm.$route.query.nutshell_id || '');
}
function beforeRouteUpdate(to, from, next){
    const vm = this;
    vm.initializeData(to.query.nutshell_id || '');
    next();
}

function getMethods(){
    return {
        displayErrorMessage,
        initializeData,
        searchCaseAndPrepareLiftnetData,
        autocompleteCaseSearch,
        cancelCaseAutocomplete,
        prepareLiftnetData,
        beginSubmissionToLiftnet,
        printQuote,
        downloadWorksheet,
        activateQuote,
        createUpdatePortalQuote,
        prepareLiftnetDataFromNutshell,
        uploadWorksheetToNutshell,
    };

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function initializeData(nutshellId = ''){
        const vm = this;
        const initialData = data();
        Object.keys(initialData).forEach(resetVMData);

        if(nutshellId){
            validateNutshellId();
        } else {
            vm.source = 'erp';
            vm.nutshellLeadId = null;
        }

        function resetVMData(key){
            vm[key] = initialData[key];
        }

        function validateNutshellId(){
            const nutshellIdType = nutshellId.split('-')[1];
            const isNutshellIdTypeSupported = nutshellIdType === 'leads';
            if(!isNutshellIdTypeSupported){
                const errorMessage = `Cannot create used flyers for Nutshell ${nutshellIdType}, currently only leads are supported.`;
                return vm.$bvModal.msgBoxConfirm(errorMessage, {
                    title: 'Unsupported Nutshell Integration',
                    footerClass: 'justify-content-between',
                    cancelTitle: 'Return to Dashboard',
                    okTitle: 'Proceed without Nutshell',
                    noCloseOnBackdrop: true,
                    centered: true
                }).then(resetData);
            } else {
                vm.source = 'nutshell';
                vm.nutshellLeadId = parseInt(vm.$route.query.nutshell_id);
                vm.prepareLiftnetDataFromNutshell();
                return Promise.resolve();
            }

            function resetData(resetForm){
                if(resetForm){
                    vm.$router.push({ name: vm.$route.name });
                } else {
                    vm.$router.push({ name: 'dashboard' });
                }
            }
        }
    }

    function searchCaseAndPrepareLiftnetData(){
        const vm = this;
        const query = {
            search_string: vm.caseSearchString,
            search_type: 'case_no'
        };
        vm.isCaseNumberFound = false;
        vm.errorMessages = [];
        vm.isManuallySearchingCases = true;
        return Vue.appApi().authorized().cases().casesNew().searchHulCasesNew(query).then(prepareLiftnetData).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function prepareLiftnetData({ data }){
            if(data.length){
                vm.prepareLiftnetData(data[0]);
            } else {
                vm.displayErrorMessage(`Case not found with case_no: ${vm.caseSearchString}`);
            }
        }

        function resetLoadingState(){
            vm.isManuallySearchingCases = false;
        }
    }

    function autocompleteCaseSearch(searchString){
        const vm = this;
        vm.isSearchingCases = true;
        if(vm.caseAutocompleteCancelToken){
            vm.caseAutocompleteCancelToken.cancel('request cancelled');
        }
        const cancelToken = window.axios.CancelToken.source();
        vm.caseAutocompleteCancelToken = cancelToken;
        const query = {
            search_string: searchString,
            search_type: 'company_name'
        };
        return Vue.appApi().authorized().cases().casesNew().searchHulCasesNew(query, cancelToken.token).then(displaySearchResults).catch(handleError);

        function displaySearchResults(response){
            vm.caseAutocompleteCancelToken = null;
            vm.caseAutoCompleteSource = response.data;
            vm.isSearchingCases = false;
        }
        function handleError(error){
            if(error.message !== 'request cancelled'){
                vm.isSearchingCases = false;
                vm.displayErrorMessage(error);
            }
        }
    }

    function cancelCaseAutocomplete(){
        const vm = this;
        if(vm.caseAutocompleteCancelToken){
            vm.caseAutocompleteCancelToken.cancel('request cancelled');
        }
        vm.caseAutoCompleteSource = [];
        vm.isSearchingCases = false;
    }

    function prepareLiftnetData(hulCase){
        const vm = this;
        vm.caseSearchString = hulCase.case_no;
        vm.isCaseNumberFound = true;
        Vue.nextTick(vm.cancelCaseAutocomplete);
        vm.mcfaQuoteId = null;
        const expectedSalesDate = hulCase.expected_sales_date && hulCase.expected_sales_date !== '01/01/1753' ? hulCase.expected_sales_date : '';
        vm.liftnetXmlData = `<Quote>
    <CustomerInformation>
        <QuoteStatus>Open</QuoteStatus>
        <CustomerName>${replaceSpecialCharacters(hulCase.company_name)}</CustomerName>
        <CustomerNumber>${replaceSpecialCharacters(hulCase.customer_no || '')}</CustomerNumber>
        <CustomerContact>${replaceSpecialCharacters(hulCase.Name || '')}</CustomerContact>
        <CustomerAddress>${replaceSpecialCharacters(hulCase.Address || '')}</CustomerAddress>
        <CustomerCity>${replaceSpecialCharacters(hulCase.City || '')}</CustomerCity>
        <CustomerState>${replaceSpecialCharacters(hulCase.state || '')}</CustomerState>
        <CustomerZip>${replaceSpecialCharacters(hulCase.zip || '')}</CustomerZip>
        <CustomerSICCode>${replaceSpecialCharacters(hulCase.SIC || '')}</CustomerSICCode>
        <CustomerPhone>${replaceSpecialCharacters(hulCase.contact_phone || '')}</CustomerPhone>
        <CustomerFax></CustomerFax>
        <CustomerEmail></CustomerEmail>
        <QuoteNumber></QuoteNumber>
        <SellProbability>${replaceSpecialCharacters(hulCase.sales_stage_percent || '')}%</SellProbability>
        <SICCode></SICCode>
        <EstimatedDOD></EstimatedDOD>
        <FOB></FOB>
        <RequestedShipDate></RequestedShipDate>
    </CustomerInformation>
    <ShipToInformation>
        <EstSaleDate>${replaceSpecialCharacters(expectedSalesDate)}</EstSaleDate>
    </ShipToInformation>
    <Configuration>
        <Manufacturer></Manufacturer>
        <ModelName></ModelName>
        <TruckClass></TruckClass>
        <ModelOrderCode></ModelOrderCode>
        <ModelQuantity></ModelQuantity>
        <ModelListPriceEach></ModelListPriceEach>
    </Configuration>
</Quote>
`;
        function replaceSpecialCharacters(xmlValue){
            if(typeof xmlValue !== 'string'){
                xmlValue = String(xmlValue);
            }
            return xmlValue.replace(/\&/g, 'and').replace(/[\\\|\~\`\[\]\{\}]/g, '');
        }
    }

    function beginSubmissionToLiftnet(){
        const vm = this;
        const hasValidCredentials = validateCredentials();
        vm.mcfaQuoteId = null;
        vm.errorMessages = [];
        if(hasValidCredentials){
            vm.isMCFAConfigRunning = true;
            vm.isQuoteActive = false;
            vm.liftnetXmlData = vm.liftnetXmlData.replace('<QuoteStatus>Active</QuoteStatus>', '<QuoteStatus>Open</QuoteStatus>');
            vm.createUpdatePortalQuote().then(launchConfigurator).catch(vm.displayErrorMessage).finally(resetLoadingState);
        }

        function validateCredentials(){
            vm.validationErrors = {};
            const validatedFields = ['username', 'password', 'salespersonCode'];
            validatedFields.forEach(fieldName => {
                if(!vm.mcfaCredentials[fieldName]){
                    vm.validationErrors[fieldName] = [`The ${fieldName} field is required.`];
                }
            });
            return !Object.values(vm.validationErrors).length;
        }

        function launchConfigurator(){
            return new Promise(function(resolve, reject){
                vm.mcfaService.LaunchConfig(vm.mcfaCredentials.username, vm.mcfaCredentials.password, vm.mcfaQuoteId, 'quote', resolve);
            });
        }

        function resetLoadingState(){
            vm.isMCFAConfigRunning = false;
        }
    }

    function printQuote(){
        const vm = this;
        vm.activateQuote().then(openPrintModal);
        function openPrintModal(){
            vm.printModalSrc = `https://liftnet2.mcfadealers.com/Document/PrintSettings/?id=${vm.mcfaQuoteId}&v=1`;
            vm.isPrintModalDisplayed = true;
        }
    }

    function downloadWorksheet(){
        const vm = this;
        vm.activateQuote().then(openDownloadWindow);
        function openDownloadWindow(){
            const downloadUrl = `https://liftnet2.mcfadealers.com/Document/ExecuteWordMerge?idmapping=quoteid&wordtemplate=DealerQuoteWorksheet.docx&id=${vm.mcfaQuoteId}&excel=false&pdf=false`;
            window.open(downloadUrl);
        }
    }

    function activateQuote(){
        const vm = this;
        let activationPromise = Promise.resolve();
        if(!vm.isQuoteActive){
            vm.isActivatingQuote = true;
            vm.liftnetXmlData = vm.liftnetXmlData.replace('<QuoteStatus>Open</QuoteStatus>', '<QuoteStatus>Active</QuoteStatus>');
            activationPromise = vm.createUpdatePortalQuote().then(updateActiveStatus).catch(vm.displayErrorMessage).finally(resetLoadingState);
        }
        return activationPromise;

        function updateActiveStatus(){
            vm.isQuoteActive = true;
        }
        function resetLoadingState(){
            vm.isActivatingQuote = false;
        }
    }

    function createUpdatePortalQuote(){
        const vm = this;
        return new Promise(function(resolve, reject){
            vm.mcfaService = new MCFAConfigurator.CreateMCFAObject('https://liftnet2.mcfadealers.com');
            vm.mcfaService.CreateUpdatePortalQuote(
                vm.mcfaCredentials.username,
                vm.mcfaCredentials.password,
                vm.caseSearchString,
                vm.liftnetXmlData,
                vm.mcfaCredentials.salespersonCode,
                'dev',
                '53200',
                '',
                handleMCFAPortalResponse
            );

            function handleMCFAPortalResponse(response){
                const error = typeof response === 'string' && response.startsWith('.Error: ');
                if(error){
                    reject(response);
                } else {
                    vm.mcfaQuoteId = response;
                    resolve(response);
                }
            }
        });
    }

    function prepareLiftnetDataFromNutshell(){
        const vm = this;
        vm.isPreparingNutshellData = true;

        return Vue.appApi().authorized().sales().nutshell().prepareLiftnetData({ lead_id: vm.nutshellLeadId }).then(parseForLiftnet).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function parseForLiftnet(response){
            vm.prepareLiftnetData(response.data);
        }
        function resetLoadingState(){
            vm.isPreparingNutshellData = false;
        }
    }

    function uploadWorksheetToNutshell(){
        const vm = this;
        vm.activateQuote().then(sendFileUrlForUpload);
        function sendFileUrlForUpload(){
            const downloadUrl = `https://liftnet2.mcfadealers.com/Document/ExecuteWordMerge?idmapping=quoteid&wordtemplate=DealerQuoteWorksheet.docx&id=${vm.mcfaQuoteId}&excel=false&pdf=false`;
            const query = {
                lead_id: vm.nutshellLeadId,
                file_ext: '.docx',
                file_url: downloadUrl,
                file_name: 'DealerQuoteWorksheet.docx'
            };
            vm.isUploadingFile = true;
            return Vue.appApi().authorized().sales().nutshell().uploadFileFromUrl(query).catch(vm.displayErrorMessage).finally(resetLoadingState);

            function resetLoadingState(){
                vm.isUploadingFile = false;
            }
        }
    }
}
