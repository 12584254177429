export default {
    components: {},
    props: {},
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        isLoadingSalesOrderLines: false,
        salesOrderLines: null,
        tableColumns: [
            {
                key: 'Doc_No',
                sortable: true,
                label: 'Doc_No'
            },
            {
                key: 'Type',
                sortable: true,
            },
            {
                key: 'Line_No',
                sortable: true,
            },
            {
                key: 'Description',
                sortable: true,
            },
            {
                key: 'Bin_Code',
                sortable: true,
            },
            {
                key: 'Qty_to_invoice',
                sortable: true,
                label: 'Qty_to_invoice'
            },
            {
                key: 'Qty_to_use',
                sortable: true,
            },
            {
                key: 'Qty_used',
                sortable: true,
            },
            {
                key: 'Quantity',
                sortable: true,
            },
            {
                key: 'Qty_to_ship',
                sortable: true,
            },
            {
                key: 'Shipment_Date',
                sortable: true,
            },
            {
                key: 'Resp_center',
                sortable: true,
            },
            {
                key: 'Requisition_Code',
                sortable: true,
                label: 'Req Code'
            },
            {
                key: 'Normal_Order_Purchase_No',
                sortable: true,
                label: 'Normal Order PO #'
            },
            {
                key: 'Location_Code',
                sortable: true,
                label: 'Location'
            },
            {
                key: 'Service_Type',
                sortable: true,
            },
            {
                key: 'Purchase_Doc_No',
                sortable: true,
            },
            {
                key: 'Inventory',
                sortable: true,
                label: 'Qty Avail Inventory'
            },
            {
                key: 'Unit_Price',
                sortable: true,
            },
            {
                key: 'Outstanding_Amount',
                sortable: true,
            },
            {
                key: 'Profit_Percent',
                sortable: true,
                label: 'Profit %'
            }
        ]
    };
}

function getComputed(){
    return {};
}

function created(){}

function getMethods(){
    return {
        displayErrorMessage,
        getSalesOrderLines,
        setLoadingState
    };

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const isValidationError = error.status === 422 && error.data.errors;
            if(isValidationError){
                vm.errorMessages = [...Object.values(error.data.errors)].flat();
            } else {
                const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
                vm.errorMessages.push(errorMessage);
            }
        }
    }

    function getSalesOrderLines(query){
        const vm = this;
        vm.setLoadingState(true);
        vm.errorMessages = [];
        Vue.appApi().authorized().salesOrderLines().querySalesOrderLines(query).then(setSalesOrderLines).catch(vm.displayErrorMessage).finally(resetLoadingState);
        function setSalesOrderLines(response){
            vm.salesOrderLines = response.data;
        }
        function resetLoadingState(){
            vm.setLoadingState(false);
        }
    }

    function setLoadingState(loadingState){
        const vm = this;
        vm.isLoadingSalesOrderLines = loadingState;
        vm.$emit('loading', loadingState);
    }
}
