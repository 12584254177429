export default {
    props: {
        customer: {
            type: Object,
            required: false,
        },
        meterReadings: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            default: 'Based On Previous Hours - Previous Customer Info'
        }
    },
};
