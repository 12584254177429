export default {
    props: {
        workorder: {
            type: Object,
            required: true,
        },
        equipment: {
            type: Object,
            required: true,
        },
    },

    methods: getMethods(),
};

function getMethods(){
    return {
        getPostingStatusText,
    };

    function getPostingStatusText(workorder){
        if(workorder.Posting_Status === 0){
            return 'OPEN';
        }
        if(workorder.Posting_Status === 2){
            return 'CLOSED';
        }
        return '';
    }
}

