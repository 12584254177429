import ClientPaginationMixin from 'vue_root/mixins/client-pagination-mixin';

export default {
    components: {},
    mixins: [
        ClientPaginationMixin
    ],
    inheritAttrs: false,
    props: {
        tableItems: {
            type: Array,
            required: true
        },
        defaultPerPage: {
            type: Number,
            default: 10
        },
        title: {
            type: String,
            default: ''
        },
        titleClass: {
            type: String,
            default: ''
        },
        headerClass: {
            type: String,
            default: ''
        },
        searchable: {
            type: Boolean,
            default: true
        }
    },
    data,
    computed: getComputed(),
    watch: getWatches(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        validationErrors: {},
        slotsWithDefaultContent: ['emptyfiltered', 'empty', 'table-busy']
    };
}

function getComputed(){
    return {
        tableItemsTotal,
        dynamicSlots
    };
    function tableItemsTotal(){
        const vm = this;
        return vm.tableItems.length;
    }
    function dynamicSlots(){
        const vm = this;
        const slotNames = Object.keys(vm.$slots);
        return slotNames.filter(slotName => !vm.slotsWithDefaultContent.includes(slotName));
    }
}

function getWatches(){
    return {
        tableItemsTotal,
        tableItems,
    };
    function tableItemsTotal(value){
        const vm = this;
        vm.updatePaginationTotal(null, value);
    }
    function tableItems(){
        const vm = this;
        vm.resetPagination();
    }
}

function created(){
    const vm = this;
    vm.paginationOptions.per_page = vm.defaultPerPage;
    vm.updatePaginationTotal(null, vm.tableItems.length);
}

function getMethods(){
    return {
        displayErrorMessage
    };

    function displayErrorMessage(error){
        const vm = this;
        const isValidationError = error && error.status === 422 && error.data.errors;
        if(isValidationError){
            vm.validationErrors = error.data.errors;
        } else {
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }
}
