export default {
    components: {},
    props: {
        repairEstimates: {
            type: Array,
            required: true
        }
    },
    data,
    computed: getComputed(),
    watch: getWatchers(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        tableColumns: [
            {
                key: 'details-link',
                label: '',
                tdClass: 'text-center'
            },
            {
                key: 'Document_no',
                sortable: true,
                label: 'Document_No'
            },
            {
                key: 'From_quote',
                sortable: true,
                label: 'Quote?'
            },
            {
                key: 'Serial',
                sortable: true,
            },
            {
                key: 'Man',
                sortable: true,
                label: 'Make'
            },
            {
                key: 'Model',
                sortable: true,
                tdClass: 'font-weight-bold'
            },
            {
                key: 'Description',
                sortable: true,
            },
            {
                key: 'Preferred_Resource',
                sortable: true,
                label: 'Resource'
            },
            {
                key: 'Start_Date',
                sortable: true,
            },
            {
                key: 'Service_type',
                sortable: true,
            },
            {
                key: 'total_labor_hours',
                sortable: true,
                label: 'Total WO Labor Hours',
                sortByFormatted: true,
                formatter: (value, key, repairEstimate) => {
                    const vm = this;
                    const totalLaborHours = repairEstimate.document_repair_lines.filter(({ Type }) => Type === 2)
                        .reduce(vm.sumQuantity, new Decimal(0))
                        .toDecimalPlaces(2)
                        .toFixed(2);
                    return totalLaborHours;
                }
            },
            {
                key: 'total_labor_amount',
                sortable: true,
                label: 'Total WO Labor',
                sortByFormatted: true,
                formatter: (value, key, repairEstimate) => {
                    const vm = this;
                    return repairEstimate.document_repair_lines.filter(({ Type }) => Type === 2)
                        .reduce(vm.sumAmount, new Decimal(0))
                        .toDecimalPlaces(2)
                        .toFixed(2);
                }
            },
            {
                key: 'total_quoted_labor_hours',
                sortable: true,
                label: 'Total WOQ Labor Hours',
                sortByFormatted: true,
                formatter: (value, key, repairEstimate) => {
                    const vm = this;
                    return repairEstimate.document_repair_lines.concat(repairEstimate.quote_repair_lines)
                        .filter(({ Type }) => Type === 9)
                        .reduce(vm.sumQuantity, new Decimal(0))
                        .toDecimalPlaces(2)
                        .toFixed(2);
                }
            },
            {
                key: 'total_quoted_labor_amount',
                sortable: true,
                label: 'Total WOQ Labor',
                sortByFormatted: true,
                formatter: (value, key, repairEstimate) => {
                    const vm = this;
                    return repairEstimate.document_repair_lines.concat(repairEstimate.quote_repair_lines)
                        .filter(({ Type }) => Type === 9)
                        .reduce(vm.sumAmount, new Decimal(0))
                        .toDecimalPlaces(2)
                        .toFixed(2);
                }
            },
            {
                key: 'total_quoted_parts_amount',
                sortable: true,
                label: 'Total WOQ Parts',
                sortByFormatted: true,
                formatter: (value, key, repairEstimate) => {
                    const vm = this;
                    return repairEstimate.quote_repair_lines
                        .filter(({ Type }) => Type === 1)
                        .reduce(vm.sumAmount, new Decimal(0))
                        .toDecimalPlaces(2)
                        .toFixed(2);
                }
            },
            {
                key: 'total_parts_amount',
                sortable: true,
                label: 'Total Parts',
                sortByFormatted: true,
                formatter: (value, key, repairEstimate) => {
                    const vm = this;
                    return repairEstimate.document_repair_lines
                        .filter(({ Type }) => Type === 1)
                        .reduce(vm.sumAmount, new Decimal(0))
                        .toDecimalPlaces(2)
                        .toFixed(2);
                }
            },
            {
                key: 'total_charge_amount',
                sortable: true,
                label: 'Total Charges',
                sortByFormatted: true,
                formatter: (value, key, repairEstimate) => {
                    const vm = this;
                    return repairEstimate.document_repair_lines
                        .filter(({ Type }) => Type === 3)
                        .reduce(vm.sumAmount, new Decimal(0))
                        .toDecimalPlaces(2)
                        .toFixed(2);
                }
            },
            {
                key: 'total_job_code_amount',
                sortable: true,
                label: 'Total Job Code',
                sortByFormatted: true,
                formatter: (value, key, repairEstimate) => {
                    const vm = this;
                    return repairEstimate.document_repair_lines
                        .filter(({ Type }) => Type === 5)
                        .reduce(vm.sumAmount, new Decimal(0))
                        .toDecimalPlaces(2)
                        .toFixed(2);
                }
            },
            {
                key: 'total_purchase_amount',
                sortable: true,
                label: 'Total Purchase',
                sortByFormatted: true,
                formatter: (value, key, repairEstimate) => {
                    const vm = this;
                    return repairEstimate.document_repair_lines
                        .filter(({ Type }) => Type === 6)
                        .reduce(vm.sumAmount, new Decimal(0))
                        .toDecimalPlaces(2)
                        .toFixed(2);
                }
            },
        ]
    };
}

function getComputed(){
    return {};
}

function getWatchers(){
    return {};
}

function created(){}

function getMethods(){
    return {
        displayErrorMessage,
        sumQuantity,
        sumAmount,
        displayDocumentRepairLines
    };

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function sumQuantity(accumulator, repairLine){
        return accumulator.plus(repairLine.Quantity);
    }

    function sumAmount(accumulator, repairLine){
        return accumulator.plus(repairLine.Amount);
    }

    function displayDocumentRepairLines(repairEstimate){
        const vm = this;
        vm.$emit('display-document-lines', repairEstimate);
    }
}
