export default {
    components: {},
    props: {},
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        validationErrors: {},
        isLoadingLookups: false,
        serviceNoteReasons: []
    };
}

function getComputed(){
    return {};
}

function created(){
    const vm = this;
    vm.fetchServiceNoteReasons();
}

function getMethods(){
    return {
        displayErrorMessage,
        fetchServiceNoteReasons,
        addReason,
        deleteReason,
        saveReason,
    };

    function displayErrorMessage(error){
        const vm = this;
        const isValidationError = error && error.status === 422 && error.data.errors;
        if(isValidationError){
            vm.validationErrors = error.data.errors;
        } else {
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function fetchServiceNoteReasons(){
        const vm = this;
        vm.isLoadingLookups = true;
        return Vue.appApi().authorized().lookups().getLookupsByType('service_note_reason')
            .then(setReasons)
            .catch(vm.displayErrorMessage)
            .finally(resetLoadingState);
        function setReasons(response){
            vm.serviceNoteReasons = response.data.map(addDisplayProperties);
            function addDisplayProperties(reason){
                reason.isDirty = false;
                reason.isSaving = false;
                reason.isDeleting = false;
                return reason;
            }
        }
        function resetLoadingState(){
            vm.isLoadingLookups = false;
        }
    }

    function addReason(reason){
        const vm = this;
        vm.serviceNoteReasons.push({
            meta_data: { value: '' },
            display_order: vm.serviceNoteReasons.length,
            type_slug: 'service_note_reason',
            isDirty: true,
            isSaving: false,
            isDeleting: false
        });
    }

    function deleteReason(reason, index){
        const vm = this;
        if(!reason.id){
            vm.serviceNoteReasons.splice(index, 1);
        } else {
            const message = 'Please confirm you want to delete this record.';
            const options = {
                hideHeader: true,
                noCloseOnEsc: true,
                noCloseOnBackdrop: true,
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Delete!',
                cancelTitle: 'Cancel',
                footerClass: 'p-2',
                centered: true
            };

            vm.$bvModal.msgBoxConfirm(message, options).then(handleConfirmation);
        }

        function handleConfirmation(isConfirmed){
            if(isConfirmed){
                reason.isDeleting = true;
                Vue.appApi().authorized().lookups(reason.id).deleteLookup().then(removeLocalCopy).catch(vm.displayErrorMessage).finally(resetLoadingState);
            }

            function removeLocalCopy(){
                const reasonIndex = vm.serviceNoteReasons.findIndex(({ id }) => id === reason.id);
                if(reasonIndex >= 0){
                    vm.serviceNoteReasons.splice(reasonIndex, 1);
                }
            }
            function resetLoadingState(){
                reason.isDeleting = false;
            }
        }
    }

    function saveReason(reason){
        const vm = this;
        reason.isSaving = true;
        const payload = JSON.parse(JSON.stringify(reason));
        payload.meta_data = JSON.stringify(payload.meta_data);
        let savePromise = Promise.resolve();
        if(reason.id){
            savePromise = Vue.appApi().authorized().lookups(reason.id).updateLookup(payload);
        } else {
            savePromise = Vue.appApi().authorized().lookups().createLookup(payload);
        }
        return savePromise.then(updateLocalCopy).catch(vm.displayErrorMessage).finally(resetLoadingState);
        function updateLocalCopy(response){
            reason.isDirty = false;
            Object.assign(reason, response.data);
        }
        function resetLoadingState(){
            reason.isSaving = false;
        }
    }
}
