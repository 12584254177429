export default {
    components: {},
    props: {},
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        isLoadingClosedClaims: false,
        closedClaims: null,
        tableColumns: [
            {
                key: 'No',
                sortable: true,
                label: 'No',
            },
            {
                key: 'Status',
                sortable: true,
                label: 'Status',
            },
            {
                key: 'Resp_Center',
                sortable: true,
                label: 'Resp Center',
            },
            {
                key: 'Vendor_No',
                sortable: true,
                label: 'Vendor No',
            },
            {
                key: 'Vendor_Name',
                sortable: true,
                label: 'Vendor Name',
            },
            {
                key: 'Description',
                sortable: true,
                label: 'Description',
            },
            {
                key: 'Claim_Manager',
                sortable: true,
                label: 'Claim Manager',
            },
            {
                key: 'Vendor_Claim_Number',
                sortable: true,
                label: 'Vendor Claim Number',
            },
            {
                key: 'Request_Date',
                sortable: true,
                label: 'Request Date',
            },
            {
                key: 'Claim_Amount_Submitted',
                sortable: true,
                label: 'Claim Amount Submitted',
            },
            {
                key: 'Claim_Type',
                sortable: true,
                label: 'Claim Type',
            },
            {
                key: 'VAT_Posting_Group',
                sortable: true,
                label: 'VAT Posting Group',
            },
            {
                key: 'Tax_Group',
                sortable: true,
                label: 'Tax Group Code',
            },
        ]
    };
}

function getComputed(){
    return {};
}

function created(){}

function getMethods(){
    return {
        displayErrorMessage,
        getClosedClaims,
    };

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const isValidationError = error.status === 422 && error.data.errors;
            if(isValidationError){
                vm.errorMessages = [...Object.values(error.data.errors)].flat();
            } else {
                const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
                vm.errorMessages.push(errorMessage);
            }
        }
    }

    function getClosedClaims(){
        const vm = this;
        vm.isLoadingClosedClaims = true;
        vm.errorMessages = [];
        Vue.appApi().authorized().claims().indexClosedClaims()
            .then(setClosedClaims)
            .catch(vm.displayErrorMessage)
            .finally(resetLoadingState);
        function setClosedClaims(response){
            vm.closedClaims = response.data;
        }
        function resetLoadingState(){
            vm.isLoadingClosedClaims = false;
        }
    }
}
