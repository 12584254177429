export default {
    props: {
        workorders: {
            type: Array,
            required: true
        },
        mechanicId: {
            type: String,
            required: true
        },
        isLoadingWorkorders: {
            type: Boolean,
            required: true
        },
        mutableTechNotes: {
            type: Boolean,
            default: false
        },
        mutableDispatchNotes: {
            type: Boolean,
            default: false
        },
        showMagnifierLinkColumn: {
            type: Boolean,
            default: false
        }
    },
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        statusOptions: [
            { value: '', text: '-' },
            { value: 'C', text: 'C' },
            { value: 'W', text: 'W' },
            { value: 'S', text: 'S' },
        ],
        defaultWorkorderTableColumns: [
            {
                key: 'WO_Number',
                label: 'WO#',
                sortable: true
            },
            {
                key: 'WO_Status',
                label: 'Status',
                sortable: true
            },
            {
                key: 'overdue',
                label: 'Overdue',
                sortable: true,
                sortByFormatted: true,
                filterByFormatted: true,
                formatter: function(value, key, workorder){
                    let daysOverdue = '';
                    if(workorder && workorder.lasttimesheetdate){
                        const today = Vue.moment.utc();
                        const lastTimesheetDate = Vue.moment.utc(workorder.lasttimesheetdate);
                        daysOverdue = today.diff(lastTimesheetDate, 'days');
                    }
                    return daysOverdue;
                }
            },
            {
                key: 'Customer',
                label: 'Customer',
                sortable: true
            },
            {
                key: 'Ship_to_Address',
                label: 'Ship to Address',
                sortable: true
            },
            {
                key: 'Ship_to_City',
                label: 'Ship to City',
                sortable: true
            },
            {
                key: 'Ship_to_Zip',
                label: 'Ship to Zip',
                sortable: true
            },
            {
                key: 'Service_Type',
                label: 'Type',
                sortable: true
            },
            {
                key: 'Description1',
                label: 'Description1',
                sortable: true
            },
            {
                key: 'Starting_Date',
                label: 'Start Date',
                sortable: true,
                formatter: function(value){
                    return value ? Vue.moment(value).format('MMM D, YYYY') : '';
                }
            },
            {
                key: 'lasttimesheetdate',
                label: 'Last Time Sheet Date',
                sortable: true,
                formatter: function(value){
                    return value ? Vue.moment(value).format('MMM D, YYYY') : '';
                }
            },
            {
                key: 'WOstatus',
                label: 'Sent',
                sortable: true
            },
            {
                key: 'text',
                label: 'Notes',
                sortable: true
            },
            {
                key: 'technician_notes',
                label: 'Tech Notes',
                sortable: true
            },
            {
                key: 'datetime',
                label: 'Sent Date',
                sortable: true
            },
            {
                key: 'datetimenote',
                label: 'Last Note Date',
                sortable: true
            },
            {
                key: 'tech_note_updated_at',
                label: 'Tech Note Updated',
                sortable: true
            },
            {
                key: 'Make',
                label: 'Make',
                sortable: true
            },
            {
                key: 'Model',
                label: 'Model',
                sortable: true
            },
            {
                key: 'Serial',
                label: 'Equipment',
                sortable: true
            },
            {
                key: 'Fleet_Code',
                label: 'Fleet',
                sortable: true
            },
        ],
    };
}

function getComputed(){
    return {
        localWorkorders(){
            const vm = this;
            return JSON.parse(JSON.stringify(vm.workorders));
        },
        workorderTableColumns(){
            const vm = this;
            const columns = vm.defaultWorkorderTableColumns;
            if(vm.showMagnifierLinkColumn){
                columns.unshift({
                    key: 'magnifier_link',
                    label: ''
                });
            }
            return columns;
        }
    };
}

function created(){}

function getMethods(){
    return {
        emitError,
        updateWorkorderDispatchNotes,
        updateWorkorderTechNotes,
        workorderStatusChanged,
        getWorkorderRowClass,
        emitWorkorderNumber,
    };

    function emitError(error){
        const vm = this;
        vm.$emit('error', error);
    }

    function updateWorkorderDispatchNotes(workorder){
        const vm = this;
        vm.errorMessages = [];
        const payload = {
            'workorder_number': workorder.WO_Number,
            'note': workorder.text
        };

        if(workorder.text === ''){
            vm.$store.dispatch('mechanics/UPDATE_WORKORDER', payload)
                .catch(alertClearNoteError);

        } else {
            vm.$store.dispatch('mechanics/UPDATE_WORKORDER', payload)
                .then(uploadLocalWorkorders)
                .catch(alertNoteError);
        }

        function uploadLocalWorkorders(data){
            const localWorkorder = vm.localWorkorders.find(eachWorkorder => eachWorkorder.WO_Number === data.workorder);
            if(localWorkorder){
                localWorkorder.datetimenote = data.datetime;
            }
        }

        function alertClearNoteError(error){
            vm.emitError('Error Finding Equipment');
            vm.emitError(error);
        }

        function alertNoteError(error){
            vm.emitError('Error making note');
            vm.emitError(error);
        }
    }

    function updateWorkorderTechNotes(workorder){
        const vm = this;
        vm.errorMessages = [];
        const payload = {
            'workorder_number': workorder.WO_Number,
            'technician_notes': workorder.technician_notes
        };

        if(workorder.technician_notes === ''){
            vm.$store.dispatch('mechanics/UPDATE_WORKORDER', payload)
                .catch(alertClearNoteError);

        } else {
            vm.$store.dispatch('mechanics/UPDATE_WORKORDER', payload)
                .then(uploadLocalWorkorders)
                .catch(alertNoteError);
        }

        function uploadLocalWorkorders(data){
            const localWorkorder = vm.localWorkorders.find(eachWorkorder => eachWorkorder.WO_Number === data.workorder);
            if(localWorkorder){
                localWorkorder.technician_notes = data.technician_notes;
                localWorkorder.tech_note_updated_at = data.tech_note_updated_at;
            }
        }

        function alertClearNoteError(error){
            vm.emitError('Error Finding Equipment');
            vm.emitError(error);
        }

        function alertNoteError(error){
            vm.emitError('Error making note');
            vm.emitError(error);
        }
    }

    function workorderStatusChanged(workorder){
        const vm = this;
        vm.errorMessages = [];
        const payload = {
            'workorder_number': workorder.WO_Number,
            'status': workorder.WOstatus
        };

        if(workorder.WOstatus === ''){
            vm.$store.dispatch('mechanics/UPDATE_WORKORDER', payload)
                .catch(alertClearStatusError);

        } else {
            vm.$store.dispatch('mechanics/UPDATE_WORKORDER', payload)
                .then(updateLocalWorkorders)
                .catch(alertStatusError);
        }

        function updateLocalWorkorders(data){
            const localWorkorder = vm.localWorkorders.find(eachWorkorder => eachWorkorder.WO_Number === data.workorder);
            if(localWorkorder){
                localWorkorder.datetime = data.datetime;
            }
        }

        function alertClearStatusError(error){
            vm.emitError('Error Clearing Sent Status');
            vm.emitError(error);
        }

        function alertStatusError(error){
            vm.emitError('Error Setting Sent Status');
            vm.emitError(error);
        }
    }

    function getWorkorderRowClass(workorder){
        let workorderRowClass = '';
        if(workorder && workorder.lasttimesheetdate){
            const lastTimesheetDate = new Date(workorder.lasttimesheetdate);
            const twoWeeksAgo = new Date();
            twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
            const isTimesheetOlderThanTwoWeeks = lastTimesheetDate < twoWeeksAgo;
            workorderRowClass += isTimesheetOlderThanTwoWeeks ? 'time-warning' : '';
        }

        return workorderRowClass;
    }

    function emitWorkorderNumber(workorder){
        const vm = this;
        vm.$emit('magnifier-clicked', workorder.WO_Number);
    }
}
