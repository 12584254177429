export default {
    components: {},
    props: {
        crmCaseNotes: {
            type: Array,
            required: true
        }
    },
    data,
    computed: getComputed(),
    watch: getWatchers(),
    created,
    methods: getMethods()
};

function data(){
    return {
        tableColumns: [
            {
                key: 'search-icon',
                label: ''
            },
            {
                key: 'Entry_No',
                sortable: true
            },
            {
                key: 'Date',
                sortable: true
            },
            {
                key: 'Case_No',
                label: 'Case No.',
                sortable: true
            },
            {
                key: 'Company_No',
                label: 'CM#',
                sortable: true
            },
            {
                key: 'Name',
                sortable: true
            },
            {
                key: 'City',
                sortable: true
            },
            {
                key: 'Subject',
                sortable: true
            },
        ]
    };
}

function getComputed(){
    return {};
}

function getWatchers(){
    return {};
}

function created(){}

function getMethods(){
    return {
        searchCrmCaseNoteLines
    };

    function searchCrmCaseNoteLines(crmCaseNote){
        const vm = this;
        vm.$emit('search-crm-case-note-lines', crmCaseNote);
    }
}
