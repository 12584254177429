export default ScrollToPlugin();

function ScrollToPlugin(){
    return {
        install
    };

    function install(Vue, options){
        Vue.mixin({
            methods: {
                scrollTo: function(ref, options){
                    const vm = this;
                    const scrollOptions = Object.assign({
                        behavior: 'smooth',
                        block: 'start'
                    }, options);
                    Vue.nextTick(() => {
                        const element = vm.$refs[ref] && vm.$refs[ref].$el ? vm.$refs[ref].$el : vm.$refs[ref];
                        if(element){
                            element.scrollIntoView(scrollOptions);
                        }
                    });
                }
            }
        });
    }
}
