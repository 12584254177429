export default {
    components: {},
    props: {
        listSections: {
            type: Array,
            required: true
        }
    },
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {};
}

function getComputed(){
    return {};
}

function created(){}

function getMethods(){
    return {};
}
