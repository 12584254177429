export default {
    components: {},
    data,
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        directoryResources: [],
        isLoadingDirectoryResources: false,
        directoryColumns: [
            {
                key: 'Name',
                label: 'Employee Name',
                sortable: true,
            },
            {
                key: 'Extension',
                label: 'Extension',
                sortable: true,
            },
            {
                key: 'Responsibility_Center',
                label: 'Branch/Location',
                sortable: true,
            },
            {
                key: 'Job_Title',
                label: 'Department',
                sortable: true,
            },
            {
                key: 'Mobile',
                sortable: true,
            }
        ]
    };
}

function created(){
    const vm = this;
    vm.getDirectoryResources();
}

function getMethods(){
    return {
        displayApiError,
        getDirectoryResources
    };

    function displayApiError(error){
        const vm = this;
        if(error.appMessage){
            vm.errorMessages = [error.appMessage];
        }
    }

    function getDirectoryResources(){
        const vm = this;
        vm.isLoadingDirectoryResources = true;
        return Vue.appApi().authorized().account().getDirectoryResources().then(setDirectoryResources).catch(vm.displayApiError).finally(resetLoadingState);

        function setDirectoryResources({ data }){
            vm.directoryResources = data;
            vm.updatePaginationTotal(null, data.length);
        }
        function resetLoadingState(){
            vm.isLoadingDirectoryResources = false;
        }
    }
}
