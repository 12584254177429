export default {
    components: {},
    props: {},
    data,
    computed: getComputed(),
    watch: getWatches(),
    created,
    beforeRouteUpdate,
    methods: getMethods(),
};

function data(){
    const vm = this;
    return {
        errorMessages: [],
        branch: 'branch_all',
        posting_status: '*',
        owner_status: '*',
        rental_status: '*',
        equip_category: '*',
        equip_group: '*',
        isLoadingEquipment: false,
        tableColumns: [
            {
                key: 'fleet_code',
                label: 'Fleet Code',
                sortable: true,
            },
            {
                key: 'Manufacturer',
                label: 'Make',
                sortable: true,
            },
            {
                key: 'Equip_Model',
                label: 'Model',
                sortable: true,
            },
            {
                key: 'No_',
                label: 'Object No',
                sortable: true,
            },
            {
                key: 'Year',
                label: 'Year',
                sortable: true,
            },
            {
                key: 'Equip_Category',
                label: 'Category',
                sortable: true,
            },
            {
                key: 'Equip_Group',
                label: 'Group',
                sortable: true,
            },
            {
                key: 'rental_return',
                label: 'Location',
                sortable: true,
            },
            {
                key: 'rental_status',
                label: 'In/Out',
                sortable: true,
                formatter: vm.getRentalStatusText
            },
            {
                key: 'next_pm',
                label: 'Next PM',
                sortable: true,
            },
            {
                key: 'redtag',
                label: 'Red Tag?',
                sortable: true,
                formatter: (value) => value ? 'X' : ''
            },
            {
                key: 'workorder',
                label: 'Red WO#',
                sortable: true,
            },
            {
                key: 'description',
                label: 'Red Description',
                sortable: true,
            },
        ]
    };
}

function getComputed(){
    return {
        localEquipment(){
            const vm = this;
            return JSON.parse(JSON.stringify(vm.$store.state.rentalRedtag.equipment));
        }
    };
}

function getWatches(){}

function created(){
    const vm = this;
    vm.branch = vm.$route.params.branch;
    vm.posting_status = vm.$route.query.posting_status;
    vm.owner_status = vm.$route.query.owner_status;
    vm.rental_status = vm.$route.query.rental_status;
    vm.equip_category = vm.$route.query.equip_category;
    vm.equip_group = vm.$route.query.equip_group;
    vm.searchEquipment();
}

function beforeRouteUpdate(to, from, next){
    const vm = this;
    vm.branch = to.params.branch;
    vm.posting_status = to.query.posting_status;
    vm.owner_status = to.query.owner_status;
    vm.rental_status = to.query.rental_status;
    vm.equip_category = to.query.equip_category;
    vm.equip_group = to.query.equip_group;
    vm.searchEquipment();
    next();
}

function getMethods(){
    return {
        searchEquipment,
        displayErrorMessage,
        getRentalStatusText,
    };

    function searchEquipment(){
        const vm = this;
        const query = {
            branch: vm.branch === 'branch_all' ? '*' : vm.branch,
            posting_status: vm.posting_status,
            owner_status: vm.owner_status,
            rental_status: vm.rental_status,
            equip_category: vm.equip_category,
            equip_group: vm.equip_group,
        };
        vm.isLoadingEquipment = true;
        return vm.$store.dispatch('rentalRedtag/GET_REDTAG_EQUIPMENT', query)
            .catch(vm.displayErrorMessage)
            .finally(stopSpinner);

        function stopSpinner(){
            vm.isLoadingEquipment = false;
            vm.scrollTo('equipmentTable');
        }
    }

    function displayErrorMessage(error){
        const vm = this;
        if(error && error.message !== 'request cancelled'){
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function getRentalStatusText(rental_status){
        let statusText = '';
        if(rental_status === 1){
            statusText = 'EQin';
        } else if(rental_status === 2){
            statusText = 'EQout';
        }
        return statusText;
    }
}
