const state = {
    openDirectory: null
};

export default {
    namespaced: true,
    state,
    mutations: getMutations(),
    actions: getActions(),
    getters: getGetters(),
    modules: {}
};

function getActions(){

    return {
        SET_OPEN_DIRECTORY: setOpenDirectory,
    };
    function setOpenDirectory({ commit }, openDirectory){
        commit('OPEN_DIRECTORY_SET', openDirectory);
    }
}

function getGetters(){}

function getMutations(){

    return {
        OPEN_DIRECTORY_SET: openDirectorySet,
    };
    function openDirectorySet(state, openDirectory){
        openDirectory.directories.sort((a, b) => {
            if(a.name < b.name){
                return -1;
            }
            if(a.name > b.name){
                return 1;
            }
            return 0;
        });
        openDirectory.files.sort((a, b) => {
            if(a.name.toLowerCase() < b.name.toLowerCase()){
                return -1;
            }
            if(a.name.toLowerCase() > b.name.toLowerCase()){
                return 1;
            }
            return 0;
        });
        state.openDirectory = openDirectory;
    }
}
