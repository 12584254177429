import fileDownload from 'js-file-download';
import MechanicPmsTable from '../../../components/mechanic-pms-table/mechanic-pms-table';
import PmMetrics from '../pm-metrics/pm-metrics';

export default {
    components: {
        MechanicPmsTable,
        PmMetrics
    },
    data,
    computed: getComputed(),
    watch: getWatches(),
    created,
    beforeRouteUpdate,
    methods: getMethods(),
};

function data(){
    const vm = this;
    return {
        localPms: [],
        isLoadingPMs: false,
        isLoadingPmComments: false,
        isDownloadingSpreadsheet: false,
        errorMessages: [],
        mechanicId: vm.$route.params.mechanicId,
    };
}

function getComputed(){}

function getWatches(){}

function created(){
    const vm = this;
    vm.searchPMs();
}

function beforeRouteUpdate(to, from, next){
    const vm = this;
    vm.mechanicId = to.params.mechanicId;
    vm.searchPMs();
    next();
}

function getMethods(){
    return {
        searchPMs,
        displayErrorMessage,
        exportResultsToExcel
    };
    function searchPMs(){
        const vm = this;
        const payload = {
            mechanic_id: vm.mechanicId,
        };
        vm.isLoadingPMs = true;
        vm.$store.dispatch('mechanics/GET_ALL_PMS', payload).then(setLocalPms).catch(vm.displayErrorMessage).finally(stopSpinner);

        function setLocalPms(data){
            vm.localPms = data.data;
            vm.isLoadingPMs = false;
        }

        function stopSpinner(){
            vm.isLoadingPMs = false;
        }
    }

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function exportResultsToExcel(){
        const vm = this;
        vm.isDownloadingSpreadsheet = true;
        const payload = {
            mechanicId: vm.mechanicId
        };

        return Vue.appApi().authorized().mechanics(vm.mechanicId).exportServicePms(payload).then(handleResult).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function handleResult(response){
            fileDownload(response.data, `Mechanic_Pms_${vm.mechanicId}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        }

        function resetLoadingState(){
            vm.isDownloadingSpreadsheet = false;
        }
    }
}
