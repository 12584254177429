export default {
    components: {},
    mixins: [],
    props: {},
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        isLoadingAccountInvites: false,
        accountInvites: [],
        tableColumns: [
            {
                key: 'email',
                label: 'Email',
                sortable: true,
            },
            {
                key: 'all_role_names',
                label: 'Roles',
                formatter: (roleNamesArray) => {
                    return roleNamesArray.join(', ');
                }
            },
            {
                key: 'updated_at',
                label: 'Invite Date'
            },
            {
                label: 'Actions',
                key: 'actions'
            }
        ],
    };
}

function getComputed(){
    return {};
}

function created(){
    const vm = this;
    vm.getAccountInvites();
}

function getMethods(){
    return {
        displayErrorMessage,
        getAccountInvites,
        resendInvite,
        addInviteToList,
        initializeDisplayProperties,
        deleteAccountInvite,
    };

    function displayErrorMessage(error){
        const vm = this;
        vm.$emit('error', error);
    }

    function getAccountInvites(){
        const vm = this;
        vm.isLoadingAccountInvites = true;
        return Vue.appApi().authorized().account().accountUsers().listInvites().then(setAccountInvites).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function setAccountInvites({ data }){
            vm.accountInvites = data.map(vm.initializeDisplayProperties);
        }
        function resetLoadingState(){
            vm.isLoadingAccountInvites = false;
        }
    }

    function resendInvite(accountInvite){
        const vm = this;
        accountInvite.isResendingInvite = true;
        return Vue.appApi().authorized().account().accountUsers().resendAccountInvite(accountInvite.id).then(updateAccountInviteList).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function updateAccountInviteList({ data: accountInvite }){
            vm.initializeDisplayProperties(accountInvite);
            const accountInviteIndex = vm.accountInvites.findIndex(({ id }) => id === accountInvite.id);
            vm.accountInvites.splice(accountInviteIndex, 1, accountInvite);
        }
        function resetLoadingState(){
            accountInvite.isResendingInvite = false;
        }
    }

    function addInviteToList(accountInvite){
        const vm = this;
        vm.initializeDisplayProperties(accountInvite);
        vm.accountInvites.push(accountInvite);
    }

    function initializeDisplayProperties(accountInvite){
        accountInvite.isResendingInvite = false;
        accountInvite.isDeletingInvite = false;
        accountInvite.isConfirmingDelete = false;
        return accountInvite;
    }

    function deleteAccountInvite(accountInvite){
        const vm = this;
        accountInvite.isDeletingInvite = true;
        return Vue.appApi().authorized().account().accountUsers().deleteAccountInvite(accountInvite.id).then(removeFromList).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function removeFromList(){
            const accountInviteIndex = vm.accountInvites.findIndex(({ id }) => id === accountInvite.id);
            vm.accountInvites.splice(accountInviteIndex, 1);
        }
        function resetLoadingState(){
            accountInvite.isDeletingInvite = false;
        }
    }
}
