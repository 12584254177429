import { render, staticRenderFns } from "./road.vue?vue&type=template&id=48976142&scoped=true&"
import script from "./road.controller.js?vue&type=script&lang=js&"
export * from "./road.controller.js?vue&type=script&lang=js&"
import style0 from "./_road.scss?vue&type=style&index=0&id=48976142&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48976142",
  null
  
)

export default component.exports