export default {
    components: {},
    props: {
        crmNotes: {
            type: Array,
            required: true
        },
        title: {
            type: String,
            default: ''
        }
    },
    data,
    computed: getComputed(),
    watch: getWatchers(),
    created,
    methods: getMethods()
};

function data(){
    return {
        tableColumns: [
            {
                key: 'search-icon',
                label: ''
            },
            {
                key: 'Date',
                sortable: true
            },
            {
                key: 'Company_No',
                label: 'CM#',
                sortable: true
            },
            {
                key: 'Name',
                sortable: true
            },
            {
                key: 'City',
                sortable: true
            },
            {
                key: 'Subject',
                sortable: true
            },
        ]
    };
}

function getComputed(){
    return {};
}

function getWatchers(){
    return {};
}

function created(){}

function getMethods(){
    return {
        searchCrmNoteLines
    };

    function searchCrmNoteLines(crmNote){
        const vm = this;
        vm.$emit('search-crm-note-lines', crmNote);
    }
}
