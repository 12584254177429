export default {
    components: {},
    props: {},
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        validationErrors: {},
        isLoadingPartsWorkOrderLines: false,
        partsWorkOrderLines: null,
        marginQuery: {
            Doc_Type: 1,
            Posting_Status: '*',
            Type: '*',
            Claim: '*',
            Waiting_parts: '*',
            Qty_to_use: {
                query_type: '*',
                value: ''
            },
            Qty_to_invoice: {
                query_type: '*',
                value: ''
            },
            Qty_invoiced: {
                query_type: '*',
                value: ''
            },
            Qty_available: {
                query_type: '*',
                value: ''
            },
            Resp_center: {
                query_type: '*',
                value: ''
            }
        },
        queryOptions: {
            standardQueryTypes: ['*', '>', '<', '=', '!='],
            Doc_Type: [{ display: 'ORDER', value: 1 }],
            Posting_Status: [
                { display: '*', value: '*' },
                { display: 'OPEN', value: 0 },
                { display: 'CLOSED', value: 2 },
            ],
            Type: [
                { display: '*', value: '*' },
                { display: 'ITEM', value: 1 },
                { display: 'RESOURCE', value: 2 },
                { display: 'CHARGE', value: 3 },
                { display: 'JOB CODE', value: 5 },
                { display: 'PURCHASE', value: 6 },
            ],
            Claim: [
                { display: '*', value: '*' },
                { display: 'NO', value: 0 },
                { display: 'YES', value: 1 },
            ],
            Waiting_parts: [
                { display: '*', value: '*' },
                { display: 'NO', value: 0 },
                { display: 'YES', value: 1 },
            ]
        },
        tableColumns: [
            {
                key: 'Doc_No',
                sortable: true,
            },
            {
                key: 'Type',
                sortable: true,
            },
            {
                key: 'Line_No',
                sortable: true,
                label: 'Line No.'
            },
            {
                key: 'Description',
                sortable: true,
            },
            {
                key: 'Qty_to_use',
                sortable: true,
            },
            {
                key: 'Qty_to_invoice',
                sortable: true,
            },
            {
                key: 'Qty_invoiced',
                sortable: true,
            },
            {
                key: 'Qty_available',
                sortable: true,
            },
            {
                key: 'Posting_Status',
                sortable: true,
                label: 'Posting'
            },
            {
                key: 'Claim',
                sortable: true,
            },
            {
                key: 'Location',
                sortable: true,
            },
            {
                key: 'Service_Type',
                sortable: true,
                label: 'Service Type'
            },
            {
                key: 'Unit_price',
                sortable: true,
                label: 'Unit Price'
            },
            {
                key: 'Unit_cost',
                sortable: true,
                label: 'Unit Cost'
            },
            {
                key: 'parts_margin_diff',
                sortable: true,
                tdClass: 'font-weight-bold text-dark'
            },
            {
                key: 'parts_margin_percent_diff',
                sortable: true,
                label: 'Margin %',
                tdClass: 'font-weight-bold text-dark'
            },
            {
                key: 'Line_discount',
                sortable: true,
                label: 'Line Discount'
            },
            {
                key: 'Resp_center',
                sortable: true,
                label: 'Resp Center'
            },
            {
                key: 'Customer',
                sortable: true,
            },
            {
                key: 'Billto_customer',
                sortable: true,
                label: 'Billto cust'
            },
            {
                key: 'WO_quote_order_line',
                sortable: true,
                label: 'WO Quote order line'
            },
            {
                key: 'Waiting_parts',
                sortable: true,
                label: 'Waiting Parts'
            },
        ]
    };
}

function getComputed(){
    return {};
}

function created(){}

function getMethods(){
    return {
        displayErrorMessage,
        searchPartsWorkOrderLines,
    };

    function displayErrorMessage(error){
        const vm = this;
        if(error){
            const isValidationError = error.status === 422 && error.data.errors;
            if(isValidationError){
                vm.validationErrors = error.data.errors;
                vm.errorMessages = ['Validation Error. Check inputs and try again.'];
            } else {
                const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
                vm.errorMessages.push(errorMessage);
            }
        }
    }

    function searchPartsWorkOrderLines(){
        const vm = this;
        vm.isLoadingPartsWorkOrderLines = true;
        vm.errorMessages = [];
        vm.validationErrors = {};
        return Vue.appApi().authorized().workorders().orderLines().searchPartsWorkOrderLines(vm.marginQuery).then(setPartsWorkOrderLines).catch(vm.displayErrorMessage).finally(resetLoadingState);

        function setPartsWorkOrderLines(response){
            vm.partsWorkOrderLines = response.data;
        }
        function resetLoadingState(){
            vm.isLoadingPartsWorkOrderLines = false;
            vm.scrollTo('workorderLinesTable');
        }
    }
}
