import EquipmentView from './equipment-view/equipment-view';
import CustomerView from './customer-view/customer-view';
import TerritoryView from './territory-view/territory-view';
import Datepicker from 'vuejs-datepicker';

export default {
    components: {
        EquipmentView,
        CustomerView,
        TerritoryView,
        Datepicker,
    },
    props: {},
    data,
    computed: getComputed(),
    created,
    beforeRouteUpdate,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        validationErrors: {},
        queryTypeOptions: [
            { value: 'equipment', text: 'Equipment' },
            { value: 'customer', text: 'Customer' },
            { value: 'territory', text: 'Territory' },
        ],
        submittedQuery: null,
        fleetReportQuery: {
            query_type: 'equipment',
            start_date: '',
            end_date: '',
            equipment: 'SERVICE_REPAIR_SEED',
            customer: '',
            salesperson_code: '',
            county: '',
        },
        view: '',
        equipmentAutocompleteCancelToken: null,
        isEquipmentAutocompleteDisabled: false,
        equipmentAutocompleteSource: [],
        isSearchingEquipmentOptions: false,
        customerAutocompleteCancelToken: null,
        isCustomerAutocompleteDisabled: false,
        customerAutocompleteSource: [],
        isSearchingCustomerOptions: false,
    };
}

function getComputed(){
    return {
        equipmentAutocompleteOptions(){
            const vm = this;
            return vm.equipmentAutocompleteSource.map(createDisplayedOptions);
            function createDisplayedOptions(equipment){
                return {
                    text: `${equipment.No_}, ${equipment.fleet_code}, ${equipment.Equip_Serial}, ${equipment.Manufacturer}, ${equipment.Equip_Model}`,
                    value: equipment.No_
                };
            }
        },
        customerAutocompleteOptions(){
            const vm = this;
            return vm.customerAutocompleteSource.map(createDisplayedOptions);
            function createDisplayedOptions(customer){
                return {
                    text: `${customer.No}, ${customer.Name}, ${customer.City}`,
                    value: customer.No
                };
            }
        }
    };
}

function created(){
    const vm = this;
    vm.loadFromQuery(vm.$route.query);
}

function beforeRouteUpdate(to, from, next){
    const vm = this;
    vm.loadFromQuery(to.query);
    next();
}

function getMethods(){
    return {
        displayErrorMessage,
        setQueryDefaults,
        submitQuery,
        loadFromQuery,
        autocompleteEquipmentSearch,
        cancelEquipmentAutocomplete,
        autocompleteCustomerSearch,
        cancelCustomerAutocomplete,
        setEquipmentQuery,
        setCustomerQuery,
    };

    function displayErrorMessage(error){
        const vm = this;
        const isValidationError = error && error.status === 422 && error.data.errors;
        if(isValidationError){
            vm.validationErrors = error.data.errors;
        } else {
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function setQueryDefaults(){
        const vm = this;
        Object.keys(vm.fleetReportQuery).forEach(resetQueryParameter);
        vm.validationErrors = {};

        function resetQueryParameter(key){
            if(key !== 'query_type'){
                vm.fleetReportQuery[key] = '';
            }
        }
    }

    function submitQuery(){
        const vm = this;
        vm.view = vm.fleetReportQuery.query_type;
        const query = JSON.parse(JSON.stringify(vm.$route.query));
        Object.keys(vm.fleetReportQuery).forEach((key) => {
            const isDateKey = ['start_date', 'end_date'].includes(key);
            const queryParam = vm.fleetReportQuery[key];
            if(isDateKey){
                query[key] = Vue.moment(queryParam).toISOString();
            } else {
                query[key] = queryParam;
            }
        });
        query.display_type = vm.fleetReportQuery.query_type;
        vm.$router.push({ name: vm.$route.name, params: vm.$route.params, query });
    }

    function loadFromQuery(query){
        const vm = this;
        vm.validationErrors = {};
        vm.errorMessages = [];
        Object.keys(vm.fleetReportQuery).forEach((key) => {
            const isDateKey = ['start_date', 'end_date'].includes(key);
            const queryParam = query[key] || '';
            if(isDateKey){
                vm.fleetReportQuery[key] = queryParam ? Vue.moment(queryParam).toISOString() : '';
            } else {
                vm.fleetReportQuery[key] = queryParam;
            }
        });
        vm.view = query.display_type;
        if(!vm.fleetReportQuery.query_type){
            vm.fleetReportQuery.query_type = 'equipment';
        }
        if(vm.view){
            vm.submittedQuery = JSON.parse(JSON.stringify(vm.fleetReportQuery));
        }
    }

    function autocompleteEquipmentSearch(value){
        const vm = this;
        vm.fleetReportQuery.equipment = value.toUpperCase();
        if(vm.fleetReportQuery.equipment.length < 4){
            return vm.cancelEquipmentAutocomplete();
        }
        vm.isSearchingEquipmentOptions = true;
        if(vm.equipmentAutocompleteCancelToken){
            vm.equipmentAutocompleteCancelToken.cancel('request cancelled');
        }
        const cancelToken = window.axios.CancelToken.source();
        vm.equipmentAutocompleteCancelToken = cancelToken;
        return Vue.appApi().authorized().equipment().autocompleteEquipmentSearch({ search_string: vm.fleetReportQuery.equipment }, cancelToken.token).then(displaySearchResults).catch(handleError);

        function displaySearchResults(response){
            vm.equipmentAutocompleteCancelToken = null;
            vm.equipmentAutocompleteSource = response.data;
            vm.isSearchingEquipmentOptions = false;
        }
        function handleError(error){
            if(error.message !== 'request cancelled'){
                vm.isSearchingEquipmentOptions = false;
                vm.displayErrorMessage(error);
            }
        }
    }

    function cancelEquipmentAutocomplete(){
        const vm = this;
        if(vm.equipmentAutocompleteCancelToken){
            vm.equipmentAutocompleteCancelToken.cancel('request cancelled');
        }
        vm.equipmentAutocompleteSource = [];
        vm.isSearchingEquipmentOptions = false;
    }

    function autocompleteCustomerSearch(value){
        const vm = this;
        vm.fleetReportQuery.customer = value;
        if(vm.fleetReportQuery.customer.length < 3){
            return vm.cancelCustomerAutocomplete();
        }
        vm.isSearchingCustomerOptions = true;
        if(vm.customerAutocompleteCancelToken){
            vm.customerAutocompleteCancelToken.cancel('request cancelled');
        }
        const cancelToken = window.axios.CancelToken.source();
        vm.customerAutocompleteCancelToken = cancelToken;
        return Vue.appApi().authorized().equipment().autocompleteCustomerSearch({ search_string: vm.fleetReportQuery.customer }, cancelToken.token).then(displaySearchResults).catch(handleError);

        function displaySearchResults(response){
            vm.customerAutocompleteCancelToken = null;
            vm.customerAutocompleteSource = response.data;
            vm.isSearchingCustomerOptions = false;
        }
        function handleError(error){
            if(error.message !== 'request cancelled'){
                vm.isSearchingCustomerOptions = false;
                vm.displayErrorMessage(error);
            }
        }
    }

    function cancelCustomerAutocomplete(){
        const vm = this;
        if(vm.customerAutocompleteCancelToken){
            vm.customerAutocompleteCancelToken.cancel('request cancelled');
        }
        vm.customerAutocompleteSource = [];
        vm.isSearchingCustomerOptions = false;
    }

    function setEquipmentQuery(value){
        const vm = this;
        vm.fleetReportQuery.equipment = value;
        Vue.nextTick(() => {
            vm.cancelEquipmentAutocomplete();
        });
    }

    function setCustomerQuery(value){
        const vm = this;
        vm.fleetReportQuery.customer = value;
        Vue.nextTick(() => {
            vm.cancelCustomerAutocomplete();
        });
    }
}
