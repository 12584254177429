import { render, staticRenderFns } from "./customer-view.vue?vue&type=template&id=bbb17c88&scoped=true&"
import script from "./customer-view.controller.js?vue&type=script&lang=js&"
export * from "./customer-view.controller.js?vue&type=script&lang=js&"
import style0 from "./_customer-view.scss?vue&type=style&index=0&id=bbb17c88&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbb17c88",
  null
  
)

export default component.exports