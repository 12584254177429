import { render, staticRenderFns } from "./workorder-pms-table.vue?vue&type=template&id=8d97bf38&scoped=true&"
import script from "./workorder-pms-table.controller.js?vue&type=script&lang=js&"
export * from "./workorder-pms-table.controller.js?vue&type=script&lang=js&"
import style0 from "./_workorder-pms-table.scss?vue&type=style&index=0&id=8d97bf38&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d97bf38",
  null
  
)

export default component.exports